import { transformPickerDateToISO } from 'utils/commonFunctions';
const useCodePreview = (
  exportType: string,
  capturePosthogEvent: () => void,
  from?: Date | null,
  to?: Date | null,
  fromPeriodType?: string,
  fromPeriod?: string
) => {
  const createRequestFromValue = () => {
    if (exportType === 'range') {
      return `${fromPeriod}${fromPeriodType}`;
    } else if (exportType === 'dates' && from) {
      return transformPickerDateToISO(from, 'start');
    } else {
      return 'all';
    }
  };

  const createRequestToValue = () =>
    exportType === 'dates' && to ? transformPickerDateToISO(to, 'end') : '';

  const downloadFileContent = (fileContent: string, fileType: 'csv' | 'json' | 'python') => {
    const now = new Date();
    const padZero = (num: number) => num.toString().padStart(2, '0');

    const fileName = `GX-Data_${now.getFullYear().toString().slice(-2)}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}_${padZero(now.getHours())}${padZero(now.getMinutes())}${padZero(now.getSeconds())}`;
    const defineType = () => {
      switch (fileType) {
        case 'csv':
          return 'text/csv;charset=utf-8;';
        case 'json':
          return 'application/json;charset=utf-8;';
        case 'python':
          return 'text/plain;charset=utf-8;';
      }
    };
    const blob = new Blob([fileContent], {
      type: defineType()
    });

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.${fileType === 'python' ? 'py' : fileType}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    capturePosthogEvent();
  };

  return {
    createRequestToValue,
    createRequestFromValue,
    downloadFileContent
  };
};

export default useCodePreview;
