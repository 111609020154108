export type FactsheetSymbol = {
  timeRef: string;
  periodType: string;
  period: string;
  alias?: string;
  timeRefDetail: string;
};
export type VersionHistory = {
  factsheetVersion?: string;
  qualitySpecification?: string;
  criteriaForInclusion?: string;
  calculationApproach?: string;
  flowcharts?: string[];
  factsheetValidFrom?: string;
  factsheetValidTo?: string;
};

export type RelatedCode = {
  title: string;
  code: string;
};

export type FactsheetDetails = {
  code: string;
  title?: string;
  description?: string;
  latestFactsheetVersion: string;
  factsheetVersionHistory: VersionHistory[];
  indexDetails: Record<string, string | boolean | string[]>;
  mapLocation?: string;
  priorityToTransactions?: string;
  soleSourcedDataAccepted?: string;
  interAffiliateDataAccepted?: string;
  symbols: FactsheetSymbol[];
  parentCodes: RelatedCode[];
  childCodes: RelatedCode[];
  spotSymbol?: FactsheetSymbol;
};

export const FACTSHEETS_ENDPOINT = import.meta.env.VITE_GX_FACTSHEETS_ENDPOINT;
export const FACTSHEET_PARAM_NAME = 'factsheetKey';
export const FACTSHEET_TABS_PARAM_NAME = 'additionalTabs';
