import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';
import useCodePreview from 'pages/DataHub/Export/ExportPreview/useCodePreview';

const DEFAULT_GROUP_NAME = 'Prod_Indexes';
const usePythonPreview = (
  exportType: string,
  includeMetadata: boolean,
  capturePosthogEvent: () => void,
  from?: Date | null,
  to?: Date | null,
  fromPeriodType?: string,
  fromPeriod?: string
) => {
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const dataHubSymbols = dataHubs.find(dataHub => dataHub.id === activeDataHubId)?.symbols || [];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [fileContent, setFileContent] = useState<string>('');

  const { userConfig } = useSelector((state: RootState) => state.userConfig);
  const userTimezone = userConfig.timezone.value;
  const { createRequestToValue, createRequestFromValue, downloadFileContent } = useCodePreview(
    exportType,
    capturePosthogEvent,
    from,
    to,
    fromPeriodType,
    fromPeriod
  );

  const downloadFile = () => downloadFileContent(fileContent, 'python');

  useEffect(() => {
    getDataHubIndexes();
  }, [dataHubSymbols, from, to, includeMetadata, fromPeriodType, fromPeriod, exportType]);

  const getDataHubIndexes = async () => {
    setLoading(true);
    setError(false);

    try {
      const results = await fetch('/export/examples/python_export_script_template.txt');
      const pythonScriptTemplate = await results.text();
      const toValue = createRequestToValue();
      const fromValue = createRequestFromValue();
      // Define placeholder replacements
      const replacements = {
        symbols: JSON.stringify(transformSymbols(dataHubSymbols), undefined, 4),
        rangeStart: fromValue,
        rangeEnd: toValue,
        timezone: userTimezone,
        withMetadata: includeMetadata.toString()
      };

      const pythonScript = replacePlaceholders(pythonScriptTemplate, replacements);
      setFileContent(pythonScript);
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  // Function to transform the array into the desired JSON format
  function transformSymbols(selectedSymbols: SelectedSymbol[]) {
    return {
      symbols: selectedSymbols.map(symbol => ({
        group: DEFAULT_GROUP_NAME,
        key: {
          code: symbol.Code,
          periodType: symbol.PeriodType,
          timeRef: symbol.TimeRef,
          period: symbol.Period
        }
      }))
    };
  }

  /**
   * Replace placeholders in a template string with provided values.
   * @param template The template string containing placeholders (e.g., {startFrom}).
   * @param replacements A map of placeholder keys and their replacement values.
   * @returns The processed string with placeholders replaced.
   */
  function replacePlaceholders(template: string, replacements: Record<string, string>): string {
    return template.replace(/\{(\w+)}/g, (match, key) => {
      return key in replacements ? replacements[key] : match; // Replace only if the key exists
    });
  }

  return { loading, error, fileContent, downloadFile };
};

export default usePythonPreview;
