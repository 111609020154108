import { Cell, flexRender } from '@tanstack/react-table';
import DateText from 'components/DateText';
import { DateTextVariants } from 'components/DateText/useDateText';
import EllipsisWithTooltip from 'components/EllipsisWithTooltip';
import { CatalogItem } from 'pages/Catalog/constants';

const useCatalogTableCell = (columnId: string, cell: Cell<CatalogItem, unknown>) => {
  const renderContent = () => {
    const cellValue = cell.getValue() as string;
    switch (columnId) {
      case 'Title':
        return <EllipsisWithTooltip text={cellValue} />;
      case 'IndexModule':
        return (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {cellValue.split(',').join(', ')}
          </div>
        );
      case 'LastDate':
      case 'StartDate':
      case 'EndDate':
        return (
          <DateText
            dateString={cellValue}
            variant={DateTextVariants.FULL}
          />
        );
      default:
        return flexRender(cell.column.columnDef.cell, cell.getContext());
    }
  };

  const defaultStyle = {
    display: 'flex',
    alignItems: 'center',
    width: cell.column.getSize(),
    padding: '1px 8px',
    fontSize: '14px',
    justifyContent: 'flex-start',
    color: 'inherit'
  };
  return { renderContent, defaultStyle };
};

export default useCatalogTableCell;
