import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DataHubState = {
  code: string;
  open: boolean;
};

const initialState: DataHubState = {
  code: '',
  open: false
};

const factsheetDialogSlice = createSlice({
  name: 'factsheetDialog',
  initialState,
  reducers: {
    openFactsheet(state, action: PayloadAction<string>) {
      state.code = action.payload;
      state.open = true;
    },
    closeFactsheet(state) {
      state.code = '';
      state.open = false;
    }
  }
});

export const { openFactsheet, closeFactsheet } = factsheetDialogSlice.actions;

export default factsheetDialogSlice.reducer;
