import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { RootState } from '../../store';
import MarketsSkeleton from './MarketsSkeleton';
import MarketsComponents from './MarketsComponents';
import useMarkets from './useMarkets';
import { useAppSelector } from 'hooks/redux-hooks';
import { selectMarketsStatus } from 'slices/marketsSlice';
import MainLayout from 'layouts/MainLayout';
import Tabs from 'components/Tabs';
import ShareButton from 'pages/Markets/ShareButton';

const Markets = () => {
  const navigate = useNavigate();
  const params = useParams();
  const status = useAppSelector(selectMarketsStatus);
  const { markets } = useSelector((state: RootState) => state.markets);
  const { activeTab, setActiveTab } = useMarkets();

  const tabNameExists = (tabName: string) => {
    // check if the tabname exists
    return markets?.pages.some(({ name }) => getTabUrl(name) === tabName);
  };

  const getActiveTabIdx = (tabName: string) => {
    // find index of the tabname
    const idx = markets?.pages.findIndex(({ name }) => getTabUrl(name) === tabName) || 0;
    return idx > -1 ? idx : 0;
  };

  const getTabUrl = (tabName: string) => {
    // remove all non-alphanumeric characters
    return tabName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  };

  const onChange = (index: number) => {
    setActiveTab(index);
    const tabName = getTabUrl(markets?.pages[index]?.name || '');
    navigate(`/markets/${params.marketId}/${tabName}`);
  };

  useEffect(() => {
    let tabName = getTabUrl(params?.tabId || markets?.pages[0]?.name || '');

    if (markets?.pages[0]?.name && !tabNameExists(tabName)) {
      tabName = getTabUrl(markets?.pages[0]?.name);
    }

    setActiveTab(getActiveTabIdx(tabName));
    if (params?.marketId) navigate(`/markets/${params.marketId}/${tabName}`);

    if (markets?.metadata?.displayName) {
      document.title = 'GX Go | ' + markets.metadata.displayName;
    }
    return () => {
      document.title = 'GX Go';
    };
  }, [markets]);

  return (
    <MainLayout>
      {markets?.pages && status !== 'cancelled' && status !== 'loading' && status !== 'idle' ? (
        <>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant='h2'
                sx={{ mr: 6 }}
              >
                {markets?.metadata?.displayName}
              </Typography>
              <Tabs
                mainPageTitle={markets?.metadata?.displayName}
                tabs={markets?.pages.map(({ name }) => ({ name }))}
                activeTab={markets?.pages.length <= activeTab ? 0 : activeTab}
                onChange={onChange}
              />
            </Box>
            <ShareButton
              title={markets?.metadata?.displayName}
              additionalTitle={markets?.pages[activeTab]?.name}
            />
          </Box>
          <MarketsComponents
            marketTitle={markets?.metadata?.displayName}
            pageTitle={markets?.pages[activeTab]?.name}
            components={markets?.pages[activeTab]?.components}
          />
        </>
      ) : (
        <MarketsSkeleton />
      )}
    </MainLayout>
  );
};

export default Markets;
