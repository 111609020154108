import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useDataHub from './useDataHub';
import DataHubSummary from './Summary';
import ActionBar from './ActionBar';
import DataHubPreview from './Preview';
import DataHubChart from './Chart/DataHubChart';
import DataHubExport from './Export';
import MainLayout from 'layouts/MainLayout';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';
import { RootState } from 'store';
import EditableName from 'pages/DataHub/EditableName';
import { setActiveDataHubId } from 'slices/dataHubSlice';
import { useAppDispatch } from 'hooks/redux-hooks';

const DataHub = () => {
  const { activeTab, setActiveTab, isDataHubEmpty } = useDataHub();
  const { loading, dhData } = useSelector((state: RootState) => state.dhData);
  const { dataHubs, activeDataHubId, status } = useSelector((state: RootState) => state.dataHub);

  const activeDataHub = dataHubs.find(dataHub => dataHub.id === activeDataHubId);
  const { name = '' } = activeDataHub ?? {};
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const paths = location.pathname.split('/');

    if (paths.length === 3) {
      dataHubTabs.forEach(({ name }, index) => {
        if (paths[2].toLocaleLowerCase().includes(name.toLocaleLowerCase())) {
          setActiveTab(index);
        }
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!activeDataHub && dataHubs.length) {
      dispatch(setActiveDataHubId(dataHubs[0]?.id));
    }
  }, [dataHubs]);

  const isDataHubLoading = status === 'loading';

  const disabled = loading || isDataHubLoading;

  const dataHubTabs = [
    {
      name: 'Summary',
      disabled
    },
    {
      name: 'Preview',
      disabled
    },
    {
      name: 'Chart',
      disabled
    },
    {
      name: 'Export',
      disabled
    }
  ];

  const returnTabPanelContent = () => {
    switch (activeTab) {
      case 1:
        return <DataHubPreview />;
      case 2:
        return (
          <DataHubChart
            mainPageTitle={'Data Hub'}
            tabName={'Chart'}
          />
        );
      case 3:
        return <DataHubExport />;
      default:
        return (
          <DataHubSummary
            setActiveTab={setActiveTab}
            isDataHubEmpty={isDataHubEmpty}
          />
        );
    }
  };

  useEffect(() => {
    document.title = 'GX Go | Data Hub';
    return () => {
      document.title = 'GX Go';
    };
  }, []);

  useEffect(() => {
    if (!dhData.length) setActiveTab(0);
  }, [dhData]);

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 3 }}>
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end' }}>
          <Typography
            variant='h2'
            sx={{ mr: 3 }}
            noWrap
          >
            Data Hub
          </Typography>
          {name && <EditableName />}
        </Box>
        <Tabs
          mainPageTitle='Data Hub'
          tabs={dataHubTabs}
          minTabWidth={145}
          activeTab={activeTab}
          onChange={setActiveTab}
        />
      </Box>
      <ActionBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {dataHubTabs.map(({ name }, index) => (
        <TabPanel
          key={name}
          index={index}
          value={activeTab}
        >
          {returnTabPanelContent()}
        </TabPanel>
      ))}
    </MainLayout>
  );
};

export default DataHub;
