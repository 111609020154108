import { Box, Card, CardContent, Typography } from '@mui/material';
import DownArrowIcon from 'assets/images/downRedCrackedArrow.svg';
import UpArrowIcon from 'assets/images/upGreenCrackedArrow.svg';
import { TileType } from 'pages/Markets/MarketsComponents/Tiles/types';
import { getDiffColor } from 'utils/commonFunctions';

const Tile = ({ tile }: { tile: TileType }) => {
  const { code, shortTitle, value, delta, deltaPercentage } = tile;

  const priceColor = getDiffColor(Number(delta));

  return (
    <Card
      sx={{
        display: 'flex',
        width: 'calc(25% - 18px)',
        minWidth: 230,
        borderLeft: `4px solid`,
        borderColor: priceColor,
        lineHeight: 1
      }}
    >
      <CardContent
        sx={{
          width: '100%',
          p: '6px 8px 6px 4px',
          '&:last-child': {
            pb: '2px'
          }
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            component='div'
            sx={{ fontSize: '24px', fontWeight: 600, lineHeight: '1' }}
            noWrap
          >
            {code || 'GXCODE'}
          </Typography>
          <Typography
            component='div'
            sx={{ fontSize: '24px', fontWeight: 600, lineHeight: '1' }}
          >
            {value}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            component='div'
            noWrap
            sx={{ fontSize: '14px', pr: 1 }}
          >
            {shortTitle}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap'
            }}
          >
            {!!delta && Number(delta) !== 0 && (
              <Box
                sx={{ fontSize: 12, marginRight: 1 }}
                component='img'
                src={String(delta).startsWith('-') ? DownArrowIcon : UpArrowIcon}
                alt={String(delta).startsWith('-') ? 'Down' : 'Up'}
              />
            )}
            <Typography>{delta}&nbsp;</Typography>
            <Typography>({deltaPercentage}%)</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Tile;
