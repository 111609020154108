import { Box, Button, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import StepButtons from 'pages/Onboarding/OnboardingForm/Steps/StepButtons';

const Usage = ({
  usage: usage,
  setUsage: setUsage,
  activeStep,
  setActiveStep,
  handleSubmit
}: {
  usage: string;
  setUsage: Dispatch<SetStateAction<string>>;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  handleSubmit: () => void;
}) => {
  const usageIds = [
    'Trading',
    'Analysis',
    'Risk Reporting',
    'Audit & Compliance',
    'P&L Reporting',
    'Other',
    'Not sure yet'
  ];
  return (
    <>
      <Typography sx={{ fontSize: 24, fontWeight: 'bold', mb: 1.5 }}>
        How are you looking to use our data?
      </Typography>
      <List
        aria-label='market-selectlist'
        sx={{
          maxHeight: '60vh',
          overflowY: 'scroll',
          pr: 2,
          '&::-webkit-scrollbar': {
            width: '8px'
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '12px',
            backgroundColor: '#6e729c',
            backgroundClip: 'content-box',
            height: ' 70px'
          }
        }}
      >
        {usageIds.map((usageId, index) => {
          return (
            <ListItemButton
              key={usageId + index}
              sx={{
                backgroundColor: 'rgba(248, 248, 248, 0.20)',
                mb: 2,
                '&.Mui-selected': {
                  border: '2px solid #E84E0F'
                }
              }}
              selected={usage === usageId}
              onClick={() => {
                setUsage(usageId);
              }}
            >
              <ListItemText primary={usageId} />
            </ListItemButton>
          );
        })}
      </List>{' '}
      <Box>
        <StepButtons
          activeStep={activeStep}
          onBackClick={() => {
            setActiveStep(activeStep - 1);
          }}
          nextDisabled={!usage}
          onNextClick={() => {
            handleSubmit();
          }}
        />
        <Button
          fullWidth
          variant='outlined'
          disabled={activeStep === 0}
          sx={{
            '&.MuiButton-outlined': {
              color: 'primary.main',
              borderColor: 'primary.main'
            },
            fontSize: 16,
            fontWeight: 600,
            mt: 1
          }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Skip for now
        </Button>
      </Box>
    </>
  );
};
export default Usage;
