import { useEffect, useState, KeyboardEvent, ChangeEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { emailValidator } from 'utils/validators';
import { useAppDispatch } from 'hooks/redux-hooks';
import { loginGX, resetStatus } from 'slices/authSlice';
import { getGxTokenFromStorage } from 'utils/commonFunctions';
import { sendHubspotInit } from 'components/Tracking/hubspotTrackingListener';
import {
  identifyUserInPostHog,
  initializePostHog
} from 'components/Tracking/PostHog/postHogTrackingListener';
import { RootState } from 'store';

const useLoginComponent = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const { status } = useSelector((state: RootState) => state.auth);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const onVerify = useCallback((token: string) => {
    setCaptchaToken(token);
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefreshReCaptcha(!refreshReCaptcha);
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (getGxTokenFromStorage()) setIsLoggedIn(true);
    }, 4000);
    if (isLoggedIn) clearInterval(intervalId);
    return () => clearInterval(intervalId);
  }, [isLoggedIn, status]);

  const identifyUserInHubSpot = (email: string) => {
    if (window._hsq) {
      sendHubspotInit(window.location.pathname, email);
    }
  };

  const validateEmail = () => {
    const errorMessage = emailValidator(email);
    if (errorMessage) setError(errorMessage);
    return !errorMessage;
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleLogin();
  };

  const handleLogin = async () => {
    if (validateEmail()) {
      identifyUserInHubSpot(email);
      initializePostHog();
      identifyUserInPostHog(email, null, null);
      dispatch(loginGX({ email, captchaToken }));
      setRefreshReCaptcha(r => !r);
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    if (error) {
      const errorMessage = emailValidator(email);
      if (errorMessage) setError(errorMessage);
      else {
        setError('');
      }
    }
    setEmail(e.target.value);
  };

  const resetPage = () => {
    setEmail('');
    setError('');
    setRefreshReCaptcha(r => !r);
    dispatch(resetStatus());
  };

  return {
    handleChangeEmail,
    handleOnKeyDown,
    status,
    error,
    handleLogin,
    email,
    resetPage,
    isLoggedIn,
    refreshReCaptcha,
    onVerify,
    captchaToken,
    setRefreshReCaptcha
  };
};

export default useLoginComponent;
