import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import posthogLib from 'posthog-js';
import { SymbolKey } from 'pages/Markets/MarketsComponents/types';
import { MAX_SELECTED_SYMBOLS } from 'utils/constants';

export type SelectedSymbol = {
  Code: string;
  PeriodType: string;
  Period: string;
  TimeRef: string;
};

export const createSelectedSymbol = (key: SymbolKey): SelectedSymbol => {
  return {
    Code: key.Code,
    Period: key.Period,
    PeriodType: key.PeriodType,
    TimeRef: key.TimeRef
  };
};

type SymbolSelectorState = {
  symbols: SelectedSymbol[];
  limitReached: boolean;
};

const initialState: SymbolSelectorState = {
  symbols: [],
  limitReached: false
};

type SymbolAction = {
  symbol: SelectedSymbol;
};

type SymbolArrayAction = {
  symbols: SelectedSymbol[];
};

function trackSymbolSelection(symbols: SelectedSymbol | SelectedSymbol[]) {
  const symbolArray = Array.isArray(symbols) ? symbols : [symbols];
  symbolArray.forEach(selectedSymbol => {
    posthogLib.capture('symbol_select', {
      symbol: selectedSymbol
    });
  });
}

const symbolSelectorSlice = createSlice({
  name: 'symbolSelector',
  initialState,
  reducers: {
    setSymbols: (state, action: PayloadAction<SelectedSymbol[]>) => {
      state.symbols = action.payload;
      trackSymbolSelection(state.symbols);
    },
    addSymbol(state, action: PayloadAction<SymbolAction>) {
      if (state.symbols.length >= MAX_SELECTED_SYMBOLS) {
        state.limitReached = true;
      } else {
        if (action.payload.symbol.Code) {
          state.symbols = Array.from(
            new Map(
              [action.payload.symbol, ...state.symbols].map(symbol => [
                symbol.Code + symbol.Period + symbol.PeriodType + symbol.TimeRef,
                symbol
              ])
            ).values()
          );
          trackSymbolSelection(state.symbols);
        }
      }
    },
    addSymbolArray(state, action: PayloadAction<SymbolArrayAction>) {
      if (state.symbols.length + action.payload.symbols.length > MAX_SELECTED_SYMBOLS) {
        state.limitReached = true;
      } else {
        state.symbols = Array.from(
          new Map(
            [...action.payload.symbols, ...state.symbols].map(symbol => [
              symbol.Code + symbol.Period + symbol.PeriodType + symbol.TimeRef,
              symbol
            ])
          ).values()
        );
        trackSymbolSelection(state.symbols);
      }
    },
    deleteSymbol(state, action: PayloadAction<SymbolAction>) {
      const selectedSymbol = action.payload.symbol;
      const symbols = Array.from([...state.symbols]).filter(
        symbol =>
          symbol.Code !== selectedSymbol.Code ||
          symbol.Period !== selectedSymbol.Period ||
          symbol.PeriodType !== selectedSymbol.PeriodType ||
          symbol.TimeRef !== selectedSymbol.TimeRef
      );
      state.symbols = symbols;
      if (symbols.length < MAX_SELECTED_SYMBOLS) {
        state.limitReached = false;
      }
    },
    clearSelection(state) {
      state.symbols = [];
      state.limitReached = false;
    },
    dismissLimit(state) {
      state.limitReached = false;
    },
    setLimitReached(state, action: PayloadAction<boolean>) {
      state.limitReached = action.payload;
    }
  }
});

export const {
  setSymbols,
  addSymbol,
  addSymbolArray,
  deleteSymbol,
  clearSelection,
  setLimitReached,
  dismissLimit
} = symbolSelectorSlice.actions;

export default symbolSelectorSlice.reducer;
