import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { DashboardOutlined, ListAltOutlined } from '@mui/icons-material';
import { SPOT_CATALOG_PATH } from 'utils/constants';

const buttonStyles: SxProps<Theme> = {
  width: 250,
  display: 'flex',
  justifyContent: 'flex-start',
  backgroundColor: 'info.light',
  '&:hover': {
    backgroundColor: 'info.dark'
  }
};

const EmptyDataHubScreen = () => {
  return (
    <Box
      sx={{
        mt: 12,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography
        variant='h4'
        sx={{ fontWeight: 700 }}
      >
        Data Hub is empty
      </Typography>
      <Typography sx={{ my: 4 }}>Let's get started by adding items through these links</Typography>
      <Button
        sx={buttonStyles}
        variant='contained'
        component={Link}
        to='/markets'
      >
        {' '}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DashboardOutlined sx={{ fontSize: '20px!important' }} />
          <Box sx={{ ml: 0.5 }}>Markets</Box>
        </Box>
      </Button>
      <Button
        sx={{
          mt: 4,
          ...buttonStyles
        }}
        variant='contained'
        component={Link}
        to={'/catalog/' + SPOT_CATALOG_PATH}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ListAltOutlined sx={{ fontSize: '20px!important' }} />
          <Box sx={{ ml: 0.5 }}>Catalog</Box>
        </Box>
      </Button>
    </Box>
  );
};

export default EmptyDataHubScreen;
