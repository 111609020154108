import { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import { ChartSeries } from '../useChartSeries';
import useFullScreenOptions from './useFullScreenOptions';
import { ChartParentComponent } from 'components/Chart/useChartFunctions';

const useFullScreenChart = ({
  chartSeries,
  isDataHub,
  parent,
  isLightMode
}: {
  chartSeries: ChartSeries[] | undefined;
  isDataHub?: boolean;
  parent: ChartParentComponent;
  isLightMode: boolean;
}) => {
  const chartHeight = window.innerHeight - (isDataHub ? 460 : 260);
  const { createOptions } = useFullScreenOptions({ height: chartHeight, isDataHub, isLightMode });

  const getOptions = () => {
    return chartSeries && createOptions(chartSeries, parent);
  };
  const options = chartSeries ? getOptions() : null;

  const chartComponentRef = useRef(null);
  const [chartObject, setChartObject] = useState<Highcharts.Chart>();
  useEffect(() => {
    if (chartComponentRef.current) {
      const chartRef: any = chartComponentRef.current;
      setChartObject(chartRef.chart);
    }
  }, [chartComponentRef.current, chartSeries]);

  return { chartObject, options, chartComponentRef };
};

export default useFullScreenChart;
