import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import {
  DashboardOutlined,
  ExpandLess,
  ExpandMore,
  ListAltOutlined,
  PersonOutlined,
  Launch,
  SettingsInputComponentOutlined,
  LogoutSharp,
  SsidChartOutlined,
  PreviewOutlined,
  FileDownloadOutlined,
  BackupTableOutlined
} from '@mui/icons-material';

import { useSelector } from 'react-redux';
import MarketsDropdown from './MarketsDropdown';
import NavBarSubMenu from './NavBarSubMenu';
import { useAppDispatch } from 'hooks/redux-hooks';
import { clearSelection } from 'slices/symbolSelectorSlice';
import { logout } from 'slices/authSlice';
import { RootState } from 'store';
import { retrieveFromUserMetadata } from 'utils/commonFunctions';
import { METADATA_FIRST_NAME_FIELD, METADATA_LAST_NAME_FIELD } from 'utils/constants';

const NavBar = ({ navBarOpen }: { navBarOpen: boolean }) => {
  const [openItem, setOpenItem] = useState<number | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname.split('/')[1];
  const dispatch = useAppDispatch();
  const { userEmail, metadata } = useSelector((state: RootState) => state.auth);
  const firstName = retrieveFromUserMetadata(metadata || [], METADATA_FIRST_NAME_FIELD);
  const lastName = retrieveFromUserMetadata(metadata || [], METADATA_LAST_NAME_FIELD);

  useEffect(() => {
    if (!navBarOpen) setOpenItem(null);
    if (openItem === null) {
      navigationBarItems.map(({ link }, index) => {
        if (link === currentRoute) setOpenItem(index);
      });
    }
  }, [navBarOpen]);

  const toggleItem = (index: number) => {
    setOpenItem(openItem === index ? null : index);
  };

  const getUserName = () => {
    if (!!firstName || !!lastName) {
      return (firstName ? firstName + ' ' : '') + lastName;
    }
    return userEmail;
  };

  const profileItems = [
    {
      name: 'Settings',
      link: '/profile',
      onClick: (link: string) => navigate(link),
      icon: <SettingsInputComponentOutlined fontSize='small' />
    },
    {
      name: 'Logout',
      link: '/login',
      onClick: (link: string) => {
        dispatch(clearSelection());
        dispatch(logout());
        navigate(link);
      },
      icon: <LogoutSharp fontSize='small' />
    }
  ];

  const dataHubItems = [
    {
      name: 'Summary',
      link: `/data-hub/summary`,
      onClick: (link: string) => navigate(link),
      icon: <BackupTableOutlined fontSize='small' />
    },
    {
      name: 'Preview',
      onClick: (link: string) => navigate(link),
      link: `/data-hub/preview`,
      icon: <PreviewOutlined fontSize='small' />
    },
    {
      name: 'Chart',
      onClick: (link: string) => navigate(link),
      link: `/data-hub/chart`,
      icon: <SsidChartOutlined fontSize='small' />
    },
    {
      name: 'Export',
      onClick: (link: string) => navigate(link),
      link: `/data-hub/export`,
      icon: <FileDownloadOutlined fontSize='small' />
    }
  ];

  const catalogueItems = [
    {
      name: 'Spot',
      link: '/catalog/spot',
      onClick: (link: string) => navigate(link)
    },
    {
      name: 'Forward Curves',
      onClick: (link: string) => navigate(link),
      link: '/catalog/fwd'
    }
  ];

  const navigationBarItems = [
    {
      name: 'Markets',
      icon: <DashboardOutlined />,
      link: 'markets',
      component: <MarketsDropdown />,
      onClick: toggleItem
    },
    {
      name: 'Catalog',
      icon: <ListAltOutlined />,
      link: 'catalog',
      component: <NavBarSubMenu subMenuItems={catalogueItems} />,
      onClick: toggleItem
    },
    {
      name: 'Data Hub',
      icon: <Launch />,
      link: 'data-hub',
      component: <NavBarSubMenu subMenuItems={dataHubItems} />,
      onClick: toggleItem
    },
    {
      name: getUserName(),
      icon: <PersonOutlined />,
      link: 'profile',
      component: <NavBarSubMenu subMenuItems={profileItems} />,
      onClick: toggleItem
    }
  ];

  return (
    <>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {navigationBarItems.map(({ name, icon, onClick, component, link }, index) => {
          const isLastItem = index + 1 === navigationBarItems.length;
          const itemIsSelected = currentRoute.includes(link);
          const itemIsOpened = openItem === index;
          const SubMenu = !!component && (
            <Collapse
              in={itemIsOpened}
              timeout='auto'
              unmountOnExit
            >
              {component}
            </Collapse>
          );

          return (
            <ListItem
              key={name}
              disablePadding
              sx={{ display: 'block', mb: 1, '&:last-child': { mt: 'auto', mb: 0 } }}
            >
              {navBarOpen && isLastItem && SubMenu}
              <ListItemButton
                selected={itemIsSelected || itemIsOpened}
                sx={{
                  minHeight: 56,
                  px: 3,
                  justifyContent: navBarOpen ? 'initial' : 'center',
                  '&.Mui-selected': itemIsSelected
                    ? {}
                    : {
                        backgroundColor: 'background.paper'
                      },
                  '&.Mui-selected:hover': itemIsSelected
                    ? {}
                    : {
                        backgroundColor: 'rgba(255, 255, 255, 0.08)'
                      }
                }}
                onClick={() => onClick(index)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: 'center',
                    mr: navBarOpen ? 3 : 'auto'
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: itemIsSelected ? 600 : 500
                      }}
                    >
                      <Typography
                        sx={{
                          maxWidth: 160,
                          overflow: 'hidden'
                        }}
                        noWrap
                      >
                        {name}
                      </Typography>

                      {itemIsOpened ? (
                        <ExpandLess sx={{ ml: 1, mb: '-3px' }} />
                      ) : (
                        <ExpandMore sx={{ ml: 1, mb: '-3px' }} />
                      )}
                    </Box>
                  }
                  sx={{ opacity: navBarOpen ? 1 : 0 }}
                />
              </ListItemButton>
              {!isLastItem && navBarOpen && SubMenu}
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default NavBar;
