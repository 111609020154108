import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { TABLE_ROW_HEIGHT_PX } from 'utils/constants';
import useChartSeries from 'components/Chart/useChartSeries';
import { TemplateChartSeries } from 'pages/Markets/MarketsComponents/types';
import useUserDetails from 'hooks/useUserAccess';

const useDhChart = (isSummary?: boolean) => {
  const calculateChartHeight = (): number => {
    const VIEWPORT_HEIGHT = window.innerHeight;
    const EXTRA_PADDING = isSummary ? 540 : 330;
    return Math.floor((VIEWPORT_HEIGHT - EXTRA_PADDING) / TABLE_ROW_HEIGHT_PX);
  };
  const { checkUserHasPremium } = useUserDetails();
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const activeDataHub = dataHubs.find(dataHub => dataHub.id === activeDataHubId);
  const { symbols } = activeDataHub ?? {};
  const dataHubSymbols = dataHubs.find(dataHub => dataHub.id === activeDataHubId)?.symbols || [];

  const [chartHeight, setChartHeight] = useState(calculateChartHeight());
  const [templateChartSeries, setTemplateChartSeries] = useState<TemplateChartSeries[]>();

  useEffect(() => {
    if (checkUserHasPremium()) {
      const templateSeries: TemplateChartSeries[] = dataHubSymbols.length
        ? dataHubSymbols.toReversed().map(key => ({
            settings: { type: 'line' as const },
            symbol: {
              key,
              group: 'Prod_Indexes'
            }
          }))
        : [];
      setTemplateChartSeries(templateSeries.slice(0, isSummary ? 4 : 6));
    }
  }, [activeDataHubId]);

  useEffect(() => {
    const handleResize = () => {
      setChartHeight(calculateChartHeight());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { chartSeries, error, loading } = useChartSeries(templateChartSeries);

  return { symbols, chartSeries, error, loading, chartHeight };
};
export default useDhChart;
