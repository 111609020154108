import { Box, Button, CircularProgress, Paper } from '@mui/material';
import useDataHubExport from './useDataHubExport';
import ExportSettingsComponent from './ExportSettings';
import { exportOptionsGroups } from './exportList';
import ExportPreview from 'pages/DataHub/Export/ExportPreview';
import SmallDialog from 'components/SmallDialog';
import SuccessScreen from 'pages/DataHub/Export/SuccessScreen';
import LoadingScreen from 'pages/DataHub/Export/LoadingScreen';
import EmptyDataHubScreen from 'pages/DataHub/EmptyDataHubScreen';
import ExportGroupComponent from 'pages/DataHub/Export/ExportGroup';

const DataHubExport = () => {
  const {
    exportData,
    loading,
    exported,
    setExported,
    exportSettings,
    updateExportSetting,
    previewOptionId,
    isExcelPreview,
    symbols,
    setPreviewOptionId
  } = useDataHubExport();

  if (loading && isExcelPreview) return <LoadingScreen />;
  if (!symbols?.length) return <EmptyDataHubScreen />;
  if (exported && isExcelPreview)
    return (
      <SuccessScreen
        setExported={setExported}
        setPreviewOptionId={setPreviewOptionId}
      />
    );

  return (
    <Box sx={{ display: 'flex', height: 820 }}>
      <Paper sx={{ flexGrow: 1, p: 2, mt: 2, pt: 0, height: 'fit-content' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <ExportSettingsComponent
            {...{
              exportSettings,
              updateExportSetting
            }}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={theme => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '65%',
              [theme.breakpoints.down('lg')]: {
                width: 'initial',
                minWidth: 280
              }
            })}
          >
            {exportOptionsGroups.map(({ groupName, GroupIcon, options }) => (
              <ExportGroupComponent
                key={groupName}
                options={options}
                groupName={groupName}
                GroupIcon={GroupIcon}
                setPreviewOptionId={setPreviewOptionId}
              />
            ))}
          </Box>
          <ExportPreview
            previewOptionId={previewOptionId}
            exportData={exportData}
            exportSettings={exportSettings}
          />
        </Box>
      </Paper>
      {!isExcelPreview && (
        <SmallDialog
          open={loading || exported}
          handleClose={() => {
            setExported(false);
            setPreviewOptionId('');
          }}
        >
          <Box sx={{ backgroundColor: 'background.default', p: 4, minWidth: 400 }}>
            {loading ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress
                  size={20}
                  sx={{ mr: 2 }}
                />
                Your request is being processed...
              </Box>
            ) : (
              <>
                Thanks! We'll be in touch shortly.
                <Box sx={{ textAlign: 'right', mt: 3 }}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setExported(false);
                      setPreviewOptionId('');
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </SmallDialog>
      )}
    </Box>
  );
};

export default DataHubExport;
