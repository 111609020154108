import { useSearchParams } from 'react-router-dom';
import { Table } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import {
  alphabeticallySortedFilters,
  CatalogItem,
  CountedLabelFunction,
  extractExistingParams,
  labeledFilterColumns,
  SpecialFiltersType
} from 'pages/Catalog/constants';
import { SEARCH_TEXT_URL_PARAM } from 'utils/constants';

type SpecialFilterOption = {
  label: string;
  key: keyof SpecialFiltersType;
  visible: boolean;
};
type FilterOption = { label: string; count: number };

const useTableFilters = (table: Table<CatalogItem>, specialFilters: SpecialFiltersType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({});

  function countOccurrence(counts: { [label: string]: number }, label: string) {
    if (counts[label]) {
      counts[label]++;
    } else {
      counts[label] = 1;
    }
  }

  useEffect(() => {
    const extracted: any = extractExistingParams(searchParams);
    let filters: { [key: string]: string[] } = {};
    labeledFilterColumns.forEach(filter => {
      const key = Object.keys(filter)[0];
      if (extracted[key]) {
        filters = { ...filters, [key]: extracted[key] };
      } else {
        filters = { ...filters, [key]: [] };
      }
    });

    Object.entries(filters).forEach(filter => {
      table.getColumn(filter[0])?.setFilterValue(filter[1]);
    });
    setFilters(filters);
  }, [searchParams]);

  const getCountedLabels: CountedLabelFunction = (
    key: string,
    joined?: boolean
  ): FilterOption[] => {
    const counts: { [label: string]: number } = {};
    table.getFilteredRowModel().rows.forEach(row => {
      if (joined) {
        const label: string | undefined = row.getValue(key);
        const labels: string[] | undefined = label?.split(',');
        labels?.forEach(label => {
          countOccurrence(counts, label);
        });
      } else {
        const label: string | undefined = row.getValue(key);
        if (label) countOccurrence(counts, label);
      }
    });

    return Object.entries(counts)
      .map(([label, count]) => ({ label, count }))
      .sort((a, b) => {
        if (alphabeticallySortedFilters.includes(key)) {
          return a.label.localeCompare(b.label);
        }
        return b.count - a.count;
      });
  };

  const columnFiltersWithCounts: Record<string, FilterOption[]> = labeledFilterColumns.reduce(
    (acc: any, column: any) => {
      const key: string = Object.keys(column)[0];
      acc[key] = column[key](getCountedLabels);
      return acc;
    },
    {}
  );
  const handleFilterChange = (key: string, value: string) => {
    let existingParams = extractExistingParams(searchParams);
    if (existingParams[key]) {
      if (existingParams[key].includes(value)) {
        existingParams[key] = existingParams[key].filter((v: string) => v !== value);
        if (existingParams[key]?.length === 0) {
          delete existingParams[key];
        }
      } else {
        existingParams[key].push(value);
      }
    }
    if (!existingParams[key]) {
      const currentValues = filters[key] || [];
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter((currentValue: string) => currentValue !== value)
        : [...currentValues, value];
      const newFilters = { ...filters, [key]: updatedValues };
      existingParams = { ...existingParams, ...newFilters };
    }
    setSearchParams({ ...searchParams, ...existingParams });
  };

  const clearAllFilters = () => {
    const existingParams = extractExistingParams(searchParams);

    Object.keys(filters).forEach(key => {
      if (existingParams[key]) {
        delete existingParams[key];
      }
    });
    Object.keys(specialFilters).forEach(key => {
      if (existingParams[key]) {
        delete existingParams[key];
      }
    });

    if (existingParams[SEARCH_TEXT_URL_PARAM]) {
      delete existingParams[SEARCH_TEXT_URL_PARAM];
    }

    setSearchParams({ ...searchParams, ...existingParams });
  };

  const handleSpecialFilterChange = (key: keyof SpecialFiltersType): void => {
    const existingParams = extractExistingParams(searchParams);
    let updatedFilters: SpecialFiltersType = {
      ...specialFilters,
      [key]: !specialFilters[key],
      ...(key === 'oilOnly' ? { energyTransitionOnly: false } : {}),
      ...(key === 'energyTransitionOnly' ? { oilOnly: false } : {})
    };
    updatedFilters = { ...existingParams, ...updatedFilters };
    setSearchParams({ ...searchParams, ...updatedFilters });
  };

  const specialFiltersToRender: SpecialFilterOption[] = [
    {
      label: 'Crude and Refined Products only',
      key: 'oilOnly',
      visible: true
    },
    {
      label: 'Energy Transition only',
      key: 'energyTransitionOnly',
      visible: true
    }
  ];

  return {
    clearAllFilters,
    specialFiltersToRender,
    handleSpecialFilterChange,
    columnFiltersWithCounts,
    filters,
    handleFilterChange
  };
};

export default useTableFilters;
