import axios from 'axios';
import { UserMetadata } from 'slices/authSlice';
import {
  GX_TOKEN_STORAGE_ATTRIBUTE_NAME,
  TRIP_TOKEN_STORAGE_ATTRIBUTE_NAME
} from 'utils/constants';
import httpInstances from 'api/axiosInstance';

const baseURL: string = import.meta.env.VITE_REACT_APP_API_ENDPOINT;

export const detectEnvironment = (): 'local' | 'dev' | 'staging' | 'prod' => {
  if (window.location.hostname.includes('localhost')) {
    return 'local';
  }
  return baseURL.includes('stage') ? 'staging' : baseURL.includes('dev') ? 'dev' : 'prod';
};
export const getTripTokenFromStorage = () =>
  sessionStorage.getItem(TRIP_TOKEN_STORAGE_ATTRIBUTE_NAME);

export const setTripTokenToStorage = (tripToken: string): void => {
  sessionStorage.setItem(TRIP_TOKEN_STORAGE_ATTRIBUTE_NAME, tripToken);
};

export const getGxTokenFromStorage = () => localStorage.getItem(GX_TOKEN_STORAGE_ATTRIBUTE_NAME);

export const setGxTokenToStorage = (gxToken: string): void => {
  localStorage.setItem(GX_TOKEN_STORAGE_ATTRIBUTE_NAME, gxToken);
};

export const clearStoragesFromTokensAndUserInfo = async () => {
  await sessionStorage.removeItem(TRIP_TOKEN_STORAGE_ATTRIBUTE_NAME);
  await localStorage.removeItem(GX_TOKEN_STORAGE_ATTRIBUTE_NAME);
};

export const getDiffColor = (value: number | null) => {
  if (!value) return '#4F5458';
  if (value === 0) return '#4F5458';
  return Math.sign(value) > 0 ? '#00E676' : '#FF1744';
};

export const generateUUID = (): string =>
  '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: string) =>
    (
      parseInt(c) ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (parseInt(c) / 4)))
    ).toString(16)
  );

export const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: unknown[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const downloadAsCsv = (values: string[]) => {
  const csv = values.join('\n');
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const anchorElemnt = document.createElement('a');
  anchorElemnt.setAttribute('href', url);
  anchorElemnt.setAttribute('download', 'key.csv');
  anchorElemnt.click();
};

export const transformCamelCaseToSpaces = (text: string) => {
  return text.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const transformPickerDateToISO = (date: Date | null | undefined, time: 'start' | 'end') => {
  if (!date) return '';
  if (date.toString() === 'Invalid Date') return 'invalid_date';

  try {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return time === 'start'
      ? `${year}-${month}-${day}T00:00:00`
      : `${year}-${month}-${day}T23:59:59`;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const sleep = async (ms: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, ms));

export const formatNumberWithPrecision = (cellValue: any) => {
  if (cellValue === undefined || cellValue === null) {
    return '';
  }
  const numberValue = +cellValue;
  if (isNaN(numberValue)) {
    return '';
  }
  const decimalPlaces = cellValue.includes('.') ? cellValue.split('.')[1].length : 0;
  return numberValue.toLocaleString('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });
};

export const retrieveFromUserMetadata = (userMetadata: UserMetadata[], key: string) => {
  const optionalValue = userMetadata.find(
    metdata => metdata.metadataKey.toLowerCase() === key.toLowerCase()
  );
  return optionalValue ? optionalValue.metadataValue : null;
};
export const estimateLastSaved = (savedTime: string) => {
  try {
    const justNow = 'just now';
    const ago = ' ago';

    const inputDate = new Date(Date.parse(savedTime));
    if (isNaN(inputDate.getTime())) {
      return 'Wrong date format';
    }

    const now = new Date();
    const diffInMilliseconds = now.getTime() - inputDate.getTime();

    const absDiff = Math.abs(diffInMilliseconds);

    const minutes = Math.floor(absDiff / (1000 * 60)) % 60;
    const hours = Math.floor(absDiff / (1000 * 60 * 60)) % 24;
    const days = Math.floor(absDiff / (1000 * 60 * 60 * 24));

    if (days > 0) return days + 'd' + ago;
    if (hours > 0) return hours + 'h' + ago;
    if (minutes > 0) return minutes + 'm' + ago;

    return justNow;
  } catch (error) {
    return `Error: ${error.message}`;
  }
};
export const downloadPDF = (url: string, filename: string) => {
  fetch(url)
    .then(response => {
      if (response.headers.get('content-type') === 'application/pdf') return response.blob();
      else throw TypeError('Code not found.');
    })
    .then(blob => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    })
    .catch(error => console.error('Error downloading PDF:', error));
};

export const downlaodFileFromDatalake = (fileId: string) => {
  return httpInstances.axiosGxInstance
    .get(`/file/${fileId}`, {
      responseType: 'json'
    })
    .then(response => {
      if (response.status === 200) {
        const locationUrl: string = response?.headers['location'];
        if (!locationUrl) throw new Error('Location of file not in response headers');
        return axios
          .get(locationUrl, {
            responseType: 'blob'
          })
          .then(response => {
            return response;
          });
      } else if (response.status === 404) {
        throw new Error('File not found');
      }
    })
    .catch(error => {
      return error;
    });
};
export function parseBackendData<T>(data: T): T {
  if (typeof data === 'string' && (data === 'unknown' || data.toLowerCase() === 'null')) {
    return undefined as unknown as T; // Convert "unknown" string to TypeScript unknown type
  } else if (Array.isArray(data)) {
    return data.map(parseBackendData) as T;
  } else if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, parseBackendData(value)])
    ) as T;
  }
  return data;
}
