import { DhData } from '../useDataHub';
import useUserDetails from 'hooks/useUserAccess';
import { mockDhData } from 'pages/DataHub/constants';

const useDataHubPreview = (dhData: DhData) => {
  const { checkUserHasPremium } = useUserDetails();
  const dataToShow = checkUserHasPremium() ? dhData : mockDhData;

  const groupedData: Record<
    string,
    {
      date: string;
      values: Record<string, { value: string; PeriodAbs: string; FactsheetVersion: string }>;
    }
  > = {};

  dataToShow.forEach(dhDataItem => {
    const transactionTime = dhDataItem.transactionTime;
    const columnKey = `${dhDataItem.symbol.TimeRef}-${dhDataItem.symbol.Period}-${dhDataItem.symbol.PeriodType}-${dhDataItem.symbol.Code}-${dhDataItem.metadata.Title}`;

    if (!groupedData[transactionTime]) {
      groupedData[transactionTime] = { date: transactionTime, values: {} };
    }

    groupedData[transactionTime].values[columnKey] = {
      value: dhDataItem.values?.Index || dhDataItem.values?.Mid || '-',
      PeriodAbs: dhDataItem.values?.PeriodAbs || '-',
      FactsheetVersion: dhDataItem.values?.FactsheetVersion || '-'
    };
  });

  const rows = Object.values(groupedData).sort((a, b) => b.date.localeCompare(a.date));

  return { rows };
};

export default useDataHubPreview;
