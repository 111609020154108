import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import httpInstances from '../api/axiosInstance';
import { CatalogItem, forwardCurvesColumns } from 'pages/Catalog/constants';

type ForwardCatalogState = {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  error: string | null;
  forwardCatalog: CatalogItem[] | null;
};

type ErrorResponse = {
  message: string;
};

const initialState: ForwardCatalogState = {
  status: 'idle',
  error: null,
  forwardCatalog: null
};

export const getForwardCatalog = createAsyncThunk(
  'getForwardCatalog',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await httpInstances.axiosDataInstance.get(
        `/catalogue/curve?status=Live,Archive`,
        {
          headers: {
            fields: forwardCurvesColumns.map(column => column.accessorKey)
          }
        }
      );
      const extendedWithSymbol: CatalogItem[] = data.map((catalogItem: CatalogItem) => ({
        ...catalogItem,
        Symbol: {
          Code: catalogItem.Code,
          Period: String(catalogItem.Period),
          PeriodType: catalogItem.PeriodType,
          TimeRef: catalogItem.TimeRef
        }
      }));

      return extendedWithSymbol;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

const forwardCatalogSlice = createSlice({
  name: 'forwardCatalog',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getForwardCatalog.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getForwardCatalog.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.forwardCatalog = action.payload;
      })
      .addCase(getForwardCatalog.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      });
  }
});

export default forwardCatalogSlice.reducer;
