import { Fragment } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CommoditiesTableSectionType } from '../../types';
import useCommoditiesSelection from '../useCommoditiesSelection';
import CommoditiesTableRow from './CommoditiesTableRow';
import Checkbox from 'components/Checkbox';
import { TABLE_ROW_HEIGHT_PX } from 'utils/constants';

const CommoditiesTableTab = ({
  sections,
  height
}: {
  sections: CommoditiesTableSectionType[];
  height?: number;
}) => {
  const renderEmptyCells = (count: number) =>
    Array.from({ length: count }).map((_, index) => <TableCell key={index} />);

  const {
    toggleSectionInSelection,
    allCodesSelected,
    addAllCodesToSelection,
    clearAllCodesFromSelection,
    isSectionSelected
  } = useCommoditiesSelection(sections);

  const handleTabSelection = () => {
    if (allCodesSelected()) {
      clearAllCodesFromSelection();
    } else {
      addAllCodesToSelection();
    }
  };

  return (
    <TableContainer
      sx={{
        pb: '2px',
        width: '100%',
        height: 'fit-content',
        maxHeight: height ? `${height * TABLE_ROW_HEIGHT_PX + 47}px` : 'fit-content'
      }}
    >
      <Table
        aria-label='commodities table'
        size='small'
        stickyHeader
      >
        <TableHead>
          <TableRow
            sx={theme => ({
              '& td, & th': {
                pb: 1,
                borderBottom: `0.5px solid ${theme.palette.text.primary}`,
                backgroundColor: 'background.paper'
              }
            })}
          >
            <TableCell
              component='th'
              scope='row'
              align='right'
              sx={{ pl: 1, pr: 1, width: 24 }}
            >
              <Checkbox
                checked={allCodesSelected()}
                onChange={handleTabSelection}
                sx={{ mb: '3px' }}
              />
            </TableCell>
            <TableCell
              sx={{
                width: '33%'
              }}
            >
              Short Title
            </TableCell>
            <TableCell align='right'>Date</TableCell>
            <TableCell align='right'>Last</TableCell>
            <TableCell align='right'>Change</TableCell>
            <TableCell align='right'>Diff</TableCell>
            <TableCell
              align='right'
              sx={{ pr: 2 }}
            >
              Change
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '&  .markets-table-row:nth-of-type(even)': {
              backgroundColor: 'secondary.main'
            },
            '&  .markets-table-row:nth-of-type(even) + td': {
              backgroundColor: 'secondary.main'
            }
          }}
        >
          {sections?.map(({ name, rows }) => {
            return (
              <Fragment key={name}>
                {name && (
                  <TableRow
                    className='markets-table-row'
                    sx={{ '&:first-of-type td, &:first-of-type th': { pt: 1 } }}
                  >
                    <TableCell
                      component='th'
                      scope='row'
                      align='right'
                      sx={{ pl: 1, pr: 1, width: 24 }}
                    >
                      <Checkbox
                        sx={{ mb: '4px' }}
                        checked={isSectionSelected(rows)}
                        onChange={() => toggleSectionInSelection(rows)}
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>{name}</TableCell>
                    {renderEmptyCells(6)}
                  </TableRow>
                )}
                {rows?.map(row => (
                  <CommoditiesTableRow
                    sections={sections}
                    key={row.shortTitle}
                    row={row}
                  />
                ))}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommoditiesTableTab;
