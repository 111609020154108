import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FactsheetDetails } from 'pages/Facthsheets/constants';
import { UnauthenticatedMessage } from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody';
import useUserDetails from 'hooks/useUserAccess';
import { TemplateChartSeries } from 'pages/Markets/MarketsComponents/types';
import { getGxTokenFromStorage } from 'utils/commonFunctions';
import UnauthenticatedChart from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/HistoryTab/UnauthenticatedChart';
import AuthenticatedChart from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/HistoryTab/AuthenticatedChart';

export const BasicUserMessage = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Typography
        variant='h5'
        sx={{ fontWeight: 600, fontSize: 24 }}
      >
        This is a Premium feature.{' '}
      </Typography>
      <Typography
        variant='h5'
        sx={{ fontWeight: 600, fontSize: 24 }}
      >
        Upgrade to GX Go Premium to enable charts and unlock advanced functionality.
      </Typography>
    </Box>
  );
};

const BlurBackdrop = ({ gxToken }: { gxToken: string | null }) => {
  const { checkUserHasPremium } = useUserDetails();
  const isPremiumUser = checkUserHasPremium();
  if (gxToken && isPremiumUser) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backdropFilter: 'blur(4px)',
          zIndex: 1400
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 'calc(30%)',
          left: 'calc(50% - 150px)',
          textAlign: 'center',
          zIndex: 1401,
          width: 300
        }}
      >
        {!gxToken && <UnauthenticatedMessage />}
        {gxToken && !isPremiumUser && <BasicUserMessage />}
      </Box>
    </>
  );
};
const HistoryTab = ({ factsheet }: { factsheet: FactsheetDetails }) => {
  const gxToken = getGxTokenFromStorage();
  const [templateChartSeries, setTemplateChartSeries] = useState<TemplateChartSeries[]>([]);
  const spotAlias = factsheet.indexDetails['spotAlias'] as string;
  const { checkUserHasPremium } = useUserDetails();
  useEffect(() => {
    if (checkUserHasPremium() && factsheet.spotSymbol) {
      const factsheetSeries: TemplateChartSeries = {
        settings: {
          type: 'line'
        },
        shortTitle:
          spotAlias ??
          `${factsheet.code} ${factsheet.spotSymbol.timeRef} ${factsheet.spotSymbol.periodType} ${factsheet.spotSymbol.period}`,
        symbol: {
          group: 'Prod_Indexes',
          key: {
            Code: factsheet.code,
            Period: factsheet.spotSymbol.period,
            PeriodType: factsheet.spotSymbol.periodType,
            TimeRef: factsheet.spotSymbol.timeRef
          }
        }
      };
      setTemplateChartSeries([factsheetSeries]);
    }
  }, []);
  return (
    <Box
      sx={{
        position: 'relative'
      }}
    >
      <BlurBackdrop gxToken={gxToken} />
      {!gxToken && <UnauthenticatedChart code={factsheet.code} />}
      {gxToken && templateChartSeries.length > 0 && (
        <AuthenticatedChart
          code={factsheet.code}
          templateChartSeries={templateChartSeries}
        />
      )}
      {gxToken && templateChartSeries.length === 0 && (
        <Box
          sx={{
            width: '100%',
            height: 300
          }}
        >
          <Typography>{factsheet.code} does not have any spot items</Typography>
        </Box>
      )}
    </Box>
  );
};
export default HistoryTab;
