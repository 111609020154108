import { ReactElement } from 'react';
import { Box, Button, ButtonBase, TextField, Typography } from '@mui/material';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import useLoginComponent from 'pages/Onboarding/LoginComponent/useLoginComponent';
import { ThanksMessage } from 'pages/Onboarding';

const LoginComponent = ({
  emailButtonLabel = 'Login',
  loginHeader,
  loginFooter
}: {
  emailButtonLabel?: string;
  loginHeader?: ReactElement;
  loginFooter?: ReactElement;
}) => {
  const {
    handleChangeEmail,
    handleOnKeyDown,
    status,
    error,
    handleLogin,
    email,
    refreshReCaptcha,
    onVerify,
    captchaToken,
    isLoggedIn,
    resetPage
  } = useLoginComponent();
  const fontStyle = { mb: 2, fontSize: 24 };
  const loading = status === 'loading';

  const renderContent = () => {
    if (status === 'fulfilled') {
      if (isLoggedIn) {
        return (
          <>
            <Typography sx={fontStyle}>You've been logged in.</Typography>
            <Typography sx={fontStyle}>You can now close this window.</Typography>
          </>
        );
      }
      return (
        <>
          <Typography sx={{ mb: 2, fontSize: 32 }}>
            We've sent a link to your email address
          </Typography>
          <Typography sx={{ mb: 2, fontSize: 32, fontWeight: 700 }}>{email}</Typography>
          <Typography sx={{ mb: 2, fontSize: 32 }}>Please check your inbox.</Typography>
          <Typography sx={{ fontSize: 20 }}>
            If you don't see the email, please check your spam folder or&nbsp;
            <ButtonBase
              sx={{ color: 'primary.main', fontSize: 20, mb: '3px' }}
              onClick={resetPage}
            >
              try resending
            </ButtonBase>
            .
          </Typography>
        </>
      );
    }
    if (status === 'notFound') {
      return (
        <>
          <Typography sx={{ fontSize: 32, fontWeight: 'bold', mb: 2 }}>
            First, let’s verify your email
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
            Check {email} to verify your account and get started
          </Typography>
        </>
      );
    }
    if (['failed', 'onSuppressionList'].includes(status)) return <ThanksMessage />;
    else {
      return (
        <Box>
          {loginHeader}
          <Box
            minHeight={84}
            minWidth={360}
          >
            <TextField
              variant='filled'
              fullWidth
              type='email'
              autoComplete='email'
              id='email'
              label='Email'
              name='email'
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus
              onKeyDown={handleOnKeyDown}
              value={email}
              onChange={handleChangeEmail}
              error={!!error}
              helperText={error}
              disabled={loading}
            />
          </Box>
          <Button
            fullWidth
            variant='contained'
            sx={{
              height: 48,
              mt: 1,
              mb: 2,
              color: 'text.primary.main',
              fontWeight: 600,
              fontSize: 16
            }}
            onClick={handleLogin}
            disabled={loading || !captchaToken}
          >
            {loading ? 'Logging in...' : emailButtonLabel}
          </Button>
          {loginFooter}
        </Box>
      );
    }
  };

  return (
    <>
      <GoogleReCaptcha
        onVerify={onVerify}
        action='login'
        refreshReCaptcha={refreshReCaptcha}
      />
      {renderContent()}
    </>
  );
};
export default LoginComponent;
