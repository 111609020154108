import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { RootState } from 'store';
import { setDhData } from 'slices/dhDataSlice';
import { useAppDispatch } from 'hooks/redux-hooks';
import { saveDataHub } from 'slices/dataHubSlice';
import { ExportSettingsType } from 'pages/DataHub/Export/ExportSettings/useExportSettings';

const useDataHubStats = () => {
  const { dhData } = useSelector((state: RootState) => state.dhData);
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const activeDataHub = dataHubs.find(dataHub => dataHub.id === activeDataHubId);
  const { symbols = [], name = '', exportSettings = {} } = activeDataHub ?? {};
  const dispatch = useAppDispatch();

  const uniqueCommodities = new Map<string, Set<string>>();
  const uniqueTradingHubs = new Map<string, Set<string>>();
  const uniquePricingBasis = new Map<string, Set<string>>();

  dhData.forEach(item => {
    if (item.length) {
      const { symbol, metadata } = item[0] || {};

      const columnKey = `${symbol.TimeRef}-${symbol.Period}-${symbol.PeriodType}-${symbol.Code}`;

      if (!uniqueCommodities.has(metadata.Commodity)) {
        uniqueCommodities.set(metadata.Commodity, new Set());
      }
      uniqueCommodities.get(metadata.Commodity)!.add(columnKey);

      if (!uniqueTradingHubs.has(metadata.TradingHub)) {
        uniqueTradingHubs.set(metadata.TradingHub, new Set());
      }
      uniqueTradingHubs.get(metadata.TradingHub)!.add(columnKey);

      if (!uniquePricingBasis.has(metadata.PricingBasis)) {
        uniquePricingBasis.set(metadata.PricingBasis, new Set());
      }
      uniquePricingBasis.get(metadata.PricingBasis)!.add(columnKey);
    }
  });

  const formatStatItems = (statItem: Map<string, Set<string>>) =>
    Array.from(statItem.entries())
      .sort((a, b) => b[1].size - a[1].size)
      .map(([key, value]) => ({ name: key, count: value.size }));

  const deleteGroupFromDataHub = (
    key: string,
    type: 'Commodity' | 'TradingHub' | 'PricingBasis'
  ) => {
    const filteredDhData = dhData.filter(([firstItem]) => {
      const matchesType =
        (type === 'Commodity' && firstItem.metadata.Commodity === key) ||
        (type === 'TradingHub' && firstItem.metadata.TradingHub === key) ||
        (type === 'PricingBasis' && firstItem.metadata.PricingBasis === key);

      return !matchesType;
    });

    const filteredDataHubSymbols = symbols.filter(dhSymbol => {
      return !dhData.some(([firstItem]) => {
        const matchesType =
          (type === 'Commodity' && firstItem.metadata.Commodity === key) ||
          (type === 'TradingHub' && firstItem.metadata.TradingHub === key) ||
          (type === 'PricingBasis' && firstItem.metadata.PricingBasis === key);
        return (
          matchesType &&
          dhSymbol.Code === firstItem.symbol.Code &&
          dhSymbol.Period === firstItem.symbol.Period &&
          dhSymbol.PeriodType === firstItem.symbol.PeriodType &&
          dhSymbol.TimeRef === firstItem.symbol.TimeRef
        );
      });
    });

    dispatch(
      saveDataHub({
        savedAt: new Date().toString(),
        name,
        id: activeDataHubId,
        symbols: filteredDataHubSymbols,
        exportSettings: exportSettings as ExportSettingsType
      })
    );
    dispatch(setDhData(filteredDhData));
    enqueueSnackbar('Selected items removed from Data Hub!', {
      variant: 'success',
      autoHideDuration: 5000
    });
  };

  const stats = [
    { label: 'Commodity', values: formatStatItems(uniqueCommodities) },
    { label: 'Trading Hub', values: formatStatItems(uniqueTradingHubs) },
    { label: 'Pricing Basis', values: formatStatItems(uniquePricingBasis) }
  ];

  return {
    stats,
    deleteGroupFromDataHub
  };
};

export default useDataHubStats;
