import { Box, Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import { ReactNode } from 'react';
import useUserDetails from 'hooks/useUserAccess';
import UpgradeToPremiumButton from 'pages/DataHub/UpgradeToPremiumScreen/UpgradeToPremiumButton';

const ExportPreviewScreen = ({
  actionHeading,
  previewOptions,
  previewImg,
  children,
  handlePrimaryButtonClick,
  primaryButtonLabel,
  secondaryButton,
  tokenLink
}: {
  actionHeading: string | undefined;
  previewOptions: never[] | string[] | undefined;
  previewImg: string | undefined;
  children?: ReactNode;
  handlePrimaryButtonClick?: () => void;
  primaryButtonLabel?: string;
  secondaryButton?: ReactNode;
  tokenLink?: ReactNode;
}) => {
  const { checkUserHasPremium } = useUserDetails();
  return (
    <>
      <Box>
        <Box sx={{ p: 2 }}>
          <Typography fontSize={24}>{actionHeading}</Typography>
          <List>
            {previewOptions?.map((option: string) => (
              <ListItem
                sx={{ p: 0 }}
                key={option}
              >
                <Box sx={{ backgroundColor: 'text.primary', width: 4, height: 4, mr: 1 }} />
                <ListItemText primary={option} />
              </ListItem>
            ))}
            {tokenLink}
          </List>
          {primaryButtonLabel && (
            <Box sx={{ mt: 2 }}>
              {checkUserHasPremium() ? (
                <Button
                  variant='contained'
                  onClick={handlePrimaryButtonClick}
                >
                  {primaryButtonLabel}
                </Button>
              ) : (
                <UpgradeToPremiumButton />
              )}
              {secondaryButton}
            </Box>
          )}
        </Box>
        {children}
      </Box>
      {previewImg && (
        <Box
          component='img'
          src={previewImg}
          alt={actionHeading}
          sx={{ height: `100%` }}
        />
      )}
    </>
  );
};
export default ExportPreviewScreen;
