import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import httpInstances from '../api/axiosInstance';
import { RootState } from '../store';
import { TileType } from 'pages/Markets/MarketsComponents/Tiles/types';
import { ChartsBlockTabType, CommoditiesTableTabType } from 'pages/Markets/MarketsComponents/types';
import { detectEnvironment } from 'utils/commonFunctions';

type MarketsState = {
  status: 'idle' | 'cancelled' | 'loading' | 'failed' | 'fulfilled';
  error: string | null;
  markets: MarketsType | null;
};

type ErrorResponse = {
  message: string;
};

export type MarketsType = {
  metadata: {
    displayName: string;
    commodity: string[];
    region: string[];
  };
  pages: MarketsPageType[];
};

export type MarketsPageType = {
  name: string;
  components: MarketsComponentType[];
};

export type MarketsComponentType = {
  type: 'table' | 'tiles' | 'graph';
  layout?: {
    fullWidth?: boolean;
    height?: number;
  };
  tiles?: TileType[];
  tabs?: CommoditiesTableTabType[] | ChartsBlockTabType[];
};

const initialState: MarketsState = {
  status: 'idle',
  error: null,
  markets: null
};
let controller: AbortController;
export const getMarkets = createAsyncThunk(
  'getMarkets',
  async ({ marketId }: { marketId: string }, { rejectWithValue }) => {
    try {
      if (controller) {
        controller.abort();
      }
      controller = new AbortController();
      const signal = controller.signal;
      const { data } = await httpInstances.axiosInternalInstance.get(
        `${detectEnvironment() === 'staging' ? '/staging/' : '/'}insights/${marketId}`,
        { signal }
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

const marketsSlice = createSlice({
  name: 'markets',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMarkets.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getMarkets.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.markets = action.payload;
      })

      .addCase(getMarkets.rejected, (state, action) => {
        if (action.error && action.error.name === 'CanceledError') {
          state.status = 'cancelled';
        } else {
          state.status = 'failed';
        }
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message ?? 'Request failed';
        } else {
          state.error = action.error.message ?? 'Request failed';
        }
      });
  }
});

export const selectMarketsStatus = (state: RootState) => state.markets.status;
export default marketsSlice.reducer;
