import { Box, styled, TableCell, tableCellClasses, TableRow, Typography } from '@mui/material';
import Highcharts from 'highcharts/highstock';
import VisibilityToggleButton from './VisibilityToggleButton';
import LegendCheckBox from './LegendCheckBox';
import FactsheetDialogButton from 'components/FactsheetDialogButton';

const StyledTableCell = styled(TableCell)(() => ({
  paddingRight: 30,
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '22px',
  paddingRight: 8,
  paddingBottom: 8,
  verticalAlign: 'center',
  borderBottom: `0.5px solid ${theme.palette.info.light}`,
  '&:last-child tr': { border: 0, borderBottom: 0 }
}));

const LegendItem = ({
  chart,
  series,
  index
}: {
  chart: Highcharts.Chart;
  series: any;
  index: number;
}) => {
  const metadata = series.userOptions?.metadata;
  if (metadata) {
    return (
      <StyledTableRow
        key={series.name + metadata.field}
        sx={{ '&:last-child': { borderBottom: 0 } }}
      >
        <StyledTableCell
          sx={{ width: '1%', pl: '2px', pr: 1 }}
          component='th'
          scope='row'
        >
          <Box
            display={'flex'}
            alignItems={'center'}
          >
            <LegendCheckBox
              series={series}
              checkboxStyles={{ mr: 1 }}
            />
            <VisibilityToggleButton
              chart={chart}
              index={index}
            />
          </Box>
        </StyledTableCell>
        <TableCell sx={{ width: '45%' }}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: 16,
                height: 2,
                flexShrink: 0,
                marginTop: 8,
                backgroundColor: series.color
              }}
            ></div>
            <Typography
              variant='body2'
              sx={{ pl: 1 }}
            >
              {series.name}
            </Typography>
          </div>
        </TableCell>
        <TableCell sx={{ width: '10%' }}>
          {' '}
          <Typography variant='body2'>{metadata.Code}</Typography>
        </TableCell>
        <TableCell
          align='right'
          sx={{ width: '5%' }}
        >
          {' '}
          <Typography variant='body2'>{metadata.TimeRef}</Typography>
        </TableCell>
        <TableCell
          align='right'
          sx={{ width: '10%' }}
        >
          {' '}
          <Typography variant='body2'>{metadata.PeriodType}</Typography>
        </TableCell>
        <TableCell
          align='right'
          sx={{ width: '5%' }}
        >
          {' '}
          <Typography variant='body2'>{metadata.Period}</Typography>
        </TableCell>

        <TableCell
          align='right'
          sx={{ width: '5%' }}
        >
          {' '}
          <Typography variant='body2'>{metadata.field}</Typography>
        </TableCell>
        <TableCell
          align='center'
          sx={{ width: '5%' }}
        >
          {' '}
          <FactsheetDialogButton code={metadata.Code} />
        </TableCell>
      </StyledTableRow>
    );
  }
};
export default LegendItem;
