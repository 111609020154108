import { enqueueSnackbar } from 'notistack';

const useCopyToClipboard = () => {
  const copyToClipboard = (textToCopy: string, toastMessage: string) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        enqueueSnackbar(toastMessage, {
          variant: 'success',
          autoHideDuration: 5000
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  return { copyToClipboard };
};
export default useCopyToClipboard;
