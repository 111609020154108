import { Box, Dialog } from '@mui/material';
import { useDispatch } from 'react-redux';
import FactsheetView from 'pages/Facthsheets/FactsheetView';
import { closeFactsheet } from 'slices/factsheetDialogSlice';

const FactsheetDialog = ({ code, open }: { code: string; open: boolean }) => {
  const dispatch = useDispatch();
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => dispatch(closeFactsheet())}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          backgroundColor: '#12163fcc'
        }}
      >
        <FactsheetView
          onDialogClose={() => dispatch(closeFactsheet())}
          factsheetKeys={code}
        />
      </Box>
    </Dialog>
  );
};

export default FactsheetDialog;
