import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { transformPickerDateToISO } from 'utils/commonFunctions';
import { saveDataHub } from 'slices/dataHubSlice';
import { useAppDispatch } from 'hooks/redux-hooks';

export interface ExportSettingsType {
  from: Date | null;
  to: Date | null;
  periodNumber: string;
  period: string;
  exportType: string;
  includeMetadata: boolean;
}

const defaultExportSettings: ExportSettingsType = {
  from: null,
  to: null,
  periodNumber: '2',
  period: 'm',
  exportType: 'all',
  includeMetadata: false
};

const useExportSettings = () => {
  const dispatch = useAppDispatch();
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const activeDataHub = dataHubs.find(dataHub => dataHub.id === activeDataHubId);

  const [exportSettings, setExportSettings] = useState<ExportSettingsType>({
    ...defaultExportSettings,
    ...(activeDataHub?.exportSettings || {})
  });

  const updateExportSetting = useCallback(
    (key: keyof typeof exportSettings, value: any) => {
      setExportSettings(prev => {
        const updatedSettings = { ...prev, [key]: value };
        if (activeDataHub) {
          dispatch(
            saveDataHub({
              ...activeDataHub,
              exportSettings: updatedSettings
            })
          );
        }
        return updatedSettings;
      });
    },
    [dispatch, activeDataHub]
  );
  const getFromToValues = (exportSettings: ExportSettingsType) => {
    let fromValue: string | null = '';
    let toValue: string | null = '';

    switch (exportSettings.exportType) {
      case 'all':
        fromValue = 'all';
        break;
      case 'range':
        fromValue = `${exportSettings.periodNumber}${exportSettings.period}`;
        break;
      case 'dates':
        fromValue = transformPickerDateToISO(exportSettings.from, 'start');
        toValue = transformPickerDateToISO(exportSettings.to, 'end');
        break;
      default:
        fromValue = 'all';
    }

    return { fromValue, toValue };
  };

  return {
    getFromToValues,
    exportSettings,
    updateExportSetting
  };
};

export default useExportSettings;
