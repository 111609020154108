import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import httpInstances from 'api/axiosInstance';
import useExportSettings from 'pages/DataHub/Export/ExportSettings/useExportSettings';

let timerId: any = null;

const useDataHubExport = () => {
  const { getFromToValues, exportSettings, updateExportSetting } = useExportSettings();
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const activeDataHub = dataHubs.find(dataHub => dataHub.id === activeDataHubId);
  const [loading, setLoading] = useState<boolean>(false);
  const [exported, setExported] = useState<boolean>(false);
  const [previewOptionId, setPreviewOptionId] = useState<string>('');
  const { symbols } = activeDataHub ?? {};

  useEffect(() => {
    setExported(false);
  }, [activeDataHub]);

  const isExcelPreview = ['excel?type=simple', 'excel?type=advanced'].includes(previewOptionId);

  useEffect(() => {
    clearTimeout(timerId);
  }, [previewOptionId]);

  const { fromValue, toValue } = getFromToValues(exportSettings);
  const urlToParam = toValue ? '&to=' + toValue : '';

  const getExportBody = () => ({
    symbols:
      activeDataHub?.symbols.map(symbol => ({
        group: 'Prod_Indexes',
        key: {
          code: symbol.Code,
          periodType: symbol.PeriodType,
          timeRef: symbol.TimeRef,
          period: symbol.Period
        }
      })) || []
  });

  const exportData = async (type: string) => {
    setLoading(true);
    try {
      const urlType = type.startsWith('excel') ? type : 'third-party?type=' + type;
      const endpoint = `export/${urlType}&from=${fromValue}${urlToParam}&metadata=${exportSettings.includeMetadata}`;
      await httpInstances.axiosGoInstance.post(endpoint, getExportBody());
      setExported(true);
      if (!type.startsWith('excel')) {
        timerId = setTimeout(() => {
          setExported(false);
          setPreviewOptionId('');
        }, 5000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    exported,
    isExcelPreview,
    symbols,
    setExported,
    exportData,
    exportSettings,
    updateExportSetting,
    previewOptionId,
    setPreviewOptionId
  };
};

export default useDataHubExport;
