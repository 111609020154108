import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';
import httpInstances from 'api/axiosInstance';
import { DhDataItem } from 'pages/DataHub/useDataHub';

export type DhData = DhDataItem[][];

type DataHubSelectionState = {
  dhData: DhData;
  loading: boolean;
  error: boolean;
};

const initialState: DataHubSelectionState = {
  dhData: [],
  loading: false,
  error: false
};

export const fetchDhData = createAsyncThunk(
  'dataHub/fetchDhData',
  async ({ symbols }: { symbols: SelectedSymbol[] }, { rejectWithValue }) => {
    try {
      const requests = symbols.map(({ Code, Period, PeriodType, TimeRef }) =>
        Code
          ? httpInstances.axiosDataInstance.get(
              `/index?code=${Code}&period=${Period}&periodType=${PeriodType}&timeRef=${TimeRef}&from=all&metadata=true`
            )
          : null
      );
      const responses = await Promise.all(requests.filter(Boolean));
      return responses.map(response => response?.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const dhDataSlice = createSlice({
  name: 'dhData',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(fetchDhData.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchDhData.fulfilled, (state, action: PayloadAction<DhData>) => {
        state.dhData = action.payload;
        state.loading = false;
      })
      .addCase(fetchDhData.rejected, state => {
        state.loading = false;
        state.error = true;
      });
  },
  reducers: {
    deleteSymbolFromDataHub(state, action: PayloadAction<string>) {
      const [timeRef, period, periodType, code] = action.payload.split('-');

      state.dhData = state.dhData.filter(dataGroup =>
        dataGroup.every(
          item =>
            !(
              item.symbol.TimeRef === timeRef &&
              item.symbol.Period === period &&
              item.symbol.PeriodType === periodType &&
              item.symbol.Code === code
            )
        )
      );
    },
    setDhData(state, action: PayloadAction<DhData>) {
      state.dhData = action.payload;
    },
    clearDataHub(state) {
      state.dhData = [];
      state.loading = false;
      state.error = false;
    }
  }
});

export const { clearDataHub, setDhData, deleteSymbolFromDataHub } = dhDataSlice.actions;

export default dhDataSlice.reducer;
