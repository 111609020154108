import { Paper } from '@mui/material';
import StockChart from 'components/Chart/StockChart';
import EmptyDataHubScreen from 'pages/DataHub/EmptyDataHubScreen';
import useDhChart from 'pages/DataHub/Chart/useDhChart';
import useUserDetails from 'hooks/useUserAccess';
import { mockChartSeries } from 'pages/DataHub/constants';

const SummaryChart = ({
  mainPageTitle,
  subPageTitle,
  tabName
}: {
  mainPageTitle: string;
  subPageTitle?: string;
  tabName?: string;
}) => {
  const { checkUserHasPremium } = useUserDetails();
  const { symbols, chartSeries, error, loading, chartHeight } = useDhChart(true);

  if (!symbols?.length) return <EmptyDataHubScreen />;
  return (
    <Paper sx={{ p: 2, height: `calc(100vh - 454px)`, position: 'relative' }}>
      <StockChart
        error={error}
        loading={loading}
        chartSeries={checkUserHasPremium() ? chartSeries : mockChartSeries}
        height={chartHeight}
        parent={{ mainPageTitle, subPageTitle, tabName }}
      />
    </Paper>
  );
};

export default SummaryChart;
