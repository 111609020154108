import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FactsheetHeader from './FactsheetHeader';
import FactsheetBody from './FactsheetBody';
import httpInstances from 'api/axiosInstance';
import Loader from 'components/Loader';
import { FactsheetDetails, FactsheetSymbol } from 'pages/Facthsheets/constants';
import { parseBackendData } from 'utils/commonFunctions';

const FactsheetWindow = ({
  factsheetKey,
  openNewTab,
  updateTabName
}: {
  factsheetKey: string;
  openNewTab: (code: string) => void;
  updateTabName: (code: string, newName: string) => void;
}) => {
  const [factsheetDetails, setFactsheetDetails] = useState<FactsheetDetails | null>(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (factsheetKey.length > 0) {
      getFactsheet(factsheetKey).then(factsheets => {
        const details = factsheets[0];
        if (details.symbols) {
          details.spotSymbol = filterOnlySpotSeries(details.symbols, details.code)[0];
        }
        setFactsheetDetails(details);
        updateTabName(factsheetKey, details.code);
      });
    }
  }, [factsheetKey]);

  function filterOnlySpotSeries(symbols: FactsheetSymbol[], code: string): FactsheetSymbol[] {
    const uniqueRowsByCode: { [code: string]: FactsheetSymbol } = {};
    symbols.forEach(symbol => {
      const existingItem = uniqueRowsByCode[code];
      if (
        !existingItem ||
        symbol.timeRef > existingItem.timeRef ||
        (symbol.timeRef === existingItem.timeRef && symbol.periodType === 'Prompt') ||
        (symbol.periodType === existingItem.periodType && symbol.period === '1')
      ) {
        uniqueRowsByCode[code] = symbol;
      }
    });

    return Object.values(uniqueRowsByCode);
  }
  const getFactsheet = async (code: string): Promise<FactsheetDetails[]> => {
    return await httpInstances.axiosInternalInstance
      .get('/factsheets/details?code=' + encodeURIComponent(code))
      .then(response => parseBackendData(response.data))
      .catch(error => {
        console.error('Error fetching factsheetDetails: ', error);
        navigate('/notfound');
      });
  };

  if (!factsheetDetails) {
    return (
      <Box sx={{ height: 700, width: 1000 }}>
        <Loader />
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
      <FactsheetHeader factsheet={factsheetDetails} />
      <FactsheetBody
        factsheet={factsheetDetails}
        openNewTab={openNewTab}
      />
    </Box>
  );
};
export default FactsheetWindow;
