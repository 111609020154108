import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table';
import { useLocation, useSearchParams } from 'react-router-dom';
import { RootState } from '../../store';
import {
  arrEqualsAll,
  CatalogItem,
  defaultVisibilityState,
  energyTransitionModules,
  extractExistingParams,
  forwardCurvesColumns,
  oilOnlyModules,
  SpecialFiltersType,
  spotColumns
} from './constants';
import { useAppDispatch } from 'hooks/redux-hooks';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';
import { FWDCURVE_CATALOG_PATH, SHOW_SELECTED_URL_PARAM } from 'utils/constants';
import { parseBackendData } from 'utils/commonFunctions';
import { getForwardCatalog } from 'slices/forwardCatalogSlice';
import { getSpotCatalog } from 'slices/spotCatalogSlice';

const useCatalog = () => {
  const location = useLocation();
  const isForwardCurves = location.pathname.toLowerCase().includes(FWDCURVE_CATALOG_PATH);
  const columns = isForwardCurves ? forwardCurvesColumns : spotColumns;

  const [activeTab, setActiveTab] = useState<number>(isForwardCurves ? 0 : 1);
  const [showDiscontinued, setShowDiscontinued] = useState<boolean>(false);
  const [showSelectedSymbols, setShowSelectedSymbols] = useState(false);

  const [expandedData, setExpandedData] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [sorting, setSorting] = useState(isForwardCurves ? [] : [{ id: 'Alias', desc: false }]);
  const [specialFilters, setSpecialFilters] = useState<SpecialFiltersType>({
    oilOnly: false,
    energyTransitionOnly: false
  });
  const [columnVisibility, setColumnVisibility] = useState(defaultVisibilityState);

  const dispatch = useAppDispatch();
  const { isSymbolSelected } = useSelectionFunctions();
  const spotCatalogState = useSelector((state: RootState) => state.spotCatalog);
  const forwardCatalogState = useSelector((state: RootState) => state.forwardCatalog);
  const status = isForwardCurves ? forwardCatalogState.status : spotCatalogState.status;
  const catalog = isForwardCurves
    ? forwardCatalogState.forwardCatalog
    : spotCatalogState.spotCatalog;
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  useEffect(() => {
    const extracted = extractExistingParams(urlSearchParams);
    if (extracted[SHOW_SELECTED_URL_PARAM]) {
      setShowSelectedSymbols(extracted[SHOW_SELECTED_URL_PARAM][0] === 'true');
    }
    if (extracted['oilOnly'] || extracted['energyTransitionOnly']) {
      setSpecialFilters({
        ...specialFilters,
        oilOnly: extracted['oilOnly'][0] === 'true',
        energyTransitionOnly: extracted['energyTransitionOnly'][0] === 'true'
      });
    } else {
      setSpecialFilters({
        ...specialFilters,
        oilOnly: false,
        energyTransitionOnly: false
      });
    }
  }, [urlSearchParams]);

  useEffect(() => {
    clearState();
    setActiveTab(isForwardCurves ? 1 : 0);
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getForwardCatalog());
    dispatch(getSpotCatalog());
  }, []);

  const clearState = () => {
    setSpecialFilters({
      oilOnly: false,
      energyTransitionOnly: false
    });
    setFilterOpen(false);
    setColumnVisibility(prevState => ({
      ...prevState,
      TimeRef: isForwardCurves || expandedData,
      PeriodType: isForwardCurves || expandedData
    }));
  };

  const filterBySelection = (catalog: CatalogItem[]) => {
    return catalog.filter(catalogItem => isSymbolSelected(catalogItem.Symbol));
  };

  const filteredData = useMemo(() => {
    if (!catalog) return [];
    const filteredBySelection: CatalogItem[] =
      showSelectedSymbols && !isForwardCurves ? filterBySelection(catalog) : catalog;

    const filteredBySpecialFilters = parseBackendData(
      filteredBySelection.filter(catalogItem => {
        const { IndexModule } = catalogItem;

        const oilOnlyCondition =
          !specialFilters.oilOnly || oilOnlyModules.some(module => IndexModule.includes(module));

        const energyTransitionCondition =
          !specialFilters.energyTransitionOnly ||
          energyTransitionModules.some(module => IndexModule.includes(module));

        return oilOnlyCondition && energyTransitionCondition;
      })
    );

    if (expandedData || isForwardCurves) return filteredBySpecialFilters;
    else {
      const uniqueRowsByCode: { [code: string]: CatalogItem } = {};
      filteredBySpecialFilters.forEach(catalogItem => {
        const existingItem = uniqueRowsByCode[catalogItem.Code];
        if (
          !existingItem ||
          catalogItem.TimeRef > existingItem.TimeRef ||
          (catalogItem.TimeRef === existingItem.TimeRef && catalogItem.PeriodType === 'Prompt') ||
          (catalogItem.PeriodType === existingItem.PeriodType && catalogItem.Period === 1)
        ) {
          uniqueRowsByCode[catalogItem.Code] = catalogItem;
        }
      });
      return Object.values(uniqueRowsByCode);
    }
  }, [catalog, expandedData, columnVisibility, specialFilters, showSelectedSymbols]);

  const defaultColumn = {
    filterFn: arrEqualsAll
  };

  const table = useReactTable({
    data: filteredData,
    columns,
    defaultColumn,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    globalFilterFn: 'includesString',
    state: {
      columnVisibility,
      sorting
    },
    onColumnVisibilityChange: setColumnVisibility as OnChangeFn<VisibilityState>,
    onSortingChange: setSorting
  });
  return {
    status,
    filterOpen,
    setFilterOpen,
    filteredData,
    table,
    activeTab,
    setActiveTab,
    isForwardCurves,
    expandedData,
    setExpandedData,
    setUrlSearchParams,
    specialFilters,
    showSelectedSymbols,
    showDiscontinued,
    setShowDiscontinued
  };
};
export default useCatalog;
