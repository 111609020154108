import { Box, List, ListItem, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
export const TERMS_VERSION = '1.1';

const StyledList = styled(List)(() => ({
  padding: 0,
  '& .MuiListItem-root': { padding: 0 }
}));

const Clause = ({ children, count }: { count: string; children: ReactNode }) => {
  return (
    <ListItem disableGutters>
      <Box display={'flex'}>
        <Typography sx={{ minWidth: 55, width: 55 }}>{count}</Typography>
        <Typography
          variant='h5'
          fontSize={16}
        >
          {children}
        </Typography>
      </Box>
    </ListItem>
  );
};

const Paragraph = ({ children, count }: { children: ReactNode; count?: string }) => {
  return (
    <ListItem disableGutters>
      <Box display={'flex'}>
        {count && <Typography sx={{ minWidth: 55, width: 55 }}>{count}</Typography>}
        <Typography>{children}</Typography>
      </Box>
    </ListItem>
  );
};
const SubParagraph = ({ children, count }: { children: ReactNode; count?: string }) => {
  return (
    <ListItem>
      <Box
        display={'flex'}
        paddingLeft={'55px'}
      >
        {count && <Typography sx={{ minWidth: 30, width: 30 }}>{count}</Typography>}
        <Typography>{children}</Typography>
      </Box>
    </ListItem>
  );
};

export const TermsText = () => {
  return (
    <Box>
      <Typography>
        This Agreement sets out the key licence permissions and restrictions in respect of any
        access to and use of the Platform and Benchmark Data (each as defined below).
      </Typography>
      <StyledList>
        <Clause count='1'>PLATFORM AND BENCHMARK DATA LICENCE</Clause>
        <Paragraph count='1.1'>
          GX grants to the Subscriber a limited, revocable, non-exclusive, non-transferable,
          worldwide licence to:
        </Paragraph>
        <Paragraph count='1.1.1'>
          remotely access (i.e. on a SaaS basis) and use the Platform;
        </Paragraph>
        <Paragraph count='1.1.2'>access and use the Benchmark Data;</Paragraph>
        <SubParagraph>
          in each case, as set out in this Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>1</span>, and subject to
          the terms of this Agreement and the Acceptable Use Policy.
        </SubParagraph>
        <Paragraph count='1.2'>
          GX and its licensors expressly reserve any and all rights (including Intellectual Property
          Rights) in and to the Benchmark Data other than the limited licence rights granted in
          accordance with the Licence.
        </Paragraph>
        <Paragraph count='1.3'>
          Subject to Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>1.1</span>, Subscriber if a
          Single Subscriber may:
        </Paragraph>
        <Paragraph count='1.3.1'>
          use, copy and store the Benchmark Data only for its internal business purposes on a local
          machine;
        </Paragraph>
        <Paragraph count='1.3.2'>share limited extracts of Derived Data externally;</Paragraph>
        <Paragraph count='1.3.3'>
          share limited extracts of the Benchmark Data internally;
        </Paragraph>
        <Paragraph count='1.3.4'>
          any further purpose explicitly licensed in writing by GX.
        </Paragraph>
        <Paragraph count='1.4'>
          Subject to Clause 1.1, Subscriber if an Enterprise Subscriber may:
        </Paragraph>
        <Paragraph count='1.4.1'>
          use, copy and store the Benchmark Data only for its internal business purposes;
        </Paragraph>
        <Paragraph count='1.4.2'>
          integrate the Benchmark Data into other systems controlled by the Subscriber for internal
          business purposes;
        </Paragraph>{' '}
        <Paragraph count='1.4.3'>
          share limited extracts of the Benchmark Data with external 3rd parties;
        </Paragraph>{' '}
        <Paragraph count='1.4.4'>share Derived Data with external 3rd parties;</Paragraph>{' '}
        <Paragraph count='1.4.5'>
          any further purpose explicitly licensed in writing by GX.
        </Paragraph>
        <Paragraph count='1.5'>
          Notwithstanding Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>1.1</span>, this Agreement
          does not include any right:
        </Paragraph>
        <Paragraph count='1.5.1'>
          to create any Financial Product using the Benchmark Data or using any Derived Data;
        </Paragraph>
        <Paragraph count='1.5.2'>
          to resell or otherwise commercialise the Benchmark Data or any Derived Data; or
        </Paragraph>
        <Paragraph count='1.5.3'>to sub-license the Benchmark Data.</Paragraph>
        <Paragraph count='1.6'>
          The Subscriber shall notify GX if the Subscriber becomes aware of any use of the Benchmark
          Data not in compliance with this Agreement and promptly take all reasonable steps to
          prevent further breach and secure an appropriate remedy including suspending the provision
          of the Benchmark Data on GX’s request.
        </Paragraph>
        <Paragraph count='1.7'>
          The Subscriber may use the Benchmark Data to create Derived Data but shall not alter the
          Benchmark Data to make it inaccurate or misleading nor use the Benchmark Data or any part
          of it for any illegal purpose or otherwise than in compliance with the applicable laws in
          the jurisdictions in which the Subscriber operates.
        </Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='2'>TERM AND SCOPE</Clause>
        <Paragraph count='2.1'>
          This Agreement shall commence on the Commencement Date, and continue until terminated in
          line with Clause 13
        </Paragraph>
        <Paragraph count='2.2'>GX may at any time:</Paragraph>
        <Paragraph count='2.2.1'>change the content, format or nature of Benchmark Data;</Paragraph>
        <Paragraph count='2.2.2'>change the means of access to the Benchmark Data; or</Paragraph>
        <Paragraph count='2.2.3'>cease publishing a specific Benchmark.</Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='3'>DATA PROTECTION</Clause>
        <Paragraph count='3.1'>
          ‘<strong>controller</strong>‘, ‘<strong>personal data</strong>’, and ‘
          <strong>processing</strong>’ shall have the meaning ascribed to them by Article 4 of the
          UK GDPR.
        </Paragraph>
        <Paragraph count='3.2'>
          Each Party agrees that it shall: (i) act as a controller when processing any personal data
          for the purposes of performing its obligations under this Agreement;
        </Paragraph>
        <SubParagraph>
          and (ii) comply with its obligations under Data Protection Law, where applicable.
        </SubParagraph>
        <Paragraph count='3.3'>
          The Parties agree that they will at all times comply with the provisions and obligations
          imposed by the Data Protection Laws from time to time.
        </Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='4'>DATA PROTECTION</Clause>
        <Paragraph count='4.1'>The Parties acknowledge that:</Paragraph>
        <Paragraph count='4.1.1'>
          all Intellectual Property Rights in the Platform (including the Benchmark Data) are the
          property of GX or its licensors, as the case may be; and
        </Paragraph>
        <Paragraph count='4.1.2'>
          neither Party shall have any rights in or to the other Party’s data other than as
          expressly set out in this Agreement.
        </Paragraph>
        <Paragraph count='4.2'>
          Any display of the Benchmark Data by the Subscriber shall credit, wherever technically and
          commercially feasible, GX, any licensor of GX or any other source of the Benchmark Data
          specified by GX as the source of the Benchmark Data.
        </Paragraph>
        <Paragraph count='4.3'>
          The Subscriber acknowledges that reference in any element of the materials provided by GX
          to trade names or proprietary products where no specific acknowledgement of such names or
          products is made does not imply that such names or products may be regarded by the
          Subscriber as free for general use, outside the scope of the use authorised by this
          Agreement.
        </Paragraph>
        <Paragraph count='4.4'>
          If GX receives any feedback (which may consist of questions, comments, suggestions or the
          like) regarding the Platform services or Benchmark Data (collectively,{' '}
          <strong>“Feedback”</strong>), all rights, including IPR in such Feedback shall belong
          exclusively to GX and such shall be considered GX’s Confidential Information. Subscriber
          hereby irrevocably and unconditionally transfers and assigns to GX all IPR it has in such
          Feedback and waives any and all moral rights that Subscriber may have in respect thereto.
          It is further understood that use of Feedback, if any, may be made by GX at its sole
          discretion, and that GX in no way shall be obliged to make use of the Feedback.
        </Paragraph>
        <Paragraph count='4.5'>
          Any anonymous information, which is derived from the use of the Platform services (i.e.,
          metadata, aggregated and/or analytics information and/or intelligence relating to the
          operation, support, and/or Subscriber’s use, of the Platform) which is not personal data (
          <strong>“Analytics Information”</strong>) may be used for providing the Platform services,
          for development, and/or for statistical purposes. Such Analytics Information is GX’s
          exclusive property.
        </Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='5'>USE OF THE PLATFORM</Clause>
        <Paragraph count='5.1'>
          Access to the Platform and use of the Benchmark Data is conditional upon, Users:
        </Paragraph>
        <Paragraph count='5.1.1'>
          only using the Platform and Benchmark Data for lawful purposes;
        </Paragraph>
        <Paragraph count='5.1.2'>
          keeping account details and Security Features secure and confidential;
        </Paragraph>
        <Paragraph count='5.1.3'>
          not introducing any virus, worm, trojan horse, cancelbot or other destructive or
          contaminating program to the Platform;
        </Paragraph>
        <Paragraph count='5.1.4'>
          using their best endeavours to prevent any unauthorised access to, or use of, the Platform
          and/or any Benchmark Data and notifying GX promptly of any such unauthorised access or
          use; and
        </Paragraph>
        <Paragraph count='5.1.5'>
          ensuring that there is no abuse or fraudulent use (as defined in Section 6 of this
          Agreement) of the Platform.
        </Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='6'>ABUSE OR FRAUDULENT USE OF THE PLATFORM</Clause>
        <Paragraph count='6.1'>
          For the purposes of this Agreement, abuse and fraudulent use includes:
        </Paragraph>
        <Paragraph count='6.1.1'>
          any attempt to copy, duplicate, modify, create derivative works from or distribute all or
          any portion of the Platform except to the extent expressly set out in the Agreement or as
          otherwise permitted under any applicable law which is incapable of exclusion by agreement
          between the parties;
        </Paragraph>
        <Paragraph count='6.1.2'>
          any attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to
          human-perceivable form all or any part of the Platform, except as may be permitted under
          any applicable law which is incapable of exclusion by agreement between the parties;
        </Paragraph>
        <Paragraph count='6.1.3'>
          any attempt to access all or any part of the Platform in order to build a product or
          service which is capable of competing for business with GX’s services;
        </Paragraph>
        <Paragraph count='6.1.4'>
          any attempt to obtain, or assist third parties in obtaining, unauthorised access to the
          Platform or any related systems or networks;
        </Paragraph>
        <Paragraph count='6.1.5'>
          the carrying out of unauthorised invisible processing when using the Platform (including
          placing web bugs and other monitoring devices on computers or devices accessing the
          Platform);
        </Paragraph>
        <Paragraph count='6.1.6'>
          interfering with or damaging or disrupting the integrity or performance of the Platform;
          and
        </Paragraph>
        <Paragraph count='6.1.7'>
          assisting another to perform the acts prohibited in this Acceptable Use Policy.
        </Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='7'>SECURITY</Clause>
        <Paragraph count='7.1'>
          Users shall ensure that the Benchmark Data are kept secure by using the Security Features
          and in an encrypted form, and shall use the best available security practices and systems
          applicable to the use of the Benchmark Data to:
        </Paragraph>
        <Paragraph count='7.1.1'>
          enforce the use restrictions detailed within the Agreement; and
        </Paragraph>
        <Paragraph count='7.1.2'>
          prevent, and take prompt and proper remedial action against, unauthorised access, copying,
          modification, storage, reproduction, display or distribution of the Benchmark Data.
        </Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='8'>BREACH OF THIS POLICY</Clause>
        <Paragraph count='8.1'>
          If GX considers, acting reasonably, that a breach of Clauses 5, 6 or 7 has occurred, it
          may take such action as it deems appropriate and the User must co-operate with GX’s
          reasonable security investigations.
        </Paragraph>
        <Paragraph count='8.2'>
          Failure to comply with Clauses 5, 6 or 7 constitutes a material breach of the Agreement
          upon which Users are permitted to use the Platform, and may result in GX taking all or any
          of the following actions:
        </Paragraph>
        <Paragraph count='8.2.1'>
          immediate, temporary or permanent withdrawal of the User’s right to use the Platform;
        </Paragraph>
        <Paragraph count='8.2.2'>issue of a warning to the User;</Paragraph>
        <Paragraph count='8.2.3'>
          legal proceedings against the User for reimbursement of all costs on an indemnity basis
          (including, but not limited to, reasonable administrative and legal costs) resulting from
          the breach;
        </Paragraph>
        <Paragraph count='8.2.4'>further legal action against the User; or</Paragraph>
        <Paragraph count='8.2.5'>
          disclosure of such information to law enforcement authorities as GX reasonably feels is
          necessary or as required by law.
        </Paragraph>
        <Paragraph count='8.3'>
          GX excludes its liability for all action GX may take in response to breaches of this
          Acceptable Use Policy. The actions GX may take are not limited to those described above,
          and GX may take any other action it reasonably deems appropriate.
        </Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='9'>INDEMNITIES</Clause>
        <Paragraph count='9.1'>
          The Subscriber shall indemnify GX against any claims, losses, damages, costs (including
          all legal fees) and expenses incurred by or awarded against GX arising out of or in
          connection with the Subscriber’s breach of the Agreement.
        </Paragraph>
        <Paragraph count='9.1.1'>
          Subject to Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>11.4</span>, GX shall
          defend, at its expense, any third party action or suit brought against Subscriber alleging
          that the Subscriber’s use of the Benchmark Data infringes the IPR of a third party (
          <strong>“IPR Claim”</strong>) and GX will pay any damages awarded in a final judgment
          against Subscriber that are attributable to such IPR Claim, provided that: (i) Subscriber
          promptly notifies GX in writing of such claim; and (ii) Subscriber grants GX the sole
          authority to handle the defence or settlement of any such claim and provides GX with all
          reasonable information and assistance in connection therewith, at GX’s expense. GX will
          not be bound by any settlement that Subscriber enters into without GX’s prior written
          consent.
        </Paragraph>
        <Paragraph count='9.2'>
          Notwithstanding the foregoing, GX shall have no responsibility for IPR Claims where the
          IPR Claim in question is attributable to:
        </Paragraph>
        <Paragraph count='9.2.1'>
          possession, use, development, modification or retention of the Benchmark Data (wholly or
          in part) by the Subscriber other than in accordance with this Agreement;
        </Paragraph>
        <Paragraph count='9.2.2'>
          use of the Benchmark Data (wholly or in part) in combination with any hardware or software
          not supplied or specified by GX to the extent that the infringement would have been
          avoided by the use of the Benchmark Data (wholly or in part) not so combined; or
        </Paragraph>
        <Paragraph count='9.2.3'>
          use of the Benchmark Data (wholly or in part) in combination with any data not supplied or
          specified by GX to the extent that the infringement would have been avoided by the use of
          the Benchmark Data (wholly or in part) not so combined.
        </Paragraph>
        <Paragraph count='9.3'>
          If any IPR Claim is made, or in GX’s reasonable opinion is likely to be made, against the
          Subscriber, GX may at its sole option and expense:
        </Paragraph>
        <Paragraph count='9.3.1'>
          procure for the Subscriber the right to continue using, developing, modifying or retaining
          the Benchmark Data (wholly or in part) in accordance with this Agreement;
        </Paragraph>
        <Paragraph count='9.3.2'>
          modify the Benchmark Data (wholly or in part) so that they cease to be infringing;
        </Paragraph>
        <Paragraph count='9.3.3'>
          replace the Benchmark Data (wholly or in part) with non-infringing items; or
        </Paragraph>
        <Paragraph count='9.3.4'>
          terminate this Agreement immediately by notice in writing to the Subscriber.
        </Paragraph>
        <Paragraph count='9.4'>
          This Clause 5 is subject to Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>11.4</span> and states GX’s
          entire liability, and Subscriber’s exclusive remedy, for any IPR Claim.
        </Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='10'>WARRANTIES</Clause>
        <Paragraph count='10.1'>
          Except as expressly stated in this Agreement, all warranties, conditions and terms,
          whether express or implied by statute, common law or otherwise are hereby excluded to the
          extent permitted by law.
        </Paragraph>
        <Paragraph count='10.2'>
          Without limiting the effect of Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>10.1</span>:
        </Paragraph>
        <Paragraph count='10.2.1'>GX does not warrant that:</Paragraph>
        <SubParagraph count='(a)'>
          the access to the Benchmark Data will be free from interruption;
        </SubParagraph>
        <SubParagraph count='(b)'>
          the Benchmark Data will be compatible with any particular purpose;
        </SubParagraph>
        <SubParagraph count='(c)'>
          the Benchmark Data is accurate, complete, reliable, secure, useful, fit for purpose or
          timely; or
        </SubParagraph>
        <SubParagraph count='(d)'>
          the Benchmark Data has been tested for use by the Subscriber or any third party or that
          the Benchmark Data will be suitable for or be capable of being used by the Subscriber or
          any third party; and
        </SubParagraph>
        <Paragraph count='10.2.2'>
          the Benchmark Data is provided on an “as is” and “as available” basis without warranty,
          representation or assurance of any kind. Subject to Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>11.2</span>, the Subscriber
          is solely responsible for any losses resulting from its reliance on the Benchmark Data.
        </Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='11'>LIMITATION OF LIABILITY</Clause>
        <Paragraph count='11.1.1'>
          the Subscriber shall be solely responsible, as against GX, for any opinions,
          recommendations, forecasts or other conclusions made or actions taken by the Subscriber or
          any other third party based (wholly or in part) on the Benchmark Data unless otherwise set
          out in Clause <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>11</span>;
          and
        </Paragraph>
        <Paragraph count='11.1.2'>
          the Subscriber acknowledge that it is in the best position to ascertain any likely loss it
          may suffer in connection with this Agreement, that it is therefore responsible for making
          appropriate insurance arrangements to address the risk of any such loss and that the
          provisions of this Clause 11 are reasonable in these circumstances.
        </Paragraph>
        <Paragraph count='11.2'>
          Neither Party excludes or limits liability to the other Party for:
        </Paragraph>
        <Paragraph count='11.2.1'>fraud or fraudulent misrepresentation;</Paragraph>
        <Paragraph count='11.2.2'>death or personal injury caused by negligence;</Paragraph>
        <Paragraph count='11.2.3'>
          a breach of any obligations implied by section 12 of the Sale of Goods Act 1979 or section
          2 of the Supply of Goods and Services Act 1982; or
        </Paragraph>
        <Paragraph count='11.2.4'>
          any matter in respect of which it would be unlawful for the Parties to exclude liability
          for respectively.
        </Paragraph>
        <Paragraph count='11.3'>
          Subject to Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>11.2</span>, GX shall not
          be liable to the Subscriber under or in connection with the Agreement for any and all of
          the following, howsoever arising and whether foreseeable or in the contemplation of the
          Parties and whether directly or indirectly arising out of breach of contract, tort
          (including negligence), breach of statutory duty, indemnity or otherwise of management
          time; trading losses; loss of income; loss of actual or anticipated profits; loss of
          business; loss of contracts; loss of goodwill or reputation; payments to the Subscriber’s
          customers or other third parties (whether due under contract or for breach of statutory
          duty, or pursuant to any order of any Court or regulatory authority, or made on an ex
          gratia basis, or otherwise); wasted expenditure; loss of opportunity; loss of anticipated
          savings; account of profits; loss of, damage to or corruption of data and/or loss or theft
          of, or damage to, any medium on which data is stored; costs of replacement services and
          workarounds (including costs of procuring such services and/or third party charges for
          their supply); costs of enforcing the Agreement; increases in insurance premia or excess;
          (to the extent permitted under applicable law), fines or statutory financial sanctions;
          obligations to pay aggravated, special, exemplary or punitive damages; and/or indirect,
          consequential or special loss or damage of any kind.
        </Paragraph>
        <Paragraph count='11.4'>
          Subject to Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>11.2</span> and Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>11.3</span>, GX’s aggregate
          liability in contract, tort (including negligence, breach of statutory duty, liability
          under indemnities or otherwise) or otherwise under or in connection with any and all
          claims made relating to this Agreement will not exceed an amount equal to £1,000.
        </Paragraph>
        <Paragraph count='11.5'>
          Without limiting the effect of the other provisions of this Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>11</span>, if this
          Agreement is terminated for any reason other than termination by the Subscriber on the
          ground of GX’s material breach under Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>13.5.1</span>, GX shall not
          be liable:
        </Paragraph>
        <Paragraph count='11.5.1'>
          to provide the Subscriber with access to the Platform or the Benchmark Data or any
          product, service or solution relating to the Benchmark Data; or
        </Paragraph>
        <Paragraph count='11.5.2'>
          for the consequences of the inability of the Subscriber to comply with the terms of any
          other arrangements which the Subscriber may have entered into with any third party.
        </Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='12'>DATA PROVIDERS</Clause>
        <SubParagraph>
          The Subscriber acknowledges that each Data Provider and any other provider to GX has the
          benefit of and may enforce the exclusions and limitations set out in Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>10</span> and Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>11</span> as if the
          provisions of this Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>12</span> were set out in
          full in this Agreement and each reference to GX were replaced by that Data Provider or
          other provider (as the case may be), and each reference to Benchmark Data were replaced by
          Data Provider Data.
        </SubParagraph>
      </StyledList>
      <StyledList>
        <Clause count='13'>INTRODUCTION OF CHARGES AND TERMINATION</Clause>
        <Paragraph count='13.1'>
          Subscribers can access the Platform either free or for a charge at GX’s discretion.
        </Paragraph>
        <Paragraph count='13.2'>
          GX will give the Subscriber notice of the introduction of, or changes to the cost of
          access to the Platform and Benchmark Data.
        </Paragraph>
        <Paragraph count='13.3'>
          Such notice will be given through either (a.) the issuing of an Order Form by GX to the
          Subscriber outlining subscription costs, Initial Term, Renewal Term and any special terms
          supplementary to this Agreement, the terms of which will continue to apply or (b.) via a
          notification on the Platform. Where a conflict exists between an Order Form and this
          Agreement, the Order Form take precedence.
        </Paragraph>
        <Paragraph count='13.4'>The Agreement may be terminated at any time:</Paragraph>
        <Paragraph count='13.4.1'>
          at will by either Party on the provision of at least 60 days’ prior written notice to that
          effect to the other Party provided that such notice shall not have effect until the expiry
          of the then current Initial Term or Renewal Term (as applicable); and/or
        </Paragraph>
        <Paragraph count='13.4.2'>
          by GX by written notice if the Subscriber is engaged in any activity that GX reasonably
          determines to be detrimental to the business of GX. Such notice may in GX’s absolute
          discretion take effect immediately or give the Subscriber an opportunity to cease or
          modify the relevant activity.
        </Paragraph>
        <Paragraph count='13.5'>
          Without prejudice to any rights that have accrued under this Agreement or any of its
          rights or remedies, either Party may terminate this Agreement with immediate effect by
          giving written notice to the other Party if:
        </Paragraph>
        <Paragraph count='13.5.1'>
          the other Party is in material breach of the Agreement (including repeated breaches which
          in aggregate constitute a material breach) and (where the matter is capable of remedy) has
          failed to remedy such default or breach within thirty days (30) following the date on
          which notice requiring such remedy is given (or deemed to be given) to the defaulting
          Party; or
        </Paragraph>
        <Paragraph count='13.5.2'>
          the other Party becomes insolvent or suspends, or threatens to suspend, payment of its
          debts or is unable to pay its debts as they fall due or admits inability to pay its debts
          or is deemed unable to pay its debts within the meaning of section 123 of the Insolvency
          Act 1986.
        </Paragraph>
        <Paragraph count='13.6'>
          Without prejudice to any rights that have accrued under this Agreement or any of its
          rights or remedies, in the event that Subscriber is affiliated, employed or associated
          with an incorporated legal entity, without prejudice to any rights that have accrued under
          this Agreement or any of its rights or remedies, the Subscriber Legal Entity must provide
          30 days’ written notice to GX if the incorporated legal entity undergoes a change of
          control (within the meaning of section 1124 of the Corporation Tax Act 2010) and GX shall
          have the option to terminate this Agreement within 30 days from the date of such notice.
        </Paragraph>
        <Paragraph count='13.7'>
          Any provision of this Agreement that expressly or by implication is intended to come into
          or continue in force on or after termination or expiry of this Agreement shall remain in
          full force and effect.
        </Paragraph>
        <Paragraph count='13.8'>
          The termination of the Agreement for any reason shall not give either Party any right to
          claim from the other or impose on either Party any liability to pay any compensation,
          indemnity or reimbursement whatsoever by reason of such termination, but termination shall
          be without prejudice to any rights or remedies available to, or any obligations or
          liabilities accrued to, either Party at the effective date of termination.
        </Paragraph>
        <Paragraph count='13.9'>
          On any termination of this Agreement for any reason or expiry of the Term, all licences
          granted by GX shall terminate and the Subscriber shall:
        </Paragraph>
        <Paragraph count='13.9.1'>
          immediately cease accessing and/or using the Platform and Benchmark Data;
        </Paragraph>
        <Paragraph count='13.9.2'>
          ensure that they receive no further Benchmark Data in any of the Subscriber’s products,
          applications or services, provided that the Subscriber shall not be obliged to remove from
          its products, applications and services any Benchmark Data incorporated into them in
          accordance with this Agreement before termination or expiry; and
        </Paragraph>
        <Paragraph count='13.9.3'>
          ensure that there is no further distribution of any new Benchmark Data to any third party;
          and
        </Paragraph>
        <Paragraph count='13.9.4'>
          return (or at GX’s option, erase or destroy) all Confidential Information excluding
          Benchmark Data but including other material provided to it in connection with the
          Agreement.
        </Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='14'>ANNOUNCEMENTS</Clause>
        <SubParagraph>
          Subject to Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>15.2</span>, either Party
          may make a public announcement of the existence of this Agreement, and that the Subscriber
          is a client in respect of the Benchmark Data. Subscriber hereby agrees that GX may make
          reasonable use of, and reference to, the Subscriber’s name and trade or service mark and
          the Subscriber’s use of the Platform services in any promotional and marketing material
          relating to GX’s services on GX’s website or otherwise. Following the termination of this
          Agreement, Subscriber may request that GX removes such reference.
        </SubParagraph>
      </StyledList>
      <StyledList>
        <Clause count='15'>GENERAL</Clause>
        <Paragraph count='15.1'>
          The Platform may use or include third party open source software, files, libraries or
          components that may be distributed to Subscriber and are subject to third party open
          source license terms. If there is a conflict between any open source license and the terms
          of this Agreement, then the open source license terms shall prevail but solely in
          connection with the related third party open source software. GX makes no warranty or
          indemnity under this Agreement with respect to any third party open source software.
        </Paragraph>
        <Paragraph count='15.2'>
          Subscriber will not at any time: (i) use any Confidential Information except strictly as
          necessary for the purpose of this Agreement; (ii) disclose to any person any Confidential
          Information; or (iii) release or authorise any press release, media communication or
          publication of any sort in relation to this Agreement without the prior written consent of
          GX. This includes the use of GX’s name or brand in any publicity or marketing materials.
        </Paragraph>
        <Paragraph count='15.3'>
          Notices must be sent in writing to the address to the address as a party may specify in
          writing. Notices may be sent by hand, by email, by registered post, or by courier (except
          that any notice of termination or breach may not be served by email).
        </Paragraph>
        <Paragraph count='15.4'>
          The Subscriber may not assign, novate or otherwise transfer, or sub-contract, any or all
          of its rights, liabilities or obligations under this Agreement to a third party without
          the prior written consent of GX.
        </Paragraph>
        <Paragraph count='15.5'>
          GX may assign, novate or otherwise transfer, or sub-contract, all or any of its rights,
          liabilities and/or obligations under the Agreement. GX shall be liable for the acts and
          omissions of its sub-contractors.
        </Paragraph>
        <Paragraph count='15.6'>
          Any right or remedy granted to a Party under this Agreement, or the exercise of any such
          right or remedy, does not prejudice, limit or exclude any other right or remedy to which
          that party is or may become entitled. A Party’s failure or delay in exercising any right
          or remedy will not waive such right or remedy.
        </Paragraph>
        <Paragraph count='15.7'>
          The Agreement constitutes the entire agreement and understanding between the Parties
          regarding its subject matter and supersedes any prior agreement, understanding or
          arrangement between the Parties, whether oral or written, regarding such subject matter.
          Without prejudice to the foregoing, to the extent that this Agreement conflicts with any
          prior Agreement entered into by the Parties in respect of the Platform and/or Benchmark
          Data, such prior Agreement will take precedence.
        </Paragraph>
        <Paragraph count='15.8'>
          Each Party agrees that in entering into the Agreement it does not rely on (and shall have
          no remedy in respect of) any statement, representation, assurance or warranty (whether
          oral or written, express or implied) by the other Party or any other person, except as
          expressly set out in this Agreement unless such statement, representation, assurance or
          warranty was made fraudulently.
        </Paragraph>
        <Paragraph count='15.9'>
          Except as expressly provided in this Agreement, no variation of this Agreement shall be
          effective unless it is in writing and signed by the Parties (or their authorised
          representatives).
        </Paragraph>
        <Paragraph count='15.10'>
          If any court finds that any part of this Agreement is illegal or unenforceable, that part
          will be considered removed, but no other part of the Agreement will be affected.
        </Paragraph>
        <Paragraph count='15.11'>
          Nothing in this Agreement is intended to, or shall be deemed to, establish any partnership
          or joint venture between any of the Parties, constitute any Party the agent of another
          Party, or authorise any Party to make or enter into any commitments for or on behalf of
          any other Party.
        </Paragraph>
        <Paragraph count='15.12'>
          Each Party represents that it is duly organised, validly existing and in good standing
          under the laws of its jurisdiction of incorporation or organisation; and that the
          execution and performance of this Agreement will not conflict with other agreements to
          which it is bound or violate applicable law.
        </Paragraph>
        <Paragraph count='15.13'>
          Except as expressly set forth herein nothing in this Agreement gives rise to any rights
          under any legislation in any jurisdiction for a third party to enforce any terms of this
          Agreement.
        </Paragraph>
        <Paragraph count='15.14'>
          Any claim or dispute in relation to this Agreement will be governed by and interpreted in
          accordance with the laws of England and Wales. The courts of England and Wales will have
          exclusive jurisdiction over any dispute or claim arising out of or in connection with it.
        </Paragraph>
      </StyledList>
      <StyledList>
        <Clause count='16'>DEFINITIONS AND INTERPRETATION</Clause>
        <Paragraph count='16.1'>
          In this Agreement, unless the context otherwise requires, the following words shall have
          the following meanings:
        </Paragraph>
        <SubParagraph>
          <strong>Agreement</strong>: means this agreement and the terms and conditions within.
        </SubParagraph>
        <SubParagraph>
          <strong>Benchmark</strong>: means the price benchmark or index for the relevant commodity
          market, provided by GX.
        </SubParagraph>
        <SubParagraph>
          <strong>Benchmark Data</strong>: means any and all data emanating directly or indirectly
          from the Benchmark Services.
        </SubParagraph>
        <SubParagraph>
          <strong>Benchmark Services</strong>: means the services offered by GX under this Agreement
          including (but not limited to) access to and use of the Agreement.{' '}
        </SubParagraph>
        <SubParagraph>
          <strong>Commencement Date</strong>: means the date that Subscriber first accesses or uses
          the Platform or Benchmark Data.
        </SubParagraph>
        <SubParagraph>
          <strong>Confidential Information</strong>: means the terms of this Agreement and all
          confidential information or material in any form (whether before, on or after the date of
          this Agreement) provided by or on behalf of GX in connection with this Agreement.
        </SubParagraph>
        <SubParagraph>
          <strong>Data Protection Laws</strong>: means all applicable data protection and privacy
          legislation in force from time to time in the UK including the General Data Protection
          Regulation ((EU) 2016/679) (<strong>GDPR</strong>); the UK GDPR; any national legislation
          implementing the Privacy and Electronic Communications Directive 2002/58/EC, including any
          amendments or replacements to them.
        </SubParagraph>
        <SubParagraph>
          <strong>Data Provider</strong>: means a third party whose data, information, software or
          other material is supplied to GX and used to create Benchmark Data.
        </SubParagraph>
        <SubParagraph>
          <strong>Data Provider Data</strong>: means data provided to GX by a Data Provider.
        </SubParagraph>
        <SubParagraph>
          <strong>Derived Data</strong>: means data created by the Subscriber which uses Benchmark
          Data as an input, provided that such data: (a) is not reverse engineerable from such
          Benchmark Data; and (b) cannot be used as a functional substitute for such Benchmark Data.
        </SubParagraph>
        <SubParagraph>
          <strong>Enterprise Subscriber</strong>: means a collection of users as defined in the
          Order Form.
        </SubParagraph>
        <SubParagraph>
          <strong>Financial Product</strong>: means any exchange-traded fund, indexes, institutional
          or retail fund, derivative contract or other tradable financial instrument and any other
          interest provided for commercial value, whether or not publicly traded.
        </SubParagraph>
        <SubParagraph>
          <strong>Initial Term</strong>: means 12 months from the date of this agreement, or the
          Initial Term specified in the Order Form. Where a conflict exists between an Order Form
          and this Agreement, the Order Form takes precedence.
        </SubParagraph>
        <SubParagraph>
          <strong>Intellectual Property Rights</strong> or <strong>IPR</strong>: means all copyright
          and related rights, moral rights, database rights, trade marks and trade, business and
          domain names, rights in trade dress or get-up, rights to goodwill and to sue in passing
          off, rights in designs, rights in computer software, patents, rights to inventions,
          utility models, rights in semiconductor or circuit layouts and the right to have
          confidential information (including know-how and trade secrets) kept confidential and any
          and all other intellectual property rights, in each case whether registered or
          unregistered, and including all applications (and rights to apply) for, and renewals and
          extensions of and rights to claim priority from such rights, and any and all similar or
          analogous rights and forms of protection, which in each case subsist now or in the future
          in any part of the world.
        </SubParagraph>
        <SubParagraph>
          <strong>Licence</strong>: means the licence granted in Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>1.1</span>.
        </SubParagraph>
        <SubParagraph>
          <strong>Order Form</strong>: means an Order Form detailing commercial and other additional
          terms executed by the Subscriber.
        </SubParagraph>
        <SubParagraph>
          <strong>Party/Parties</strong>: means the Subscriber and/or GX as applicable.
        </SubParagraph>
        <SubParagraph>
          <strong>Platform</strong>: means GX’s proprietary software application named GX GO
          available at http/go.g-x.co.
        </SubParagraph>
        <SubParagraph>
          <strong>Renewal Term</strong>: means 12 months from the end of the Initial Term or the
          Renewal Term period specified in the Order Form. Where a conflict exists between an Order
          Form and this Agreement, the Order Form take precedence.
        </SubParagraph>
        <SubParagraph>
          <strong>Single Subscriber</strong>: means a user who is provided with access to the
          Platform and/or Benchmark Data by GX through an individual login account or as otherwise
          defined in an Order Form. If no Order Form exists, user is a Single Subscriber.
        </SubParagraph>
        <SubParagraph>
          <strong>Subscriber</strong>: means a single or multiple individuals who are provided with
          access to the Platform and/or Benchmark Data by GX through individual login accounts or as
          otherwise defined in an Order FormSubscriber is either a Single Subscriber or Enterprise
          Subscriber.
        </SubParagraph>
        <SubParagraph>
          <strong>Subscriber Legal Entity</strong>: meaning the legal entity with which the
          Subscriber is affiliated, employed or associated.
        </SubParagraph>
        <SubParagraph>
          <strong>Term</strong>: has the meaning given in Clause{' '}
          <span style={{ backgroundColor: 'rgba(248, 248, 248, 0.20)' }}>2.1</span>.
        </SubParagraph>
        <SubParagraph>
          <strong>UK GDPR</strong>: means the Data Protection Act 2018 and the GDPR as transposed
          into the laws of the United Kingdom by operation of section 3 of the European Union
          (Withdrawal) Act 2018.
        </SubParagraph>
        <SubParagraph>
          <strong>Year</strong>: means the period of twelve (12) months from the Commencement Date
          of this Agreement and each consecutive period of twelve (12) months thereafter during the
          term of this Agreement.
        </SubParagraph>
      </StyledList>
      <StyledList>
        <Clause count='17'>INTERPRETATION</Clause>
        <Paragraph count='17.1'>In this Agreement a reference to:</Paragraph>
        <SubParagraph count='(a)'>
          a Clause is, except where expressly stated otherwise, a reference to a clause of this
          Agreement;
        </SubParagraph>
        <SubParagraph count='(b)'>
          a person includes a natural person, corporate or unincorporated body (whether or not
          having separate legal personality). A reference to a company shall include any company,
          corporation or other body corporate, wherever and however incorporated or established;
        </SubParagraph>
        <SubParagraph count='(c)'>
          a Party is a party to the Agreement and includes that Party’s successors and permitted
          transferees;
        </SubParagraph>
        <SubParagraph count='(d)'>
          a word importing the singular shall (where appropriate) include the plural and vice versa;
          and
        </SubParagraph>
        <SubParagraph count='(e)'>
          use of the terms <strong>include(s)</strong>, <strong>including</strong>,{' '}
          <strong>such as</strong>, or similar expressions, will be read without limitation;
        </SubParagraph>
        <Paragraph count='17.2'>
          The headings in this Agreement are for convenience only and shall not affect its
          interpretation.
        </Paragraph>
      </StyledList>
    </Box>
  );
};
