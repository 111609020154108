import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { emailValidator } from 'utils/validators';
import StepButtons from 'pages/Onboarding/OnboardingForm/Steps/StepButtons';
import { InviteRecipient } from 'pages/Onboarding/OnboardingForm/useOnboardingForm';
const Invite = ({
  activeStep,
  setActiveStep,
  inviteRecipients,
  setInviteRecipients
}: {
  inviteRecipients: InviteRecipient[];
  setInviteRecipients: Dispatch<SetStateAction<InviteRecipient[]>>;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  const [emailErrors, setEmailErrors] = useState<Map<string, string>>(new Map());

  const validateEmail = () => {
    const errors: Map<string, string> = new Map();
    inviteRecipients.forEach(recipient => {
      const { email, id } = recipient;
      if (email.length) {
        const errorMessage = emailValidator(email);
        if (errorMessage) {
          errors.set(id, errorMessage);
        }
      }
    });
    setEmailErrors(errors);
    return !errors
      .values()
      .filter(email => email?.length > 0)
      .toArray().length;
  };

  const handleRecipientChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    emailInput: InviteRecipient
  ) => {
    {
      const errors = new Map(emailErrors);
      let temp: InviteRecipient[] = inviteRecipients.map(inviteRecipient => {
        if (inviteRecipient.id === emailInput.id) {
          errors.delete(inviteRecipient.id);
          return { email: e.target.value, id: inviteRecipient.id };
        }
        return inviteRecipient;
      });

      temp = temp.filter(rec => rec.email.length);

      if (temp.filter(rec => rec.email.length).length === temp.length) {
        temp = [...temp, { email: '', id: Math.random().toString(32) }];
      }
      setInviteRecipients(temp);
      setEmailErrors(errors);
    }
  };
  return (
    <Box>
      <Typography sx={{ fontSize: 24, fontWeight: 'bold', mb: 1.5 }}>
        Data is more valuable when it’s shared!
      </Typography>
      <Typography sx={{ fontSize: 20, fontWeight: 'bold', mb: 1.5 }}>
        Invite your teammates to collaborate...
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {inviteRecipients.map(emailInput => {
          return (
            <TextField
              key={emailInput.id}
              variant='filled'
              autoComplete={'inviteEmail' + emailInput.id}
              id={'inviteEmail' + emailInput.id}
              label='Work email'
              name='inviteEmail'
              sx={{ minWidth: 220, mb: 1.5 }}
              value={emailInput.email}
              onChange={e => handleRecipientChange(e, emailInput)}
              error={!!emailErrors.get(emailInput.id)}
              helperText={emailErrors.get(emailInput.id)}
            />
          );
        })}
      </Box>
      <Box>
        <StepButtons
          activeStep={activeStep}
          onBackClick={() => {
            setActiveStep(activeStep - 1);
          }}
          nextDisabled={!inviteRecipients.filter(recipient => recipient.email?.length > 0).length}
          onNextClick={() => {
            if (validateEmail()) setActiveStep(activeStep + 1);
          }}
        />
        <Button
          fullWidth
          variant='outlined'
          disabled={activeStep === 0}
          sx={{
            '&.MuiButton-outlined': {
              color: 'primary.main',
              borderColor: 'primary.main'
            },
            fontSize: 16,
            fontWeight: 600,
            mt: 1
          }}
          onClick={() => {
            setActiveStep(activeStep + 1);
          }}
        >
          Skip for now
        </Button>
      </Box>
    </Box>
  );
};
export default Invite;
