import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux-hooks';
import { getMarkets } from 'slices/marketsSlice';
import { RootState } from 'store';

const useMarkets = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { marketsList, status: marketListStatus } = useSelector(
    (state: RootState) => state.marketsList
  );

  const { userConfig, status: configStatus } = useSelector((state: RootState) => state.userConfig);
  const [activeTab, setActiveTab] = useState(0);

  const marketExists = (marketId: string) => {
    return marketsList.some(market => market.marketId === marketId);
  };

  useEffect(() => {
    // if the marketId is not in lowercase, redirect to the lowercase version
    if (params.marketId && params.marketId.toLowerCase() !== params.marketId) {
      if (params.tabId) {
        navigate(`/markets/${params.marketId.toLowerCase()}/${params.tabId}`);
      } else {
        navigate(`/markets/${params.marketId.toLowerCase()}`);
      }
    } else if (
      !params?.marketId &&
      marketListStatus === 'fulfilled' &&
      configStatus !== 'loading'
    ) {
      let marketId = marketsList[0]?.marketId;
      if (configStatus === 'fulfilled') {
        const defaultMarket = userConfig.defaultMarket.value;
        if (defaultMarket.length && checkMarketsInList(defaultMarket.toLowerCase())) {
          marketId = defaultMarket.toLowerCase();
        }
      }
      navigate(`/markets/${marketId}`);
    } else if (
      params?.marketId &&
      marketListStatus === 'fulfilled' &&
      !marketExists(params.marketId)
    ) {
      navigate(`/notfound`);
    }
  }, [marketsList, configStatus]);

  useEffect(() => {
    // it is important for changing tabs
    setActiveTab(0);
    fetchMarkets();
  }, [params.marketId, marketsList, configStatus]);

  const fetchMarkets = () => {
    if (params?.marketId && configStatus !== 'loading') {
      dispatch(getMarkets({ marketId: params.marketId }));
    }
  };

  const checkMarketsInList = (defaultMarket: string) => {
    return marketsList.some(market => market.marketId === defaultMarket);
  };

  return {
    activeTab,
    setActiveTab
  };
};

export default useMarkets;
