import StockChart from 'components/Chart/StockChart';
import useChartSeries from 'components/Chart/useChartSeries';
import { TemplateChartSeries } from 'pages/Markets/MarketsComponents/types';
import useUserDetails from 'hooks/useUserAccess';
import { mockChartSeries } from 'pages/DataHub/constants';

const AuthenticatedChart = ({
  templateChartSeries,
  code
}: {
  templateChartSeries: TemplateChartSeries[];
  code: string;
}) => {
  const { loading, error, chartSeries } = useChartSeries(templateChartSeries);
  const { checkUserHasPremium } = useUserDetails();
  return (
    <StockChart
      height={14}
      chartSeries={checkUserHasPremium() ? chartSeries : mockChartSeries}
      error={error}
      loading={loading}
      parent={{
        mainPageTitle: 'factsheets',
        tabName: code
      }}
      showLegend={false}
    />
  );
};
export default AuthenticatedChart;
