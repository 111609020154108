import { Box, Button, Link, Typography } from '@mui/material';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import usePythonPreview from './usePythonPreview';
import useUserDetails from 'hooks/useUserAccess';
import UpgradeToPremiumButton from 'pages/DataHub/UpgradeToPremiumScreen/UpgradeToPremiumButton';
import useCopyToClipboard from 'utils/useCopyToClipboard';

const PythonPreviewScreen = ({
  exportType,
  from,
  to,
  fromPeriodType,
  fromPeriod,
  includeMetadata,
  capturePosthogEvent
}: {
  exportType: string;
  from?: Date | null;
  to?: Date | null;
  fromPeriodType?: string;
  fromPeriod?: string;
  includeMetadata: boolean;
  capturePosthogEvent: () => void;
}) => {
  const { loading, error, fileContent, downloadFile } = usePythonPreview(
    exportType,
    includeMetadata,
    capturePosthogEvent,
    from,
    to,
    fromPeriodType,
    fromPeriod
  );

  const { copyToClipboard } = useCopyToClipboard();

  const { checkUserHasPremium } = useUserDetails();

  const docsLink = (
    <Typography sx={{ p: 2, fontSize: '12px' }}>
      For more information on the GX SDK, please visit our{' '}
      <Link
        href={`https://docs.g-x.co/#python-sdk`}
        target='_blank'
        rel='noopener noreferrer'
        underline='hover'
      >
        documentation site
      </Link>
      .
    </Typography>
  );

  if (!checkUserHasPremium())
    return (
      <>
        <UpgradeToPremiumButton sx={{ ml: 2 }} />
        {docsLink}
      </>
    );

  return (
    <Box sx={{ minWidth: 470, maxWidth: 735 }}>
      <Box sx={{ ml: 2, display: 'flex' }}>
        <Button
          variant='contained'
          onClick={() => {
            capturePosthogEvent();
            copyToClipboard(fileContent, 'Code snippet copied to clipboard!');
          }}
          disabled={!fileContent || loading}
          sx={{ mr: 2 }}
        >
          Copy to clipboard
        </Button>
        <Button
          variant='contained'
          onClick={downloadFile}
          disabled={!fileContent || loading}
        >
          Download
        </Button>
      </Box>
      {error && (
        <Typography
          color='error'
          sx={{ p: 2, fontSize: '14px' }}
        >
          An error occurred while fetching data. Please try again.
        </Typography>
      )}
      {loading ? (
        <Typography sx={{ p: 2, fontSize: '14px' }}>
          Retrieving your data, please wait...
        </Typography>
      ) : (
        <Box
          sx={{
            whiteSpace: 'pre',
            fontFamily: 'monospace',
            fontSize: '14px',
            mt: 3
          }}
        >
          {docsLink}
          <SyntaxHighlighter
            language='python'
            style={nightOwl}
            customStyle={{ backgroundColor: '#1A224D', margin: 0, height: 487 }}
          >
            {fileContent}
          </SyntaxHighlighter>
        </Box>
      )}
    </Box>
  );
};

export default PythonPreviewScreen;
