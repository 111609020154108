import { Box } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import FactsheetView from 'pages/Facthsheets/FactsheetView';
import { FACTSHEET_PARAM_NAME, FACTSHEET_TABS_PARAM_NAME } from 'pages/Facthsheets/constants';
import { getGxTokenFromStorage } from 'utils/commonFunctions';
import { useAppDispatch } from 'hooks/redux-hooks';
import { loginTrip } from 'slices/authSlice';

const Factsheets = () => {
  const params = useParams();
  const factsheetKey = params[FACTSHEET_PARAM_NAME];
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [additionalTabs, setAdditionalTabs] = useState<string[]>([]);
  const gxToken = getGxTokenFromStorage();
  const dispatch = useAppDispatch();
  const setAdditionalTabsParam = (tabs: string[]) => {
    if (tabs.length === 0) {
      const temp = { ...urlSearchParams };
      // @ts-expect-error --deleting
      delete temp[FACTSHEET_TABS_PARAM_NAME];
      setUrlSearchParams({ ...urlSearchParams, ...temp });
    } else {
      const temp = { [FACTSHEET_TABS_PARAM_NAME]: tabs.join(',') };
      setUrlSearchParams({ ...urlSearchParams, ...temp });
    }
  };
  useEffect(() => {
    if (gxToken) {
      dispatch(loginTrip(gxToken));
    }
  }, [gxToken]);

  useEffect(() => {
    const additionalTabsParam = decodeURIComponent(
      urlSearchParams.get(FACTSHEET_TABS_PARAM_NAME) ?? ''
    );
    if (additionalTabsParam) {
      setAdditionalTabs(additionalTabsParam.split(','));
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#12163fcc',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        height: '100vh'
      }}
    >
      {factsheetKey && (
        <FactsheetView
          factsheetKeys={[factsheetKey, ...additionalTabs].join(',')}
          setAdditionalTabsParam={setAdditionalTabsParam}
        />
      )}
    </Box>
  );
};

export default Factsheets;
