import { SxProps, Theme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';

const LegendCheckBox = ({
  series,
  checkboxStyles
}: {
  series: any;
  checkboxStyles: SxProps<Theme>;
}) => {
  const { isSymbolSelected, removeSymbolFromSelection, addSymbolToSelection } =
    useSelectionFunctions();
  const metadata = series.userOptions.metadata;
  const symbol: SelectedSymbol = {
    Code: metadata.Code,
    Period: metadata.Period,
    PeriodType: metadata.PeriodType,
    TimeRef: metadata.TimeRef
  };

  const handleChange = () => {
    if (isSymbolSelected(symbol)) {
      removeSymbolFromSelection(symbol);
    } else {
      addSymbolToSelection(symbol);
    }
  };
  return (
    <Checkbox
      sx={{ ...checkboxStyles }}
      checked={isSymbolSelected(symbol)}
      onChange={handleChange}
      size='small'
    />
  );
};
export default LegendCheckBox;
