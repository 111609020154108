import { ReactElement } from 'react';
import { HubspotTrackingLister } from './hubspotTrackingListener';
import { PostHogTrackingLister } from './PostHog/postHogTrackingListener';
import { detectEnvironment } from 'utils/commonFunctions';

export function TrackingLister({ children }: { children: ReactElement }) {
  if (detectEnvironment() === 'local') return children;
  return (
    <HubspotTrackingLister>
      <PostHogTrackingLister>{children}</PostHogTrackingLister>
    </HubspotTrackingLister>
  );
}
