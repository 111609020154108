import { ChangeEvent, useEffect, useState } from 'react';
import { TextField, IconButton, Box, Typography, ButtonBase } from '@mui/material';
import { Check, Clear, ModeEditOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { RootState } from '../../store';
import { estimateLastSaved } from 'utils/commonFunctions';
import { saveDataHub } from 'slices/dataHubSlice';
import { useAppDispatch } from 'hooks/redux-hooks';
import useUserDetails from 'hooks/useUserAccess';

const EditableName = () => {
  const dispatch = useAppDispatch();
  const { checkUserHasPremium } = useUserDetails();

  const isPremiumUser = checkUserHasPremium();
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const activeDataHub = dataHubs.find(dataHub => dataHub.id === activeDataHubId);

  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState<string>('');
  const [error, setError] = useState('');

  useEffect(() => {
    setNewName(activeDataHub?.name || '');
  }, [activeDataHub]);

  if (!activeDataHub) return null;
  const { symbols = [], name = '', savedAt = '' } = activeDataHub;

  const handleCancel = () => {
    setIsEditing(false);
    setError('');
    setNewName(activeDataHub.name);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 25) return;
    setNewName(event.target.value);
    setError('');
  };

  const validateName = () => {
    if (newName.trim().length === 0) {
      setError("Name can't be blank.");
      return false;
    }
    if (newName.length > 25) {
      setError("Name can't exceed 25 chars.");
      return false;
    }
    if (newName === name) return true;
    if (dataHubs.some(({ name }) => name.toLowerCase() === newName.toLowerCase())) {
      setError('This name already exists!');
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (validateName()) {
      dispatch(saveDataHub({ ...activeDataHub, name: newName }));
      setIsEditing(false);
      enqueueSnackbar('Data Hub name changed!', {
        variant: 'success',
        autoHideDuration: 5000
      });
    }
  };

  return (
    <>
      {isEditing ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            position: 'relative'
          }}
        >
          <TextField
            autoFocus
            value={newName}
            onChange={handleChange}
            variant='filled'
            size='small'
            sx={{
              width: 220,
              mb: 2,
              '& .MuiFilledInput-input': {
                fontSize: 16,
                p: '2px 2px 2px 8px!important'
              },
              '& .MuiInputBase-root': {
                backgroundColor: 'background.paper',
                '&:active': {
                  backgroundColor: 'background.paper'
                }
              }
            }}
          />
          {!!error && (
            <Typography
              sx={{ fontSize: 12, position: 'absolute', color: 'error.main', top: 35, left: 7 }}
            >
              {error}
            </Typography>
          )}
          <Box sx={{ display: 'flex', gap: 1, position: 'absolute', top: 35, right: 0 }}>
            <ButtonBase
              sx={{ backgroundColor: 'background.paper' }}
              onClick={handleSave}
            >
              <Check />
            </ButtonBase>
            <ButtonBase
              sx={{ backgroundColor: 'background.paper' }}
              onClick={handleCancel}
            >
              <Clear />
            </ButtonBase>
          </Box>
        </Box>
      ) : (
        <>
          <Typography
            noWrap
            sx={{ fontSize: 16, mb: '2px', opacity: !isPremiumUser ? 0.2 : 1 }}
          >
            {newName || name}
          </Typography>
          <IconButton
            sx={{ mx: 1 }}
            disabled={!isPremiumUser}
            onClick={() => {
              setIsEditing(true);
              setNewName(name);
            }}
          >
            <ModeEditOutlined sx={{ fontSize: 20 }} />
          </IconButton>
          {!!symbols.length && (
            <Typography
              sx={{ fontSize: 16, mb: '2px', opacity: !isPremiumUser ? 0.2 : 1 }}
              noWrap
              variant='h6'
            >
              {symbols.length} item{symbols.length > 1 && 's'}, last saved&nbsp;
              {estimateLastSaved(savedAt)}
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default EditableName;
