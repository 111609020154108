import { Theme, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { Series } from 'highcharts';
import createRangeSelector from '../useRangeSelector';
import useChartFunctions, {
  CHART_GRID_COLOR,
  ChartParentComponent,
  getBaseImageUrl
} from '../useChartFunctions';
import { ChartSeries } from '../useChartSeries';
import useTooltip from '../useTooltip';
import { RootState } from '../../../store';
import { DEFAULT_CHART_HEIGHT, stockChartPalette, TABLE_ROW_HEIGHT_PX } from 'utils/constants';
import useUserDetails from 'hooks/useUserAccess';

const useStockOptions = ({ height }: { height?: number }) => {
  const { createTooltip } = useTooltip();
  const { checkUserIsBasic } = useUserDetails();
  const { userConfig } = useSelector((state: RootState) => state.userConfig);
  const theme = useTheme();
  const {
    createNavigator,
    createPlotOptions,
    createYAxis,
    createXAxis,
    captureChartDownloadAction
  } = useChartFunctions();

  function createTooltipOption(theme: Theme) {
    return {
      positioner: function (labelWidth: any, labelHeight: any, point: any): any {
        // @ts-expect-error: highcharts rules.
        const chart = this.chart;
        let x = point.plotX + chart.plotLeft - labelWidth / 2;
        let y = point.plotY + chart.plotTop - labelHeight - 10;

        const chartWidth = chart.plotWidth;

        if (x < chart.plotLeft) {
          x = chart.plotLeft;
        } else if (x + labelWidth > chart.plotLeft + chartWidth) {
          x = chart.plotLeft + chartWidth - labelWidth;
        }

        if (y < chart.plotTop) {
          y = point.plotY + chart.plotTop + 10;
        }

        return { x, y };
      },
      formatter: function (): string {
        // @ts-expect-error: highcharts rules.
        const allChartSeries: Highcharts.Series[] = this.series.chart.series;
        // @ts-expect-error: highcharts rules.
        const hoveredPointXValue = this.x;
        return createTooltip(allChartSeries, hoveredPointXValue);
      },
      outside: true,
      className: 'tooltip-stock-chart',
      enabled: true,
      split: false,
      followPointer: true,
      backgroundColor: CHART_GRID_COLOR,
      borderRadius: 0,
      useHTML: true,
      style: {
        zIndex: 1303,
        color: theme.palette.text.primary,
        fontFamily: 'Cairo, sans-serif'
      }
    };
  }

  const plotOptions = createPlotOptions();

  if (plotOptions.series) {
    plotOptions.series.dataLabels = {
      position: 'right',
      enabled: true,
      useHTML: true,
      formatter: function (): string | undefined {
        const plot: any = this;
        const last = plot.series.points[plot.series.points.length - 1].index;
        if (plot.point.index === last) {
          const decimalPlaces = plot.point.custom.decimalPlaces;
          const value = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces
          }).format(plot.y);
          return `<div style="color: ${theme.palette.background.default}; display: inline-flex; padding: 0px 4px 0px 4px; justify-content: center;text-align: center;align-items: center;  width:55px;height:15px;font-size: 10px;font-style: normal;font-weight: 400;line-height: normal;background-color:${plot.series.color};">${value}</div>`;
        }
      }
    };
  }

  const createOptions = (chartSeries: ChartSeries[], chartParent: ChartParentComponent) => {
    const getWatermarkAttributes = () =>
      checkUserIsBasic()
        ? {
            x: 'calc(50% - 285px)',
            y: 'calc(50% - 75px)',
            zIndex: 1,
            width: 190 * 3,
            height: 50 * 3
          }
        : {
            x: 'calc(100% - 250px)',
            y: 'calc(100% - 190px)',
            zIndex: 1,
            width: '190px',
            height: '50px'
          };
    chartSeries = chartSeries.map(series => {
      series.color =
        series.metadata.combinedIndex > 5
          ? '#F8F8F8'
          : stockChartPalette[series.metadata.combinedIndex % stockChartPalette.length];
      return series;
    });
    return {
      time: { timezone: userConfig?.timezone.value },
      chart: {
        animation: false,
        events: {
          load: function () {
            const chart: any = this;
            chart.watermarkImage = chart.renderer
              .image(getBaseImageUrl() + '/watermark-black.svg')
              .attr(getWatermarkAttributes())
              .add();
          },
          redraw: function () {
            const chart: any = this;
            if (chart.watermarkImage) {
              chart.watermarkImage.attr(getWatermarkAttributes());
            }
          }
        },
        type: 'line',
        backgroundColor: theme.palette.background.paper,
        height: height ? height * TABLE_ROW_HEIGHT_PX + 40 : DEFAULT_CHART_HEIGHT,
        style: {
          fontFamily: 'Cairo, sans-serif'
        },
        zooming: {
          type: 'x'
        }
      },
      lang: {
        contextButtonTitle: 'Export chart'
      },
      exporting: {
        sourceWidth: null,
        sourceHeight: null,
        scale: 1,
        enabled: true,
        filename: 'GX-Chart',
        chartOptions: {
          chart: {
            events: {
              load: function () {
                const chart: any = this;
                chart.series.forEach((series: Series) => {
                  if (!series.visible) {
                    // @ts-expect-error: highcharts rules.
                    series.update({ showInLegend: false });
                  }
                });
              }
            }
          },
          legend: {
            enabled: true,
            itemStyle: {
              color: '#f8f8f8',
              fontSize: '0.8em',
              textOverflow: 'ellipsis'
            }
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: false
              }
            }
          },
          navigator: {
            enabled: false
          },
          rangeSelector: { enabled: false },
          scrollbar: {
            enabled: false
          }
        },
        menuItemDefinitions: {
          // Define custom menu items
          downloadPNG: {
            textKey: 'downloadPNG',
            onclick: function () {
              captureChartDownloadAction(chartSeries, {
                format: 'PNG',
                chartLocation: chartParent
              });
              // @ts-expect-error: highcharts rules.
              this.exportChart({ type: 'image/png' });
            }
          },
          downloadJPEG: {
            textKey: 'downloadJPEG',
            onclick: function () {
              captureChartDownloadAction(chartSeries, {
                format: 'JPEG',
                chartLocation: chartParent
              });
              // @ts-expect-error: highcharts rules.
              this.exportChart({ type: 'image/jpeg' });
            }
          },
          downloadPDF: {
            textKey: 'downloadPDF',
            onclick: function () {
              captureChartDownloadAction(chartSeries, {
                format: 'PDF',
                chartLocation: chartParent
              });
              // @ts-expect-error: highcharts rules.
              this.exportChart({ type: 'application/pdf' });
            }
          },
          downloadSVG: {
            textKey: 'downloadSVG',
            onclick: function () {
              captureChartDownloadAction(chartSeries, {
                format: 'SVG',
                chartLocation: chartParent
              });
              // @ts-expect-error: highcharts rules.
              this.exportChart({ type: 'image/svg+xml' });
            }
          }
        },
        buttons: {
          contextButton: {
            height: 24,
            width: 30,
            x: -13,
            symbol:
              "url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.196 19.021 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.021 19.8043 18.55 20 18 20H6ZM12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16Z' fill='%23F8F8F8'/%3E%3C/svg%3E)",
            symbolSize: 16,
            menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
          }
        }
      },
      stockTools: {
        gui: {
          enabled: false
        }
      },
      plotHeight: (height || DEFAULT_CHART_HEIGHT) - 30,
      xAxis: createXAxis(theme.palette.text.primary),
      yAxis: createYAxis(theme.palette.text.primary),
      tooltip: createTooltipOption(theme),
      plotOptions,
      series: chartSeries,
      legend: { enabled: false },
      navigator: createNavigator(theme),
      rangeSelector: createRangeSelector(theme, chartSeries),
      credits: {
        enabled: false
      }
    };
  };
  return { createOptions };
};

export default useStockOptions;
