import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField
} from '@mui/material';
import posthogLib from 'posthog-js';
import { SyntheticEvent, useMemo } from 'react';
import { debounce } from 'utils/commonFunctions';

export type PostHogTextFieldType =
  | ({
      id: string;
    } & FilledTextFieldProps)
  | OutlinedTextFieldProps
  | StandardTextFieldProps;

const PostHogTextField = (props: PostHogTextFieldType) => {
  const handleCaptureEvent = (event: SyntheticEvent) => {
    capturePostHogSearch(String(props.value || ''));
    props[event.type as keyof typeof props]?.(event);
  };

  const capturePostHogSearch = useMemo(
    () =>
      debounce((inputValue: string) => {
        if (inputValue.trim() !== '') {
          posthogLib.capture('gxgo_text_field_submitted', {
            id: props.id,
            searchValue: inputValue
          });
        }
      }, 2000),
    [props.id]
  );

  return (
    <TextField
      {...props}
      variant={props.variant}
      onClick={e => handleCaptureEvent(e)}
      onKeyDown={e => handleCaptureEvent(e)}
    />
  );
};

export default PostHogTextField;
