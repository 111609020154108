import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import httpInstances from 'api/axiosInstance';
import { emailValidator } from 'utils/validators';
import useCopyToClipboard from 'utils/useCopyToClipboard';

const useShareButton = (code?: string) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const [recipient, setRecipient] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  useEffect(() => {
    setContent('');
    setEmailError('');
    setRecipient('');
  }, [modalOpen]);

  const { protocol, host } = window.location;
  const shareUrl = code ? `${protocol}//${host}/${code}` : window.location.href;

  const sendEmail = async () => {
    const errorMessage = emailValidator(recipient);
    if (errorMessage) {
      setEmailError(errorMessage);
      return;
    }
    try {
      enqueueSnackbar('Email is sending, please wait...', {
        variant: 'loading'
      });
      setModalOpen(false);
      await httpInstances.axiosGoInstance.post(`/share`, {
        content: content,
        recipient,
        url: shareUrl
      });
      enqueueSnackbar('Email sent!', {
        variant: 'success',
        autoHideDuration: 5000
      });
    } catch {
      enqueueSnackbar('Something went wrong!', {
        variant: 'error',
        autoHideDuration: 5000
      });
    }
  };
  const { copyToClipboard } = useCopyToClipboard();
  const handleCopyToClipboard = () => {
    copyToClipboard(shareUrl, 'Link copied to clipboard!');
  };

  const handleChangeRecipient = (value: string) => {
    setEmailError('');
    setRecipient(value);
  };

  return {
    modalOpen,
    setModalOpen,
    content,
    setContent,
    recipient,
    handleChangeRecipient,
    handleCopyToClipboard,
    sendEmail,
    emailError
  };
};

export default useShareButton;
