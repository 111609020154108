import { Box, FormControlLabel, Paper, Switch } from '@mui/material';
import { useState } from 'react';
import FullScreenChart from 'components/Chart/FullScreenChart';
import PremiumFeatureScreen from 'pages/DataHub/UpgradeToPremiumScreen';
import EmptyDataHubScreen from 'pages/DataHub/EmptyDataHubScreen';
import useDhChart from 'pages/DataHub/Chart/useDhChart';
import { mockChartSeries } from 'pages/DataHub/constants';
import useUserDetails from 'hooks/useUserAccess';

const DataHubChart = ({
  mainPageTitle,
  subPageTitle,
  tabName
}: {
  mainPageTitle: string;
  subPageTitle?: string;
  tabName?: string;
}) => {
  const { checkUserHasPremium } = useUserDetails();
  const [isLightMode, setIsLightMode] = useState<boolean>(false);
  const { symbols, chartSeries, error, loading } = useDhChart();
  if (!symbols?.length) return <EmptyDataHubScreen />;

  return (
    <Box sx={{ position: 'relative' }}>
      <PremiumFeatureScreen />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row-reverse',
          backgroundColor: 'background.paper'
        }}
      >
        {!loading && !error && (
          <FormControlLabel
            sx={{ fontWeight: 500 }}
            control={
              <Switch
                checked={isLightMode}
                onChange={() => setIsLightMode(!isLightMode)}
              />
            }
            labelPlacement={'start'}
            label={isLightMode ? 'Dark mode' : 'Light mode'}
          />
        )}
      </Box>
      <Paper sx={{ p: 2, height: `calc(100vh - 275px)` }}>
        <FullScreenChart
          isDataHub
          error={error}
          loading={loading}
          chartSeries={checkUserHasPremium() ? chartSeries : mockChartSeries}
          parent={{ mainPageTitle, subPageTitle, tabName }}
          isLightMode={isLightMode}
        />
      </Paper>
    </Box>
  );
};

export default DataHubChart;
