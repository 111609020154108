import { Box, Button, Link, Paper, Typography } from '@mui/material';
import JsonCsvPreviewScreen from './JsonCsvPreviewScreen';
import PythonPreviewScreen from './PythonPreviewScreen';
import useUserDetails from 'hooks/useUserAccess';
import TokenLink from 'pages/DataHub/Export/ExportPreview/TokenLink';
import ExportPreviewScreen from 'pages/DataHub/Export/ExportPreview/ExportPreviewScreen';
import useExportPreview from 'pages/DataHub/Export/ExportPreview/useExportPreview';
import { ExportSettingsType } from 'pages/DataHub/Export/ExportSettings/useExportSettings';

const ExportPreview = ({
  previewOptionId,
  exportData,
  exportSettings
}: {
  previewOptionId: string;
  exportData: (type: string) => Promise<void>;
  exportSettings: ExportSettingsType;
}) => {
  const {
    exportOption,
    capturePosthogEvent,
    handleExportData,
    scrambleApiToken,
    snowflakeSqlQuery,
    exportApiRequestString,
    exportCurlRequestString,
    scrambleCurlApiKey,
    handleCopyToClipBoard
  } = useExportPreview(previewOptionId, exportData, exportSettings);
  const { exportType, includeMetadata, from, to, period, periodNumber } = exportSettings;
  const { id, buttonLabel, actionHeading, previewOptions, previewImg } = exportOption;
  const { checkUserHasPremium } = useUserDetails();
  const renderPreview = () => {
    if (!id) {
      return (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          Please select a download option
        </Box>
      );
    }
    switch (id) {
      case 'csv': {
        return (
          <ExportPreviewScreen
            actionHeading={actionHeading}
            previewOptions={previewOptions}
            previewImg={previewImg}
          >
            <JsonCsvPreviewScreen
              isCSV={true}
              exportType={exportType}
              includeMetadata={includeMetadata}
              from={from}
              to={to}
              fromPeriodType={period}
              fromPeriod={periodNumber}
              capturePosthogEvent={capturePosthogEvent}
            />
          </ExportPreviewScreen>
        );
      }
      case 'json': {
        return (
          <ExportPreviewScreen
            actionHeading={actionHeading}
            previewOptions={previewOptions}
            previewImg={previewImg}
            tokenLink={<TokenLink />}
          >
            <JsonCsvPreviewScreen
              isCSV={false}
              exportType={exportType}
              includeMetadata={includeMetadata}
              exportCurlRequestString={scrambleCurlApiKey(exportCurlRequestString)}
              from={from}
              to={to}
              fromPeriodType={period}
              fromPeriod={periodNumber}
              handlePrimaryButtonClick={() => handleCopyToClipBoard(exportCurlRequestString)}
              capturePosthogEvent={capturePosthogEvent}
            />
          </ExportPreviewScreen>
        );
      }
      case 'python': {
        return (
          <ExportPreviewScreen
            actionHeading={actionHeading}
            previewOptions={previewOptions}
            previewImg={previewImg}
          >
            <PythonPreviewScreen
              exportType={exportType}
              includeMetadata={includeMetadata}
              from={from}
              to={to}
              fromPeriodType={period}
              fromPeriod={periodNumber}
              capturePosthogEvent={capturePosthogEvent}
            />
          </ExportPreviewScreen>
        );
      }

      case 'gx_api': {
        return (
          <ExportPreviewScreen
            actionHeading={actionHeading}
            primaryButtonLabel={buttonLabel}
            handlePrimaryButtonClick={() => handleCopyToClipBoard(exportApiRequestString)}
            previewOptions={previewOptions}
            previewImg={previewImg}
            tokenLink={<TokenLink />}
            secondaryButton={
              checkUserHasPremium() ? (
                <Button
                  component={Link}
                  target='_blank'
                  rel='noopener noreferrer'
                  variant='contained'
                  href={exportApiRequestString}
                  onClick={capturePosthogEvent}
                  sx={{ ml: 2 }}
                >
                  Launch
                </Button>
              ) : (
                <></>
              )
            }
          >
            {checkUserHasPremium() ? (
              <>
                <Paper
                  sx={{
                    m: 2,
                    p: 3,
                    userSelect: 'none',
                    backgroundColor: '#1A224D',
                    fontFamily: 'monospace',
                    wordBreak: 'break-all'
                  }}
                >
                  {scrambleApiToken(exportApiRequestString)}
                </Paper>
                <Typography sx={{ p: 2, fontSize: '12px' }}>
                  For more information on the GX API, please visit our{' '}
                  <Link
                    href='https://docs.g-x.co/#introduction'
                    target='_blank'
                    rel='noopener noreferrer'
                    underline='hover'
                  >
                    documentation site.
                  </Link>
                </Typography>
              </>
            ) : (
              <></>
            )}
          </ExportPreviewScreen>
        );
      }
      case 'snowflake': {
        return (
          <ExportPreviewScreen
            actionHeading={actionHeading}
            primaryButtonLabel={buttonLabel}
            handlePrimaryButtonClick={handleExportData}
            previewOptions={previewOptions}
            previewImg={previewImg}
            secondaryButton={
              checkUserHasPremium() ? (
                <Button
                  variant='contained'
                  sx={{ ml: 2 }}
                  onClick={() => handleCopyToClipBoard(snowflakeSqlQuery)}
                >
                  Copy to clipboard
                </Button>
              ) : (
                <></>
              )
            }
          >
            {checkUserHasPremium() ? (
              <Paper
                sx={{
                  m: 2,
                  p: 3,
                  userSelect: 'none',
                  backgroundColor: '#1A224D',
                  fontFamily: 'monospace',
                  wordBreak: 'break-all'
                }}
              >
                {snowflakeSqlQuery}
              </Paper>
            ) : (
              <></>
            )}
          </ExportPreviewScreen>
        );
      }
      default:
        return (
          <ExportPreviewScreen
            actionHeading={actionHeading}
            previewOptions={previewOptions}
            primaryButtonLabel={buttonLabel}
            handlePrimaryButtonClick={handleExportData}
            previewImg={previewImg}
          />
        );
    }
  };
  return (
    <Paper
      sx={{
        ml: 2,
        mt: 2,
        height: 708,
        flexGrow: 1,
        width: '35%',
        minWidth: 470,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'secondary.main',
        overflow: 'hidden'
      }}
    >
      {renderPreview()}
    </Paper>
  );
};

export default ExportPreview;
