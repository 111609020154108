import { Box, Button, Paper } from '@mui/material';
import { FileDownload, OpenInFull } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import DataHubPreview from './Preview';
import DataHubStats from './Stats';
import EmptyDataHubScreen from './EmptyDataHubScreen';
import Tabs from 'components/Tabs';
import PremiumFeatureScreen from 'pages/DataHub/UpgradeToPremiumScreen';
import Loader from 'components/Loader';
import SummaryChart from 'pages/DataHub/Chart/SummaryChart';

const DataHubSummary = ({
  setActiveTab,
  isDataHubEmpty
}: {
  setActiveTab: (index: number) => void;
  isDataHubEmpty: boolean;
}) => {
  const { loading } = useSelector((state: RootState) => state.dhData);
  const { status } = useSelector((state: RootState) => state.dataHub);
  const isDataHubLoading = status === 'loading';

  if (isDataHubLoading)
    return (
      <Box sx={{ height: 'calc(100vh - 300px)' }}>
        <Loader />
      </Box>
    );

  if (isDataHubEmpty) return <EmptyDataHubScreen />;

  return (
    <Box sx={{ display: 'flex', mt: 2, position: 'relative' }}>
      <Box sx={{ width: 595, mr: 2 }}>
        <PremiumFeatureScreen />
        <DataHubStats />
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          <Tabs
            mainPageTitle='Data Hub'
            tabs={[{ name: 'Recently added' }]}
            activeTab={0}
          />
          <Button
            disabled={loading}
            variant='outlined'
            sx={{ width: 93, mr: 2, fontSize: 10, px: 0.5 }}
            onClick={() => setActiveTab(2)}
          >
            <OpenInFull />
          </Button>
        </Paper>
        <SummaryChart
          mainPageTitle={'Data Hub'}
          tabName={'Recently added'}
        />
      </Box>
      <Paper sx={{ flexGrow: 1, overflowX: 'auto', position: 'relative' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tabs
            mainPageTitle='Data Hub'
            tabs={[{ name: 'All Data Hub items' }]}
            activeTab={0}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              disabled={loading}
              variant='outlined'
              sx={{ width: 93, mr: 2, fontSize: 10, px: 0.5 }}
              onClick={() => setActiveTab(1)}
            >
              <OpenInFull />
              Full screen
            </Button>
            <Button
              disabled={loading}
              variant='outlined'
              sx={{ width: 158, mr: 2, fontSize: 10 }}
              onClick={() => setActiveTab(3)}
            >
              <FileDownload />
              Export data
            </Button>
          </Box>
        </Box>
        <DataHubPreview isSummary />
      </Paper>
    </Box>
  );
};

export default DataHubSummary;
