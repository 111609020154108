import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { RootState } from 'store';
import { UserConfig } from 'slices/userConfigSlice';
export enum DateTextVariants {
  FULL,
  DAY_MONTH,
  MONTH_YEAR,
  WITH_TIME
}

const useDateText = () => {
  const { userConfig } = useSelector((state: RootState) => state.userConfig);

  const formatDateString = (date: string, variant: DateTextVariants) => {
    const dateFormat = getDateFormat(userConfig, variant);
    const userTimezone = userConfig.timezone.value;
    try {
      const zonedDateTime = toZonedTime(
        new Date(date.includes('Z') ? date : date + 'Z'),
        userTimezone
      );
      const localDate = format(zonedDateTime, dateFormat);
      const localTime = format(zonedDateTime, 'HH:mm:ss');
      return localDate + (variant === DateTextVariants.WITH_TIME ? ' ' + localTime : '');
    } catch (e) {
      console.error(e);
      console.log(date);
      return date;
    }
  };
  return { formatDateString };
};

const getDateFormat = (userConfig: UserConfig, variant: DateTextVariants) => {
  const configFormat = userConfig?.dateFormat.value;
  switch (variant) {
    case DateTextVariants.DAY_MONTH: {
      return parseDayMonth(configFormat);
    }
    case DateTextVariants.MONTH_YEAR: {
      return parseMonthYear(configFormat);
    }
    default:
      return configFormat;
  }
};

const parseMonthYear = (configFormat: string) => {
  const firstOccurrence = configFormat.indexOf('d');
  const lastOccurrence = configFormat.lastIndexOf('d');

  configFormat = sliceConfigFormat(firstOccurrence, configFormat, lastOccurrence);
  return configFormat;
};

const parseDayMonth = (configFormat: string) => {
  const firstOccurrence = configFormat.indexOf('y');
  const lastOccurrence = configFormat.lastIndexOf('y');

  configFormat = sliceConfigFormat(firstOccurrence, configFormat, lastOccurrence);
  return configFormat;
};

const sliceConfigFormat = (
  firstOccurrence: number,
  configFormat: string,
  lastOccurrence: number
) => {
  if (firstOccurrence === 0) {
    configFormat = configFormat.slice(lastOccurrence + 2, configFormat.length);
  } else if (lastOccurrence === configFormat.length - 1) {
    configFormat = configFormat.slice(0, firstOccurrence - 1);
  }
  return configFormat;
};

export default useDateText;
