import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { fetchDhData } from 'slices/dhDataSlice';
import { useAppDispatch } from 'hooks/redux-hooks';
import useUserDetails from 'hooks/useUserAccess';

export interface DhDataItem {
  transactionTime: string;
  insertTime: string;
  symbol: {
    PeriodType: string;
    TimeRef: string;
    Period: string;
    Code: string;
  };
  values: {
    High?: string;
    Low?: string;
    PeriodStart?: string;
    PeriodEnd?: string;
    PeriodRel?: string;
    RecordStatus?: string;
    Mid?: string;
    Index?: string;
    PeriodAbs: string;
    FactsheetVersion: string;
  };
  metadata: {
    TimeZone: string;
    Alias: string;
    Currency: string;
    Frequency: string;
    HolidayCalendar: string;
    DeliveryBasis: string;
    Source: string;
    Units: string;
    Title: string;
    Commodity: string;
    PricingBasis: string;
    TradingHub: string;
  };
}

export type DhData = DhDataItem[];

const useDataHub = () => {
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const { checkUserHasPremium } = useUserDetails();
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState<number>(0);

  const dataHubSymbols = dataHubs.find(dataHub => dataHub.id === activeDataHubId)?.symbols || [];
  const isDataHubEmpty = !dataHubSymbols.length;

  useEffect(() => {
    if (checkUserHasPremium()) dispatch(fetchDhData({ symbols: dataHubSymbols }));
  }, [activeDataHubId]);

  return {
    activeTab,
    setActiveTab,
    isDataHubEmpty
  };
};

export default useDataHub;
