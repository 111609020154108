import { DhData } from 'pages/DataHub/useDataHub';
import { ChartSeries } from 'components/Chart/useChartSeries';

export const mockChartSeries: ChartSeries[] = [
  {
    showInNavigator: true,
    name: 'mock1',
    data: [
      {
        x: 1199232000000,
        y: 481,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199318400000,
        y: 492.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199404800000,
        y: 486.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199664000000,
        y: 476.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199750400000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199836800000,
        y: 474,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199923200000,
        y: 461.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200009600000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200268800000,
        y: 453.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200355200000,
        y: 450.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200441600000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200528000000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200614400000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200873600000,
        y: 426.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200960000000,
        y: 421.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201046400000,
        y: 422.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201132800000,
        y: 426.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201219200000,
        y: 442.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201478400000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201564800000,
        y: 448.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201651200000,
        y: 448.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201737600000,
        y: 437,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201824000000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202083200000,
        y: 425,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202169600000,
        y: 415.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202256000000,
        y: 409,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202342400000,
        y: 406.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202428800000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202688000000,
        y: 421.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202774400000,
        y: 433,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202860800000,
        y: 443.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202947200000,
        y: 460.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203033600000,
        y: 452.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203292800000,
        y: 471.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203379200000,
        y: 466.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203465600000,
        y: 468.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203552000000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203638400000,
        y: 454.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203897600000,
        y: 456.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203984000000,
        y: 454,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204070400000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204156800000,
        y: 462.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204243200000,
        y: 463.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204502400000,
        y: 487,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204588800000,
        y: 489.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204675200000,
        y: 482.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204761600000,
        y: 485.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204848000000,
        y: 479.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205107200000,
        y: 479.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205193600000,
        y: 482.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205280000000,
        y: 482.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205366400000,
        y: 488.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205452800000,
        y: 487.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205712000000,
        y: 482.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205798400000,
        y: 487.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205884800000,
        y: 482,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205971200000,
        y: 468.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1206403200000,
        y: 475.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1206489600000,
        y: 486.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1206576000000,
        y: 491.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1206662400000,
        y: 483.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1206921600000,
        y: 485.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207008000000,
        y: 475.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207094400000,
        y: 476,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207180800000,
        y: 487,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207267200000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207526400000,
        y: 506.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207612800000,
        y: 507.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207699200000,
        y: 504.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207785600000,
        y: 505.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207872000000,
        y: 506,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208131200000,
        y: 510.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208217600000,
        y: 517.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208304000000,
        y: 522.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208390400000,
        y: 519.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208476800000,
        y: 515.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208736000000,
        y: 517,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208822400000,
        y: 516.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208908800000,
        y: 517.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208995200000,
        y: 513,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1209081600000,
        y: 517.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1209340800000,
        y: 521.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1209427200000,
        y: 511.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1209513600000,
        y: 504.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1209600000000,
        y: 486.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1209686400000,
        y: 502.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210032000000,
        y: 537,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210118400000,
        y: 546,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210204800000,
        y: 544.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210291200000,
        y: 550.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210550400000,
        y: 543.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210636800000,
        y: 547.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210723200000,
        y: 549.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210809600000,
        y: 556.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210896000000,
        y: 556,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211155200000,
        y: 555.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211241600000,
        y: 567,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211328000000,
        y: 585,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211414400000,
        y: 600.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211500800000,
        y: 601.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211760000000,
        y: 603.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211846400000,
        y: 589.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211932800000,
        y: 574.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212019200000,
        y: 574.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212105600000,
        y: 562,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212364800000,
        y: 563,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212451200000,
        y: 559.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212537600000,
        y: 554.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212624000000,
        y: 562.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212710400000,
        y: 598.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212969600000,
        y: 606.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213056000000,
        y: 614.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213142400000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213228800000,
        y: 620,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213315200000,
        y: 632,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213574400000,
        y: 637,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213660800000,
        y: 632.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213747200000,
        y: 629.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213833600000,
        y: 635.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213920000000,
        y: 634.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214179200000,
        y: 639.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214265600000,
        y: 645.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214352000000,
        y: 635.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214438400000,
        y: 649,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214524800000,
        y: 655.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214784000000,
        y: 670.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214870400000,
        y: 676.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214956800000,
        y: 699.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215043200000,
        y: 717.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215129600000,
        y: 725.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215388800000,
        y: 722.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215475200000,
        y: 707.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215561600000,
        y: 713.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215648000000,
        y: 722.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215734400000,
        y: 750.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215993600000,
        y: 753.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216080000000,
        y: 744,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216166400000,
        y: 743.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216252800000,
        y: 733,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216339200000,
        y: 707.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216598400000,
        y: 698.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216684800000,
        y: 686.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216771200000,
        y: 685.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216857600000,
        y: 662,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216944000000,
        y: 654.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217203200000,
        y: 668,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217289600000,
        y: 663,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217376000000,
        y: 672.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217462400000,
        y: 680.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217548800000,
        y: 691.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217808000000,
        y: 684.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217894400000,
        y: 662.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217980800000,
        y: 654,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218067200000,
        y: 651.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218153600000,
        y: 619.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218412800000,
        y: 615.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218499200000,
        y: 599.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218585600000,
        y: 609.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218672000000,
        y: 625.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218758400000,
        y: 614.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219017600000,
        y: 622.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219104000000,
        y: 627.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219190400000,
        y: 630.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219276800000,
        y: 658.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219363200000,
        y: 655.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219622400000,
        y: 656.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219708800000,
        y: 656.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219795200000,
        y: 663.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219881600000,
        y: 667.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219968000000,
        y: 664.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220227200000,
        y: 649.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220313600000,
        y: 638,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220400000000,
        y: 627.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220486400000,
        y: 618.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220572800000,
        y: 587.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220832000000,
        y: 587.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220918400000,
        y: 574.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221004800000,
        y: 563.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221091200000,
        y: 555.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221177600000,
        y: 554.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221436800000,
        y: 527.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221523200000,
        y: 503.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221609600000,
        y: 483.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221696000000,
        y: 505.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221782400000,
        y: 526,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222041600000,
        y: 570.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222128000000,
        y: 577.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222214400000,
        y: 576.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222300800000,
        y: 571.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222387200000,
        y: 571.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222646400000,
        y: 562.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222732800000,
        y: 565.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222819200000,
        y: 553.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222905600000,
        y: 525,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222992000000,
        y: 515.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223251200000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223337600000,
        y: 479.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223424000000,
        y: 454.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223510400000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223596800000,
        y: 409,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223856000000,
        y: 407.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223942400000,
        y: 405.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224028800000,
        y: 380.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224115200000,
        y: 343,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224201600000,
        y: 332.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224460800000,
        y: 338.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224547200000,
        y: 344.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224633600000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224720000000,
        y: 315.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224806400000,
        y: 284.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225065600000,
        y: 278.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225152000000,
        y: 272.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225238400000,
        y: 288.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225324800000,
        y: 268.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225411200000,
        y: 252.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225670400000,
        y: 247.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225756800000,
        y: 253.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225843200000,
        y: 243.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225929600000,
        y: 227.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226016000000,
        y: 225,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226275200000,
        y: 228,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226361600000,
        y: 209,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226448000000,
        y: 200.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226534400000,
        y: 191,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226620800000,
        y: 189,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226880000000,
        y: 200.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226966400000,
        y: 202.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227052800000,
        y: 195,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227139200000,
        y: 187.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227225600000,
        y: 179.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227484800000,
        y: 196.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227571200000,
        y: 198.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227657600000,
        y: 199.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227744000000,
        y: 207.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227830400000,
        y: 199.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228089600000,
        y: 204,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228176000000,
        y: 215,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228262400000,
        y: 185,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228348800000,
        y: 178,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228435200000,
        y: 165.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228694400000,
        y: 174.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228780800000,
        y: 181.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228867200000,
        y: 176.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228953600000,
        y: 199.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229040000000,
        y: 190.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229299200000,
        y: 208,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229385600000,
        y: 191.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229472000000,
        y: 193.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229558400000,
        y: 185.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229644800000,
        y: 176.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229904000000,
        y: 166.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229990400000,
        y: 153.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1230076800000,
        y: 141.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1230508800000,
        y: 145.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1230595200000,
        y: 146.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1230854400000,
        y: 157.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231113600000,
        y: 190,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231200000000,
        y: 222,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231286400000,
        y: 219.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231372800000,
        y: 205,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231459200000,
        y: 198.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231718400000,
        y: 196.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231804800000,
        y: 215.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231891200000,
        y: 210.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231977600000,
        y: 224.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232064000000,
        y: 225.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232323200000,
        y: 220.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232409600000,
        y: 218.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232496000000,
        y: 214,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232582400000,
        y: 215,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232668800000,
        y: 217.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232928000000,
        y: 242,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233014400000,
        y: 232.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233100800000,
        y: 240.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233187200000,
        y: 242.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233273600000,
        y: 242,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233532800000,
        y: 234.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233619200000,
        y: 235,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233705600000,
        y: 238,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233792000000,
        y: 239,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233878400000,
        y: 238.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234137600000,
        y: 248.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234224000000,
        y: 248.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234310400000,
        y: 241.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234396800000,
        y: 238.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234483200000,
        y: 237.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234742400000,
        y: 238,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234828800000,
        y: 225.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234915200000,
        y: 214.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235001600000,
        y: 219,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235088000000,
        y: 214.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235347200000,
        y: 216,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235433600000,
        y: 210,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235520000000,
        y: 210.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235606400000,
        y: 226.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235692800000,
        y: 222.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235952000000,
        y: 220.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236038400000,
        y: 209.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236124800000,
        y: 222,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236211200000,
        y: 218.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236297600000,
        y: 217.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236556800000,
        y: 225,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236643200000,
        y: 226.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236729600000,
        y: 221.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236816000000,
        y: 218,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236902400000,
        y: 225.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237161600000,
        y: 222,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237248000000,
        y: 226.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237334400000,
        y: 225,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237420800000,
        y: 237.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237507200000,
        y: 238,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237766400000,
        y: 248.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237852800000,
        y: 248.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237939200000,
        y: 251.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238025600000,
        y: 259.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238112000000,
        y: 254.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238371200000,
        y: 249,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238457600000,
        y: 245,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238544000000,
        y: 247.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238630400000,
        y: 258.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238716800000,
        y: 263,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238976000000,
        y: 268.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239062400000,
        y: 269.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239148800000,
        y: 269.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239667200000,
        y: 277.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239753600000,
        y: 277,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239840000000,
        y: 279.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239926400000,
        y: 278,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240185600000,
        y: 264.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240272000000,
        y: 263.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240358400000,
        y: 261.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240444800000,
        y: 260.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240531200000,
        y: 269.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240790400000,
        y: 262,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240876800000,
        y: 262.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240963200000,
        y: 266.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241049600000,
        y: 272.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241136000000,
        y: 280.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241481600000,
        y: 293.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241568000000,
        y: 309.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241654400000,
        y: 319.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241740800000,
        y: 321.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242000000000,
        y: 323,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242086400000,
        y: 328.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242172800000,
        y: 331.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242259200000,
        y: 323.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242345600000,
        y: 323.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242604800000,
        y: 324.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242691200000,
        y: 330.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242777600000,
        y: 338.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242864000000,
        y: 331.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242950400000,
        y: 334,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243296000000,
        y: 333.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243382400000,
        y: 337.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243468800000,
        y: 350.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243555200000,
        y: 355.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243814400000,
        y: 366.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243900800000,
        y: 372.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243987200000,
        y: 366.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244073600000,
        y: 376.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244160000000,
        y: 381.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244419200000,
        y: 380.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244505600000,
        y: 381,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244592000000,
        y: 389.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244678400000,
        y: 392.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244764800000,
        y: 393.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245024000000,
        y: 374,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245110400000,
        y: 384.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245196800000,
        y: 379.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245283200000,
        y: 384.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245369600000,
        y: 388.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245628800000,
        y: 376,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245715200000,
        y: 373,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245801600000,
        y: 377.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245888000000,
        y: 380,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245974400000,
        y: 379.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246233600000,
        y: 390.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246320000000,
        y: 385.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246406400000,
        y: 395.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246492800000,
        y: 381.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246579200000,
        y: 377.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246838400000,
        y: 377.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246924800000,
        y: 369.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247011200000,
        y: 363,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247097600000,
        y: 361.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247184000000,
        y: 354.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247443200000,
        y: 359.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247529600000,
        y: 367.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247616000000,
        y: 376.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247702400000,
        y: 377,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247788800000,
        y: 380.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248048000000,
        y: 387.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248134400000,
        y: 397,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248220800000,
        y: 395.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248307200000,
        y: 401.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248393600000,
        y: 405.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248652800000,
        y: 410.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248739200000,
        y: 406,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248825600000,
        y: 390,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248912000000,
        y: 399.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248998400000,
        y: 408,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249257600000,
        y: 431.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249344000000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249430400000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249516800000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249603200000,
        y: 425,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249862400000,
        y: 426.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249948800000,
        y: 424.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250035200000,
        y: 428,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250121600000,
        y: 434,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250208000000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250467200000,
        y: 415.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250553600000,
        y: 424.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250640000000,
        y: 434.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250726400000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250812800000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251072000000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251158400000,
        y: 438.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251244800000,
        y: 432.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251331200000,
        y: 431.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251417600000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251763200000,
        y: 429,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251849600000,
        y: 417.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251936000000,
        y: 417,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252022400000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252281600000,
        y: 409.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252368000000,
        y: 417.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252454400000,
        y: 418.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252540800000,
        y: 419.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252627200000,
        y: 414,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252886400000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252972800000,
        y: 411.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253059200000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253145600000,
        y: 424.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253232000000,
        y: 417.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253491200000,
        y: 415.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253577600000,
        y: 421,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253664000000,
        y: 408.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253750400000,
        y: 404.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253836800000,
        y: 395.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254096000000,
        y: 399.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254182400000,
        y: 396.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254268800000,
        y: 397.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254355200000,
        y: 405,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254441600000,
        y: 397.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254700800000,
        y: 399.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254787200000,
        y: 403.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254873600000,
        y: 409,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254960000000,
        y: 414.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255046400000,
        y: 417.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255305600000,
        y: 425.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255392000000,
        y: 423,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255478400000,
        y: 421,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255564800000,
        y: 431,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255651200000,
        y: 438.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255910400000,
        y: 445.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255996800000,
        y: 447.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256083200000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256169600000,
        y: 464.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256256000000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256515200000,
        y: 460.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256601600000,
        y: 459.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256688000000,
        y: 452,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256774400000,
        y: 458.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256860800000,
        y: 451.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257120000000,
        y: 454.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257206400000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257292800000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257379200000,
        y: 470.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257465600000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257724800000,
        y: 467.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257811200000,
        y: 470.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257897600000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257984000000,
        y: 458.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258070400000,
        y: 457.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258329600000,
        y: 468.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258416000000,
        y: 467.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258502400000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258588800000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258934400000,
        y: 470.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259020800000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259107200000,
        y: 466.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259193600000,
        y: 466.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259280000000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259539200000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259625600000,
        y: 470.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259712000000,
        y: 463.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259798400000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259884800000,
        y: 466,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260144000000,
        y: 460.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260230400000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260316800000,
        y: 451.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260403200000,
        y: 438.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260489600000,
        y: 426.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260748800000,
        y: 431.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260835200000,
        y: 432,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260921600000,
        y: 436.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261008000000,
        y: 437.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261094400000,
        y: 435.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261353600000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261440000000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261526400000,
        y: 437.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262044800000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262131200000,
        y: 456.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262563200000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262649600000,
        y: 474,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262736000000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262822400000,
        y: 486.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262908800000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263168000000,
        y: 488.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263254400000,
        y: 484.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263340800000,
        y: 475.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263427200000,
        y: 473,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263513600000,
        y: 470,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263772800000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263859200000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263945600000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264032000000,
        y: 454.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264118400000,
        y: 453.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264377600000,
        y: 443.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264464000000,
        y: 443.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264550400000,
        y: 444.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264636800000,
        y: 445.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264723200000,
        y: 444.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264982400000,
        y: 442.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265068800000,
        y: 449,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265155200000,
        y: 451.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265241600000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265328000000,
        y: 436.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265587200000,
        y: 430.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265673600000,
        y: 433.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265760000000,
        y: 436.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265846400000,
        y: 442.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265932800000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266192000000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266278400000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266364800000,
        y: 452.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266451200000,
        y: 459.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266537600000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266796800000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266883200000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266969600000,
        y: 463.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267056000000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267142400000,
        y: 459.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267401600000,
        y: 462,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267488000000,
        y: 467.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267574400000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267660800000,
        y: 466,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267747200000,
        y: 465.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268006400000,
        y: 470.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268092800000,
        y: 460.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268179200000,
        y: 467.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268265600000,
        y: 464.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268352000000,
        y: 457.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268611200000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268697600000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268784000000,
        y: 457.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268870400000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268956800000,
        y: 449.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269216000000,
        y: 454.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269302400000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269388800000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269475200000,
        y: 460.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269561600000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269820800000,
        y: 459.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269907200000,
        y: 458,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269993600000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270512000000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270598400000,
        y: 468.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270684800000,
        y: 468.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270771200000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271030400000,
        y: 477,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271116800000,
        y: 474.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271203200000,
        y: 480,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271289600000,
        y: 483.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271376000000,
        y: 478.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271635200000,
        y: 473,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271721600000,
        y: 478.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271808000000,
        y: 480,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271894400000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271980800000,
        y: 486.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272240000000,
        y: 488.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272326400000,
        y: 488.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272412800000,
        y: 478.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272499200000,
        y: 488.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272585600000,
        y: 490.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272931200000,
        y: 491,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273017600000,
        y: 481.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273104000000,
        y: 471.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273190400000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273449600000,
        y: 464.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273536000000,
        y: 470,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273622400000,
        y: 473.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273708800000,
        y: 471.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273795200000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274054400000,
        y: 447.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274140800000,
        y: 445.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274227200000,
        y: 434,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274313600000,
        y: 423.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274400000000,
        y: 423.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274659200000,
        y: 411.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274745600000,
        y: 398.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274832000000,
        y: 400,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274918400000,
        y: 413,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275004800000,
        y: 425.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275350400000,
        y: 421.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275436800000,
        y: 421.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275523200000,
        y: 426.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275609600000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275868800000,
        y: 422,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275955200000,
        y: 420.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276041600000,
        y: 426.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276128000000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276214400000,
        y: 423,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276473600000,
        y: 430.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276560000000,
        y: 431.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276646400000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276732800000,
        y: 438.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276819200000,
        y: 438,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277078400000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277164800000,
        y: 438.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277251200000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277337600000,
        y: 433,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277424000000,
        y: 433,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277683200000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277769600000,
        y: 424.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277856000000,
        y: 424.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277942400000,
        y: 409.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278028800000,
        y: 407.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278288000000,
        y: 405.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278374400000,
        y: 409,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278460800000,
        y: 410,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278547200000,
        y: 413,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278633600000,
        y: 425,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278892800000,
        y: 424.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278979200000,
        y: 433.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279065600000,
        y: 435.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279152000000,
        y: 435.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279238400000,
        y: 438.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279497600000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279584000000,
        y: 438.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279670400000,
        y: 440.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279756800000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279843200000,
        y: 444.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280102400000,
        y: 446.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280188800000,
        y: 440.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280275200000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280361600000,
        y: 444.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280448000000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280707200000,
        y: 459.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280793600000,
        y: 465.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280880000000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280966400000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281052800000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281312000000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281398400000,
        y: 462.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281484800000,
        y: 461.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281571200000,
        y: 448.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281657600000,
        y: 437.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281916800000,
        y: 435.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282003200000,
        y: 444.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282089600000,
        y: 438.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282176000000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282262400000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282521600000,
        y: 432.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282608000000,
        y: 425.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282694400000,
        y: 422.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282780800000,
        y: 431.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282867200000,
        y: 436.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283212800000,
        y: 437.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283299200000,
        y: 436.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283385600000,
        y: 433.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283472000000,
        y: 437.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283731200000,
        y: 436.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283817600000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283904000000,
        y: 438.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283990400000,
        y: 440.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284076800000,
        y: 437.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284336000000,
        y: 439.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284422400000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284508800000,
        y: 444.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284595200000,
        y: 444.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284681600000,
        y: 440.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284940800000,
        y: 446.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285027200000,
        y: 442.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285113600000,
        y: 439.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285200000000,
        y: 437.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285286400000,
        y: 441.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285545600000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285632000000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285718400000,
        y: 442.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285804800000,
        y: 449.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285891200000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286150400000,
        y: 471,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286236800000,
        y: 470.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286323200000,
        y: 479.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286409600000,
        y: 478.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286496000000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286755200000,
        y: 474.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286841600000,
        y: 470.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286928000000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287014400000,
        y: 471.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287100800000,
        y: 468.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287360000000,
        y: 468.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287446400000,
        y: 464.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287532800000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287619200000,
        y: 462.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287705600000,
        y: 461.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287964800000,
        y: 466,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288051200000,
        y: 466.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288137600000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288224000000,
        y: 467.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288310400000,
        y: 463.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288569600000,
        y: 471.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288656000000,
        y: 477,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288742400000,
        y: 482.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288828800000,
        y: 491.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288915200000,
        y: 493,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289174400000,
        y: 493.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289260800000,
        y: 496.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289347200000,
        y: 499,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289433600000,
        y: 504.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289520000000,
        y: 494.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289779200000,
        y: 491.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289865600000,
        y: 481.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289952000000,
        y: 473.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290038400000,
        y: 472,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290124800000,
        y: 468.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290384000000,
        y: 467.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290470400000,
        y: 467.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290556800000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290643200000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290729600000,
        y: 479,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290988800000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291075200000,
        y: 478,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291161600000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291248000000,
        y: 492.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291334400000,
        y: 493.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291593600000,
        y: 492.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291680000000,
        y: 490.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291766400000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291852800000,
        y: 489.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291939200000,
        y: 490,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292198400000,
        y: 491.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292284800000,
        y: 492.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292371200000,
        y: 495.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292457600000,
        y: 499.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292544000000,
        y: 502.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292803200000,
        y: 501,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292889600000,
        y: 505.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292976000000,
        y: 503.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293148800000,
        y: 500.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293580800000,
        y: 506,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293667200000,
        y: 505.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294099200000,
        y: 509.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294185600000,
        y: 517.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294272000000,
        y: 520,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294358400000,
        y: 517.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294617600000,
        y: 525.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294704000000,
        y: 519.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294790400000,
        y: 524,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294876800000,
        y: 526.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294963200000,
        y: 529,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295222400000,
        y: 527.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295308800000,
        y: 529.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295395200000,
        y: 531.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295481600000,
        y: 526.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295568000000,
        y: 526.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295827200000,
        y: 533.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295913600000,
        y: 526,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296000000000,
        y: 523.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296086400000,
        y: 529.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296172800000,
        y: 530.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296432000000,
        y: 533,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296518400000,
        y: 546.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296604800000,
        y: 557.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296691200000,
        y: 559.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296777600000,
        y: 557.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297036800000,
        y: 559,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297123200000,
        y: 559.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297209600000,
        y: 560,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297296000000,
        y: 562.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297382400000,
        y: 564,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297641600000,
        y: 579.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297728000000,
        y: 580.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297814400000,
        y: 585,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297900800000,
        y: 587.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297987200000,
        y: 584.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298246400000,
        y: 593,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298332800000,
        y: 594.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298419200000,
        y: 616,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298505600000,
        y: 630.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298592000000,
        y: 622.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298851200000,
        y: 619.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298937600000,
        y: 621.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299024000000,
        y: 625.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299110400000,
        y: 619,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299196800000,
        y: 622.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299456000000,
        y: 627,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299542400000,
        y: 624,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299628800000,
        y: 630,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299715200000,
        y: 628.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299801600000,
        y: 630,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300060800000,
        y: 630.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300147200000,
        y: 623.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300233600000,
        y: 620.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300320000000,
        y: 629.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300406400000,
        y: 620.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300665600000,
        y: 631.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300752000000,
        y: 632.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300838400000,
        y: 631.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300924800000,
        y: 630.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301011200000,
        y: 634.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301270400000,
        y: 636.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301356800000,
        y: 636.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301443200000,
        y: 638,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301529600000,
        y: 643.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301616000000,
        y: 654,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301875200000,
        y: 662.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301961600000,
        y: 672.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302048000000,
        y: 677.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302134400000,
        y: 678.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302220800000,
        y: 681.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302480000000,
        y: 683,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302566400000,
        y: 669.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302652800000,
        y: 677.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302739200000,
        y: 673,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302825600000,
        y: 679.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303084800000,
        y: 674,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303171200000,
        y: 665.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303257600000,
        y: 668,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303776000000,
        y: 673.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303862400000,
        y: 674.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303948800000,
        y: 680.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304380800000,
        y: 681.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304467200000,
        y: 675,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304553600000,
        y: 652.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304640000000,
        y: 655.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304899200000,
        y: 635,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304985600000,
        y: 648.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305072000000,
        y: 647.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305158400000,
        y: 644.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305244800000,
        y: 642.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305504000000,
        y: 647.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305590400000,
        y: 630.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305676800000,
        y: 633.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305763200000,
        y: 633,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305849600000,
        y: 625.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306108800000,
        y: 622.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306195200000,
        y: 618.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306281600000,
        y: 639.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306368000000,
        y: 642.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306454400000,
        y: 646,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306800000000,
        y: 646.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306886400000,
        y: 653.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306972800000,
        y: 651.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307059200000,
        y: 650,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307318400000,
        y: 656,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307404800000,
        y: 658.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307491200000,
        y: 666.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307577600000,
        y: 672.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307664000000,
        y: 670.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307923200000,
        y: 678.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308009600000,
        y: 678.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308096000000,
        y: 673.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308182400000,
        y: 662.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308268800000,
        y: 652,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308528000000,
        y: 648,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308614400000,
        y: 647.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308700800000,
        y: 653,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308787200000,
        y: 649.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308873600000,
        y: 631.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309132800000,
        y: 628.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309219200000,
        y: 632.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309305600000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309392000000,
        y: 649.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309478400000,
        y: 649,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309737600000,
        y: 649,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309824000000,
        y: 649.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309910400000,
        y: 654,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309996800000,
        y: 665,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310083200000,
        y: 663.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310342400000,
        y: 666.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310428800000,
        y: 670,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310515200000,
        y: 677.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310601600000,
        y: 676.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310688000000,
        y: 681.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310947200000,
        y: 680.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311033600000,
        y: 682,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311120000000,
        y: 687.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311206400000,
        y: 689.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311292800000,
        y: 691.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311552000000,
        y: 691.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311638400000,
        y: 694,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311724800000,
        y: 693.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311811200000,
        y: 695.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311897600000,
        y: 686,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312156800000,
        y: 685.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312243200000,
        y: 694.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312329600000,
        y: 685.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312416000000,
        y: 676.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312502400000,
        y: 660.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312761600000,
        y: 655,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312848000000,
        y: 644.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312934400000,
        y: 626.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313020800000,
        y: 626.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313107200000,
        y: 634.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313366400000,
        y: 640.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313452800000,
        y: 646.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313539200000,
        y: 653.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313625600000,
        y: 643,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313712000000,
        y: 645.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313971200000,
        y: 636.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314057600000,
        y: 644,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314144000000,
        y: 655,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314230400000,
        y: 656.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314316800000,
        y: 663.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314662400000,
        y: 666.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314748800000,
        y: 677.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314835200000,
        y: 679.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314921600000,
        y: 671.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315180800000,
        y: 664.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315267200000,
        y: 663.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315353600000,
        y: 673,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315440000000,
        y: 679.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315526400000,
        y: 669.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315785600000,
        y: 663.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315872000000,
        y: 659.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315958400000,
        y: 653.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316044800000,
        y: 670.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316131200000,
        y: 657.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316390400000,
        y: 665.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316476800000,
        y: 670.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316563200000,
        y: 670.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316649600000,
        y: 636.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316736000000,
        y: 641.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316995200000,
        y: 633,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317081600000,
        y: 636.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317168000000,
        y: 642,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317254400000,
        y: 638.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317340800000,
        y: 635,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317600000000,
        y: 629.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317686400000,
        y: 622.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317772800000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317859200000,
        y: 626,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317945600000,
        y: 633.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318204800000,
        y: 644.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318291200000,
        y: 646.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318377600000,
        y: 658.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318464000000,
        y: 655.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318550400000,
        y: 672.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318809600000,
        y: 669.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318896000000,
        y: 669.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318982400000,
        y: 671.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319068800000,
        y: 664.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319155200000,
        y: 670.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319414400000,
        y: 672,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319500800000,
        y: 670.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319587200000,
        y: 668.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319673600000,
        y: 675,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319760000000,
        y: 675,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320019200000,
        y: 670.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320105600000,
        y: 657.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320192000000,
        y: 668,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320278400000,
        y: 673.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320364800000,
        y: 672.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320624000000,
        y: 684.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320710400000,
        y: 687,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320796800000,
        y: 685.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320883200000,
        y: 681.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320969600000,
        y: 691.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321228800000,
        y: 688,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321315200000,
        y: 687.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321401600000,
        y: 685.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321488000000,
        y: 673.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321574400000,
        y: 662.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321833600000,
        y: 650.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321920000000,
        y: 656,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322006400000,
        y: 654.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322092800000,
        y: 656,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322179200000,
        y: 640.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322438400000,
        y: 647.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322524800000,
        y: 655.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322611200000,
        y: 656.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322697600000,
        y: 650.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322784000000,
        y: 656.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323043200000,
        y: 662.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323129600000,
        y: 658.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323216000000,
        y: 655.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323302400000,
        y: 655.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323388800000,
        y: 647,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323648000000,
        y: 644.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323734400000,
        y: 651,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323820800000,
        y: 636,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323907200000,
        y: 634,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323993600000,
        y: 623.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324252800000,
        y: 626.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324339200000,
        y: 638.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324425600000,
        y: 642,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324512000000,
        y: 647.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325030400000,
        y: 649.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325116800000,
        y: 648.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325548800000,
        y: 674.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325635200000,
        y: 683,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325721600000,
        y: 686,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325808000000,
        y: 699.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326067200000,
        y: 702,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326153600000,
        y: 701.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326240000000,
        y: 712.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326326400000,
        y: 717.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326412800000,
        y: 710.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326672000000,
        y: 714.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326758400000,
        y: 711,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326844800000,
        y: 706.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326931200000,
        y: 706,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327017600000,
        y: 694,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327276800000,
        y: 698.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327363200000,
        y: 699.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327449600000,
        y: 703,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327536000000,
        y: 708.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327622400000,
        y: 707.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327881600000,
        y: 705.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327968000000,
        y: 700.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328054400000,
        y: 705,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328140800000,
        y: 697.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328227200000,
        y: 699.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328486400000,
        y: 715,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328572800000,
        y: 717.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328659200000,
        y: 715.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328745600000,
        y: 723.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328832000000,
        y: 730.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329091200000,
        y: 727.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329177600000,
        y: 722,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329264000000,
        y: 727,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329350400000,
        y: 726,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329436800000,
        y: 721.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329696000000,
        y: 721.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329782400000,
        y: 726.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329868800000,
        y: 731.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329955200000,
        y: 734.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330041600000,
        y: 736.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330300800000,
        y: 741.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330387200000,
        y: 738.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330473600000,
        y: 730.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330560000000,
        y: 739.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330646400000,
        y: 740.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330905600000,
        y: 744.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330992000000,
        y: 738.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331078400000,
        y: 736.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331164800000,
        y: 744,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331251200000,
        y: 746.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331510400000,
        y: 744.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331596800000,
        y: 753,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331683200000,
        y: 752,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331769600000,
        y: 753.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331856000000,
        y: 748,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332115200000,
        y: 749.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332201600000,
        y: 746.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332288000000,
        y: 741.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332374400000,
        y: 735.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332460800000,
        y: 736.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332720000000,
        y: 740,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332806400000,
        y: 739.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332892800000,
        y: 739.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332979200000,
        y: 738.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333065600000,
        y: 737.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333324800000,
        y: 740.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333411200000,
        y: 741.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333497600000,
        y: 735,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334016000000,
        y: 726,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334102400000,
        y: 721.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334188800000,
        y: 727.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334275200000,
        y: 729,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334534400000,
        y: 722.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334620800000,
        y: 720,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334707200000,
        y: 711.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334793600000,
        y: 713.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334880000000,
        y: 714.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335139200000,
        y: 712,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335225600000,
        y: 711,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335312000000,
        y: 719.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335398400000,
        y: 727.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335484800000,
        y: 724.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335744000000,
        y: 724.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335830400000,
        y: 730,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335916800000,
        y: 722.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336003200000,
        y: 722.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336089600000,
        y: 696,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336435200000,
        y: 676.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336521600000,
        y: 677.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336608000000,
        y: 672.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336694400000,
        y: 669.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336953600000,
        y: 662.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337040000000,
        y: 663,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337126400000,
        y: 666.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337212800000,
        y: 656.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337299200000,
        y: 649.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337558400000,
        y: 649.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337644800000,
        y: 652,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337731200000,
        y: 646.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337817600000,
        y: 645.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337904000000,
        y: 647.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338163200000,
        y: 648.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338249600000,
        y: 652.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338336000000,
        y: 634.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338422400000,
        y: 625,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338508800000,
        y: 601.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338940800000,
        y: 597.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339027200000,
        y: 598,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339113600000,
        y: 593.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339372800000,
        y: 593.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339459200000,
        y: 600.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339545600000,
        y: 599.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339632000000,
        y: 605.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339718400000,
        y: 604.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339977600000,
        y: 608.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340064000000,
        y: 594.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340150400000,
        y: 589.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340236800000,
        y: 589.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340323200000,
        y: 566,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340582400000,
        y: 557.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340668800000,
        y: 562.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340755200000,
        y: 570.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340841600000,
        y: 564,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340928000000,
        y: 576.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341187200000,
        y: 580.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341273600000,
        y: 603,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341360000000,
        y: 603,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341446400000,
        y: 608.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341532800000,
        y: 600.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341792000000,
        y: 597.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341878400000,
        y: 593,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341964800000,
        y: 593.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342051200000,
        y: 592.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342137600000,
        y: 600.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342396800000,
        y: 612.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342483200000,
        y: 621.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342569600000,
        y: 629,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342656000000,
        y: 640.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342742400000,
        y: 629.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343001600000,
        y: 628,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343088000000,
        y: 623.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343174400000,
        y: 611,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343260800000,
        y: 619,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343347200000,
        y: 632.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343606400000,
        y: 638.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343692800000,
        y: 635,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343779200000,
        y: 632.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343865600000,
        y: 631.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343952000000,
        y: 641,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344211200000,
        y: 643,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344297600000,
        y: 652,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344384000000,
        y: 659.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344470400000,
        y: 665.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344556800000,
        y: 661.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344816000000,
        y: 663.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344902400000,
        y: 668,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344988800000,
        y: 673.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345075200000,
        y: 679.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345161600000,
        y: 679.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345420800000,
        y: 678,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345507200000,
        y: 684,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345593600000,
        y: 685,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345680000000,
        y: 687,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345766400000,
        y: 686,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346112000000,
        y: 677.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346198400000,
        y: 675.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346284800000,
        y: 675.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346371200000,
        y: 682.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346630400000,
        y: 687,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346716800000,
        y: 689.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346803200000,
        y: 692.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346889600000,
        y: 686.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346976000000,
        y: 681.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347235200000,
        y: 685.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347321600000,
        y: 685,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347408000000,
        y: 688,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347494400000,
        y: 693,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347580800000,
        y: 697.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347840000000,
        y: 697.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347926400000,
        y: 682.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348012800000,
        y: 659.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348099200000,
        y: 652,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348185600000,
        y: 650.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348444800000,
        y: 657.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348531200000,
        y: 663,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348617600000,
        y: 655.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348704000000,
        y: 658.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348790400000,
        y: 658.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349049600000,
        y: 664,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349136000000,
        y: 663.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349222400000,
        y: 649.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349308800000,
        y: 649.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349395200000,
        y: 647.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349654400000,
        y: 644.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349740800000,
        y: 643,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349827200000,
        y: 654.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349913600000,
        y: 657.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350000000000,
        y: 653.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350259200000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350345600000,
        y: 650.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350432000000,
        y: 647.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350518400000,
        y: 644.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350604800000,
        y: 642.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350864000000,
        y: 641.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350950400000,
        y: 637.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351036800000,
        y: 628.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351123200000,
        y: 627.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351209600000,
        y: 630,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351468800000,
        y: 625,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351555200000,
        y: 620.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351641600000,
        y: 619.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351728000000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351814400000,
        y: 608.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352073600000,
        y: 603.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352160000000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352246400000,
        y: 609.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352332800000,
        y: 605.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352419200000,
        y: 601,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352678400000,
        y: 607.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352764800000,
        y: 624.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352851200000,
        y: 614.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352937600000,
        y: 620.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353024000000,
        y: 613.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353283200000,
        y: 619.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353369600000,
        y: 615,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353456000000,
        y: 617.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353542400000,
        y: 617.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353628800000,
        y: 616.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353888000000,
        y: 616,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353974400000,
        y: 614,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354060800000,
        y: 608.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354147200000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354233600000,
        y: 607.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354492800000,
        y: 608.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354579200000,
        y: 605.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354665600000,
        y: 607,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354752000000,
        y: 601.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354838400000,
        y: 596,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355097600000,
        y: 598.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355184000000,
        y: 596.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355270400000,
        y: 599,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355356800000,
        y: 597.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355443200000,
        y: 598.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355702400000,
        y: 600,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355788800000,
        y: 602.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355875200000,
        y: 605,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355961600000,
        y: 605,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356048000000,
        y: 599.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356566400000,
        y: 607,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356652800000,
        y: 603.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357084800000,
        y: 615.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357171200000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357257600000,
        y: 630,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357516800000,
        y: 630.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357603200000,
        y: 633.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357689600000,
        y: 636.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357776000000,
        y: 646.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357862400000,
        y: 637.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358121600000,
        y: 635.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358208000000,
        y: 637.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358294400000,
        y: 635.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358380800000,
        y: 638,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358467200000,
        y: 636.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358726400000,
        y: 642.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358812800000,
        y: 640.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358899200000,
        y: 642.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358985600000,
        y: 638.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359072000000,
        y: 646.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359331200000,
        y: 646.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359417600000,
        y: 648,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359504000000,
        y: 652.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359590400000,
        y: 658.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359676800000,
        y: 665.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359936000000,
        y: 665.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360022400000,
        y: 667.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360108800000,
        y: 668,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360195200000,
        y: 672.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360281600000,
        y: 677.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360540800000,
        y: 675.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360627200000,
        y: 673,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360713600000,
        y: 674,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360800000000,
        y: 673.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360886400000,
        y: 665,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361145600000,
        y: 664.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361232000000,
        y: 663.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361318400000,
        y: 662.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361404800000,
        y: 649.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361491200000,
        y: 642.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361750400000,
        y: 647.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361836800000,
        y: 651,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361923200000,
        y: 643.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362009600000,
        y: 638.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362096000000,
        y: 632.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362355200000,
        y: 625.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362441600000,
        y: 629.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362528000000,
        y: 632.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362614400000,
        y: 635.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362700800000,
        y: 631.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362960000000,
        y: 633.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363046400000,
        y: 633,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363132800000,
        y: 629,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363219200000,
        y: 630.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363305600000,
        y: 633,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363564800000,
        y: 633.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363651200000,
        y: 631.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363737600000,
        y: 626.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363824000000,
        y: 626.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363910400000,
        y: 624.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364169600000,
        y: 626,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364256000000,
        y: 628.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364342400000,
        y: 632.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364860800000,
        y: 639,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364947200000,
        y: 633.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365120000000,
        y: 614,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365379200000,
        y: 616.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365465600000,
        y: 617,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365552000000,
        y: 620.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365638400000,
        y: 622.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365724800000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365984000000,
        y: 604.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366070400000,
        y: 597.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366156800000,
        y: 593.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366243200000,
        y: 594,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366329600000,
        y: 596,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366588800000,
        y: 596.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366675200000,
        y: 595.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366761600000,
        y: 598.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366848000000,
        y: 603.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366934400000,
        y: 606.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367193600000,
        y: 609.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367280000000,
        y: 609.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367366400000,
        y: 600.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367452800000,
        y: 606,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367539200000,
        y: 613.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367884800000,
        y: 620.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367971200000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368057600000,
        y: 616.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368144000000,
        y: 611.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368403200000,
        y: 609.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368489600000,
        y: 607.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368576000000,
        y: 601,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368662400000,
        y: 620.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368748800000,
        y: 619.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369008000000,
        y: 622.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369094400000,
        y: 619,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369180800000,
        y: 610,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369267200000,
        y: 602.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369353600000,
        y: 599.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369699200000,
        y: 611,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369785600000,
        y: 607.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369872000000,
        y: 604.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369958400000,
        y: 598.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370217600000,
        y: 601.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370304000000,
        y: 600,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370390400000,
        y: 606.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370476800000,
        y: 606,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370563200000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370822400000,
        y: 607.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370908800000,
        y: 604.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370995200000,
        y: 612,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371081600000,
        y: 612.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371168000000,
        y: 617.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371427200000,
        y: 619.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371513600000,
        y: 618.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371600000000,
        y: 617.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371686400000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371772800000,
        y: 597,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372032000000,
        y: 592.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372118400000,
        y: 597.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372204800000,
        y: 593,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372291200000,
        y: 596.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372377600000,
        y: 595.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372636800000,
        y: 604,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372723200000,
        y: 605.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372809600000,
        y: 609.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372896000000,
        y: 610.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372982400000,
        y: 616,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373241600000,
        y: 619,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373328000000,
        y: 621.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373414400000,
        y: 624.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373500800000,
        y: 625.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373587200000,
        y: 629.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373846400000,
        y: 625,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373932800000,
        y: 624.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374019200000,
        y: 628,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374105600000,
        y: 631.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374192000000,
        y: 630.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374451200000,
        y: 626.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374537600000,
        y: 629.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374624000000,
        y: 627.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374710400000,
        y: 627,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374796800000,
        y: 626.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375056000000,
        y: 628.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375142400000,
        y: 626.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375228800000,
        y: 626.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375315200000,
        y: 635.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375401600000,
        y: 640.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375660800000,
        y: 638,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375747200000,
        y: 629.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375833600000,
        y: 624.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375920000000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376006400000,
        y: 614,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376265600000,
        y: 616.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376352000000,
        y: 623.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376438400000,
        y: 625,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376524800000,
        y: 628.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376611200000,
        y: 631.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376870400000,
        y: 631.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376956800000,
        y: 627.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377043200000,
        y: 623,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377129600000,
        y: 624,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377216000000,
        y: 623.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377561600000,
        y: 623.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377648000000,
        y: 633.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377734400000,
        y: 634.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377820800000,
        y: 629,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378080000000,
        y: 625.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378166400000,
        y: 623.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378252800000,
        y: 624.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378339200000,
        y: 624.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378425600000,
        y: 624.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378684800000,
        y: 624.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378771200000,
        y: 613,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378857600000,
        y: 614.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378944000000,
        y: 620,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379030400000,
        y: 619.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379289600000,
        y: 617.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379376000000,
        y: 615.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379462400000,
        y: 615.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379548800000,
        y: 619.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379635200000,
        y: 615,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379894400000,
        y: 613.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379980800000,
        y: 614.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380067200000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380153600000,
        y: 618,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380240000000,
        y: 622.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380499200000,
        y: 617.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380585600000,
        y: 612.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380672000000,
        y: 620.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380758400000,
        y: 619,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380844800000,
        y: 611.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381104000000,
        y: 612.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381190400000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381276800000,
        y: 605.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381363200000,
        y: 613.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381449600000,
        y: 612.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381708800000,
        y: 611.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381795200000,
        y: 611.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381881600000,
        y: 614.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381968000000,
        y: 609.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382054400000,
        y: 611.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382313600000,
        y: 611.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382400000000,
        y: 610.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382486400000,
        y: 609.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382572800000,
        y: 608.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382659200000,
        y: 599.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382918400000,
        y: 600.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383004800000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383091200000,
        y: 609.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383177600000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383264000000,
        y: 606.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383523200000,
        y: 597.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383609600000,
        y: 594.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383696000000,
        y: 596.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383782400000,
        y: 591,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383868800000,
        y: 591.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384128000000,
        y: 595.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384214400000,
        y: 594.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384300800000,
        y: 594,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384387200000,
        y: 596.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384473600000,
        y: 597.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384732800000,
        y: 598,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384819200000,
        y: 598.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384905600000,
        y: 601.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384992000000,
        y: 600,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385078400000,
        y: 603,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385337600000,
        y: 602,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385424000000,
        y: 602.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385510400000,
        y: 602.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385596800000,
        y: 602.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385683200000,
        y: 604.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385942400000,
        y: 605,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386028800000,
        y: 607.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386115200000,
        y: 609.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386201600000,
        y: 606,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386288000000,
        y: 606.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386547200000,
        y: 604,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386633600000,
        y: 604.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386720000000,
        y: 604.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386806400000,
        y: 602.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386892800000,
        y: 602,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387152000000,
        y: 607.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387238400000,
        y: 608.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387324800000,
        y: 604.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387411200000,
        y: 601.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387497600000,
        y: 600.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387756800000,
        y: 604.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388102400000,
        y: 610.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388361600000,
        y: 608.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388620800000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388707200000,
        y: 597.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388966400000,
        y: 594.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389052800000,
        y: 592.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389139200000,
        y: 587.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389225600000,
        y: 586.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389312000000,
        y: 584.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389571200000,
        y: 584.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389657600000,
        y: 581.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389744000000,
        y: 586.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389830400000,
        y: 579.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389916800000,
        y: 580.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390176000000,
        y: 582,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390262400000,
        y: 585.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390348800000,
        y: 584.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390435200000,
        y: 585.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390521600000,
        y: 582.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390780800000,
        y: 586.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390867200000,
        y: 591.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390953600000,
        y: 592.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391040000000,
        y: 598.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391126400000,
        y: 599.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391385600000,
        y: 590.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391472000000,
        y: 589.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391558400000,
        y: 591.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391644800000,
        y: 596.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391731200000,
        y: 597.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391990400000,
        y: 600.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392076800000,
        y: 602.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392163200000,
        y: 602,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392249600000,
        y: 603.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392336000000,
        y: 602.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392595200000,
        y: 605.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392681600000,
        y: 607.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392768000000,
        y: 612.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392854400000,
        y: 611.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392940800000,
        y: 611,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393200000000,
        y: 613.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393286400000,
        y: 609.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393372800000,
        y: 610.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393459200000,
        y: 610.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393545600000,
        y: 608,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393804800000,
        y: 614.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393891200000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393977600000,
        y: 603.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394064000000,
        y: 600.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394150400000,
        y: 601.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394409600000,
        y: 600.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394496000000,
        y: 598.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394582400000,
        y: 599.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394668800000,
        y: 598.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394755200000,
        y: 602.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395014400000,
        y: 598,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395100800000,
        y: 599.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395187200000,
        y: 597.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395273600000,
        y: 597.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395360000000,
        y: 598,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395619200000,
        y: 597,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395705600000,
        y: 599.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395792000000,
        y: 600.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395878400000,
        y: 604.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395964800000,
        y: 604.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396224000000,
        y: 601.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396310400000,
        y: 603.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396396800000,
        y: 599.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396483200000,
        y: 600.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396569600000,
        y: 602.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396828800000,
        y: 597.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396915200000,
        y: 598,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397001600000,
        y: 602.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397088000000,
        y: 603.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397174400000,
        y: 603,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397433600000,
        y: 604,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397520000000,
        y: 607,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397606400000,
        y: 604.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398124800000,
        y: 602.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398211200000,
        y: 603,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398297600000,
        y: 605.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398384000000,
        y: 602.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398643200000,
        y: 601,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398729600000,
        y: 600.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398816000000,
        y: 596.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398902400000,
        y: 593.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398988800000,
        y: 593,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399334400000,
        y: 592.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399420800000,
        y: 594.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399507200000,
        y: 594,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399593600000,
        y: 594.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399852800000,
        y: 596,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399939200000,
        y: 597.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400025600000,
        y: 600.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400112000000,
        y: 601.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400198400000,
        y: 603.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400457600000,
        y: 605.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400544000000,
        y: 605.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400630400000,
        y: 607.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400716800000,
        y: 609.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400803200000,
        y: 607.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401148800000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401235200000,
        y: 605.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401321600000,
        y: 606.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401408000000,
        y: 603.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401667200000,
        y: 603.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401753600000,
        y: 602.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401840000000,
        y: 602.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401926400000,
        y: 601,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402012800000,
        y: 598.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402272000000,
        y: 602,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402358400000,
        y: 602.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402444800000,
        y: 603.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402531200000,
        y: 609.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402617600000,
        y: 610.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402876800000,
        y: 616.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402963200000,
        y: 620.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403049600000,
        y: 622.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403136000000,
        y: 627.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403222400000,
        y: 629.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403481600000,
        y: 629.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403568000000,
        y: 630.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403654400000,
        y: 625,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403740800000,
        y: 621.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403827200000,
        y: 620.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404086400000,
        y: 618,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404172800000,
        y: 614.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404259200000,
        y: 612.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404345600000,
        y: 608.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404432000000,
        y: 605.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404691200000,
        y: 601.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404777600000,
        y: 597.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404864000000,
        y: 594.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404950400000,
        y: 595.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405036800000,
        y: 593.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405296000000,
        y: 593.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405382400000,
        y: 591.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405468800000,
        y: 594.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405555200000,
        y: 595.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405641600000,
        y: 596,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405900800000,
        y: 595.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405987200000,
        y: 595,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406073600000,
        y: 597.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406160000000,
        y: 597,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406246400000,
        y: 598,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406505600000,
        y: 596.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406592000000,
        y: 598,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406678400000,
        y: 597.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406764800000,
        y: 595.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406851200000,
        y: 592,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407110400000,
        y: 592.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407196800000,
        y: 588.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407283200000,
        y: 590.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407369600000,
        y: 590.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407456000000,
        y: 590.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407715200000,
        y: 591.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407801600000,
        y: 587,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407888000000,
        y: 584.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407974400000,
        y: 583.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408060800000,
        y: 584,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408320000000,
        y: 580,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408406400000,
        y: 579,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408492800000,
        y: 583.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408579200000,
        y: 583,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408665600000,
        y: 579.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409011200000,
        y: 585,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409097600000,
        y: 588.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409184000000,
        y: 589.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409270400000,
        y: 593.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409529600000,
        y: 586.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409616000000,
        y: 581.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409702400000,
        y: 583,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409788800000,
        y: 583.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409875200000,
        y: 578,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410134400000,
        y: 577.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410220800000,
        y: 573.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410307200000,
        y: 569,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410393600000,
        y: 565.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410480000000,
        y: 562,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410739200000,
        y: 560.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410825600000,
        y: 561,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410912000000,
        y: 564,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410998400000,
        y: 560,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411084800000,
        y: 559.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411344000000,
        y: 557.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411430400000,
        y: 558.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411516800000,
        y: 559,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411603200000,
        y: 561.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411689600000,
        y: 562,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411948800000,
        y: 560.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412035200000,
        y: 561.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412121600000,
        y: 567.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412208000000,
        y: 551.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412294400000,
        y: 543.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412553600000,
        y: 542.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412640000000,
        y: 537.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412726400000,
        y: 527.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412812800000,
        y: 522.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412899200000,
        y: 512.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413158400000,
        y: 506.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413244800000,
        y: 496,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413331200000,
        y: 481.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413417600000,
        y: 473.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413504000000,
        y: 481.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413763200000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413849600000,
        y: 479,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413936000000,
        y: 482.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414022400000,
        y: 480,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414108800000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414368000000,
        y: 476.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414454400000,
        y: 476.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414540800000,
        y: 483.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414627200000,
        y: 481,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414713600000,
        y: 479.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414972800000,
        y: 475,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415059200000,
        y: 461.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415145600000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415232000000,
        y: 457.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415318400000,
        y: 461.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415577600000,
        y: 464.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415664000000,
        y: 458.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415750400000,
        y: 458,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415836800000,
        y: 448.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415923200000,
        y: 438.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416182400000,
        y: 435.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416268800000,
        y: 436.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416355200000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416441600000,
        y: 433,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416528000000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416787200000,
        y: 444,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416873600000,
        y: 436.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416960000000,
        y: 434.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417046400000,
        y: 415,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417132800000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417392000000,
        y: 400.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417478400000,
        y: 396.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417564800000,
        y: 393.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417651200000,
        y: 385.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417737600000,
        y: 381.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417996800000,
        y: 372,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418083200000,
        y: 375,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418169600000,
        y: 360,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418256000000,
        y: 359,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418342400000,
        y: 348.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418601600000,
        y: 340.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418688000000,
        y: 331,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418774400000,
        y: 324.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418860800000,
        y: 325,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418947200000,
        y: 326.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419206400000,
        y: 317.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419292800000,
        y: 314,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419811200000,
        y: 284.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419897600000,
        y: 275.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420156800000,
        y: 270,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420416000000,
        y: 249.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420502400000,
        y: 240,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420588800000,
        y: 234.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420675200000,
        y: 242,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420761600000,
        y: 236.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421020800000,
        y: 233.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421107200000,
        y: 226.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421193600000,
        y: 228,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421280000000,
        y: 232.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421366400000,
        y: 231.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421625600000,
        y: 232.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421712000000,
        y: 232.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421798400000,
        y: 231.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421884800000,
        y: 233.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421971200000,
        y: 235,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422230400000,
        y: 234.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422316800000,
        y: 235,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422403200000,
        y: 237.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422489600000,
        y: 238,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422576000000,
        y: 239.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422835200000,
        y: 257,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422921600000,
        y: 266,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423008000000,
        y: 286.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423094400000,
        y: 288.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423180800000,
        y: 295,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423440000000,
        y: 301.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423526400000,
        y: 297.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423612800000,
        y: 292,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423699200000,
        y: 301.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423785600000,
        y: 316.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424044800000,
        y: 320.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424131200000,
        y: 322.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424217600000,
        y: 319.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424304000000,
        y: 309.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424390400000,
        y: 314.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424649600000,
        y: 307.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424736000000,
        y: 305.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424822400000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424908800000,
        y: 312.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424995200000,
        y: 316.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425254400000,
        y: 315,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425340800000,
        y: 313.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425427200000,
        y: 308.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425513600000,
        y: 311.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425600000000,
        y: 309.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425859200000,
        y: 302.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425945600000,
        y: 291.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426032000000,
        y: 291.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426118400000,
        y: 293.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426204800000,
        y: 287.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426464000000,
        y: 274.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426550400000,
        y: 272,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426636800000,
        y: 273.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426723200000,
        y: 279.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426809600000,
        y: 279.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427068800000,
        y: 282,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427155200000,
        y: 284.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427241600000,
        y: 290.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427328000000,
        y: 304.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427414400000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427673600000,
        y: 289.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427760000000,
        y: 286,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427846400000,
        y: 288.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427932800000,
        y: 284.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428364800000,
        y: 299.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428451200000,
        y: 291.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428537600000,
        y: 292.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428624000000,
        y: 294.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428883200000,
        y: 299,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428969600000,
        y: 307.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429056000000,
        y: 310.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429142400000,
        y: 319.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429228800000,
        y: 326,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429488000000,
        y: 326.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429574400000,
        y: 322.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429660800000,
        y: 324.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429747200000,
        y: 330.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429833600000,
        y: 334.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430092800000,
        y: 336.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430179200000,
        y: 337,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430265600000,
        y: 340.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430352000000,
        y: 343.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430438400000,
        y: 342.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430784000000,
        y: 353.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430870400000,
        y: 362,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430956800000,
        y: 355.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431043200000,
        y: 351,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431302400000,
        y: 348.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431388800000,
        y: 354.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431475200000,
        y: 355.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431561600000,
        y: 353.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431648000000,
        y: 349.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431907200000,
        y: 343.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431993600000,
        y: 336.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432080000000,
        y: 333.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432166400000,
        y: 339,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432252800000,
        y: 334.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432598400000,
        y: 329.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432684800000,
        y: 326,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432771200000,
        y: 323.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432857600000,
        y: 332.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433116800000,
        y: 335.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433203200000,
        y: 340.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433289600000,
        y: 332.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433376000000,
        y: 322,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433462400000,
        y: 318.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433721600000,
        y: 322.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433808000000,
        y: 327.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433894400000,
        y: 335.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433980800000,
        y: 331,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434067200000,
        y: 329.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434326400000,
        y: 324.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434412800000,
        y: 325.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434499200000,
        y: 327.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434585600000,
        y: 328.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434672000000,
        y: 324.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434931200000,
        y: 324.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435017600000,
        y: 328.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435104000000,
        y: 328.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435190400000,
        y: 324.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435276800000,
        y: 323.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435536000000,
        y: 322,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435622400000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435708800000,
        y: 322.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435795200000,
        y: 322.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435881600000,
        y: 319.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436140800000,
        y: 301,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436227200000,
        y: 284.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436313600000,
        y: 287.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436400000000,
        y: 294.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436486400000,
        y: 294.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436745600000,
        y: 297.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436832000000,
        y: 291,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436918400000,
        y: 291.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437004800000,
        y: 289.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437091200000,
        y: 285.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437350400000,
        y: 284,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437436800000,
        y: 282.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437523200000,
        y: 282.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437609600000,
        y: 278.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437696000000,
        y: 272.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437955200000,
        y: 269,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438041600000,
        y: 262,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438128000000,
        y: 264.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438214400000,
        y: 265.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438300800000,
        y: 261.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438560000000,
        y: 252.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438646400000,
        y: 248.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438732800000,
        y: 248,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438819200000,
        y: 244,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438905600000,
        y: 243.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439164800000,
        y: 243.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439251200000,
        y: 239.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439337600000,
        y: 240,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439424000000,
        y: 237.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439510400000,
        y: 232.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439769600000,
        y: 228.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439856000000,
        y: 223.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439942400000,
        y: 221.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440028800000,
        y: 214.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440115200000,
        y: 210,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440374400000,
        y: 187.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440460800000,
        y: 184.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440547200000,
        y: 179.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440633600000,
        y: 194.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440720000000,
        y: 216.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441065600000,
        y: 232.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441152000000,
        y: 234.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441238400000,
        y: 242.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441324800000,
        y: 235.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441584000000,
        y: 227.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441670400000,
        y: 224,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441756800000,
        y: 220,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441843200000,
        y: 214.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441929600000,
        y: 211,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442188800000,
        y: 205.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442275200000,
        y: 200.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442361600000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442448000000,
        y: 207.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442534400000,
        y: 205.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442793600000,
        y: 202.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442880000000,
        y: 200,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442966400000,
        y: 206.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443052800000,
        y: 201.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443139200000,
        y: 203,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443398400000,
        y: 200.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443484800000,
        y: 201.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443571200000,
        y: 201.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443657600000,
        y: 204.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443744000000,
        y: 202,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444003200000,
        y: 214.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444089600000,
        y: 220.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444176000000,
        y: 224.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444262400000,
        y: 229,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444348800000,
        y: 229.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444608000000,
        y: 228.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444694400000,
        y: 220.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444780800000,
        y: 215.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444867200000,
        y: 211.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444953600000,
        y: 210.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445212800000,
        y: 206.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445299200000,
        y: 208.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445385600000,
        y: 205.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445472000000,
        y: 205.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445558400000,
        y: 204.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445817600000,
        y: 205.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445904000000,
        y: 201.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445990400000,
        y: 199.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446076800000,
        y: 209.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446163200000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446422400000,
        y: 209,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446508800000,
        y: 211.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446595200000,
        y: 208,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446681600000,
        y: 207.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446768000000,
        y: 203.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447027200000,
        y: 200.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447113600000,
        y: 197.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447200000000,
        y: 197.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447286400000,
        y: 181.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447372800000,
        y: 177.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447632000000,
        y: 172,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447718400000,
        y: 175,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447804800000,
        y: 173.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447891200000,
        y: 174.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447977600000,
        y: 176,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448236800000,
        y: 180,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448323200000,
        y: 183,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448409600000,
        y: 184.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448496000000,
        y: 182.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448582400000,
        y: 178.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448841600000,
        y: 179.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448928000000,
        y: 176.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449014400000,
        y: 172,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449100800000,
        y: 166.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449187200000,
        y: 163.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449446400000,
        y: 151.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449532800000,
        y: 142.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449619200000,
        y: 142,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449705600000,
        y: 140.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449792000000,
        y: 133.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450051200000,
        y: 126.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450137600000,
        y: 129,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450224000000,
        y: 126,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450310400000,
        y: 122.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450396800000,
        y: 123,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450656000000,
        y: 116.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450742400000,
        y: 116.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450828800000,
        y: 114.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451347200000,
        y: 120.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451433600000,
        y: 118.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451865600000,
        y: 118.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451952000000,
        y: 117.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452038400000,
        y: 110,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452124800000,
        y: 110.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452211200000,
        y: 101.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452470400000,
        y: 97.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452556800000,
        y: 94.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452643200000,
        y: 92.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452729600000,
        y: 93.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452816000000,
        y: 89.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453075200000,
        y: 87.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453161600000,
        y: 89.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453248000000,
        y: 85.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453334400000,
        y: 94,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453420800000,
        y: 105.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453680000000,
        y: 105,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453766400000,
        y: 114.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453852800000,
        y: 116,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453939200000,
        y: 126.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454025600000,
        y: 132,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454284800000,
        y: 130.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454371200000,
        y: 123.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454457600000,
        y: 128,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454544000000,
        y: 128.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454630400000,
        y: 130.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454889600000,
        y: 129.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454976000000,
        y: 123,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455062400000,
        y: 123.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455148800000,
        y: 116.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455235200000,
        y: 124,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455494400000,
        y: 125.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455580800000,
        y: 120,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455667200000,
        y: 128.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455753600000,
        y: 127.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455840000000,
        y: 119.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456099200000,
        y: 126.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456185600000,
        y: 119.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456272000000,
        y: 117,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456358400000,
        y: 118,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456444800000,
        y: 124.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456704000000,
        y: 127,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456790400000,
        y: 127.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456876800000,
        y: 128.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456963200000,
        y: 125.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457049600000,
        y: 131.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457308800000,
        y: 140.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457395200000,
        y: 144,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457481600000,
        y: 151,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457568000000,
        y: 147.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457654400000,
        y: 150.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457913600000,
        y: 144.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458000000000,
        y: 137.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458086400000,
        y: 140,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458172800000,
        y: 143.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458259200000,
        y: 143,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458518400000,
        y: 148,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458604800000,
        y: 148.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458691200000,
        y: 146.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459209600000,
        y: 139.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459296000000,
        y: 142.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459382400000,
        y: 144.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459468800000,
        y: 135.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459728000000,
        y: 130,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459814400000,
        y: 127,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459900800000,
        y: 132.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459987200000,
        y: 131.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460073600000,
        y: 147,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460332800000,
        y: 153.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460419200000,
        y: 160,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460505600000,
        y: 162.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460592000000,
        y: 163.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460678400000,
        y: 158.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460937600000,
        y: 156,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461024000000,
        y: 156.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461110400000,
        y: 164.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461196800000,
        y: 170,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461283200000,
        y: 175.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461542400000,
        y: 173.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461628800000,
        y: 173.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461715200000,
        y: 179.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461801600000,
        y: 184.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461888000000,
        y: 192.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462233600000,
        y: 185.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462320000000,
        y: 182.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462406400000,
        y: 183,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462492800000,
        y: 186,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462752000000,
        y: 180.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462838400000,
        y: 184.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462924800000,
        y: 193.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463011200000,
        y: 197,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463097600000,
        y: 203.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463356800000,
        y: 208.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463443200000,
        y: 209.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463529600000,
        y: 209,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463616000000,
        y: 201,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463702400000,
        y: 206.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463961600000,
        y: 206,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464048000000,
        y: 206.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464134400000,
        y: 206,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464220800000,
        y: 210,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464307200000,
        y: 210.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464652800000,
        y: 213.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464739200000,
        y: 212.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464825600000,
        y: 214.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464912000000,
        y: 214.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465171200000,
        y: 217.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465257600000,
        y: 221.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465344000000,
        y: 226.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465430400000,
        y: 227.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465516800000,
        y: 222,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465776000000,
        y: 220.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465862400000,
        y: 212.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465948800000,
        y: 213.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466035200000,
        y: 205.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466121600000,
        y: 209.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466380800000,
        y: 218.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466467200000,
        y: 221,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466553600000,
        y: 225.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466640000000,
        y: 226.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466726400000,
        y: 220,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466985600000,
        y: 214.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467072000000,
        y: 219.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467158400000,
        y: 232.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467244800000,
        y: 237,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467331200000,
        y: 233.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467590400000,
        y: 233.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467676800000,
        y: 224.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467763200000,
        y: 222.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467849600000,
        y: 226.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467936000000,
        y: 223.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468195200000,
        y: 222,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468281600000,
        y: 224.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468368000000,
        y: 222.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468454400000,
        y: 226,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468540800000,
        y: 223.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468800000000,
        y: 222.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468886400000,
        y: 221.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468972800000,
        y: 220.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469059200000,
        y: 219.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469145600000,
        y: 214.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469404800000,
        y: 211,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469491200000,
        y: 208.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469577600000,
        y: 208.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469664000000,
        y: 206.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469750400000,
        y: 206.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470009600000,
        y: 201.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470096000000,
        y: 196.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470182400000,
        y: 194.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470268800000,
        y: 198.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470355200000,
        y: 197.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470614400000,
        y: 203.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470700800000,
        y: 206,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470787200000,
        y: 204.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470873600000,
        y: 210.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470960000000,
        y: 215.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471219200000,
        y: 221.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471305600000,
        y: 228,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471392000000,
        y: 236.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471478400000,
        y: 240.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471564800000,
        y: 242.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471824000000,
        y: 237.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471910400000,
        y: 236.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471996800000,
        y: 232.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472083200000,
        y: 236.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472169600000,
        y: 238.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472515200000,
        y: 237,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472601600000,
        y: 231.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472688000000,
        y: 225,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472774400000,
        y: 226.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473033600000,
        y: 230.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473120000000,
        y: 230,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473206400000,
        y: 232.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473292800000,
        y: 243,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473379200000,
        y: 241.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473638400000,
        y: 239.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473724800000,
        y: 234.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473811200000,
        y: 232,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473897600000,
        y: 233.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473984000000,
        y: 231.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474243200000,
        y: 234.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474329600000,
        y: 234,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474416000000,
        y: 234.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474502400000,
        y: 241.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474588800000,
        y: 241.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474848000000,
        y: 242,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474934400000,
        y: 232.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475020800000,
        y: 232,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475107200000,
        y: 242.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475193600000,
        y: 243.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475452800000,
        y: 244.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475539200000,
        y: 247,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475625600000,
        y: 253,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475712000000,
        y: 258.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475798400000,
        y: 262.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476057600000,
        y: 265.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476144000000,
        y: 260.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476230400000,
        y: 258.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476316800000,
        y: 257.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476403200000,
        y: 257.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476662400000,
        y: 257.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476748800000,
        y: 259.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476835200000,
        y: 268,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476921600000,
        y: 263.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477008000000,
        y: 264.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477267200000,
        y: 264.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477353600000,
        y: 264.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477440000000,
        y: 261.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477526400000,
        y: 259.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477612800000,
        y: 258.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477872000000,
        y: 249.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477958400000,
        y: 244,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478044800000,
        y: 234.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478131200000,
        y: 233.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478217600000,
        y: 234.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478476800000,
        y: 239.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478563200000,
        y: 240,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478649600000,
        y: 240.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478736000000,
        y: 239,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478822400000,
        y: 231.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479081600000,
        y: 226,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479168000000,
        y: 232.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479254400000,
        y: 237.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479340800000,
        y: 238.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479427200000,
        y: 238.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479686400000,
        y: 249.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479772800000,
        y: 254.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479859200000,
        y: 257,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479945600000,
        y: 256,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480032000000,
        y: 253,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480291200000,
        y: 256.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480377600000,
        y: 249.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480464000000,
        y: 265,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480550400000,
        y: 270.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480636800000,
        y: 274.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480896000000,
        y: 283,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480982400000,
        y: 282,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481068800000,
        y: 283.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481155200000,
        y: 282,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481241600000,
        y: 284,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481500800000,
        y: 293.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481587200000,
        y: 291.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481673600000,
        y: 290.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481760000000,
        y: 294,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481846400000,
        y: 300.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482105600000,
        y: 300,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482192000000,
        y: 299.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482278400000,
        y: 299.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482364800000,
        y: 298.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482969600000,
        y: 305.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483401600000,
        y: 307.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483488000000,
        y: 305.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483574400000,
        y: 309.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483660800000,
        y: 311,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483920000000,
        y: 306.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484006400000,
        y: 299.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484092800000,
        y: 301,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484179200000,
        y: 306.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484265600000,
        y: 307.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484524800000,
        y: 306.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484611200000,
        y: 309.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484697600000,
        y: 302.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484784000000,
        y: 297.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484870400000,
        y: 301.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485129600000,
        y: 298.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485216000000,
        y: 297.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485302400000,
        y: 296.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485388800000,
        y: 297.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485475200000,
        y: 294.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485734400000,
        y: 290.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485820800000,
        y: 289.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485907200000,
        y: 292,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485993600000,
        y: 295.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486080000000,
        y: 301.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486339200000,
        y: 301.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486425600000,
        y: 295,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486512000000,
        y: 295.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486598400000,
        y: 296.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486684800000,
        y: 304,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486944000000,
        y: 299.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487030400000,
        y: 303.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487116800000,
        y: 303.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487203200000,
        y: 300.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487289600000,
        y: 300.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487548800000,
        y: 301.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487635200000,
        y: 303.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487721600000,
        y: 298.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487808000000,
        y: 302.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487894400000,
        y: 302.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488153600000,
        y: 304.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488240000000,
        y: 302.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488326400000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488412800000,
        y: 297.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488499200000,
        y: 297.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488758400000,
        y: 297.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488844800000,
        y: 297.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488931200000,
        y: 294.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489017600000,
        y: 279.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489104000000,
        y: 276.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489363200000,
        y: 271.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489449600000,
        y: 264.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489536000000,
        y: 268.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489622400000,
        y: 270.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489708800000,
        y: 271.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489968000000,
        y: 270,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490054400000,
        y: 267.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490140800000,
        y: 263.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490227200000,
        y: 264.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490313600000,
        y: 264.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490572800000,
        y: 264.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490659200000,
        y: 268.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490745600000,
        y: 270,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490832000000,
        y: 276,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490918400000,
        y: 277.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491177600000,
        y: 280.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491264000000,
        y: 285.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491350400000,
        y: 289.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491436800000,
        y: 293.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491523200000,
        y: 295.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491782400000,
        y: 300,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491868800000,
        y: 299.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491955200000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492473600000,
        y: 296.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492560000000,
        y: 294.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492646400000,
        y: 286,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492732800000,
        y: 279.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492992000000,
        y: 276.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493078400000,
        y: 275,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493164800000,
        y: 277,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493251200000,
        y: 270.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493337600000,
        y: 274.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493683200000,
        y: 273,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493769600000,
        y: 267.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493856000000,
        y: 262,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493942400000,
        y: 262.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494201600000,
        y: 260.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494288000000,
        y: 261,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494374400000,
        y: 266.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494460800000,
        y: 274.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494547200000,
        y: 275.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494806400000,
        y: 282.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494892800000,
        y: 283,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494979200000,
        y: 285,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495065600000,
        y: 286.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495152000000,
        y: 292,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495411200000,
        y: 294.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495497600000,
        y: 295.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495584000000,
        y: 298,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495670400000,
        y: 295,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495756800000,
        y: 288.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496102400000,
        y: 286.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496188800000,
        y: 282,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496275200000,
        y: 285.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496361600000,
        y: 278,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496620800000,
        y: 277,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496707200000,
        y: 274.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496793600000,
        y: 274,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496880000000,
        y: 274,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496966400000,
        y: 273.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497225600000,
        y: 273.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497312000000,
        y: 273.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497398400000,
        y: 269,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497484800000,
        y: 266.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497571200000,
        y: 265.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497830400000,
        y: 268.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497916800000,
        y: 264,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498003200000,
        y: 264,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498089600000,
        y: 264.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498176000000,
        y: 265.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498435200000,
        y: 267.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498521600000,
        y: 273,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498608000000,
        y: 274.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498694400000,
        y: 277.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498780800000,
        y: 282,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499040000000,
        y: 285.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499126400000,
        y: 285.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499212800000,
        y: 278.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499299200000,
        y: 281,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499385600000,
        y: 270.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499644800000,
        y: 269.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499731200000,
        y: 267.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499817600000,
        y: 271.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499904000000,
        y: 276.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499990400000,
        y: 278.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500249600000,
        y: 280.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500336000000,
        y: 279,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500422400000,
        y: 284,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500508800000,
        y: 285.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500595200000,
        y: 278.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500854400000,
        y: 283.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500940800000,
        y: 288.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501027200000,
        y: 293.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501113600000,
        y: 296.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501200000000,
        y: 299,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501459200000,
        y: 300.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501545600000,
        y: 298.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501632000000,
        y: 298.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501718400000,
        y: 300.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501804800000,
        y: 289,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502064000000,
        y: 286,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502150400000,
        y: 289.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502236800000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502323200000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502409600000,
        y: 296.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502668800000,
        y: 295.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502755200000,
        y: 287.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502841600000,
        y: 288.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502928000000,
        y: 285.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503014400000,
        y: 287.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503273600000,
        y: 291.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503360000000,
        y: 292.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503446400000,
        y: 292.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503532800000,
        y: 291.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503619200000,
        y: 292.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503964800000,
        y: 291.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504051200000,
        y: 293.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504137600000,
        y: 296.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504224000000,
        y: 301.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504483200000,
        y: 298,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504569600000,
        y: 307.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504656000000,
        y: 311.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504742400000,
        y: 311.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504828800000,
        y: 311.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505088000000,
        y: 306.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505174400000,
        y: 310.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505260800000,
        y: 316.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505347200000,
        y: 321,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505433600000,
        y: 321.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505692800000,
        y: 318.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505779200000,
        y: 319.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505865600000,
        y: 321,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505952000000,
        y: 321,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506038400000,
        y: 319.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506297600000,
        y: 320.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506384000000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506470400000,
        y: 320.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506556800000,
        y: 323.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506643200000,
        y: 318.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506902400000,
        y: 315,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506988800000,
        y: 313.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507075200000,
        y: 312,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507161600000,
        y: 316.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507248000000,
        y: 309.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507507200000,
        y: 310.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507593600000,
        y: 314.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507680000000,
        y: 317.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507766400000,
        y: 317.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507852800000,
        y: 321.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508112000000,
        y: 325.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508198400000,
        y: 324.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508284800000,
        y: 319,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508371200000,
        y: 319.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508457600000,
        y: 321.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508716800000,
        y: 321,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508803200000,
        y: 322.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508889600000,
        y: 323.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508976000000,
        y: 325,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509062400000,
        y: 330.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509321600000,
        y: 335,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509408000000,
        y: 343.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509494400000,
        y: 350.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509580800000,
        y: 349,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509667200000,
        y: 349.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509926400000,
        y: 359.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510012800000,
        y: 363.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510099200000,
        y: 362.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510185600000,
        y: 362.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510272000000,
        y: 365.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510531200000,
        y: 361,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510617600000,
        y: 351,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510704000000,
        y: 351.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510790400000,
        y: 351.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510876800000,
        y: 352.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511136000000,
        y: 349.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511222400000,
        y: 349.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511308800000,
        y: 350.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511395200000,
        y: 350,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511481600000,
        y: 349.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511740800000,
        y: 348.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511827200000,
        y: 346.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511913600000,
        y: 346.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512000000000,
        y: 347.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512086400000,
        y: 352.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512345600000,
        y: 348.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512432000000,
        y: 345.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512518400000,
        y: 337.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512604800000,
        y: 332,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512691200000,
        y: 337.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512950400000,
        y: 343.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513036800000,
        y: 344,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513123200000,
        y: 340.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513209600000,
        y: 342.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513296000000,
        y: 345.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513555200000,
        y: 348.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513641600000,
        y: 352,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513728000000,
        y: 357.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513814400000,
        y: 360.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514332800000,
        y: 363.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514419200000,
        y: 363,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514851200000,
        y: 360.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514937600000,
        y: 372.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515024000000,
        y: 372,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515110400000,
        y: 368.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515369600000,
        y: 369.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515456000000,
        y: 368.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515542400000,
        y: 371.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515628800000,
        y: 373.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515715200000,
        y: 371,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515974400000,
        y: 373,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516060800000,
        y: 369.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516147200000,
        y: 368,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516233600000,
        y: 373,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516320000000,
        y: 373.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516579200000,
        y: 373.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516665600000,
        y: 373,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516752000000,
        y: 373,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516838400000,
        y: 377,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516924800000,
        y: 377.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517184000000,
        y: 373.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517270400000,
        y: 371,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517356800000,
        y: 372.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517443200000,
        y: 374.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517529600000,
        y: 372,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517788800000,
        y: 373.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517875200000,
        y: 367.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517961600000,
        y: 360.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518048000000,
        y: 350.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518134400000,
        y: 344.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518393600000,
        y: 342.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518480000000,
        y: 340.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518566400000,
        y: 341.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518652800000,
        y: 344,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518739200000,
        y: 351,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518998400000,
        y: 354,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519084800000,
        y: 353.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519171200000,
        y: 353.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519257600000,
        y: 354.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519344000000,
        y: 358.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519603200000,
        y: 359.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519689600000,
        y: 356,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519776000000,
        y: 351.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519862400000,
        y: 346.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519948800000,
        y: 342.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520208000000,
        y: 348,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520294400000,
        y: 350.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520380800000,
        y: 350.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520467200000,
        y: 350,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520553600000,
        y: 349,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520812800000,
        y: 349.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520899200000,
        y: 349.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520985600000,
        y: 350.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521072000000,
        y: 350.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521158400000,
        y: 352.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521417600000,
        y: 353.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521504000000,
        y: 357.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521590400000,
        y: 362.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521676800000,
        y: 364.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521763200000,
        y: 367.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522022400000,
        y: 366.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522108800000,
        y: 366.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522195200000,
        y: 362.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522713600000,
        y: 360,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522800000000,
        y: 358.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522886400000,
        y: 360,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522972800000,
        y: 363.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523232000000,
        y: 367,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523318400000,
        y: 370,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523404800000,
        y: 378,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523491200000,
        y: 378.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523577600000,
        y: 383,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523836800000,
        y: 379,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523923200000,
        y: 377,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524009600000,
        y: 382.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524096000000,
        y: 388.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524182400000,
        y: 385,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524441600000,
        y: 386,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524528000000,
        y: 388.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524614400000,
        y: 384.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524700800000,
        y: 390.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524787200000,
        y: 393.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525046400000,
        y: 395.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525132800000,
        y: 397.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525219200000,
        y: 393.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525305600000,
        y: 393,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525392000000,
        y: 398.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525737600000,
        y: 404,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525824000000,
        y: 417.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525910400000,
        y: 419,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525996800000,
        y: 421.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526256000000,
        y: 422.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526342400000,
        y: 429,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526428800000,
        y: 431.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526515200000,
        y: 444,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526601600000,
        y: 451.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526860800000,
        y: 449.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526947200000,
        y: 454.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527033600000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527120000000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527206400000,
        y: 435,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527552000000,
        y: 431,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527638400000,
        y: 435,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527724800000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527811200000,
        y: 431.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528070400000,
        y: 430,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528156800000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528243200000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528329600000,
        y: 435.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528416000000,
        y: 437,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528675200000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528761600000,
        y: 434.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528848000000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528934400000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529020800000,
        y: 419.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529280000000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529366400000,
        y: 420.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529452800000,
        y: 423,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529539200000,
        y: 415.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529625600000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529884800000,
        y: 420.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529971200000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530057600000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530144000000,
        y: 443.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530230400000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530489600000,
        y: 451,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530576000000,
        y: 449.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530662400000,
        y: 450.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530748800000,
        y: 452.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530835200000,
        y: 448.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531094400000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531180800000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531267200000,
        y: 447.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531353600000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531440000000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531699200000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531785600000,
        y: 420,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531872000000,
        y: 416.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531958400000,
        y: 426,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532044800000,
        y: 431.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532304000000,
        y: 435.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532390400000,
        y: 436.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532476800000,
        y: 438.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532563200000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532649600000,
        y: 449.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532908800000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532995200000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533081600000,
        y: 435.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533168000000,
        y: 432.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533254400000,
        y: 431.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533513600000,
        y: 433.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533600000000,
        y: 436.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533686400000,
        y: 430.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533772800000,
        y: 427,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533859200000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534118400000,
        y: 426.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534204800000,
        y: 426.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534291200000,
        y: 416.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534377600000,
        y: 417,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534464000000,
        y: 417.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534723200000,
        y: 416.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534809600000,
        y: 417.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534896000000,
        y: 423.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534982400000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535068800000,
        y: 432.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535414400000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535500800000,
        y: 437,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535587200000,
        y: 440.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535673600000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535932800000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536019200000,
        y: 437.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536105600000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536192000000,
        y: 426,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536278400000,
        y: 424,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536537600000,
        y: 431.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536624000000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536710400000,
        y: 442.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536796800000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536883200000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537142400000,
        y: 436.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537228800000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537315200000,
        y: 440.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537401600000,
        y: 441.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537488000000,
        y: 442,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537747200000,
        y: 450.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537833600000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537920000000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538006400000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538092800000,
        y: 467.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538352000000,
        y: 472.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538438400000,
        y: 483.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538524800000,
        y: 485.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538611200000,
        y: 489.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538697600000,
        y: 492.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538956800000,
        y: 491.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539043200000,
        y: 494.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539129600000,
        y: 497.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539216000000,
        y: 487,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539302400000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539561600000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539648000000,
        y: 473.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539734400000,
        y: 473.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539820800000,
        y: 473.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539907200000,
        y: 478.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540166400000,
        y: 482.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540252800000,
        y: 473.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540339200000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540425600000,
        y: 477.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540512000000,
        y: 476.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540771200000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540857600000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540944000000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541030400000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541116800000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541376000000,
        y: 452.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541462400000,
        y: 444,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541548800000,
        y: 443.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541635200000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541721600000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541980800000,
        y: 436.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542067200000,
        y: 423.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542153600000,
        y: 416.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542240000000,
        y: 410.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542326400000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542585600000,
        y: 412.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542672000000,
        y: 402.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542758400000,
        y: 397.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542844800000,
        y: 393.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542931200000,
        y: 375,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543190400000,
        y: 379,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543276800000,
        y: 373,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543363200000,
        y: 362,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543449600000,
        y: 359.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543536000000,
        y: 357,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543795200000,
        y: 372.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543881600000,
        y: 384,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543968000000,
        y: 386,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544054400000,
        y: 363,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544140800000,
        y: 373.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544400000000,
        y: 363,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544486400000,
        y: 360,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544572800000,
        y: 353.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544659200000,
        y: 350.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544745600000,
        y: 348,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545004800000,
        y: 340.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545091200000,
        y: 324.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545177600000,
        y: 321.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545264000000,
        y: 311.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545350400000,
        y: 304.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545868800000,
        y: 306.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545955200000,
        y: 299.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546387200000,
        y: 313.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546473600000,
        y: 311,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546560000000,
        y: 325.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546819200000,
        y: 334.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546905600000,
        y: 333.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546992000000,
        y: 339.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547078400000,
        y: 345.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547164800000,
        y: 358.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547424000000,
        y: 351,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547510400000,
        y: 347.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547596800000,
        y: 352.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547683200000,
        y: 355.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547769600000,
        y: 363.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548028800000,
        y: 365.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548115200000,
        y: 364.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548201600000,
        y: 361.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548288000000,
        y: 365,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548374400000,
        y: 366,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548633600000,
        y: 360.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548720000000,
        y: 369.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548806400000,
        y: 376.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548892800000,
        y: 375.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548979200000,
        y: 375.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549238400000,
        y: 377.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549324800000,
        y: 390.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549411200000,
        y: 391.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549497600000,
        y: 384.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549584000000,
        y: 385.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549843200000,
        y: 382.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549929600000,
        y: 391.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550016000000,
        y: 398.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550102400000,
        y: 406.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550188800000,
        y: 409,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550448000000,
        y: 410.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550534400000,
        y: 410,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550620800000,
        y: 414.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550707200000,
        y: 414.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550793600000,
        y: 413,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551052800000,
        y: 401.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551139200000,
        y: 402,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551225600000,
        y: 409.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551312000000,
        y: 410.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551398400000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551657600000,
        y: 412,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551744000000,
        y: 413,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551830400000,
        y: 410,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551916800000,
        y: 411.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552003200000,
        y: 405.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552262400000,
        y: 413,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552348800000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552435200000,
        y: 416,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552521600000,
        y: 417.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552608000000,
        y: 413,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552867200000,
        y: 415,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552953600000,
        y: 412,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553040000000,
        y: 413.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553126400000,
        y: 413.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553212800000,
        y: 406.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553472000000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553558400000,
        y: 406.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553644800000,
        y: 409.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553731200000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553817600000,
        y: 412.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554076800000,
        y: 415.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554163200000,
        y: 419,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554249600000,
        y: 420,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554336000000,
        y: 420.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554422400000,
        y: 418.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554681600000,
        y: 423,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554768000000,
        y: 421.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554854400000,
        y: 422.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554940800000,
        y: 423.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555027200000,
        y: 425,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555286400000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555372800000,
        y: 416.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555459200000,
        y: 421,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555977600000,
        y: 433,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556064000000,
        y: 437.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556150400000,
        y: 440.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556236800000,
        y: 429.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556496000000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556582400000,
        y: 429,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556668800000,
        y: 425.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556755200000,
        y: 415.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556841600000,
        y: 417.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557187200000,
        y: 404,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557273600000,
        y: 406.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557360000000,
        y: 404.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557446400000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557705600000,
        y: 408,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557792000000,
        y: 402.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557878400000,
        y: 402.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557964800000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558051200000,
        y: 413.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558310400000,
        y: 414,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558396800000,
        y: 410.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558483200000,
        y: 400.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558569600000,
        y: 388.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558656000000,
        y: 387.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559001600000,
        y: 393,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559088000000,
        y: 390,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559174400000,
        y: 385.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559260800000,
        y: 380.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559520000000,
        y: 367.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559606400000,
        y: 372.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559692800000,
        y: 363.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559779200000,
        y: 364,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559865600000,
        y: 372.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560124800000,
        y: 372.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560211200000,
        y: 370.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560297600000,
        y: 357.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560384000000,
        y: 355,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560470400000,
        y: 354,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560729600000,
        y: 345.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560816000000,
        y: 352.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560902400000,
        y: 351.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560988800000,
        y: 360.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561075200000,
        y: 370.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561334400000,
        y: 375.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561420800000,
        y: 379,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561507200000,
        y: 389,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561593600000,
        y: 391.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561680000000,
        y: 393.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561939200000,
        y: 391,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562025600000,
        y: 379.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562112000000,
        y: 381.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562198400000,
        y: 384.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562284800000,
        y: 387.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562544000000,
        y: 392,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562630400000,
        y: 392,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562716800000,
        y: 408,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562803200000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562889600000,
        y: 412.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563148800000,
        y: 404,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563235200000,
        y: 399.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563321600000,
        y: 386,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563408000000,
        y: 377.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563494400000,
        y: 375.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563753600000,
        y: 374.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563840000000,
        y: 364.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563926400000,
        y: 364.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564012800000,
        y: 365.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564099200000,
        y: 367,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564358400000,
        y: 370.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564444800000,
        y: 374,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564531200000,
        y: 384.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564617600000,
        y: 366.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564704000000,
        y: 356.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564963200000,
        y: 337.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565049600000,
        y: 331.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565136000000,
        y: 300.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565222400000,
        y: 292,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565308800000,
        y: 291.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565568000000,
        y: 285.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565654400000,
        y: 293.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565740800000,
        y: 271,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565827200000,
        y: 262.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565913600000,
        y: 274,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566172800000,
        y: 279.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566259200000,
        y: 275.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566345600000,
        y: 284,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566432000000,
        y: 284.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566518400000,
        y: 284.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566864000000,
        y: 291,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566950400000,
        y: 290.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567036800000,
        y: 290,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567123200000,
        y: 278.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567382400000,
        y: 276,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567468800000,
        y: 261.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567555200000,
        y: 272.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567641600000,
        y: 293.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567728000000,
        y: 309.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567987200000,
        y: 313,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568073600000,
        y: 306,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568160000000,
        y: 303.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568246400000,
        y: 309.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568332800000,
        y: 316.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568592000000,
        y: 377.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568678400000,
        y: 397.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568764800000,
        y: 373.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568851200000,
        y: 386.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568937600000,
        y: 378,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569196800000,
        y: 360.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569283200000,
        y: 364.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569369600000,
        y: 362.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569456000000,
        y: 390.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569542400000,
        y: 358,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569801600000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569888000000,
        y: 286.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569974400000,
        y: 284.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570060800000,
        y: 290.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570147200000,
        y: 294.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570406400000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570492800000,
        y: 287,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570579200000,
        y: 286.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570665600000,
        y: 300.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570752000000,
        y: 281.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571011200000,
        y: 249,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571097600000,
        y: 257,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571184000000,
        y: 242,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571270400000,
        y: 226.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571356800000,
        y: 214.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571616000000,
        y: 214,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571702400000,
        y: 231.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571788800000,
        y: 223.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571875200000,
        y: 234,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571961600000,
        y: 237.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572220800000,
        y: 233.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572307200000,
        y: 230.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572393600000,
        y: 227.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572480000000,
        y: 238.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572566400000,
        y: 244.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572825600000,
        y: 250.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572912000000,
        y: 238.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572998400000,
        y: 236.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573084800000,
        y: 231,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573171200000,
        y: 228.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573430400000,
        y: 226.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573516800000,
        y: 225,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573603200000,
        y: 214.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573689600000,
        y: 229,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573776000000,
        y: 229.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574035200000,
        y: 221.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574121600000,
        y: 221.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574208000000,
        y: 213.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574294400000,
        y: 214.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574380800000,
        y: 212.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574640000000,
        y: 209.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574726400000,
        y: 213.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574812800000,
        y: 211.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574899200000,
        y: 205,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574985600000,
        y: 200.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575244800000,
        y: 206.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575331200000,
        y: 221.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575417600000,
        y: 216.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575504000000,
        y: 225.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575590400000,
        y: 228,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575849600000,
        y: 232.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575936000000,
        y: 251.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576022400000,
        y: 223,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576108800000,
        y: 233.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576195200000,
        y: 236.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576454400000,
        y: 236.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576540800000,
        y: 243.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576627200000,
        y: 247.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576713600000,
        y: 252.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576800000000,
        y: 253.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577059200000,
        y: 251.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577404800000,
        y: 256,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577664000000,
        y: 255.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577750400000,
        y: 254.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577923200000,
        y: 255.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578009600000,
        y: 261,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578268800000,
        y: 275.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578355200000,
        y: 273.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578441600000,
        y: 281.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578528000000,
        y: 281,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578614400000,
        y: 280.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578873600000,
        y: 282,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578960000000,
        y: 270.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579046400000,
        y: 273.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579132800000,
        y: 274.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579219200000,
        y: 279.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579478400000,
        y: 281,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579564800000,
        y: 284.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579651200000,
        y: 286.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579737600000,
        y: 284.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579824000000,
        y: 276.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580083200000,
        y: 266.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580169600000,
        y: 270.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580256000000,
        y: 274.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580342400000,
        y: 270.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580428800000,
        y: 263.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580688000000,
        y: 258,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580774400000,
        y: 261.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580860800000,
        y: 267.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580947200000,
        y: 263,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581033600000,
        y: 261.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581292800000,
        y: 260.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581379200000,
        y: 262,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581465600000,
        y: 269.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581552000000,
        y: 277.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581638400000,
        y: 276,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581897600000,
        y: 275.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581984000000,
        y: 276.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582070400000,
        y: 280.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582156800000,
        y: 282,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582243200000,
        y: 279.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582502400000,
        y: 268.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582588800000,
        y: 268.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582675200000,
        y: 269.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582761600000,
        y: 255.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582848000000,
        y: 250,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583107200000,
        y: 252.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583193600000,
        y: 253.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583280000000,
        y: 255.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583366400000,
        y: 256.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583452800000,
        y: 238.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583712000000,
        y: 191.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583798400000,
        y: 186.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583884800000,
        y: 179.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583971200000,
        y: 159.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584057600000,
        y: 158.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584316800000,
        y: 145.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584403200000,
        y: 119.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584489600000,
        y: 108.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584576000000,
        y: 112,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584662400000,
        y: 122.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584921600000,
        y: 113.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585008000000,
        y: 114.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585094400000,
        y: 109.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585180800000,
        y: 113.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585267200000,
        y: 108.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585526400000,
        y: 94.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585612800000,
        y: 99.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585699200000,
        y: 101.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585785600000,
        y: 113.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585872000000,
        y: 136,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586131200000,
        y: 137.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586217600000,
        y: 140.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586304000000,
        y: 139.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586390400000,
        y: 143.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586822400000,
        y: 135,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586908800000,
        y: 118.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586995200000,
        y: 117.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587081600000,
        y: 118,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587340800000,
        y: 135.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587427200000,
        y: 94,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587513600000,
        y: 83.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587600000000,
        y: 87.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587686400000,
        y: 82.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587945600000,
        y: 78.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588032000000,
        y: 78.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588118400000,
        y: 82.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588204800000,
        y: 91,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588291200000,
        y: 96,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588550400000,
        y: 102,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588636800000,
        y: 116.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588723200000,
        y: 114,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588809600000,
        y: 112,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589155200000,
        y: 117,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589241600000,
        y: 117,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589328000000,
        y: 117.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589414400000,
        y: 122,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589500800000,
        y: 132.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589760000000,
        y: 167.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589846400000,
        y: 153.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589932800000,
        y: 160.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590019200000,
        y: 162.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590105600000,
        y: 156.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590451200000,
        y: 157.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590537600000,
        y: 156.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590624000000,
        y: 161,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590710400000,
        y: 163.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590969600000,
        y: 174.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591056000000,
        y: 188.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591142400000,
        y: 192.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591228800000,
        y: 192.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591315200000,
        y: 205.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591574400000,
        y: 205,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591660800000,
        y: 203.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591747200000,
        y: 202,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591833600000,
        y: 189.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591920000000,
        y: 186.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592179200000,
        y: 187.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592265600000,
        y: 206.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592352000000,
        y: 210.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592438400000,
        y: 202,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592524800000,
        y: 210.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592784000000,
        y: 213,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592870400000,
        y: 218.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592956800000,
        y: 205.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593043200000,
        y: 203.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593129600000,
        y: 200.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593388800000,
        y: 202.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593475200000,
        y: 205.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593561600000,
        y: 203.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593648000000,
        y: 207.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593734400000,
        y: 209,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593993600000,
        y: 213.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594080000000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594166400000,
        y: 217.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594252800000,
        y: 214,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594339200000,
        y: 215.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594598400000,
        y: 217.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594684800000,
        y: 218,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594771200000,
        y: 218.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594857600000,
        y: 218.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594944000000,
        y: 215,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595203200000,
        y: 214.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595289600000,
        y: 218.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595376000000,
        y: 214.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595462400000,
        y: 221.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595548800000,
        y: 219,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595808000000,
        y: 219,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595894400000,
        y: 221,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595980800000,
        y: 249,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596067200000,
        y: 244.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596153600000,
        y: 220.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596412800000,
        y: 224.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596499200000,
        y: 224.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596585600000,
        y: 228.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596672000000,
        y: 252.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596758400000,
        y: 248.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597017600000,
        y: 249.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597104000000,
        y: 251.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597190400000,
        y: 253,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597276800000,
        y: 256,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597363200000,
        y: 253,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597622400000,
        y: 253.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597708800000,
        y: 257.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597795200000,
        y: 258.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597881600000,
        y: 256.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597968000000,
        y: 255,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598227200000,
        y: 257.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598313600000,
        y: 263.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598400000000,
        y: 265.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598486400000,
        y: 259.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598572800000,
        y: 259.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598832000000,
        y: 260.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598918400000,
        y: 262,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599004800000,
        y: 258,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599091200000,
        y: 249.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599177600000,
        y: 245,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599436800000,
        y: 242.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599523200000,
        y: 232,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599609600000,
        y: 233.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599696000000,
        y: 230,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599782400000,
        y: 228.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600041600000,
        y: 226,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600128000000,
        y: 228.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600214400000,
        y: 237.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600300800000,
        y: 242,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600387200000,
        y: 242.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600646400000,
        y: 233.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600732800000,
        y: 234.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600819200000,
        y: 234,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600905600000,
        y: 232.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600992000000,
        y: 234,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601251200000,
        y: 235,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601337600000,
        y: 229.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601424000000,
        y: 233.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601510400000,
        y: 226.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601596800000,
        y: 224,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601856000000,
        y: 229,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601942400000,
        y: 232.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602028800000,
        y: 229.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602115200000,
        y: 235,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602201600000,
        y: 238,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602460800000,
        y: 234,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602547200000,
        y: 239,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602633600000,
        y: 244.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602720000000,
        y: 243,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602806400000,
        y: 247.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603065600000,
        y: 247.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603152000000,
        y: 248,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603238400000,
        y: 248.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603324800000,
        y: 252.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603411200000,
        y: 249.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603670400000,
        y: 242.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603756800000,
        y: 246.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603843200000,
        y: 240.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603929600000,
        y: 230.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604016000000,
        y: 229.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604275200000,
        y: 232.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604361600000,
        y: 242.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604448000000,
        y: 245,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604534400000,
        y: 244.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604620800000,
        y: 243.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604880000000,
        y: 248,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604966400000,
        y: 256.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605052800000,
        y: 265,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605139200000,
        y: 258.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605225600000,
        y: 253.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605484800000,
        y: 256.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605571200000,
        y: 252.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605657600000,
        y: 255.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605744000000,
        y: 258.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605830400000,
        y: 259,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606089600000,
        y: 263.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606176000000,
        y: 272.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606262400000,
        y: 273,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606348800000,
        y: 270.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606435200000,
        y: 272.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606694400000,
        y: 267.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606780800000,
        y: 265.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606867200000,
        y: 269.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606953600000,
        y: 272.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607040000000,
        y: 275,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607299200000,
        y: 275,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607385600000,
        y: 273.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607472000000,
        y: 271.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607558400000,
        y: 277.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607644800000,
        y: 277.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607904000000,
        y: 277.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607990400000,
        y: 282.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608076800000,
        y: 285.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608163200000,
        y: 290,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608249600000,
        y: 294,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608508800000,
        y: 287.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608595200000,
        y: 284.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608681600000,
        y: 288,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608768000000,
        y: 286.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609200000000,
        y: 289.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609286400000,
        y: 290.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609372800000,
        y: 288,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609718400000,
        y: 288.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609804800000,
        y: 296.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609891200000,
        y: 307.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609977600000,
        y: 308.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610064000000,
        y: 315.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610323200000,
        y: 316.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610409600000,
        y: 322.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610496000000,
        y: 321,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610582400000,
        y: 318,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610668800000,
        y: 314,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610928000000,
        y: 314.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611014400000,
        y: 317.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611100800000,
        y: 319.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611187200000,
        y: 316,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611273600000,
        y: 314,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611532800000,
        y: 312.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611619200000,
        y: 314.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611705600000,
        y: 316.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611792000000,
        y: 312.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611878400000,
        y: 312.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612137600000,
        y: 316.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612224000000,
        y: 325.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612310400000,
        y: 330.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612396800000,
        y: 330.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612483200000,
        y: 335,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612742400000,
        y: 339.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612828800000,
        y: 340,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612915200000,
        y: 343.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613001600000,
        y: 343.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613088000000,
        y: 344.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613347200000,
        y: 350.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613433600000,
        y: 350.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613520000000,
        y: 352,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613606400000,
        y: 357.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613692800000,
        y: 350,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613952000000,
        y: 353.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614038400000,
        y: 358.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614124800000,
        y: 366.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614211200000,
        y: 370.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614297600000,
        y: 367,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614556800000,
        y: 365,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614643200000,
        y: 358,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614729600000,
        y: 359.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614816000000,
        y: 371,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614902400000,
        y: 379.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615161600000,
        y: 382.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615248000000,
        y: 379.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615334400000,
        y: 375,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615420800000,
        y: 380.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615507200000,
        y: 383,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615766400000,
        y: 381.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615852800000,
        y: 383.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615939200000,
        y: 376.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616025600000,
        y: 376.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616112000000,
        y: 363.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616371200000,
        y: 360.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616457600000,
        y: 350.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616544000000,
        y: 355.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616630400000,
        y: 347.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616716800000,
        y: 356,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616976000000,
        y: 354.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617062400000,
        y: 350.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617148800000,
        y: 355.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617235200000,
        y: 346.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617667200000,
        y: 352,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617753600000,
        y: 339.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617840000000,
        y: 339,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617926400000,
        y: 340.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618185600000,
        y: 339.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618272000000,
        y: 345.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618358400000,
        y: 358.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618444800000,
        y: 366,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618531200000,
        y: 369.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618790400000,
        y: 369.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618876800000,
        y: 366.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618963200000,
        y: 365.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619049600000,
        y: 362,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619136000000,
        y: 363.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619395200000,
        y: 360.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619481600000,
        y: 361.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619568000000,
        y: 367.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619654400000,
        y: 368.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619740800000,
        y: 368.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620086400000,
        y: 375,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620172800000,
        y: 378.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620259200000,
        y: 376.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620345600000,
        y: 372.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620604800000,
        y: 369.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620691200000,
        y: 371,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620777600000,
        y: 375.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620864000000,
        y: 370.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620950400000,
        y: 365,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621209600000,
        y: 366,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621296000000,
        y: 365.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621382400000,
        y: 351.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621468800000,
        y: 351.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621555200000,
        y: 351.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621814400000,
        y: 357.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621900800000,
        y: 360.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621987200000,
        y: 360.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622073600000,
        y: 363,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622160000000,
        y: 366,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622505600000,
        y: 376.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622592000000,
        y: 382.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622678400000,
        y: 382,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622764800000,
        y: 384.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623024000000,
        y: 382.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623110400000,
        y: 382.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623196800000,
        y: 382.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623283200000,
        y: 383.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623369600000,
        y: 385.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623628800000,
        y: 388,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623715200000,
        y: 390,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623801600000,
        y: 396.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623888000000,
        y: 391.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623974400000,
        y: 391.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624233600000,
        y: 394,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624320000000,
        y: 394.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624406400000,
        y: 400.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624492800000,
        y: 402.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624579200000,
        y: 407.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624838400000,
        y: 404,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624924800000,
        y: 402.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625011200000,
        y: 403.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625097600000,
        y: 408.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625184000000,
        y: 406.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625443200000,
        y: 407.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625529600000,
        y: 398.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625616000000,
        y: 389.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625702400000,
        y: 386,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625788800000,
        y: 394.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626048000000,
        y: 394,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626134400000,
        y: 401.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626220800000,
        y: 405.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626307200000,
        y: 400,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626393600000,
        y: 398.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626652800000,
        y: 376.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626739200000,
        y: 373.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626825600000,
        y: 383.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626912000000,
        y: 387.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626998400000,
        y: 394.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627257600000,
        y: 395,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627344000000,
        y: 394.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627430400000,
        y: 395,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627516800000,
        y: 395.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627603200000,
        y: 407,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627862400000,
        y: 396.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627948800000,
        y: 393.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628035200000,
        y: 384.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628121600000,
        y: 382.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628208000000,
        y: 379.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628467200000,
        y: 365.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628553600000,
        y: 363.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628640000000,
        y: 371.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628726400000,
        y: 372.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628812800000,
        y: 388.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629072000000,
        y: 379.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629158400000,
        y: 376.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629244800000,
        y: 376.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629331200000,
        y: 374.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629417600000,
        y: 359.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629676800000,
        y: 369.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629763200000,
        y: 379.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629849600000,
        y: 385,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629936000000,
        y: 387,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630022400000,
        y: 389.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630368000000,
        y: 395.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630454400000,
        y: 387.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630540800000,
        y: 395.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630627200000,
        y: 398.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630886400000,
        y: 395.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630972800000,
        y: 396.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631059200000,
        y: 397,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631145600000,
        y: 397,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631232000000,
        y: 396.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631491200000,
        y: 398.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631577600000,
        y: 400.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631664000000,
        y: 401.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631750400000,
        y: 406.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631836800000,
        y: 407.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632096000000,
        y: 405.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632182400000,
        y: 402.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632268800000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632355200000,
        y: 419.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632441600000,
        y: 424.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632700800000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632787200000,
        y: 433.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632873600000,
        y: 435,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632960000000,
        y: 433.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633046400000,
        y: 435.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633305600000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633392000000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633478400000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633564800000,
        y: 464.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633651200000,
        y: 467,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633910400000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633996800000,
        y: 471.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634083200000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634169600000,
        y: 470.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634256000000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634515200000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634601600000,
        y: 469.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634688000000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634774400000,
        y: 453.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634860800000,
        y: 453.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635120000000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635206400000,
        y: 457.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635292800000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635379200000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635465600000,
        y: 444.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635724800000,
        y: 444,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635811200000,
        y: 440.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635897600000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635984000000,
        y: 425,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636070400000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636329600000,
        y: 430.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636416000000,
        y: 432.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636502400000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636588800000,
        y: 422.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636675200000,
        y: 417.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636934400000,
        y: 417.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637020800000,
        y: 421.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637107200000,
        y: 421,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637193600000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637280000000,
        y: 410.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637539200000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637625600000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637712000000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637798400000,
        y: 425.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637884800000,
        y: 391.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638144000000,
        y: 395.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638230400000,
        y: 376.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638316800000,
        y: 377.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638403200000,
        y: 371.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638489600000,
        y: 375.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638748800000,
        y: 379.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638835200000,
        y: 396.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638921600000,
        y: 399,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639008000000,
        y: 401.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639094400000,
        y: 402.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639353600000,
        y: 398.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639440000000,
        y: 399.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639526400000,
        y: 397.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639612800000,
        y: 407.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639699200000,
        y: 401.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639958400000,
        y: 385.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640044800000,
        y: 398.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640131200000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640217600000,
        y: 412.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640304000000,
        y: 411.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640736000000,
        y: 426,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640822400000,
        y: 426.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640908800000,
        y: 426.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641254400000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641340800000,
        y: 435.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641427200000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641513600000,
        y: 442,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641772800000,
        y: 437.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641859200000,
        y: 444.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641945600000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642032000000,
        y: 452.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642118400000,
        y: 457.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642377600000,
        y: 462.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642464000000,
        y: 468.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642550400000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642636800000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642723200000,
        y: 485,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642982400000,
        y: 475.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643068800000,
        y: 483,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643155200000,
        y: 500.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643241600000,
        y: 496.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643328000000,
        y: 494.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643587200000,
        y: 503.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643673600000,
        y: 493.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643760000000,
        y: 486.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643846400000,
        y: 492.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643932800000,
        y: 512.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644192000000,
        y: 503.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644278400000,
        y: 485.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644364800000,
        y: 505.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644451200000,
        y: 510.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644537600000,
        y: 512.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644796800000,
        y: 514.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644883200000,
        y: 509.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644969600000,
        y: 514.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645056000000,
        y: 494,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645142400000,
        y: 498,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645401600000,
        y: 517.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645488000000,
        y: 516.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645574400000,
        y: 521,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645660800000,
        y: 540,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645747200000,
        y: 495.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646006400000,
        y: 517.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646092800000,
        y: 571.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646179200000,
        y: 541.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646265600000,
        y: 557.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646352000000,
        y: 598.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646611200000,
        y: 641.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646697600000,
        y: 683.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646784000000,
        y: 644.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646870400000,
        y: 611,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646956800000,
        y: 593.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647216000000,
        y: 567,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647302400000,
        y: 553.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647388800000,
        y: 546.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647475200000,
        y: 588.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647561600000,
        y: 556.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647820800000,
        y: 624,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647907200000,
        y: 620.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647993600000,
        y: 648.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648080000000,
        y: 644.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648166400000,
        y: 659,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648425600000,
        y: 624.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648512000000,
        y: 624.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648598400000,
        y: 624,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648684800000,
        y: 624,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648771200000,
        y: 625.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649030400000,
        y: 625.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649116800000,
        y: 625.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649203200000,
        y: 625.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649289600000,
        y: 625.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649376000000,
        y: 625.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649635200000,
        y: 625.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649721600000,
        y: 625.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649808000000,
        y: 625,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649894400000,
        y: 625,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650326400000,
        y: 624.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650412800000,
        y: 624.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650499200000,
        y: 624.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650585600000,
        y: 624.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650844800000,
        y: 550.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650931200000,
        y: 570.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651017600000,
        y: 577.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651104000000,
        y: 594,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651190400000,
        y: 602.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651536000000,
        y: 584.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651622400000,
        y: 605,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651708800000,
        y: 618.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651795200000,
        y: 623.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652054400000,
        y: 573.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652140800000,
        y: 560.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652227200000,
        y: 586,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652313600000,
        y: 591.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652400000000,
        y: 600.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652659200000,
        y: 615.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652745600000,
        y: 628.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652832000000,
        y: 601.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652918400000,
        y: 598.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653004800000,
        y: 617.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653264000000,
        y: 636.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653350400000,
        y: 629,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653436800000,
        y: 626.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653523200000,
        y: 641.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653609600000,
        y: 627.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653868800000,
        y: 621,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653955200000,
        y: 631.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654041600000,
        y: 618.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654473600000,
        y: 605.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654560000000,
        y: 591.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654646400000,
        y: 595.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654732800000,
        y: 592.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654819200000,
        y: 590.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655078400000,
        y: 607.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655164800000,
        y: 630.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655251200000,
        y: 591.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655337600000,
        y: 573.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655424000000,
        y: 554.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655683200000,
        y: 554.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655769600000,
        y: 583.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655856000000,
        y: 543.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655942400000,
        y: 564,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656028800000,
        y: 569.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656288000000,
        y: 569.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656374400000,
        y: 571.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656460800000,
        y: 570.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656547200000,
        y: 542.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656633600000,
        y: 536.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656892800000,
        y: 522.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656979200000,
        y: 427.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657065600000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657152000000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657238400000,
        y: 473.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657497600000,
        y: 469.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657584000000,
        y: 420.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657670400000,
        y: 409.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657756800000,
        y: 396.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657843200000,
        y: 423,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658102400000,
        y: 462.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658188800000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658275200000,
        y: 453.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658361600000,
        y: 450.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658448000000,
        y: 468.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658707200000,
        y: 478,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658793600000,
        y: 460.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658880000000,
        y: 456.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658966400000,
        y: 469.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659052800000,
        y: 486,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659312000000,
        y: 450.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659398400000,
        y: 477.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659484800000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659571200000,
        y: 445.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659657600000,
        y: 462.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659916800000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660003200000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660089600000,
        y: 462.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660176000000,
        y: 501.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660262400000,
        y: 506.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660521600000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660608000000,
        y: 474,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660694400000,
        y: 476.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660780800000,
        y: 489.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660867200000,
        y: 479.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661126400000,
        y: 462.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661212800000,
        y: 482,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661299200000,
        y: 472.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661385600000,
        y: 477,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661472000000,
        y: 464.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661817600000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661904000000,
        y: 429.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661990400000,
        y: 398.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662076800000,
        y: 420.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662336000000,
        y: 423.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662422400000,
        y: 402.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662508800000,
        y: 377.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662595200000,
        y: 373.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662681600000,
        y: 395.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662940800000,
        y: 416.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663027200000,
        y: 402.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663113600000,
        y: 413,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663200000000,
        y: 383.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663286400000,
        y: 402.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663632000000,
        y: 376,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663718400000,
        y: 377,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663804800000,
        y: 386.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663891200000,
        y: 371.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664150400000,
        y: 378.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664236800000,
        y: 378.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664323200000,
        y: 386.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664409600000,
        y: 388,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664496000000,
        y: 378.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664755200000,
        y: 382.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664841600000,
        y: 395.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664928000000,
        y: 408,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665014400000,
        y: 401.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665100800000,
        y: 416.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665360000000,
        y: 405,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665446400000,
        y: 377.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665532800000,
        y: 362.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665619200000,
        y: 360.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665705600000,
        y: 347.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665964800000,
        y: 343.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666051200000,
        y: 332.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666137600000,
        y: 357.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666224000000,
        y: 373.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666310400000,
        y: 354.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666569600000,
        y: 359.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666656000000,
        y: 349.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666742400000,
        y: 363.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666828800000,
        y: 369.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666915200000,
        y: 371.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667174400000,
        y: 364,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667260800000,
        y: 383.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667347200000,
        y: 393.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667433600000,
        y: 384.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667520000000,
        y: 401.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667779200000,
        y: 414.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667865600000,
        y: 403.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667952000000,
        y: 386.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668038400000,
        y: 394,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668124800000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668384000000,
        y: 400,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668470400000,
        y: 406.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668556800000,
        y: 396.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668643200000,
        y: 387.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668729600000,
        y: 371.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668988800000,
        y: 346.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669075200000,
        y: 370.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669161600000,
        y: 335,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669248000000,
        y: 348.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669334400000,
        y: 351.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669593600000,
        y: 342.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669680000000,
        y: 340,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669766400000,
        y: 345,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669852800000,
        y: 357.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669939200000,
        y: 351,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670198400000,
        y: 348.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670284800000,
        y: 326.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670371200000,
        y: 333.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670457600000,
        y: 314.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670544000000,
        y: 348.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670803200000,
        y: 345.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670889600000,
        y: 349,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670976000000,
        y: 357.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671062400000,
        y: 341.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671148800000,
        y: 321,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671408000000,
        y: 339.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671494400000,
        y: 336.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671580800000,
        y: 360.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671667200000,
        y: 368,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671753600000,
        y: 375,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672185600000,
        y: 354.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672272000000,
        y: 344.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672358400000,
        y: 362,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672704000000,
        y: 356.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672790400000,
        y: 328,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672876800000,
        y: 297.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672963200000,
        y: 348.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673222400000,
        y: 345.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673308800000,
        y: 349.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673395200000,
        y: 361.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673481600000,
        y: 363.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673568000000,
        y: 367.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673827200000,
        y: 364.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673913600000,
        y: 381.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674000000000,
        y: 394.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674086400000,
        y: 390.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674172800000,
        y: 387.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674432000000,
        y: 400.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674518400000,
        y: 382,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674604800000,
        y: 386.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674691200000,
        y: 388.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674777600000,
        y: 380.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675036800000,
        y: 373.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675123200000,
        y: 379,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675209600000,
        y: 373,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675296000000,
        y: 356.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675382400000,
        y: 354,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675641600000,
        y: 348.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675728000000,
        y: 364.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675814400000,
        y: 375.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675900800000,
        y: 385.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675987200000,
        y: 398.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676246400000,
        y: 397.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676332800000,
        y: 392,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676419200000,
        y: 380.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676505600000,
        y: 390,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676592000000,
        y: 381.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676851200000,
        y: 384.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676937600000,
        y: 381.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677024000000,
        y: 374,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677110400000,
        y: 384,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677196800000,
        y: 379.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677456000000,
        y: 378.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677542400000,
        y: 386.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677628800000,
        y: 387.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677715200000,
        y: 400.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677801600000,
        y: 400,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678060800000,
        y: 404.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678147200000,
        y: 402.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678233600000,
        y: 398.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678320000000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678406400000,
        y: 401,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678665600000,
        y: 391.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678752000000,
        y: 380,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678838400000,
        y: 337,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678924800000,
        y: 354.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679011200000,
        y: 344.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679270400000,
        y: 350.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679356800000,
        y: 359.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679443200000,
        y: 365.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679529600000,
        y: 371.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679616000000,
        y: 360.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679875200000,
        y: 374.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679961600000,
        y: 392.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680048000000,
        y: 395,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680134400000,
        y: 399.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680220800000,
        y: 410.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680480000000,
        y: 442.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680566400000,
        y: 434.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680652800000,
        y: 441.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680739200000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681171200000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681257600000,
        y: 464.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681344000000,
        y: 460.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681430400000,
        y: 456.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681689600000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681776000000,
        y: 456.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681862400000,
        y: 446.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681948800000,
        y: 434,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682035200000,
        y: 437,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682294400000,
        y: 447.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682380800000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682467200000,
        y: 435,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682553600000,
        y: 421.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682640000000,
        y: 427,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682985600000,
        y: 404.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683072000000,
        y: 388.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683158400000,
        y: 383.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683244800000,
        y: 390.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683590400000,
        y: 400.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683676800000,
        y: 412.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683763200000,
        y: 401.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683849600000,
        y: 397.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684108800000,
        y: 402.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684195200000,
        y: 399.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684281600000,
        y: 406.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684368000000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684454400000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684713600000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684800000000,
        y: 411.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684886400000,
        y: 414.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684972800000,
        y: 406.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685059200000,
        y: 410.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685404800000,
        y: 396.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685491200000,
        y: 390,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685577600000,
        y: 407.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685664000000,
        y: 416.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685923200000,
        y: 424,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686009600000,
        y: 419.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686096000000,
        y: 425,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686182400000,
        y: 413.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686268800000,
        y: 418.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686528000000,
        y: 402.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686614400000,
        y: 419.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686700800000,
        y: 420.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686787200000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686873600000,
        y: 438.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687132800000,
        y: 436.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687219200000,
        y: 436.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687305600000,
        y: 450.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687392000000,
        y: 430.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687478400000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687737600000,
        y: 436.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687824000000,
        y: 424.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687910400000,
        y: 430,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687996800000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688083200000,
        y: 450.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688342400000,
        y: 454.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688428800000,
        y: 449.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688515200000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688601600000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688688000000,
        y: 448.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688947200000,
        y: 452.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689033600000,
        y: 459.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689120000000,
        y: 459.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689206400000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689292800000,
        y: 458.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689552000000,
        y: 450.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689638400000,
        y: 453.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689724800000,
        y: 459.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689811200000,
        y: 455.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689897600000,
        y: 464.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690156800000,
        y: 479.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690243200000,
        y: 478.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690329600000,
        y: 483.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690416000000,
        y: 489.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690502400000,
        y: 498.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690761600000,
        y: 513.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690848000000,
        y: 514.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690934400000,
        y: 522,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691020800000,
        y: 530.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691107200000,
        y: 541,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691366400000,
        y: 528.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691452800000,
        y: 524.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691539200000,
        y: 543.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691625600000,
        y: 552.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691712000000,
        y: 552.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691971200000,
        y: 539.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692057600000,
        y: 527.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692144000000,
        y: 525,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692230400000,
        y: 518.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692316800000,
        y: 523.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692576000000,
        y: 526.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692662400000,
        y: 524.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692748800000,
        y: 514.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692835200000,
        y: 505,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692921600000,
        y: 508.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693267200000,
        y: 516.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693353600000,
        y: 515.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693440000000,
        y: 525,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693526400000,
        y: 546.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693785600000,
        y: 522.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693872000000,
        y: 539,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693958400000,
        y: 545,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694044800000,
        y: 549.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694131200000,
        y: 548.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694390400000,
        y: 524.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694476800000,
        y: 554.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694563200000,
        y: 557.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694649600000,
        y: 572.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694736000000,
        y: 568.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694995200000,
        y: 569.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695081600000,
        y: 557.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695168000000,
        y: 547,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695254400000,
        y: 545.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695340800000,
        y: 545,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695600000000,
        y: 540.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695686400000,
        y: 543,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695772800000,
        y: 563.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695859200000,
        y: 549,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695945600000,
        y: 529.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696204800000,
        y: 495.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696291200000,
        y: 495,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696377600000,
        y: 478.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696464000000,
        y: 457.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696550400000,
        y: 468.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696809600000,
        y: 492,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696896000000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696982400000,
        y: 473,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697068800000,
        y: 465.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697155200000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697414400000,
        y: 487,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697500800000,
        y: 480.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697587200000,
        y: 496.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697673600000,
        y: 498.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697760000000,
        y: 498,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698019200000,
        y: 480.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698105600000,
        y: 464.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698192000000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698278400000,
        y: 462.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698364800000,
        y: 451.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698624000000,
        y: 437.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698710400000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698796800000,
        y: 457.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698883200000,
        y: 461.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698969600000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699228800000,
        y: 451.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699315200000,
        y: 427.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699401600000,
        y: 414.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699488000000,
        y: 427.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699574400000,
        y: 434,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699833600000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699920000000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700006400000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700092800000,
        y: 421.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700179200000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700438400000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700524800000,
        y: 444.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700611200000,
        y: 426.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700697600000,
        y: 438,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700784000000,
        y: 443.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701043200000,
        y: 433.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701129600000,
        y: 441.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701216000000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701302400000,
        y: 431.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701388800000,
        y: 440.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701648000000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701734400000,
        y: 435,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701820800000,
        y: 408.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701907200000,
        y: 403.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701993600000,
        y: 412.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702252800000,
        y: 412,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702339200000,
        y: 402,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702425600000,
        y: 411,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702512000000,
        y: 425.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702598400000,
        y: 420,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702857600000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702944000000,
        y: 431.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703030400000,
        y: 438,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703116800000,
        y: 430.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703203200000,
        y: 433.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703635200000,
        y: 430.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703721600000,
        y: 414.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703808000000,
        y: 405.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704153600000,
        y: 401.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704240000000,
        y: 427,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704326400000,
        y: 423.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704412800000,
        y: 435.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704672000000,
        y: 409,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704758400000,
        y: 425.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704844800000,
        y: 418.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704931200000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705017600000,
        y: 410.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705276800000,
        y: 412.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705363200000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705449600000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705536000000,
        y: 409.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705622400000,
        y: 413.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705881600000,
        y: 416.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705968000000,
        y: 416.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706054400000,
        y: 414.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706140800000,
        y: 422.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706227200000,
        y: 429,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706486400000,
        y: 432,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706572800000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706659200000,
        y: 428.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706745600000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706832000000,
        y: 407.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707091200000,
        y: 417,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707177600000,
        y: 428.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707264000000,
        y: 435,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707350400000,
        y: 446.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707436800000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707696000000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707782400000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707868800000,
        y: 444.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707955200000,
        y: 445.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708041600000,
        y: 451,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708300800000,
        y: 451.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708387200000,
        y: 442,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708473600000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708560000000,
        y: 454,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708646400000,
        y: 446.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708905600000,
        y: 451.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708992000000,
        y: 454.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1709078400000,
        y: 445.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710374400000,
        y: 461.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710460800000,
        y: 461.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710720000000,
        y: 471.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710806400000,
        y: 475.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710892800000,
        y: 467,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710979200000,
        y: 463.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711065600000,
        y: 467,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711324800000,
        y: 475.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711411200000,
        y: 472.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711497600000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711584000000,
        y: 472.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712016000000,
        y: 479,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712102400000,
        y: 481.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712188800000,
        y: 470.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712275200000,
        y: 489.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712534400000,
        y: 478.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712620800000,
        y: 475.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712707200000,
        y: 470.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712793600000,
        y: 473.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712880000000,
        y: 483.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713139200000,
        y: 472.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713225600000,
        y: 484,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713312000000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713398400000,
        y: 476,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713484800000,
        y: 479,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713744000000,
        y: 473,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713830400000,
        y: 474.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713916800000,
        y: 476,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714003200000,
        y: 480.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714089600000,
        y: 491.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714348800000,
        y: 483.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714435200000,
        y: 486.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714608000000,
        y: 473.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714694400000,
        y: 467.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715040000000,
        y: 470.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715126400000,
        y: 472.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715212800000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715299200000,
        y: 468.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715558400000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715644800000,
        y: 452,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715731200000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715817600000,
        y: 454.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715904000000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716163200000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716249600000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716336000000,
        y: 459.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716422400000,
        y: 451.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716508800000,
        y: 457.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716854400000,
        y: 478,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716940800000,
        y: 479,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717027200000,
        y: 473.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717113600000,
        y: 468.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717372800000,
        y: 449.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717459200000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717545600000,
        y: 441.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717632000000,
        y: 452.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717718400000,
        y: 455.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717977600000,
        y: 466.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718064000000,
        y: 470.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718150400000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718236800000,
        y: 471,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718323200000,
        y: 472.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718582400000,
        y: 477,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718668800000,
        y: 477.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718755200000,
        y: 494,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718841600000,
        y: 489.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718928000000,
        y: 489,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719187200000,
        y: 490.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719273600000,
        y: 484.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719360000000,
        y: 478.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719446400000,
        y: 487,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719532800000,
        y: 485.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719792000000,
        y: 493,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719878400000,
        y: 506,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719964800000,
        y: 505.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720051200000,
        y: 509.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720137600000,
        y: 505,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720396800000,
        y: 499.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720483200000,
        y: 494.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720569600000,
        y: 494.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720656000000,
        y: 488,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720742400000,
        y: 489.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721001600000,
        y: 487.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721088000000,
        y: 481.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721174400000,
        y: 482,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721260800000,
        y: 473.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721347200000,
        y: 472.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721692800000,
        y: 453.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721779200000,
        y: 462.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721865600000,
        y: 458.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721952000000,
        y: 449.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722211200000,
        y: 448.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722297600000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722384000000,
        y: 452.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722470400000,
        y: 449.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722556800000,
        y: 426.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722816000000,
        y: 428.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722902400000,
        y: 427.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722988800000,
        y: 441.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723075200000,
        y: 441.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723161600000,
        y: 442.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723420800000,
        y: 453.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723507200000,
        y: 450.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723593600000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723680000000,
        y: 456.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723766400000,
        y: 446.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724025600000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724112000000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724198400000,
        y: 434.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724284800000,
        y: 433.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724371200000,
        y: 442.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724716800000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724803200000,
        y: 435.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724889600000,
        y: 439.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724976000000,
        y: 419,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725235200000,
        y: 414.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725321600000,
        y: 391.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725408000000,
        y: 393.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725494400000,
        y: 391.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725580800000,
        y: 382.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725840000000,
        y: 375.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725926400000,
        y: 367.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726012800000,
        y: 375.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726099200000,
        y: 393.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726185600000,
        y: 389.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726444800000,
        y: 397,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726531200000,
        y: 401.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726617600000,
        y: 408.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726704000000,
        y: 420.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726790400000,
        y: 425.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1727049600000,
        y: 420.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1727136000000,
        y: 424.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729209600000,
        y: 434.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729468800000,
        y: 441.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729555200000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729641600000,
        y: 470.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729728000000,
        y: 460.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729814400000,
        y: 469.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730073600000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730160000000,
        y: 438.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730246400000,
        y: 449.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730332800000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730419200000,
        y: 440.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730678400000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730764800000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730851200000,
        y: 444.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730937600000,
        y: 436.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731024000000,
        y: 430,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731283200000,
        y: 427.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731369600000,
        y: 430.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731456000000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731542400000,
        y: 430.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731628800000,
        y: 423,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731888000000,
        y: 423,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731974400000,
        y: 410.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732060800000,
        y: 417,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732147200000,
        y: 418.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732233600000,
        y: 427,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732492800000,
        y: 413.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732579200000,
        y: 419.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732665600000,
        y: 429.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732752000000,
        y: 435.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732838400000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733097600000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733184000000,
        y: 443.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733270400000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733356800000,
        y: 423.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733443200000,
        y: 415.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733702400000,
        y: 421.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733788800000,
        y: 427.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733875200000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733961600000,
        y: 423.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734048000000,
        y: 436.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734307200000,
        y: 439.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734393600000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734480000000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734566400000,
        y: 434,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734652800000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734912000000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734998400000,
        y: 435.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735257600000,
        y: 438,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735516800000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735603200000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735776000000,
        y: 443.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735862400000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736121600000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736208000000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736294400000,
        y: 429.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736380800000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736467200000,
        y: 448,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736726400000,
        y: 463.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736812800000,
        y: 451.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736899200000,
        y: 456.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736985600000,
        y: 454,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737072000000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737331200000,
        y: 452.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737417600000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737504000000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737590400000,
        y: 452.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737676800000,
        y: 451.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737936000000,
        y: 442.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738022400000,
        y: 442,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738108800000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738195200000,
        y: 443.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738281600000,
        y: 443.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738540800000,
        y: 444,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738627200000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738713600000,
        y: 442,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738800000000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738886400000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1739145600000,
        y: 462,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1739232000000,
        y: 468.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1739318400000,
        y: 457.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1739404800000,
        y: 451.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      }
    ],
    id: 'mock1-Mid',
    marker: {
      radius: 1,
      symbol: 'circle'
    },

    metadata: {
      decimalPlaces: 2,
      hideName: true,
      field: 'Mid',
      combinedIndex: 0,
      Code: 'GX0000266',
      Period: '1',
      PeriodType: 'Prompt',
      TimeRef: '1630',
      type: 'Last',
      TimeZone: 'Europe/London',
      Commodity: 'Fuel Oil',
      PricingBasis: 'Flat',
      Alias: 'FO35NWE',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_Europe',
      Title: 'Fuel Oil 3.5% 380 CST NWE FOB Barges',
      TradingHub: 'NWE',
      DeliveryBasis: 'FOB Barges',
      Source: 'GX',
      Units: 'MT'
    },
    color: '#00BFFF'
  },
  {
    showInNavigator: true,
    name: 'mock2',
    data: [
      {
        x: 1199145600000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199232000000,
        y: 501.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199318400000,
        y: 510.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199404800000,
        y: 517.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199664000000,
        y: 507.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199750400000,
        y: 502.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199836800000,
        y: 507,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1199923200000,
        y: 502.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200009600000,
        y: 462.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200268800000,
        y: 462.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200355200000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200441600000,
        y: 441.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200528000000,
        y: 441.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200614400000,
        y: 441.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200873600000,
        y: 454,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1200960000000,
        y: 449,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201046400000,
        y: 431.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201132800000,
        y: 437,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201219200000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201478400000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201564800000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201651200000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201737600000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1201824000000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202083200000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202169600000,
        y: 429.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202256000000,
        y: 424.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202342400000,
        y: 419.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202428800000,
        y: 425.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202688000000,
        y: 442.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202774400000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202860800000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1202947200000,
        y: 492.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203033600000,
        y: 498.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203292800000,
        y: 491.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203379200000,
        y: 497.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203465600000,
        y: 502.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203552000000,
        y: 502.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203638400000,
        y: 490.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203897600000,
        y: 484.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1203984000000,
        y: 489.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204070400000,
        y: 497.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204156800000,
        y: 498.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204243200000,
        y: 499.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204502400000,
        y: 519,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204588800000,
        y: 499.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204675200000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204761600000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1204848000000,
        y: 492.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205107200000,
        y: 492.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205193600000,
        y: 493.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205280000000,
        y: 488.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205366400000,
        y: 493.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205452800000,
        y: 498.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205712000000,
        y: 497.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205798400000,
        y: 504.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205884800000,
        y: 493.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1205971200000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1206403200000,
        y: 495.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1206489600000,
        y: 503.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1206576000000,
        y: 504.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1206662400000,
        y: 506.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1206921600000,
        y: 513.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207008000000,
        y: 497.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207094400000,
        y: 513.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207180800000,
        y: 512,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207267200000,
        y: 510.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207526400000,
        y: 540.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207612800000,
        y: 537.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207699200000,
        y: 545.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207785600000,
        y: 531,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1207872000000,
        y: 533,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208131200000,
        y: 548.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208217600000,
        y: 538,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208304000000,
        y: 552.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208390400000,
        y: 549.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208476800000,
        y: 549.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208736000000,
        y: 547.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208822400000,
        y: 544.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208908800000,
        y: 545.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1208995200000,
        y: 526,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1209081600000,
        y: 556.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1209340800000,
        y: 558.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1209427200000,
        y: 558.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1209513600000,
        y: 542,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1209600000000,
        y: 532,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1209686400000,
        y: 546.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210032000000,
        y: 581.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210118400000,
        y: 527,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210204800000,
        y: 526,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210291200000,
        y: 587.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210550400000,
        y: 582.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210636800000,
        y: 586.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210723200000,
        y: 596.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210809600000,
        y: 601.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1210896000000,
        y: 589,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211155200000,
        y: 604,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211241600000,
        y: 614.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211328000000,
        y: 644.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211414400000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211500800000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211760000000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211846400000,
        y: 637.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1211932800000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212019200000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212105600000,
        y: 623.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212364800000,
        y: 618.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212451200000,
        y: 605,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212537600000,
        y: 599,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212624000000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212710400000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1212969600000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213056000000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213142400000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213228800000,
        y: 638.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213315200000,
        y: 673.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213574400000,
        y: 678.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213660800000,
        y: 663.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213747200000,
        y: 649.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213833600000,
        y: 657.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1213920000000,
        y: 656.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214179200000,
        y: 660.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214265600000,
        y: 668.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214352000000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214438400000,
        y: 669.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214524800000,
        y: 689.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214784000000,
        y: 708.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214870400000,
        y: 716.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1214956800000,
        y: 736,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215043200000,
        y: 755.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215129600000,
        y: 784.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215388800000,
        y: 787.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215475200000,
        y: 787.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215561600000,
        y: 787.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215648000000,
        y: 787.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215734400000,
        y: 782.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1215993600000,
        y: 777.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216080000000,
        y: 772.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216166400000,
        y: 767.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216252800000,
        y: 748,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216339200000,
        y: 708.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216598400000,
        y: 705.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216684800000,
        y: 687.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216771200000,
        y: 687.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216857600000,
        y: 681,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1216944000000,
        y: 678.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217203200000,
        y: 693.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217289600000,
        y: 683.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217376000000,
        y: 683.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217462400000,
        y: 690.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217548800000,
        y: 683.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217808000000,
        y: 685.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217894400000,
        y: 676,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1217980800000,
        y: 661,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218067200000,
        y: 638.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218153600000,
        y: 589,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218412800000,
        y: 594,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218499200000,
        y: 579,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218585600000,
        y: 579,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218672000000,
        y: 579,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1218758400000,
        y: 579,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219017600000,
        y: 594,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219104000000,
        y: 594,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219190400000,
        y: 591.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219276800000,
        y: 628.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219363200000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219622400000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219708800000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219795200000,
        y: 631.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219881600000,
        y: 629.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1219968000000,
        y: 628.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220227200000,
        y: 613.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220313600000,
        y: 579,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220400000000,
        y: 582,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220486400000,
        y: 588.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220572800000,
        y: 564.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220832000000,
        y: 566.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1220918400000,
        y: 551.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221004800000,
        y: 544.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221091200000,
        y: 534.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221177600000,
        y: 534.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221436800000,
        y: 494.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221523200000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221609600000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221696000000,
        y: 479.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1221782400000,
        y: 504.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222041600000,
        y: 544.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222128000000,
        y: 529.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222214400000,
        y: 551.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222300800000,
        y: 539.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222387200000,
        y: 546.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222646400000,
        y: 546.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222732800000,
        y: 512,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222819200000,
        y: 539.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222905600000,
        y: 507,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1222992000000,
        y: 522,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223251200000,
        y: 486.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223337600000,
        y: 522.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223424000000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223510400000,
        y: 437.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223596800000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223856000000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1223942400000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224028800000,
        y: 359.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224115200000,
        y: 322,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224201600000,
        y: 317,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224460800000,
        y: 306,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224547200000,
        y: 311,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224633600000,
        y: 298,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224720000000,
        y: 311,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1224806400000,
        y: 290.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225065600000,
        y: 286.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225152000000,
        y: 286.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225238400000,
        y: 306,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225324800000,
        y: 266.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225411200000,
        y: 250.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225670400000,
        y: 250.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225756800000,
        y: 250.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225843200000,
        y: 291.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1225929600000,
        y: 261.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226016000000,
        y: 266.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226275200000,
        y: 271.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226361600000,
        y: 271.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226448000000,
        y: 271.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226534400000,
        y: 266.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226620800000,
        y: 266.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226880000000,
        y: 266.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1226966400000,
        y: 276.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227052800000,
        y: 277.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227139200000,
        y: 299,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227225600000,
        y: 261.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227484800000,
        y: 271.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227571200000,
        y: 261.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227657600000,
        y: 261.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227744000000,
        y: 265.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1227830400000,
        y: 265.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228089600000,
        y: 265.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228176000000,
        y: 251.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228262400000,
        y: 251.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228348800000,
        y: 241.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228435200000,
        y: 241.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228694400000,
        y: 226.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228780800000,
        y: 226.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228867200000,
        y: 217.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1228953600000,
        y: 204.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229040000000,
        y: 204.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229299200000,
        y: 204.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229385600000,
        y: 204.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229472000000,
        y: 204.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229558400000,
        y: 264.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229644800000,
        y: 231.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229904000000,
        y: 231.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1229990400000,
        y: 231.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1230076800000,
        y: 231.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1230249600000,
        y: 231.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1230508800000,
        y: 231.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1230595200000,
        y: 231.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1230681600000,
        y: 211.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1230768000000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1230854400000,
        y: 211.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231113600000,
        y: 211.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231200000000,
        y: 211.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231286400000,
        y: 211.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231372800000,
        y: 226.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231459200000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231718400000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231804800000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231891200000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1231977600000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232064000000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232323200000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232409600000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232496000000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232582400000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232668800000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232928000000,
        y: 255,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233014400000,
        y: 255,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233100800000,
        y: 255,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233187200000,
        y: 255,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233273600000,
        y: 255,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233532800000,
        y: 245,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233619200000,
        y: 247,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233705600000,
        y: 247,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233792000000,
        y: 247,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233878400000,
        y: 247,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234137600000,
        y: 261.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234224000000,
        y: 258.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234310400000,
        y: 258.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234396800000,
        y: 249,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234483200000,
        y: 249,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234742400000,
        y: 249,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234828800000,
        y: 249,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234915200000,
        y: 239,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235001600000,
        y: 239,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235088000000,
        y: 239,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235347200000,
        y: 239,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235433600000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235520000000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235606400000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235692800000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235952000000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236038400000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236124800000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236211200000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236297600000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236556800000,
        y: 227.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236643200000,
        y: 235.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236729600000,
        y: 215.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236816000000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236902400000,
        y: 227,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237161600000,
        y: 219.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237248000000,
        y: 224.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237334400000,
        y: 224.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237420800000,
        y: 235.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237507200000,
        y: 240.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237766400000,
        y: 243.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237852800000,
        y: 250.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237939200000,
        y: 251.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238025600000,
        y: 263.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238112000000,
        y: 253,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238371200000,
        y: 237.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238457600000,
        y: 233.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238544000000,
        y: 251.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238630400000,
        y: 270.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238716800000,
        y: 270.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238976000000,
        y: 290.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239062400000,
        y: 293.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239148800000,
        y: 292.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239235200000,
        y: 288.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239580800000,
        y: 288.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239667200000,
        y: 288.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239753600000,
        y: 282.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239840000000,
        y: 281.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239926400000,
        y: 281.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240185600000,
        y: 256,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240272000000,
        y: 263.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240358400000,
        y: 281.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240444800000,
        y: 271.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240531200000,
        y: 271.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240790400000,
        y: 268.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240876800000,
        y: 271.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240963200000,
        y: 273.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241049600000,
        y: 277.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241136000000,
        y: 282.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241395200000,
        y: 282.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241481600000,
        y: 282.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241568000000,
        y: 307,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241654400000,
        y: 316,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241740800000,
        y: 314,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242000000000,
        y: 305,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242086400000,
        y: 305,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242172800000,
        y: 322,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242259200000,
        y: 313.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242345600000,
        y: 312.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242604800000,
        y: 328,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242691200000,
        y: 329.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242777600000,
        y: 334.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242864000000,
        y: 333.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242950400000,
        y: 335.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243209600000,
        y: 335.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243296000000,
        y: 338.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243382400000,
        y: 343.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243468800000,
        y: 357.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243555200000,
        y: 370,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243814400000,
        y: 370,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243900800000,
        y: 386.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243987200000,
        y: 377.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244073600000,
        y: 375.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244160000000,
        y: 385.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244419200000,
        y: 383.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244505600000,
        y: 384,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244592000000,
        y: 384.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244678400000,
        y: 391.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244764800000,
        y: 395.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245024000000,
        y: 384.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245110400000,
        y: 385.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245196800000,
        y: 386.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245283200000,
        y: 398.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245369600000,
        y: 390.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245628800000,
        y: 370.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245715200000,
        y: 371.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245801600000,
        y: 381.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245888000000,
        y: 383,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245974400000,
        y: 383,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246233600000,
        y: 394,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246320000000,
        y: 385.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246406400000,
        y: 402.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246492800000,
        y: 392,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246579200000,
        y: 383.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246838400000,
        y: 371,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246924800000,
        y: 371,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247011200000,
        y: 368,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247097600000,
        y: 365.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247184000000,
        y: 364.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247443200000,
        y: 347.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247529600000,
        y: 355.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247616000000,
        y: 361.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247702400000,
        y: 381.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247788800000,
        y: 373.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248048000000,
        y: 376.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248134400000,
        y: 385.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248220800000,
        y: 393.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248307200000,
        y: 393.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248393600000,
        y: 403.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248652800000,
        y: 403.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248739200000,
        y: 395.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248825600000,
        y: 388.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248912000000,
        y: 393.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248998400000,
        y: 397.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249257600000,
        y: 416.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249344000000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249430400000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249516800000,
        y: 430.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249603200000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249862400000,
        y: 423.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249948800000,
        y: 417.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250035200000,
        y: 425.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250121600000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250208000000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250467200000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250553600000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250640000000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250726400000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250812800000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251072000000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251158400000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251244800000,
        y: 431.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251331200000,
        y: 430.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251417600000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251676800000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251763200000,
        y: 435.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251849600000,
        y: 407.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251936000000,
        y: 407.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252022400000,
        y: 402.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252281600000,
        y: 405.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252368000000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252454400000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252540800000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252627200000,
        y: 418.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252886400000,
        y: 410.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252972800000,
        y: 413.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253059200000,
        y: 423.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253145600000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253232000000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253491200000,
        y: 426.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253577600000,
        y: 417.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253664000000,
        y: 415.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253750400000,
        y: 405.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253836800000,
        y: 405.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254096000000,
        y: 409.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254182400000,
        y: 405.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254268800000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254355200000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254441600000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254700800000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254787200000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254873600000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254960000000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255046400000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255305600000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255392000000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255478400000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255564800000,
        y: 426.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255651200000,
        y: 430.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255910400000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255996800000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256083200000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256169600000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256256000000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256515200000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256601600000,
        y: 451,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256688000000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256774400000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256860800000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257120000000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257206400000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257292800000,
        y: 471.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257379200000,
        y: 472.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257465600000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257724800000,
        y: 472.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257811200000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257897600000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257984000000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258070400000,
        y: 471,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258329600000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258416000000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258502400000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258588800000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258675200000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258934400000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259020800000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259107200000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259193600000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259280000000,
        y: 466,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259539200000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259625600000,
        y: 471,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259712000000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259798400000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259884800000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260144000000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260230400000,
        y: 448,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260316800000,
        y: 448,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260403200000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260489600000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260748800000,
        y: 426.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260835200000,
        y: 424.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260921600000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261008000000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261094400000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261353600000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261440000000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261526400000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261612800000,
        y: 435.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261958400000,
        y: 435.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262044800000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262131200000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262217600000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262304000000,
        y: 500.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262563200000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262649600000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262736000000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262822400000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262908800000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263168000000,
        y: 481.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263254400000,
        y: 481.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263340800000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263427200000,
        y: 462,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263513600000,
        y: 462,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263772800000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263859200000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263945600000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264032000000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264118400000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264377600000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264464000000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264550400000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264636800000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264723200000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264982400000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265068800000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265155200000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265241600000,
        y: 431.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265328000000,
        y: 421.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265587200000,
        y: 418.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265673600000,
        y: 421.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265760000000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265846400000,
        y: 435.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265932800000,
        y: 435.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266192000000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266278400000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266364800000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266451200000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266537600000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266796800000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266883200000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266969600000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267056000000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267142400000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267401600000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267488000000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267574400000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267660800000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267747200000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268006400000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268092800000,
        y: 449.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268179200000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268265600000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268352000000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268611200000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268697600000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268784000000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268870400000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268956800000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269216000000,
        y: 458,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269302400000,
        y: 458,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269388800000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269475200000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269561600000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269820800000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269907200000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269993600000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270080000000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270512000000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270598400000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270684800000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270771200000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271030400000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271116800000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271203200000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271289600000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271376000000,
        y: 461.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271635200000,
        y: 461.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271721600000,
        y: 483.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271808000000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271894400000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271980800000,
        y: 492.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272240000000,
        y: 497.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272326400000,
        y: 497.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272412800000,
        y: 479.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272499200000,
        y: 489.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272585600000,
        y: 489.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272931200000,
        y: 481.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273017600000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273104000000,
        y: 482.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273190400000,
        y: 482.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273449600000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273536000000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273622400000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273708800000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273795200000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274054400000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274140800000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274227200000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274313600000,
        y: 409.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274400000000,
        y: 409.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274659200000,
        y: 413.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274745600000,
        y: 402.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274832000000,
        y: 402.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274918400000,
        y: 402.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275004800000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275350400000,
        y: 418.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275436800000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275523200000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275609600000,
        y: 436.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275868800000,
        y: 422.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275955200000,
        y: 422.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276041600000,
        y: 435.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276128000000,
        y: 437.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276214400000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276473600000,
        y: 437,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276560000000,
        y: 437,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276646400000,
        y: 437,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276732800000,
        y: 437,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276819200000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277078400000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277164800000,
        y: 448,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277251200000,
        y: 448,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277337600000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277424000000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277683200000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277769600000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277856000000,
        y: 433.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277942400000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278028800000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278288000000,
        y: 416.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278374400000,
        y: 433.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278460800000,
        y: 433.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278547200000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278633600000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278892800000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278979200000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279065600000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279152000000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279238400000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279497600000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279584000000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279670400000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279756800000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279843200000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280102400000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280188800000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280275200000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280361600000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280448000000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280707200000,
        y: 479.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280793600000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280880000000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280966400000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281052800000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281312000000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281398400000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281484800000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281571200000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281657600000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281916800000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282003200000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282089600000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282176000000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282262400000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282521600000,
        y: 438,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282608000000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282694400000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282780800000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282867200000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283212800000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283299200000,
        y: 444,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283385600000,
        y: 444,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283472000000,
        y: 444,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283731200000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283817600000,
        y: 432.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283904000000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283990400000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284076800000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284336000000,
        y: 454,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284422400000,
        y: 454,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284508800000,
        y: 449,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284595200000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284681600000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284940800000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285027200000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285113600000,
        y: 449,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285200000000,
        y: 449,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285286400000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285545600000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285632000000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285718400000,
        y: 444,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285804800000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285891200000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286150400000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286236800000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286323200000,
        y: 473.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286409600000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286496000000,
        y: 472.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286755200000,
        y: 466,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286841600000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286928000000,
        y: 472.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287014400000,
        y: 470,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287100800000,
        y: 470,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287360000000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287446400000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287532800000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287619200000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287705600000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287964800000,
        y: 467,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288051200000,
        y: 467,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288137600000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288224000000,
        y: 466,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288310400000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288569600000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288656000000,
        y: 471.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288742400000,
        y: 473.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288828800000,
        y: 482.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288915200000,
        y: 489.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289174400000,
        y: 491.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289260800000,
        y: 497.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289347200000,
        y: 497.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289433600000,
        y: 503.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289520000000,
        y: 491.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289779200000,
        y: 488.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289865600000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289952000000,
        y: 462,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290038400000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290124800000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290384000000,
        y: 452,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290470400000,
        y: 452,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290556800000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290643200000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290729600000,
        y: 471.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290988800000,
        y: 480.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291075200000,
        y: 479.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291161600000,
        y: 472.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291248000000,
        y: 482.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291334400000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291593600000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291680000000,
        y: 469.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291766400000,
        y: 465.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291852800000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291939200000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292025600000,
        y: 469.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292112000000,
        y: 469.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292198400000,
        y: 469.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292284800000,
        y: 471.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292371200000,
        y: 476.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292457600000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292544000000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292630400000,
        y: 478.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292716800000,
        y: 485.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292803200000,
        y: 484.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292889600000,
        y: 484.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292976000000,
        y: 484.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293062400000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293148800000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293235200000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293321600000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293408000000,
        y: 483.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293494400000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293580800000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293667200000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293753600000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293840000000,
        y: 502.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293926400000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294012800000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294099200000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294185600000,
        y: 496.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294272000000,
        y: 501.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294358400000,
        y: 498.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294444800000,
        y: 501.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294531200000,
        y: 501.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294617600000,
        y: 501.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294704000000,
        y: 500.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294790400000,
        y: 500.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294876800000,
        y: 504.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294963200000,
        y: 503.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295049600000,
        y: 501.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295136000000,
        y: 501.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295222400000,
        y: 501.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295308800000,
        y: 504.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295395200000,
        y: 509,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295481600000,
        y: 500.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295568000000,
        y: 502,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295654400000,
        y: 502,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295740800000,
        y: 502,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295827200000,
        y: 510,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295913600000,
        y: 499.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296000000000,
        y: 518.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296086400000,
        y: 529,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296172800000,
        y: 534.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296432000000,
        y: 534,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296518400000,
        y: 554.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296604800000,
        y: 562.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296691200000,
        y: 562.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296777600000,
        y: 478.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297036800000,
        y: 534.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297123200000,
        y: 537.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297209600000,
        y: 558.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297296000000,
        y: 549.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297382400000,
        y: 555.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297641600000,
        y: 559.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297728000000,
        y: 561.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297814400000,
        y: 564.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297900800000,
        y: 567.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297987200000,
        y: 563.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298246400000,
        y: 573.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298332800000,
        y: 573.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298419200000,
        y: 573.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298505600000,
        y: 573.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298592000000,
        y: 612,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298851200000,
        y: 611,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298937600000,
        y: 611,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299024000000,
        y: 614.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299110400000,
        y: 624.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299196800000,
        y: 624.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299456000000,
        y: 640.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299542400000,
        y: 633.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299628800000,
        y: 633.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299715200000,
        y: 641.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299801600000,
        y: 641.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300060800000,
        y: 673,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300147200000,
        y: 647.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300233600000,
        y: 644.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300320000000,
        y: 644.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300406400000,
        y: 643.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300665600000,
        y: 633.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300752000000,
        y: 633.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300838400000,
        y: 627.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300924800000,
        y: 627.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301011200000,
        y: 659,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301270400000,
        y: 656,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301356800000,
        y: 654.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301443200000,
        y: 648.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301529600000,
        y: 641.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301616000000,
        y: 676,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301875200000,
        y: 681,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301961600000,
        y: 681,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302048000000,
        y: 704.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302134400000,
        y: 699.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302220800000,
        y: 718.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302480000000,
        y: 718.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302566400000,
        y: 718.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302652800000,
        y: 718.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302739200000,
        y: 718.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302825600000,
        y: 718.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303084800000,
        y: 692.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303171200000,
        y: 684.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303257600000,
        y: 678.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303344000000,
        y: 687.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303776000000,
        y: 684.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303862400000,
        y: 684.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303948800000,
        y: 698.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304380800000,
        y: 698.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304467200000,
        y: 691.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304553600000,
        y: 671.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304640000000,
        y: 666.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304899200000,
        y: 643.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304985600000,
        y: 643.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305072000000,
        y: 636.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305158400000,
        y: 632.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305244800000,
        y: 632.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305504000000,
        y: 632.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305590400000,
        y: 632.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305676800000,
        y: 633.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305763200000,
        y: 619.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305849600000,
        y: 612.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306108800000,
        y: 610,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306195200000,
        y: 610,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306281600000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306368000000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306454400000,
        y: 618.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306800000000,
        y: 618.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306886400000,
        y: 618.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306972800000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307059200000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307318400000,
        y: 651.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307404800000,
        y: 658.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307491200000,
        y: 674.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307577600000,
        y: 674.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307664000000,
        y: 675.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307923200000,
        y: 675.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308009600000,
        y: 678.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308096000000,
        y: 678.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308182400000,
        y: 678.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308268800000,
        y: 654.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308528000000,
        y: 655.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308614400000,
        y: 647.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308700800000,
        y: 657.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308787200000,
        y: 652.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308873600000,
        y: 652.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309132800000,
        y: 651.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309219200000,
        y: 651.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309305600000,
        y: 664.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309392000000,
        y: 664.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309478400000,
        y: 657.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309737600000,
        y: 660.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309824000000,
        y: 660.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309910400000,
        y: 671,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309996800000,
        y: 671,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310083200000,
        y: 675.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310342400000,
        y: 684.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310428800000,
        y: 684.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310515200000,
        y: 689.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310601600000,
        y: 681,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310688000000,
        y: 681,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310947200000,
        y: 674.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311033600000,
        y: 684.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311120000000,
        y: 686.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311206400000,
        y: 689.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311292800000,
        y: 689.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311552000000,
        y: 688.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311638400000,
        y: 684.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311724800000,
        y: 684.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311811200000,
        y: 687.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311897600000,
        y: 677.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312156800000,
        y: 675.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312243200000,
        y: 667.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312329600000,
        y: 670,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312416000000,
        y: 667,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312502400000,
        y: 667,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312761600000,
        y: 662,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312848000000,
        y: 662,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312934400000,
        y: 679,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313020800000,
        y: 679,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313107200000,
        y: 681,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313366400000,
        y: 682.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313452800000,
        y: 688.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313539200000,
        y: 697.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313625600000,
        y: 627.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313712000000,
        y: 626.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313971200000,
        y: 626.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314057600000,
        y: 636.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314144000000,
        y: 646.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314230400000,
        y: 646.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314316800000,
        y: 650.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314662400000,
        y: 650.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314748800000,
        y: 663.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314835200000,
        y: 663.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314921600000,
        y: 653.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315180800000,
        y: 644.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315267200000,
        y: 644.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315353600000,
        y: 649.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315440000000,
        y: 653.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315526400000,
        y: 635.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315785600000,
        y: 632.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315872000000,
        y: 629.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315958400000,
        y: 629.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316044800000,
        y: 659.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316131200000,
        y: 644.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316390400000,
        y: 637.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316476800000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316563200000,
        y: 651.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316649600000,
        y: 626.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316736000000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316995200000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317081600000,
        y: 632.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317168000000,
        y: 623.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317254400000,
        y: 623.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317340800000,
        y: 614.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317600000000,
        y: 610,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317686400000,
        y: 614.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317772800000,
        y: 614.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317859200000,
        y: 617.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317945600000,
        y: 617.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318204800000,
        y: 632.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318291200000,
        y: 637.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318377600000,
        y: 637.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318464000000,
        y: 641.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318550400000,
        y: 651.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318809600000,
        y: 637.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318896000000,
        y: 628.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318982400000,
        y: 624.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319068800000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319155200000,
        y: 643.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319414400000,
        y: 644.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319500800000,
        y: 638.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319587200000,
        y: 627.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319673600000,
        y: 627.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319760000000,
        y: 652.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320019200000,
        y: 647.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320105600000,
        y: 629.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320192000000,
        y: 650.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320278400000,
        y: 652.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320364800000,
        y: 658.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320624000000,
        y: 670,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320710400000,
        y: 678,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320796800000,
        y: 678,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320883200000,
        y: 668,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320969600000,
        y: 673,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321228800000,
        y: 661.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321315200000,
        y: 659.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321401600000,
        y: 663,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321488000000,
        y: 647.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321574400000,
        y: 633.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321833600000,
        y: 640.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321920000000,
        y: 632.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322006400000,
        y: 632.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322092800000,
        y: 633.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322179200000,
        y: 624.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322438400000,
        y: 643.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322524800000,
        y: 653,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322611200000,
        y: 653,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322697600000,
        y: 639.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322784000000,
        y: 645.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323043200000,
        y: 656.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323129600000,
        y: 652.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323216000000,
        y: 649.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323302400000,
        y: 649.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323388800000,
        y: 638.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323648000000,
        y: 637.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323734400000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323820800000,
        y: 624.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323907200000,
        y: 628.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323993600000,
        y: 629.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324252800000,
        y: 628.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324339200000,
        y: 646.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324425600000,
        y: 646.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324512000000,
        y: 646.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324598400000,
        y: 668,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324857600000,
        y: 668,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324944000000,
        y: 668,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325030400000,
        y: 673,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325116800000,
        y: 670,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325203200000,
        y: 670,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325548800000,
        y: 679.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325635200000,
        y: 683.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325721600000,
        y: 680.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325808000000,
        y: 675.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326067200000,
        y: 676.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326153600000,
        y: 676.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326240000000,
        y: 669.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326326400000,
        y: 677,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326412800000,
        y: 667,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326672000000,
        y: 665,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326758400000,
        y: 665.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326844800000,
        y: 662.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326931200000,
        y: 661.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327017600000,
        y: 656.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327276800000,
        y: 658.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327363200000,
        y: 654,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327449600000,
        y: 657,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327536000000,
        y: 667.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327622400000,
        y: 667.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327881600000,
        y: 661.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327968000000,
        y: 666.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328054400000,
        y: 669,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328140800000,
        y: 666,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328227200000,
        y: 666,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328486400000,
        y: 683.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328572800000,
        y: 699.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328659200000,
        y: 700.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328745600000,
        y: 713.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328832000000,
        y: 714.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329091200000,
        y: 709.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329177600000,
        y: 700.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329264000000,
        y: 706.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329350400000,
        y: 706.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329436800000,
        y: 715.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329696000000,
        y: 705.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329782400000,
        y: 705.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329868800000,
        y: 730,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329955200000,
        y: 731,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330041600000,
        y: 731,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330300800000,
        y: 740,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330387200000,
        y: 735,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330473600000,
        y: 732,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330560000000,
        y: 737,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330646400000,
        y: 745.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330905600000,
        y: 744.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330992000000,
        y: 738.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331078400000,
        y: 743,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331164800000,
        y: 754,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331251200000,
        y: 753.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331510400000,
        y: 753.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331596800000,
        y: 754,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331683200000,
        y: 747,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331769600000,
        y: 747,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331856000000,
        y: 747,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332115200000,
        y: 745,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332201600000,
        y: 745,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332288000000,
        y: 746.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332374400000,
        y: 735.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332460800000,
        y: 745.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332720000000,
        y: 752.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332806400000,
        y: 748.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332892800000,
        y: 746,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332979200000,
        y: 740,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333065600000,
        y: 745.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333324800000,
        y: 748.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333411200000,
        y: 756.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333497600000,
        y: 749.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333584000000,
        y: 747,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334016000000,
        y: 743,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334102400000,
        y: 736.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334188800000,
        y: 740.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334275200000,
        y: 740.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334534400000,
        y: 735,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334620800000,
        y: 730,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334707200000,
        y: 713.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334793600000,
        y: 721,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334880000000,
        y: 721,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335139200000,
        y: 713.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335225600000,
        y: 713.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335312000000,
        y: 709.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335398400000,
        y: 719.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335484800000,
        y: 718.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335744000000,
        y: 716.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335830400000,
        y: 723,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335916800000,
        y: 724.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336003200000,
        y: 724.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336089600000,
        y: 710.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336435200000,
        y: 694.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336521600000,
        y: 699.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336608000000,
        y: 692.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336694400000,
        y: 668,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336953600000,
        y: 659.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337040000000,
        y: 661.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337126400000,
        y: 662.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337212800000,
        y: 659.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337299200000,
        y: 649.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337558400000,
        y: 656.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337644800000,
        y: 662,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337731200000,
        y: 647.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337817600000,
        y: 647.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337904000000,
        y: 647.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338163200000,
        y: 650.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338249600000,
        y: 654.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338336000000,
        y: 634.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338422400000,
        y: 630.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338508800000,
        y: 591.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338940800000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339027200000,
        y: 607.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339113600000,
        y: 599,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339372800000,
        y: 597,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339459200000,
        y: 598,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339545600000,
        y: 598.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339632000000,
        y: 596.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339718400000,
        y: 599.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339977600000,
        y: 599.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340064000000,
        y: 599.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340150400000,
        y: 583,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340236800000,
        y: 564.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340323200000,
        y: 563.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340582400000,
        y: 558.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340668800000,
        y: 566.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340755200000,
        y: 572.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340841600000,
        y: 568.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340928000000,
        y: 588,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341187200000,
        y: 598,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341273600000,
        y: 617.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341360000000,
        y: 617.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341446400000,
        y: 622.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341532800000,
        y: 604.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341792000000,
        y: 608,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341878400000,
        y: 604,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341964800000,
        y: 600.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342051200000,
        y: 596.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342137600000,
        y: 617.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342396800000,
        y: 623.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342483200000,
        y: 627.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342569600000,
        y: 640.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342656000000,
        y: 654.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342742400000,
        y: 648.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343001600000,
        y: 618.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343088000000,
        y: 622.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343174400000,
        y: 622.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343260800000,
        y: 632.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343347200000,
        y: 632.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343606400000,
        y: 637.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343692800000,
        y: 636.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343779200000,
        y: 634.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343865600000,
        y: 634.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343952000000,
        y: 651.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344211200000,
        y: 657.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344297600000,
        y: 674.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344384000000,
        y: 676.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344470400000,
        y: 677.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344556800000,
        y: 676.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344816000000,
        y: 679.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344902400000,
        y: 681.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344988800000,
        y: 683.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345075200000,
        y: 704.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345161600000,
        y: 705.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345420800000,
        y: 694.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345507200000,
        y: 692.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345593600000,
        y: 689.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345680000000,
        y: 691.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345766400000,
        y: 681,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346025600000,
        y: 681,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346112000000,
        y: 678.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346198400000,
        y: 688.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346284800000,
        y: 688.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346371200000,
        y: 695.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346630400000,
        y: 708.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346716800000,
        y: 707.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346803200000,
        y: 707.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346889600000,
        y: 706.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346976000000,
        y: 698.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347235200000,
        y: 702.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347321600000,
        y: 704.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347408000000,
        y: 706.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347494400000,
        y: 708.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347580800000,
        y: 713.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347840000000,
        y: 708.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347926400000,
        y: 688.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348012800000,
        y: 660.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348099200000,
        y: 660.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348185600000,
        y: 660.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348444800000,
        y: 665.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348531200000,
        y: 669.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348617600000,
        y: 669.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348704000000,
        y: 664.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348790400000,
        y: 670.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349049600000,
        y: 672.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349136000000,
        y: 672.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349222400000,
        y: 648.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349308800000,
        y: 645.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349395200000,
        y: 648.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349654400000,
        y: 641.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349740800000,
        y: 642.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349827200000,
        y: 651,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349913600000,
        y: 653.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350000000000,
        y: 644.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350259200000,
        y: 637.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350345600000,
        y: 638.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350432000000,
        y: 636.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350518400000,
        y: 637.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350604800000,
        y: 635.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350864000000,
        y: 630.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350950400000,
        y: 630.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351036800000,
        y: 632.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351123200000,
        y: 634.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351209600000,
        y: 641.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351468800000,
        y: 631.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351555200000,
        y: 613,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351641600000,
        y: 612,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351728000000,
        y: 603.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351814400000,
        y: 597,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352073600000,
        y: 591,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352160000000,
        y: 606,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352246400000,
        y: 600,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352332800000,
        y: 592,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352419200000,
        y: 592,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352678400000,
        y: 596,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352764800000,
        y: 598,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352851200000,
        y: 599,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352937600000,
        y: 607,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353024000000,
        y: 603,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353283200000,
        y: 613,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353369600000,
        y: 611.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353456000000,
        y: 611.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353542400000,
        y: 613.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353628800000,
        y: 612,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353888000000,
        y: 608,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353974400000,
        y: 597.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354060800000,
        y: 586,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354147200000,
        y: 593,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354233600000,
        y: 604,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354492800000,
        y: 599,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354579200000,
        y: 595,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354665600000,
        y: 595,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354752000000,
        y: 586,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354838400000,
        y: 584,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355097600000,
        y: 593,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355184000000,
        y: 590,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355270400000,
        y: 595.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355356800000,
        y: 595.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355443200000,
        y: 589,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355702400000,
        y: 592,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355788800000,
        y: 600,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355875200000,
        y: 603,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355961600000,
        y: 595,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356048000000,
        y: 584,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356307200000,
        y: 587,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356566400000,
        y: 597,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356652800000,
        y: 599,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356912000000,
        y: 594,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357084800000,
        y: 601,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357171200000,
        y: 612,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357257600000,
        y: 611,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357516800000,
        y: 613.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357603200000,
        y: 620.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357689600000,
        y: 628.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357776000000,
        y: 634.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357862400000,
        y: 618.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358121600000,
        y: 618.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358208000000,
        y: 617.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358294400000,
        y: 615.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358380800000,
        y: 622.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358467200000,
        y: 621.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358726400000,
        y: 627.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358812800000,
        y: 627.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358899200000,
        y: 627.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358985600000,
        y: 635.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359072000000,
        y: 635.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359331200000,
        y: 634.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359417600000,
        y: 634.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359504000000,
        y: 640.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359590400000,
        y: 649.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359676800000,
        y: 654.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359936000000,
        y: 657.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360022400000,
        y: 662.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360108800000,
        y: 663.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360195200000,
        y: 669,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360281600000,
        y: 679,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360540800000,
        y: 677,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360627200000,
        y: 670.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360713600000,
        y: 673.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360800000000,
        y: 673.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360886400000,
        y: 670,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361145600000,
        y: 662.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361232000000,
        y: 660.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361318400000,
        y: 660.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361404800000,
        y: 644.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361491200000,
        y: 635.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361750400000,
        y: 638.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361836800000,
        y: 638.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361923200000,
        y: 630.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362009600000,
        y: 622.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362096000000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362355200000,
        y: 615.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362441600000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362528000000,
        y: 617.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362614400000,
        y: 618.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362700800000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362960000000,
        y: 614.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363046400000,
        y: 614.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363132800000,
        y: 604,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363219200000,
        y: 605,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363305600000,
        y: 600.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363564800000,
        y: 605.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363651200000,
        y: 606.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363737600000,
        y: 599.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363824000000,
        y: 603,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363910400000,
        y: 602.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364169600000,
        y: 604,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364256000000,
        y: 607,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364342400000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364428800000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364774400000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364860800000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364947200000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365033600000,
        y: 589,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365120000000,
        y: 596,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365379200000,
        y: 588.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365465600000,
        y: 588.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365552000000,
        y: 594.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365638400000,
        y: 593.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365724800000,
        y: 578.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365984000000,
        y: 574.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366070400000,
        y: 573.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366156800000,
        y: 569.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366243200000,
        y: 568.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366329600000,
        y: 568.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366588800000,
        y: 573.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366675200000,
        y: 573.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366761600000,
        y: 576.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366848000000,
        y: 582,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366934400000,
        y: 582,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367193600000,
        y: 586,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367280000000,
        y: 586,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367366400000,
        y: 568.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367452800000,
        y: 581,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367539200000,
        y: 593,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367884800000,
        y: 593,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367971200000,
        y: 589,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368057600000,
        y: 589,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368144000000,
        y: 599.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368403200000,
        y: 596.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368489600000,
        y: 598.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368576000000,
        y: 588.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368662400000,
        y: 600.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368748800000,
        y: 597.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369008000000,
        y: 590.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369094400000,
        y: 589,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369180800000,
        y: 589,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369267200000,
        y: 592,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369353600000,
        y: 586.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369699200000,
        y: 596.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369785600000,
        y: 596,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369872000000,
        y: 596,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369958400000,
        y: 591,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370217600000,
        y: 595,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370304000000,
        y: 596,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370390400000,
        y: 604,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370476800000,
        y: 602,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370563200000,
        y: 602,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370822400000,
        y: 601,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370908800000,
        y: 596,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370995200000,
        y: 586,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371081600000,
        y: 606,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371168000000,
        y: 614.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371427200000,
        y: 618.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371513600000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371600000000,
        y: 613.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371686400000,
        y: 596,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371772800000,
        y: 583,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372032000000,
        y: 585,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372118400000,
        y: 594,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372204800000,
        y: 579,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372291200000,
        y: 581,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372377600000,
        y: 581,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372636800000,
        y: 585,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372723200000,
        y: 590,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372809600000,
        y: 592,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372896000000,
        y: 588.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372982400000,
        y: 591.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373241600000,
        y: 591.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373328000000,
        y: 592,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373414400000,
        y: 587,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373500800000,
        y: 588,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373587200000,
        y: 588,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373846400000,
        y: 589.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373932800000,
        y: 589.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374019200000,
        y: 593.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374105600000,
        y: 599.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374192000000,
        y: 601.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374451200000,
        y: 597.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374537600000,
        y: 602,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374624000000,
        y: 599.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374710400000,
        y: 597.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374796800000,
        y: 596.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375056000000,
        y: 596.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375142400000,
        y: 596,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375228800000,
        y: 595.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375315200000,
        y: 605,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375401600000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375660800000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375747200000,
        y: 603.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375833600000,
        y: 598.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375920000000,
        y: 592.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376006400000,
        y: 592.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376265600000,
        y: 595,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376352000000,
        y: 602.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376438400000,
        y: 605.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376524800000,
        y: 612.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376611200000,
        y: 615.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376870400000,
        y: 615.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376956800000,
        y: 610.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377043200000,
        y: 609.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377129600000,
        y: 611,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377216000000,
        y: 608.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377561600000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377648000000,
        y: 620.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377734400000,
        y: 621.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377820800000,
        y: 614.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378080000000,
        y: 609.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378166400000,
        y: 616.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378252800000,
        y: 617.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378339200000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378425600000,
        y: 622.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378684800000,
        y: 624,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378771200000,
        y: 614.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378857600000,
        y: 610,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378944000000,
        y: 617,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379030400000,
        y: 615.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379289600000,
        y: 615.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379376000000,
        y: 609.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379462400000,
        y: 607.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379548800000,
        y: 609.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379635200000,
        y: 598.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379894400000,
        y: 600.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379980800000,
        y: 597,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380067200000,
        y: 591.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380153600000,
        y: 596.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380240000000,
        y: 603.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380499200000,
        y: 596.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380585600000,
        y: 592.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380672000000,
        y: 598,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380758400000,
        y: 596.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380844800000,
        y: 587.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381104000000,
        y: 591.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381190400000,
        y: 593,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381276800000,
        y: 585,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381363200000,
        y: 602.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381449600000,
        y: 601.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381708800000,
        y: 598.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381795200000,
        y: 601.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381881600000,
        y: 601.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381968000000,
        y: 597.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382054400000,
        y: 593.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382313600000,
        y: 595.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382400000000,
        y: 599.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382486400000,
        y: 599,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382572800000,
        y: 581.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382659200000,
        y: 584.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382918400000,
        y: 583.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383004800000,
        y: 591.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383091200000,
        y: 591,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383177600000,
        y: 590,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383264000000,
        y: 586.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383523200000,
        y: 580,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383609600000,
        y: 583.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383696000000,
        y: 581.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383782400000,
        y: 574.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383868800000,
        y: 578.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384128000000,
        y: 582.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384214400000,
        y: 584,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384300800000,
        y: 582.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384387200000,
        y: 588.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384473600000,
        y: 592.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384732800000,
        y: 591.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384819200000,
        y: 590.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384905600000,
        y: 589.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384992000000,
        y: 590.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385078400000,
        y: 592,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385337600000,
        y: 602.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385424000000,
        y: 606.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385510400000,
        y: 610.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385596800000,
        y: 608.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385683200000,
        y: 613.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385942400000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386028800000,
        y: 627.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386115200000,
        y: 625,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386201600000,
        y: 617,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386288000000,
        y: 609.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386547200000,
        y: 597.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386633600000,
        y: 601.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386720000000,
        y: 607.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386806400000,
        y: 606.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386892800000,
        y: 597.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387152000000,
        y: 603.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387238400000,
        y: 602.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387324800000,
        y: 599.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387411200000,
        y: 606.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387497600000,
        y: 602,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387756800000,
        y: 609.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387843200000,
        y: 608.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388102400000,
        y: 612,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388361600000,
        y: 606.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388448000000,
        y: 592.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388620800000,
        y: 593.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388707200000,
        y: 583,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388966400000,
        y: 581,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389052800000,
        y: 586,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389139200000,
        y: 579,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389225600000,
        y: 580,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389312000000,
        y: 577,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389571200000,
        y: 577,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389657600000,
        y: 575.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389744000000,
        y: 581.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389830400000,
        y: 582.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389916800000,
        y: 581,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390176000000,
        y: 578,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390262400000,
        y: 578,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390348800000,
        y: 577.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390435200000,
        y: 583,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390521600000,
        y: 580.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390780800000,
        y: 581.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390867200000,
        y: 587.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390953600000,
        y: 590.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391040000000,
        y: 601,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391126400000,
        y: 599.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391385600000,
        y: 588.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391472000000,
        y: 592,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391558400000,
        y: 598.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391644800000,
        y: 609.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391731200000,
        y: 609.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391990400000,
        y: 609,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392076800000,
        y: 608.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392163200000,
        y: 618.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392249600000,
        y: 621.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392336000000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392595200000,
        y: 608.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392681600000,
        y: 624.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392768000000,
        y: 625.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392854400000,
        y: 634.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392940800000,
        y: 630.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393200000000,
        y: 633.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393286400000,
        y: 629.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393372800000,
        y: 635,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393459200000,
        y: 634.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393545600000,
        y: 634.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393804800000,
        y: 653.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393891200000,
        y: 631,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393977600000,
        y: 624.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394064000000,
        y: 620,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394150400000,
        y: 635.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394409600000,
        y: 631,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394496000000,
        y: 637.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394582400000,
        y: 621,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394668800000,
        y: 619,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394755200000,
        y: 628.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395014400000,
        y: 618.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395100800000,
        y: 627.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395187200000,
        y: 625.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395273600000,
        y: 629.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395360000000,
        y: 626.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395619200000,
        y: 629,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395705600000,
        y: 630.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395792000000,
        y: 623.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395878400000,
        y: 623.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395964800000,
        y: 628.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396224000000,
        y: 633.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396310400000,
        y: 632.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396396800000,
        y: 632.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396483200000,
        y: 625.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396569600000,
        y: 623.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396828800000,
        y: 619.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396915200000,
        y: 621.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397001600000,
        y: 633.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397088000000,
        y: 629.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397174400000,
        y: 620.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397433600000,
        y: 620.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397520000000,
        y: 614.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397606400000,
        y: 619.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397692800000,
        y: 614.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398124800000,
        y: 618.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398211200000,
        y: 612.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398297600000,
        y: 615.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398384000000,
        y: 613.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398643200000,
        y: 608,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398729600000,
        y: 613,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398816000000,
        y: 613.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398902400000,
        y: 597.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398988800000,
        y: 600.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399334400000,
        y: 601.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399420800000,
        y: 608.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399507200000,
        y: 608.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399593600000,
        y: 613,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399852800000,
        y: 610.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399939200000,
        y: 619,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400025600000,
        y: 624.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400112000000,
        y: 625.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400198400000,
        y: 627.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400457600000,
        y: 634.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400544000000,
        y: 634.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400630400000,
        y: 639,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400716800000,
        y: 644.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400803200000,
        y: 641.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401148800000,
        y: 637,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401235200000,
        y: 631.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401321600000,
        y: 634.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401408000000,
        y: 628.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401667200000,
        y: 631.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401753600000,
        y: 634,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401840000000,
        y: 639.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401926400000,
        y: 625.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402012800000,
        y: 623,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402272000000,
        y: 626.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402358400000,
        y: 624.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402444800000,
        y: 625.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402531200000,
        y: 636.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402617600000,
        y: 635.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402876800000,
        y: 631,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402963200000,
        y: 636.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403049600000,
        y: 639,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403136000000,
        y: 641.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403222400000,
        y: 637,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403481600000,
        y: 637.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403568000000,
        y: 636.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403654400000,
        y: 633.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403740800000,
        y: 631,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403827200000,
        y: 633.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404086400000,
        y: 631,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404172800000,
        y: 629.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404259200000,
        y: 622.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404345600000,
        y: 623,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404432000000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404691200000,
        y: 613.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404777600000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404864000000,
        y: 604.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404950400000,
        y: 606.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405036800000,
        y: 600,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405296000000,
        y: 594.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405382400000,
        y: 592.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405468800000,
        y: 585.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405555200000,
        y: 588.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405641600000,
        y: 588.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405900800000,
        y: 587.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405987200000,
        y: 588.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406073600000,
        y: 586.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406160000000,
        y: 585.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406246400000,
        y: 586,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406505600000,
        y: 582.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406592000000,
        y: 583,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406678400000,
        y: 582.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406764800000,
        y: 577.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406851200000,
        y: 576.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407110400000,
        y: 575.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407196800000,
        y: 565.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407283200000,
        y: 568.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407369600000,
        y: 567.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407456000000,
        y: 560,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407715200000,
        y: 564.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407801600000,
        y: 560.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407888000000,
        y: 557,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407974400000,
        y: 562.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408060800000,
        y: 566.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408320000000,
        y: 562.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408406400000,
        y: 564,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408492800000,
        y: 562.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408579200000,
        y: 562,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408665600000,
        y: 565.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409011200000,
        y: 565.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409097600000,
        y: 568.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409184000000,
        y: 572.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409270400000,
        y: 571.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409529600000,
        y: 567.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409616000000,
        y: 565.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409702400000,
        y: 566.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409788800000,
        y: 566.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409875200000,
        y: 559.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410134400000,
        y: 560.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410220800000,
        y: 559.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410307200000,
        y: 546.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410393600000,
        y: 548.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410480000000,
        y: 546,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410739200000,
        y: 546,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410825600000,
        y: 548.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410912000000,
        y: 549.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410998400000,
        y: 549,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411084800000,
        y: 547.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411344000000,
        y: 547.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411430400000,
        y: 543.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411516800000,
        y: 540.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411603200000,
        y: 540.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411689600000,
        y: 542.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411948800000,
        y: 539.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412035200000,
        y: 547.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412121600000,
        y: 544.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412208000000,
        y: 529.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412294400000,
        y: 525.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412553600000,
        y: 517.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412640000000,
        y: 513.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412726400000,
        y: 506.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412812800000,
        y: 501,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412899200000,
        y: 491.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413158400000,
        y: 483.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413244800000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413331200000,
        y: 457.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413417600000,
        y: 443.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413504000000,
        y: 443.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413763200000,
        y: 452,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413849600000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413936000000,
        y: 452.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414022400000,
        y: 452,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414108800000,
        y: 462.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414368000000,
        y: 462.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414454400000,
        y: 464.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414540800000,
        y: 467.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414627200000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414713600000,
        y: 463.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414972800000,
        y: 449.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415059200000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415145600000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415232000000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415318400000,
        y: 435,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415577600000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415664000000,
        y: 432.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415750400000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415836800000,
        y: 416.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415923200000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416182400000,
        y: 406,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416268800000,
        y: 404.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416355200000,
        y: 407.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416441600000,
        y: 407.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416528000000,
        y: 415.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416787200000,
        y: 419.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416873600000,
        y: 410,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416960000000,
        y: 410.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417046400000,
        y: 380.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417132800000,
        y: 385,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417392000000,
        y: 368,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417478400000,
        y: 370.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417564800000,
        y: 367.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417651200000,
        y: 364.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417737600000,
        y: 360.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417996800000,
        y: 346.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418083200000,
        y: 346.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418169600000,
        y: 333.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418256000000,
        y: 332.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418342400000,
        y: 315.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418601600000,
        y: 316,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418688000000,
        y: 299,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418774400000,
        y: 301.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418860800000,
        y: 300.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418947200000,
        y: 295.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419206400000,
        y: 292.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419292800000,
        y: 291.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419379200000,
        y: 290.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419811200000,
        y: 288.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419897600000,
        y: 277,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419984000000,
        y: 270.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420156800000,
        y: 272.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420416000000,
        y: 253,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420502400000,
        y: 255,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420588800000,
        y: 249.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420675200000,
        y: 255.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420761600000,
        y: 255.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421020800000,
        y: 256,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421107200000,
        y: 252.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421193600000,
        y: 251.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421280000000,
        y: 252.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421366400000,
        y: 255.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421625600000,
        y: 256.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421712000000,
        y: 256.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421798400000,
        y: 253.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421884800000,
        y: 254.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421971200000,
        y: 257,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422230400000,
        y: 254,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422316800000,
        y: 255,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422403200000,
        y: 255.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422489600000,
        y: 253.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422576000000,
        y: 255,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422835200000,
        y: 260.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422921600000,
        y: 259.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423008000000,
        y: 259.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423094400000,
        y: 258.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423180800000,
        y: 257.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423440000000,
        y: 258.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423526400000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423612800000,
        y: 301.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423699200000,
        y: 299.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423785600000,
        y: 306.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424044800000,
        y: 301.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424131200000,
        y: 303,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424217600000,
        y: 300.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424304000000,
        y: 281.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424390400000,
        y: 288.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424649600000,
        y: 287.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424736000000,
        y: 289.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424822400000,
        y: 289.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424908800000,
        y: 292.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424995200000,
        y: 294,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425254400000,
        y: 322,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425340800000,
        y: 326,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425427200000,
        y: 326,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425513600000,
        y: 325.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425600000000,
        y: 324.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425859200000,
        y: 323.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425945600000,
        y: 319.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426032000000,
        y: 317.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426118400000,
        y: 290.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426204800000,
        y: 294,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426464000000,
        y: 294.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426550400000,
        y: 293.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426636800000,
        y: 295,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426723200000,
        y: 292.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426809600000,
        y: 296.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427068800000,
        y: 294.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427155200000,
        y: 292,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427241600000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427328000000,
        y: 303.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427414400000,
        y: 300,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427673600000,
        y: 299.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427760000000,
        y: 302.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427846400000,
        y: 295.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427932800000,
        y: 286.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428364800000,
        y: 303.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428451200000,
        y: 296.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428537600000,
        y: 295.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428624000000,
        y: 301,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428883200000,
        y: 304.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428969600000,
        y: 312.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429056000000,
        y: 322.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429142400000,
        y: 330.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429228800000,
        y: 338.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429488000000,
        y: 337.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429574400000,
        y: 330.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429660800000,
        y: 330.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429747200000,
        y: 333.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429833600000,
        y: 333.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430092800000,
        y: 341,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430179200000,
        y: 341,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430265600000,
        y: 348.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430352000000,
        y: 350.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430438400000,
        y: 340,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430784000000,
        y: 356.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430870400000,
        y: 360.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430956800000,
        y: 350.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431043200000,
        y: 350.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431302400000,
        y: 347,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431388800000,
        y: 353.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431475200000,
        y: 357.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431561600000,
        y: 359.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431648000000,
        y: 342.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431907200000,
        y: 341.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431993600000,
        y: 334.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432080000000,
        y: 333.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432166400000,
        y: 344,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432252800000,
        y: 341.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432598400000,
        y: 333.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432684800000,
        y: 327.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432771200000,
        y: 321.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432857600000,
        y: 339.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433116800000,
        y: 336,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433203200000,
        y: 342.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433289600000,
        y: 335,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433376000000,
        y: 323.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433462400000,
        y: 324,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433721600000,
        y: 324.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433808000000,
        y: 334,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433894400000,
        y: 337.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433980800000,
        y: 335,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434067200000,
        y: 330.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434326400000,
        y: 327,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434412800000,
        y: 328.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434499200000,
        y: 327.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434585600000,
        y: 328,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434672000000,
        y: 324.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434931200000,
        y: 322.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435017600000,
        y: 328.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435104000000,
        y: 327,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435190400000,
        y: 319.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435276800000,
        y: 323.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435536000000,
        y: 316.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435622400000,
        y: 316.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435708800000,
        y: 316.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435795200000,
        y: 315.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435881600000,
        y: 309,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436140800000,
        y: 291.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436227200000,
        y: 280,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436313600000,
        y: 285.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436400000000,
        y: 293,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436486400000,
        y: 290.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436745600000,
        y: 294,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436832000000,
        y: 291,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436918400000,
        y: 288.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437004800000,
        y: 287,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437091200000,
        y: 282.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437350400000,
        y: 284,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437436800000,
        y: 282.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437523200000,
        y: 279.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437609600000,
        y: 279.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437696000000,
        y: 269.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437955200000,
        y: 265.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438041600000,
        y: 265.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438128000000,
        y: 266.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438214400000,
        y: 265,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438300800000,
        y: 261.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438560000000,
        y: 260.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438646400000,
        y: 247.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438732800000,
        y: 246.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438819200000,
        y: 238,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438905600000,
        y: 238,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439164800000,
        y: 240.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439251200000,
        y: 235.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439337600000,
        y: 237.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439424000000,
        y: 234.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439510400000,
        y: 218.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439769600000,
        y: 230,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439856000000,
        y: 225,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439942400000,
        y: 223.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440028800000,
        y: 223.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440115200000,
        y: 213,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440374400000,
        y: 193.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440460800000,
        y: 197.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440547200000,
        y: 190.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440633600000,
        y: 209,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440720000000,
        y: 232.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441065600000,
        y: 233,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441152000000,
        y: 224.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441238400000,
        y: 232.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441324800000,
        y: 226.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441584000000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441670400000,
        y: 216,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441756800000,
        y: 214.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441843200000,
        y: 211.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441929600000,
        y: 208.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442188800000,
        y: 204,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442275200000,
        y: 200.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442361600000,
        y: 218.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442448000000,
        y: 214.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442534400000,
        y: 207.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442793600000,
        y: 209.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442880000000,
        y: 206.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442966400000,
        y: 214.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443052800000,
        y: 205.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443139200000,
        y: 209.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443398400000,
        y: 205,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443484800000,
        y: 208.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443571200000,
        y: 212.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443657600000,
        y: 213.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443744000000,
        y: 213.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444003200000,
        y: 233.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444089600000,
        y: 241,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444176000000,
        y: 239,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444262400000,
        y: 241,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444348800000,
        y: 240.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444608000000,
        y: 236,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444694400000,
        y: 232.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444780800000,
        y: 231.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444867200000,
        y: 224.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444953600000,
        y: 224.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445212800000,
        y: 214,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445299200000,
        y: 222,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445385600000,
        y: 221,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445472000000,
        y: 222,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445558400000,
        y: 220.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445817600000,
        y: 222.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445904000000,
        y: 220.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445990400000,
        y: 219.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446076800000,
        y: 230.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446163200000,
        y: 229.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446422400000,
        y: 228,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446508800000,
        y: 232.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446595200000,
        y: 228.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446681600000,
        y: 228.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446768000000,
        y: 219.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447027200000,
        y: 220.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447113600000,
        y: 221.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447200000000,
        y: 220,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447286400000,
        y: 202.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447372800000,
        y: 198.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447632000000,
        y: 193,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447718400000,
        y: 194,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447804800000,
        y: 195.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447891200000,
        y: 194.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447977600000,
        y: 195.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448236800000,
        y: 201,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448323200000,
        y: 205.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448409600000,
        y: 201.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448496000000,
        y: 198,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448582400000,
        y: 193,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448841600000,
        y: 194,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448928000000,
        y: 195.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449014400000,
        y: 184.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449100800000,
        y: 184,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449187200000,
        y: 181.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449446400000,
        y: 166.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449532800000,
        y: 163.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449619200000,
        y: 163.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449705600000,
        y: 162.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449792000000,
        y: 154.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450051200000,
        y: 154,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450137600000,
        y: 156,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450224000000,
        y: 152.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450310400000,
        y: 150.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450396800000,
        y: 149,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450656000000,
        y: 147.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450742400000,
        y: 150,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450828800000,
        y: 151.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450915200000,
        y: 153.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451347200000,
        y: 153.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451433600000,
        y: 147.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451520000000,
        y: 148.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451865600000,
        y: 147.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451952000000,
        y: 136.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452038400000,
        y: 125.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452124800000,
        y: 141.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452211200000,
        y: 126.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452470400000,
        y: 120.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452556800000,
        y: 113.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452643200000,
        y: 113.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452729600000,
        y: 114.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452816000000,
        y: 112.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453075200000,
        y: 108.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453161600000,
        y: 111,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453248000000,
        y: 108.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453334400000,
        y: 122.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453420800000,
        y: 136,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453680000000,
        y: 131.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453766400000,
        y: 134.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453852800000,
        y: 138.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453939200000,
        y: 150.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454025600000,
        y: 155,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454284800000,
        y: 153,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454371200000,
        y: 140.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454457600000,
        y: 143.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454544000000,
        y: 143.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454630400000,
        y: 143.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454889600000,
        y: 140,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454976000000,
        y: 132.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455062400000,
        y: 130,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455148800000,
        y: 127,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455235200000,
        y: 140.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455494400000,
        y: 141.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455580800000,
        y: 133.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455667200000,
        y: 144,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455753600000,
        y: 142,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455840000000,
        y: 136.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456099200000,
        y: 147.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456185600000,
        y: 136.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456272000000,
        y: 137,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456358400000,
        y: 141.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456444800000,
        y: 148.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456704000000,
        y: 147,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456790400000,
        y: 149.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456876800000,
        y: 146,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456963200000,
        y: 141.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457049600000,
        y: 148.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457308800000,
        y: 158.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457395200000,
        y: 158.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457481600000,
        y: 163.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457568000000,
        y: 162.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457654400000,
        y: 162.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457913600000,
        y: 158,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458000000000,
        y: 156.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458086400000,
        y: 159.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458172800000,
        y: 165.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458259200000,
        y: 159.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458518400000,
        y: 161,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458604800000,
        y: 165.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458691200000,
        y: 161.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458777600000,
        y: 156,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459209600000,
        y: 152.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459296000000,
        y: 156.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459382400000,
        y: 159.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459468800000,
        y: 145.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459728000000,
        y: 146.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459814400000,
        y: 147.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459900800000,
        y: 148.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459987200000,
        y: 157,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460073600000,
        y: 173.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460332800000,
        y: 180,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460419200000,
        y: 186,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460505600000,
        y: 183.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460592000000,
        y: 182.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460678400000,
        y: 174.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460937600000,
        y: 173,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461024000000,
        y: 173.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461110400000,
        y: 182.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461196800000,
        y: 187.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461283200000,
        y: 187.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461542400000,
        y: 187,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461628800000,
        y: 186,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461715200000,
        y: 192.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461801600000,
        y: 200.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461888000000,
        y: 202.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462233600000,
        y: 193,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462320000000,
        y: 192.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462406400000,
        y: 196,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462492800000,
        y: 202,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462752000000,
        y: 190.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462838400000,
        y: 199.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462924800000,
        y: 211.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463011200000,
        y: 209.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463097600000,
        y: 213.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463356800000,
        y: 222,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463443200000,
        y: 220.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463529600000,
        y: 222,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463616000000,
        y: 214,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463702400000,
        y: 220.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463961600000,
        y: 222.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464048000000,
        y: 225.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464134400000,
        y: 226,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464220800000,
        y: 226.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464307200000,
        y: 225,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464652800000,
        y: 229.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464739200000,
        y: 226,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464825600000,
        y: 226.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464912000000,
        y: 227.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465171200000,
        y: 231,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465257600000,
        y: 228.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465344000000,
        y: 232,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465430400000,
        y: 241.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465516800000,
        y: 226,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465776000000,
        y: 223.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465862400000,
        y: 216.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465948800000,
        y: 215,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466035200000,
        y: 209.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466121600000,
        y: 212.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466380800000,
        y: 226.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466467200000,
        y: 220.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466553600000,
        y: 227,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466640000000,
        y: 223.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466726400000,
        y: 221.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466985600000,
        y: 210.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467072000000,
        y: 221.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467158400000,
        y: 237,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467244800000,
        y: 237.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467331200000,
        y: 240.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467590400000,
        y: 244.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467676800000,
        y: 242.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467763200000,
        y: 247,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467849600000,
        y: 248.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467936000000,
        y: 241.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468195200000,
        y: 239.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468281600000,
        y: 242,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468368000000,
        y: 234.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468454400000,
        y: 240.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468540800000,
        y: 239,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468800000000,
        y: 239.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468886400000,
        y: 239.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468972800000,
        y: 241,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469059200000,
        y: 239.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469145600000,
        y: 233.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469404800000,
        y: 229,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469491200000,
        y: 230,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469577600000,
        y: 229.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469664000000,
        y: 224.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469750400000,
        y: 224.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470009600000,
        y: 219.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470096000000,
        y: 213,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470182400000,
        y: 206.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470268800000,
        y: 213.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470355200000,
        y: 208.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470614400000,
        y: 217.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470700800000,
        y: 218,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470787200000,
        y: 211.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470873600000,
        y: 232,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470960000000,
        y: 228.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471219200000,
        y: 231,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471305600000,
        y: 245.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471392000000,
        y: 253.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471478400000,
        y: 256,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471564800000,
        y: 254.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471824000000,
        y: 253.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471910400000,
        y: 249.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471996800000,
        y: 250,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472083200000,
        y: 254.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472169600000,
        y: 257.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472515200000,
        y: 255,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472601600000,
        y: 242.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472688000000,
        y: 240,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472774400000,
        y: 240.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473033600000,
        y: 246.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473120000000,
        y: 241.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473206400000,
        y: 251.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473292800000,
        y: 261.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473379200000,
        y: 252.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473638400000,
        y: 252.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473724800000,
        y: 247.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473811200000,
        y: 239.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473897600000,
        y: 243.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473984000000,
        y: 247.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474243200000,
        y: 252.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474329600000,
        y: 251.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474416000000,
        y: 252.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474502400000,
        y: 258.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474588800000,
        y: 258,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474848000000,
        y: 260.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474934400000,
        y: 246.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475020800000,
        y: 248.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475107200000,
        y: 261.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475193600000,
        y: 262.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475452800000,
        y: 271.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475539200000,
        y: 256.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475625600000,
        y: 265,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475712000000,
        y: 273.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475798400000,
        y: 265.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476057600000,
        y: 273.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476144000000,
        y: 275,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476230400000,
        y: 273.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476316800000,
        y: 259.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476403200000,
        y: 258.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476662400000,
        y: 256.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476748800000,
        y: 265.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476835200000,
        y: 277.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476921600000,
        y: 273,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477008000000,
        y: 277.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477267200000,
        y: 281.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477353600000,
        y: 286.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477440000000,
        y: 288.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477526400000,
        y: 285,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477612800000,
        y: 282.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477872000000,
        y: 266,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477958400000,
        y: 258.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478044800000,
        y: 249.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478131200000,
        y: 251,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478217600000,
        y: 253,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478476800000,
        y: 255.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478563200000,
        y: 254.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478649600000,
        y: 251.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478736000000,
        y: 247.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478822400000,
        y: 241,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479081600000,
        y: 237.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479168000000,
        y: 250.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479254400000,
        y: 258.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479340800000,
        y: 256.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479427200000,
        y: 257.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479686400000,
        y: 271.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479772800000,
        y: 270.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479859200000,
        y: 272.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479945600000,
        y: 268.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480032000000,
        y: 266.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480291200000,
        y: 274.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480377600000,
        y: 263.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480464000000,
        y: 282,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480550400000,
        y: 289,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480636800000,
        y: 292,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480896000000,
        y: 299.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480982400000,
        y: 296,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481068800000,
        y: 297,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481155200000,
        y: 296,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481241600000,
        y: 299.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481500800000,
        y: 307.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481587200000,
        y: 303.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481673600000,
        y: 303.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481760000000,
        y: 300,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481846400000,
        y: 314.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482105600000,
        y: 313.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482192000000,
        y: 313.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482278400000,
        y: 311,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482364800000,
        y: 312.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482451200000,
        y: 307.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482883200000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482969600000,
        y: 315.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483056000000,
        y: 316.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483401600000,
        y: 302.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483488000000,
        y: 310.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483574400000,
        y: 309.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483660800000,
        y: 322.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483920000000,
        y: 308.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484006400000,
        y: 280.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484092800000,
        y: 305.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484179200000,
        y: 309.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484265600000,
        y: 307.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484524800000,
        y: 310.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484611200000,
        y: 311.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484697600000,
        y: 308.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484784000000,
        y: 296.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484870400000,
        y: 305.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485129600000,
        y: 300.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485216000000,
        y: 297.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485302400000,
        y: 300,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485388800000,
        y: 291,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485475200000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485734400000,
        y: 295.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485820800000,
        y: 291,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485907200000,
        y: 297.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485993600000,
        y: 304.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486080000000,
        y: 313.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486339200000,
        y: 311.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486425600000,
        y: 303.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486512000000,
        y: 307,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486598400000,
        y: 308,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486684800000,
        y: 319,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486944000000,
        y: 312,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487030400000,
        y: 313.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487116800000,
        y: 312,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487203200000,
        y: 305.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487289600000,
        y: 303,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487548800000,
        y: 307,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487635200000,
        y: 310.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487721600000,
        y: 305.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487808000000,
        y: 310.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487894400000,
        y: 308.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488153600000,
        y: 311.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488240000000,
        y: 307.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488326400000,
        y: 306.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488412800000,
        y: 302.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488499200000,
        y: 294.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488758400000,
        y: 303.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488844800000,
        y: 305.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488931200000,
        y: 294.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489017600000,
        y: 280.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489104000000,
        y: 279,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489363200000,
        y: 277.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489449600000,
        y: 268.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489536000000,
        y: 274,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489622400000,
        y: 273,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489708800000,
        y: 274.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489968000000,
        y: 276,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490054400000,
        y: 270.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490140800000,
        y: 267.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490227200000,
        y: 270.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490313600000,
        y: 270,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490572800000,
        y: 272.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490659200000,
        y: 277,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490745600000,
        y: 276.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490832000000,
        y: 283.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490918400000,
        y: 280.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491177600000,
        y: 284.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491264000000,
        y: 293.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491350400000,
        y: 292.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491436800000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491523200000,
        y: 296,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491782400000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491868800000,
        y: 302.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491955200000,
        y: 305.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492041600000,
        y: 303.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492128000000,
        y: 304,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492473600000,
        y: 299,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492560000000,
        y: 298.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492646400000,
        y: 287.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492732800000,
        y: 282.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492992000000,
        y: 277.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493078400000,
        y: 277.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493164800000,
        y: 281.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493251200000,
        y: 276.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493337600000,
        y: 285,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493596800000,
        y: 285,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493683200000,
        y: 286.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493769600000,
        y: 283.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493856000000,
        y: 282.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493942400000,
        y: 283,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494201600000,
        y: 281.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494288000000,
        y: 280.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494374400000,
        y: 288,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494460800000,
        y: 295.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494547200000,
        y: 291.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494806400000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494892800000,
        y: 300.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494979200000,
        y: 304,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495065600000,
        y: 303.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495152000000,
        y: 307.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495411200000,
        y: 308,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495497600000,
        y: 309,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495584000000,
        y: 312.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495670400000,
        y: 306.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495756800000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496016000000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496102400000,
        y: 296.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496188800000,
        y: 291,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496275200000,
        y: 299.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496361600000,
        y: 294.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496620800000,
        y: 289.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496707200000,
        y: 280.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496793600000,
        y: 277.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496880000000,
        y: 279.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496966400000,
        y: 284.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497225600000,
        y: 282.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497312000000,
        y: 280,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497398400000,
        y: 271.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497484800000,
        y: 273.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497571200000,
        y: 273.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497830400000,
        y: 274.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497916800000,
        y: 267.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498003200000,
        y: 270,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498089600000,
        y: 272.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498176000000,
        y: 273.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498435200000,
        y: 271.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498521600000,
        y: 279,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498608000000,
        y: 280.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498694400000,
        y: 285.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498780800000,
        y: 287,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499040000000,
        y: 293.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499126400000,
        y: 290.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499212800000,
        y: 280.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499299200000,
        y: 284,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499385600000,
        y: 275.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499644800000,
        y: 275.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499731200000,
        y: 273.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499817600000,
        y: 276.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499904000000,
        y: 284.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499990400000,
        y: 286.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500249600000,
        y: 284,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500336000000,
        y: 281.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500422400000,
        y: 288,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500508800000,
        y: 288.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500595200000,
        y: 286,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500854400000,
        y: 287.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500940800000,
        y: 296.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501027200000,
        y: 300,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501113600000,
        y: 302.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501200000000,
        y: 302.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501459200000,
        y: 303.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501545600000,
        y: 300,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501632000000,
        y: 300,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501718400000,
        y: 302.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501804800000,
        y: 302.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502064000000,
        y: 299.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502150400000,
        y: 302.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502236800000,
        y: 299.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502323200000,
        y: 301,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502409600000,
        y: 296,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502668800000,
        y: 295.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502755200000,
        y: 288.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502841600000,
        y: 290,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502928000000,
        y: 290.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503014400000,
        y: 294,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503273600000,
        y: 297,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503360000000,
        y: 298.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503446400000,
        y: 299.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503532800000,
        y: 299,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503619200000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503964800000,
        y: 298,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504051200000,
        y: 300.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504137600000,
        y: 301.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504224000000,
        y: 309.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504483200000,
        y: 310,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504569600000,
        y: 313.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504656000000,
        y: 318.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504742400000,
        y: 312.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504828800000,
        y: 311,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505088000000,
        y: 306.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505174400000,
        y: 313,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505260800000,
        y: 319.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505347200000,
        y: 322,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505433600000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505692800000,
        y: 315.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505779200000,
        y: 317,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505865600000,
        y: 322.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505952000000,
        y: 322.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506038400000,
        y: 324,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506297600000,
        y: 329.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506384000000,
        y: 327,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506470400000,
        y: 325.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506556800000,
        y: 328,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506643200000,
        y: 327.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506902400000,
        y: 323,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506988800000,
        y: 324.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507075200000,
        y: 320.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507161600000,
        y: 328.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507248000000,
        y: 318.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507507200000,
        y: 318.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507593600000,
        y: 326.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507680000000,
        y: 324.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507766400000,
        y: 321.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507852800000,
        y: 325.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508112000000,
        y: 327.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508198400000,
        y: 326.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508284800000,
        y: 327.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508371200000,
        y: 318.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508457600000,
        y: 322,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508716800000,
        y: 322.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508803200000,
        y: 322.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508889600000,
        y: 318.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508976000000,
        y: 324.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509062400000,
        y: 332.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509321600000,
        y: 334.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509408000000,
        y: 335.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509494400000,
        y: 345.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509580800000,
        y: 349,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509667200000,
        y: 342.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509926400000,
        y: 357.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510012800000,
        y: 355.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510099200000,
        y: 354,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510185600000,
        y: 359.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510272000000,
        y: 359.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510531200000,
        y: 355.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510617600000,
        y: 343,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510704000000,
        y: 346.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510790400000,
        y: 346.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510876800000,
        y: 351.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511136000000,
        y: 348.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511222400000,
        y: 348.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511308800000,
        y: 350.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511395200000,
        y: 353,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511481600000,
        y: 352.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511740800000,
        y: 352.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511827200000,
        y: 349.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511913600000,
        y: 351.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512000000000,
        y: 351.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512086400000,
        y: 358.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512345600000,
        y: 345,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512432000000,
        y: 340.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512518400000,
        y: 329.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512604800000,
        y: 328.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512691200000,
        y: 337.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512950400000,
        y: 343.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513036800000,
        y: 341,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513123200000,
        y: 335.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513209600000,
        y: 338.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513296000000,
        y: 343.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513555200000,
        y: 348.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513641600000,
        y: 349.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513728000000,
        y: 356.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513814400000,
        y: 358.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513900800000,
        y: 360.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514332800000,
        y: 368,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514419200000,
        y: 366.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514505600000,
        y: 370,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514851200000,
        y: 370.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514937600000,
        y: 379,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515024000000,
        y: 378.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515110400000,
        y: 375,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515369600000,
        y: 374.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515456000000,
        y: 374.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515542400000,
        y: 375.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515628800000,
        y: 379.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515715200000,
        y: 375.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515974400000,
        y: 377,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516060800000,
        y: 372.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516147200000,
        y: 370.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516233600000,
        y: 373.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516320000000,
        y: 374.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516579200000,
        y: 374.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516665600000,
        y: 373.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516752000000,
        y: 374.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516838400000,
        y: 379,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516924800000,
        y: 380.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517184000000,
        y: 375,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517270400000,
        y: 372,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517356800000,
        y: 375.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517443200000,
        y: 374,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517529600000,
        y: 367,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517788800000,
        y: 368.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517875200000,
        y: 365.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517961600000,
        y: 357.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518048000000,
        y: 348.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518134400000,
        y: 342,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518393600000,
        y: 343,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518480000000,
        y: 342,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518566400000,
        y: 343.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518652800000,
        y: 347.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518739200000,
        y: 354.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518998400000,
        y: 359.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519084800000,
        y: 354.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519171200000,
        y: 354.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519257600000,
        y: 360.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519344000000,
        y: 360.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519603200000,
        y: 363,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519689600000,
        y: 360,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519776000000,
        y: 353,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519862400000,
        y: 344.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519948800000,
        y: 342.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520208000000,
        y: 351.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520294400000,
        y: 352.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520380800000,
        y: 351.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520467200000,
        y: 352.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520553600000,
        y: 352.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520812800000,
        y: 353,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520899200000,
        y: 350.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520985600000,
        y: 351.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521072000000,
        y: 351.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521158400000,
        y: 356.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521417600000,
        y: 356.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521504000000,
        y: 359,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521590400000,
        y: 365.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521676800000,
        y: 365,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521763200000,
        y: 369.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522022400000,
        y: 368.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522108800000,
        y: 370,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522195200000,
        y: 369.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522281600000,
        y: 357.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522627200000,
        y: 357.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522713600000,
        y: 360.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522800000000,
        y: 359.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522886400000,
        y: 359.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522972800000,
        y: 354.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523232000000,
        y: 366.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523318400000,
        y: 371,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523404800000,
        y: 380.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523491200000,
        y: 378.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523577600000,
        y: 381.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523836800000,
        y: 377.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523923200000,
        y: 378.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524009600000,
        y: 384.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524096000000,
        y: 391.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524182400000,
        y: 386,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524441600000,
        y: 384,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524528000000,
        y: 387.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524614400000,
        y: 384,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524700800000,
        y: 390.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524787200000,
        y: 393.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525046400000,
        y: 394,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525132800000,
        y: 396.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525219200000,
        y: 390.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525305600000,
        y: 391.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525392000000,
        y: 399,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525737600000,
        y: 401.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525824000000,
        y: 422,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525910400000,
        y: 422,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525996800000,
        y: 424,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526256000000,
        y: 423.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526342400000,
        y: 430.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526428800000,
        y: 431.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526515200000,
        y: 446.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526601600000,
        y: 443.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526860800000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526947200000,
        y: 445.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527033600000,
        y: 443.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527120000000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527206400000,
        y: 424.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527552000000,
        y: 419.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527638400000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527724800000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527811200000,
        y: 424.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528070400000,
        y: 425.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528156800000,
        y: 425.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528243200000,
        y: 426,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528329600000,
        y: 435.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528416000000,
        y: 436.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528675200000,
        y: 433.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528761600000,
        y: 431.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528848000000,
        y: 431.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528934400000,
        y: 424.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529020800000,
        y: 411.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529280000000,
        y: 418.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529366400000,
        y: 419,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529452800000,
        y: 421.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529539200000,
        y: 414.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529625600000,
        y: 421.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529884800000,
        y: 420.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529971200000,
        y: 426.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530057600000,
        y: 442,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530144000000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530230400000,
        y: 450.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530489600000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530576000000,
        y: 438.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530662400000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530748800000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530835200000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531094400000,
        y: 445.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531180800000,
        y: 449.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531267200000,
        y: 437.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531353600000,
        y: 424,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531440000000,
        y: 436.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531699200000,
        y: 416.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531785600000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531872000000,
        y: 412.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531958400000,
        y: 423.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532044800000,
        y: 423.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532304000000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532390400000,
        y: 430.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532476800000,
        y: 430.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532563200000,
        y: 438,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532649600000,
        y: 439.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532908800000,
        y: 440.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532995200000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533081600000,
        y: 426.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533168000000,
        y: 425.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533254400000,
        y: 424.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533513600000,
        y: 425.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533600000000,
        y: 427,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533686400000,
        y: 416.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533772800000,
        y: 416,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533859200000,
        y: 418.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534118400000,
        y: 412.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534204800000,
        y: 411,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534291200000,
        y: 400,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534377600000,
        y: 405,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534464000000,
        y: 407.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534723200000,
        y: 407.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534809600000,
        y: 409.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534896000000,
        y: 418,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534982400000,
        y: 421.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535068800000,
        y: 426.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535414400000,
        y: 425.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535500800000,
        y: 429,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535587200000,
        y: 432.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535673600000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535932800000,
        y: 431.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536019200000,
        y: 429.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536105600000,
        y: 420.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536192000000,
        y: 419.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536278400000,
        y: 420.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536537600000,
        y: 424.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536624000000,
        y: 432.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536710400000,
        y: 437,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536796800000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536883200000,
        y: 427.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537142400000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537228800000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537315200000,
        y: 434,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537401600000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537488000000,
        y: 436.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537747200000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537833600000,
        y: 453.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537920000000,
        y: 452.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538006400000,
        y: 453.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538092800000,
        y: 462.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538352000000,
        y: 468.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538438400000,
        y: 479.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538524800000,
        y: 481.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538611200000,
        y: 486.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538697600000,
        y: 484.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538956800000,
        y: 484.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539043200000,
        y: 488.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539129600000,
        y: 488,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539216000000,
        y: 478.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539302400000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539561600000,
        y: 464.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539648000000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539734400000,
        y: 462.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539820800000,
        y: 459.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539907200000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540166400000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540252800000,
        y: 453.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540339200000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540425600000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540512000000,
        y: 461.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540771200000,
        y: 460.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540857600000,
        y: 451,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540944000000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541030400000,
        y: 441.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541116800000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541376000000,
        y: 443.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541462400000,
        y: 433.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541548800000,
        y: 433.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541635200000,
        y: 433.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541721600000,
        y: 424.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541980800000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542067200000,
        y: 406.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542153600000,
        y: 408,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542240000000,
        y: 409.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542326400000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542585600000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542672000000,
        y: 394.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542758400000,
        y: 394.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542844800000,
        y: 389,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542931200000,
        y: 365,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543190400000,
        y: 373.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543276800000,
        y: 369.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543363200000,
        y: 360,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543449600000,
        y: 358.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543536000000,
        y: 354.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543795200000,
        y: 369.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543881600000,
        y: 375,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543968000000,
        y: 373.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544054400000,
        y: 349.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544140800000,
        y: 369.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544400000000,
        y: 356,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544486400000,
        y: 356.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544572800000,
        y: 350.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544659200000,
        y: 347.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544745600000,
        y: 344.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545004800000,
        y: 335.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545091200000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545177600000,
        y: 322.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545264000000,
        y: 315.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545350400000,
        y: 307,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545609600000,
        y: 307,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545868800000,
        y: 308.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545955200000,
        y: 300,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546214400000,
        y: 300,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546387200000,
        y: 324.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546473600000,
        y: 322.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546560000000,
        y: 333.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546819200000,
        y: 346,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546905600000,
        y: 345.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546992000000,
        y: 346.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547078400000,
        y: 345.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547164800000,
        y: 361,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547424000000,
        y: 352.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547510400000,
        y: 350.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547596800000,
        y: 354.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547683200000,
        y: 357,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547769600000,
        y: 368.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548028800000,
        y: 368.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548115200000,
        y: 361.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548201600000,
        y: 357.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548288000000,
        y: 363.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548374400000,
        y: 366.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548633600000,
        y: 358,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548720000000,
        y: 371.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548806400000,
        y: 374.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548892800000,
        y: 372.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548979200000,
        y: 374,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549238400000,
        y: 370.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549324800000,
        y: 381.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549411200000,
        y: 384.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549497600000,
        y: 374.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549584000000,
        y: 378.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549843200000,
        y: 373.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549929600000,
        y: 385.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550016000000,
        y: 393,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550102400000,
        y: 398.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550188800000,
        y: 401.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550448000000,
        y: 403.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550534400000,
        y: 400.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550620800000,
        y: 405.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550707200000,
        y: 406.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550793600000,
        y: 404.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551052800000,
        y: 387.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551139200000,
        y: 388.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551225600000,
        y: 403.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551312000000,
        y: 404.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551398400000,
        y: 401.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551657600000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551744000000,
        y: 405.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551830400000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551916800000,
        y: 407.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552003200000,
        y: 401.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552262400000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552348800000,
        y: 415.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552435200000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552521600000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552608000000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552867200000,
        y: 412.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552953600000,
        y: 407.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553040000000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553126400000,
        y: 407.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553212800000,
        y: 399,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553472000000,
        y: 397.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553558400000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553644800000,
        y: 403.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553731200000,
        y: 402.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553817600000,
        y: 406,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554076800000,
        y: 409.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554163200000,
        y: 412.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554249600000,
        y: 412,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554336000000,
        y: 412.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554422400000,
        y: 409.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554681600000,
        y: 416.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554768000000,
        y: 411.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554854400000,
        y: 413,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554940800000,
        y: 413,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555027200000,
        y: 414,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555286400000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555372800000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555459200000,
        y: 410.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555545600000,
        y: 411.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555977600000,
        y: 422.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556064000000,
        y: 427.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556150400000,
        y: 434,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556236800000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556496000000,
        y: 419.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556582400000,
        y: 424,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556668800000,
        y: 424.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556755200000,
        y: 416.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556841600000,
        y: 423,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557187200000,
        y: 409.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557273600000,
        y: 412,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557360000000,
        y: 405.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557446400000,
        y: 408.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557705600000,
        y: 406.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557792000000,
        y: 400,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557878400000,
        y: 408.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557964800000,
        y: 417.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558051200000,
        y: 420.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558310400000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558396800000,
        y: 431.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558483200000,
        y: 414.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558569600000,
        y: 400.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558656000000,
        y: 404,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559001600000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559088000000,
        y: 411,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559174400000,
        y: 404.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559260800000,
        y: 393.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559520000000,
        y: 380.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559606400000,
        y: 388.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559692800000,
        y: 383,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559779200000,
        y: 387,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559865600000,
        y: 387.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560124800000,
        y: 389.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560211200000,
        y: 382.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560297600000,
        y: 366.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560384000000,
        y: 366.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560470400000,
        y: 367.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560729600000,
        y: 364.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560816000000,
        y: 360.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560902400000,
        y: 364.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560988800000,
        y: 374.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561075200000,
        y: 382,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561334400000,
        y: 384,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561420800000,
        y: 390.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561507200000,
        y: 399,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561593600000,
        y: 399.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561680000000,
        y: 396.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561939200000,
        y: 391.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562025600000,
        y: 378.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562112000000,
        y: 387.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562198400000,
        y: 393,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562284800000,
        y: 396.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562544000000,
        y: 400.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562630400000,
        y: 400.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562716800000,
        y: 414.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562803200000,
        y: 418.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562889600000,
        y: 404,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563148800000,
        y: 394,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563235200000,
        y: 394.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563321600000,
        y: 381,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563408000000,
        y: 371,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563494400000,
        y: 372.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563753600000,
        y: 370.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563840000000,
        y: 364.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563926400000,
        y: 380.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564012800000,
        y: 390,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564099200000,
        y: 393.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564358400000,
        y: 397.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564444800000,
        y: 400.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564531200000,
        y: 406.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564617600000,
        y: 383.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564704000000,
        y: 377.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564963200000,
        y: 365.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565049600000,
        y: 361.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565136000000,
        y: 332.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565222400000,
        y: 336.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565308800000,
        y: 340.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565568000000,
        y: 335.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565654400000,
        y: 345.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565740800000,
        y: 315.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565827200000,
        y: 309,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565913600000,
        y: 326.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566172800000,
        y: 332.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566259200000,
        y: 322,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566345600000,
        y: 333.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566432000000,
        y: 328.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566518400000,
        y: 315.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566864000000,
        y: 318,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566950400000,
        y: 314.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567036800000,
        y: 309.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567123200000,
        y: 285.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567382400000,
        y: 275.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567468800000,
        y: 262.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567555200000,
        y: 292.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567641600000,
        y: 314.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567728000000,
        y: 324.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567987200000,
        y: 322,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568073600000,
        y: 306,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568160000000,
        y: 307,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568246400000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568332800000,
        y: 329.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568592000000,
        y: 399.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568678400000,
        y: 398.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568764800000,
        y: 361.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568851200000,
        y: 392.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568937600000,
        y: 376.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569196800000,
        y: 350.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569283200000,
        y: 356.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569369600000,
        y: 362.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569456000000,
        y: 390,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569542400000,
        y: 346.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569801600000,
        y: 346.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569888000000,
        y: 327.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569974400000,
        y: 383.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570060800000,
        y: 394,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570147200000,
        y: 341.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570406400000,
        y: 364,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570492800000,
        y: 368.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570579200000,
        y: 364.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570665600000,
        y: 347.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570752000000,
        y: 325.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571011200000,
        y: 339.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571097600000,
        y: 338.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571184000000,
        y: 353.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571270400000,
        y: 343.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571356800000,
        y: 331.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571616000000,
        y: 338.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571702400000,
        y: 339.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571788800000,
        y: 357.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571875200000,
        y: 368.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571961600000,
        y: 373,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572220800000,
        y: 356.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572307200000,
        y: 364,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572393600000,
        y: 368.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572480000000,
        y: 381.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572566400000,
        y: 390,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572825600000,
        y: 371,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572912000000,
        y: 378.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572998400000,
        y: 384.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573084800000,
        y: 356.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573171200000,
        y: 364.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573430400000,
        y: 354.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573516800000,
        y: 386,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573603200000,
        y: 357.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573689600000,
        y: 340.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573776000000,
        y: 385.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574035200000,
        y: 381,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574121600000,
        y: 371,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574208000000,
        y: 377.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574294400000,
        y: 389.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574380800000,
        y: 373.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574640000000,
        y: 366.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574726400000,
        y: 379.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574812800000,
        y: 378.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574899200000,
        y: 375.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574985600000,
        y: 370.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575244800000,
        y: 403.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575331200000,
        y: 403,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575417600000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575504000000,
        y: 400.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575590400000,
        y: 417,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575849600000,
        y: 405.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575936000000,
        y: 433.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576022400000,
        y: 424.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576108800000,
        y: 412.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576195200000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576454400000,
        y: 424,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576540800000,
        y: 448.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576627200000,
        y: 448.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576713600000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576800000000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577059200000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577145600000,
        y: 447.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577404800000,
        y: 457.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577664000000,
        y: 461.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577750400000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577923200000,
        y: 469.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578009600000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578268800000,
        y: 493,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578355200000,
        y: 493,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578441600000,
        y: 481,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578528000000,
        y: 479.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578614400000,
        y: 471,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578873600000,
        y: 474.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578960000000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579046400000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579132800000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579219200000,
        y: 445.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579478400000,
        y: 453.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579564800000,
        y: 444.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579651200000,
        y: 429.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579737600000,
        y: 417.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579824000000,
        y: 402.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580083200000,
        y: 393.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580169600000,
        y: 409.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580256000000,
        y: 414.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580342400000,
        y: 404.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580428800000,
        y: 392.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580688000000,
        y: 367,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580774400000,
        y: 361.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580860800000,
        y: 376.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580947200000,
        y: 377.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581033600000,
        y: 376.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581292800000,
        y: 360.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581379200000,
        y: 363.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581465600000,
        y: 369.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581552000000,
        y: 375.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581638400000,
        y: 373.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581897600000,
        y: 375,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581984000000,
        y: 370.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582070400000,
        y: 372.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582156800000,
        y: 373.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582243200000,
        y: 364.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582502400000,
        y: 338.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582588800000,
        y: 335,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582675200000,
        y: 324.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582761600000,
        y: 311.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582848000000,
        y: 294.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583107200000,
        y: 288.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583193600000,
        y: 321.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583280000000,
        y: 323.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583366400000,
        y: 307.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583452800000,
        y: 265.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583712000000,
        y: 205.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583798400000,
        y: 230.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583884800000,
        y: 227.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583971200000,
        y: 201.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584057600000,
        y: 199.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584316800000,
        y: 174,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584403200000,
        y: 161.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584489600000,
        y: 141.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584576000000,
        y: 153,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584662400000,
        y: 158.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584921600000,
        y: 147.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585008000000,
        y: 162.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585094400000,
        y: 163,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585180800000,
        y: 165,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585267200000,
        y: 144.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585526400000,
        y: 145.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585612800000,
        y: 151.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585699200000,
        y: 139.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585785600000,
        y: 137,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585872000000,
        y: 165.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586131200000,
        y: 172.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586217600000,
        y: 177,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586304000000,
        y: 174.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586390400000,
        y: 172.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586822400000,
        y: 171.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586908800000,
        y: 153.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586995200000,
        y: 154,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587081600000,
        y: 157.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587340800000,
        y: 165.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587427200000,
        y: 125.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587513600000,
        y: 126,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587600000000,
        y: 127.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587686400000,
        y: 118.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587945600000,
        y: 109.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588032000000,
        y: 111.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588118400000,
        y: 116.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588204800000,
        y: 127.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588291200000,
        y: 131.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588550400000,
        y: 125.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588636800000,
        y: 159.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588723200000,
        y: 152,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588809600000,
        y: 159.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589155200000,
        y: 164,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589241600000,
        y: 160.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589328000000,
        y: 165,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589414400000,
        y: 170,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589500800000,
        y: 178.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589760000000,
        y: 196.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589846400000,
        y: 196.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589932800000,
        y: 198,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590019200000,
        y: 203.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590105600000,
        y: 196.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590451200000,
        y: 199.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590537600000,
        y: 192,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590624000000,
        y: 199.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590710400000,
        y: 202.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590969600000,
        y: 203.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591056000000,
        y: 221.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591142400000,
        y: 221.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591228800000,
        y: 227,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591315200000,
        y: 241.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591574400000,
        y: 238,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591660800000,
        y: 238,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591747200000,
        y: 238.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591833600000,
        y: 221.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591920000000,
        y: 227.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592179200000,
        y: 224.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592265600000,
        y: 235.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592352000000,
        y: 243.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592438400000,
        y: 246,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592524800000,
        y: 258.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592784000000,
        y: 260.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592870400000,
        y: 260.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592956800000,
        y: 244,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593043200000,
        y: 244,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593129600000,
        y: 239.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593388800000,
        y: 244,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593475200000,
        y: 242.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593561600000,
        y: 250.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593648000000,
        y: 255.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593734400000,
        y: 254.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593993600000,
        y: 263.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594080000000,
        y: 262.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594166400000,
        y: 257.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594252800000,
        y: 255.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594339200000,
        y: 259.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594598400000,
        y: 257,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594684800000,
        y: 256.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594771200000,
        y: 259.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594857600000,
        y: 256.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594944000000,
        y: 253,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595203200000,
        y: 252.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595289600000,
        y: 262.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595376000000,
        y: 257.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595462400000,
        y: 259.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595548800000,
        y: 254.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595808000000,
        y: 254,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595894400000,
        y: 256.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595980800000,
        y: 262.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596067200000,
        y: 254,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596153600000,
        y: 259.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596412800000,
        y: 266,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596499200000,
        y: 264.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596585600000,
        y: 272.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596672000000,
        y: 269.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596758400000,
        y: 266.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597017600000,
        y: 269.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597104000000,
        y: 272.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597190400000,
        y: 274.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597276800000,
        y: 272.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597363200000,
        y: 269.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597622400000,
        y: 269.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597708800000,
        y: 277.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597795200000,
        y: 276.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597881600000,
        y: 271.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597968000000,
        y: 268,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598227200000,
        y: 273.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598313600000,
        y: 280,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598400000000,
        y: 277.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598486400000,
        y: 271,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598572800000,
        y: 276,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598832000000,
        y: 276,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598918400000,
        y: 277.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599004800000,
        y: 275.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599091200000,
        y: 259.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599177600000,
        y: 257,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599436800000,
        y: 252,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599523200000,
        y: 238.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599609600000,
        y: 243,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599696000000,
        y: 245.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599782400000,
        y: 242.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600041600000,
        y: 243,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600128000000,
        y: 247.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600214400000,
        y: 259.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600300800000,
        y: 270,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600387200000,
        y: 270.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600646400000,
        y: 258,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600732800000,
        y: 258.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600819200000,
        y: 260.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600905600000,
        y: 258,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600992000000,
        y: 262.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601251200000,
        y: 264,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601337600000,
        y: 258.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601424000000,
        y: 265,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601510400000,
        y: 254,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601596800000,
        y: 260.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601856000000,
        y: 270.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601942400000,
        y: 283.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602028800000,
        y: 274.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602115200000,
        y: 280,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602201600000,
        y: 282.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602460800000,
        y: 263.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602547200000,
        y: 270,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602633600000,
        y: 278.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602720000000,
        y: 279.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602806400000,
        y: 286.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603065600000,
        y: 284.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603152000000,
        y: 286,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603238400000,
        y: 274.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603324800000,
        y: 280.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603411200000,
        y: 279.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603670400000,
        y: 268.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603756800000,
        y: 277,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603843200000,
        y: 263.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603929600000,
        y: 252.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604016000000,
        y: 251.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604275200000,
        y: 257,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604361600000,
        y: 263.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604448000000,
        y: 271.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604534400000,
        y: 271,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604620800000,
        y: 273.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604880000000,
        y: 286.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604966400000,
        y: 294,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605052800000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605139200000,
        y: 301.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605225600000,
        y: 287,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605484800000,
        y: 292.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605571200000,
        y: 287.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605657600000,
        y: 288.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605744000000,
        y: 295.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605830400000,
        y: 298.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606089600000,
        y: 301.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606176000000,
        y: 310.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606262400000,
        y: 315,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606348800000,
        y: 314.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606435200000,
        y: 314.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606694400000,
        y: 310.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606780800000,
        y: 309.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606867200000,
        y: 316.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606953600000,
        y: 317.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607040000000,
        y: 320.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607299200000,
        y: 318,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607385600000,
        y: 318.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607472000000,
        y: 319.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607558400000,
        y: 324.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607644800000,
        y: 320.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607904000000,
        y: 320.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607990400000,
        y: 327.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608076800000,
        y: 328.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608163200000,
        y: 330.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608249600000,
        y: 334.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608508800000,
        y: 321.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608595200000,
        y: 318.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608681600000,
        y: 327.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608768000000,
        y: 325.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609200000000,
        y: 327.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609286400000,
        y: 326.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609372800000,
        y: 323.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609718400000,
        y: 321.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609804800000,
        y: 337.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609891200000,
        y: 364.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609977600000,
        y: 347.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610064000000,
        y: 353.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610323200000,
        y: 356,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610409600000,
        y: 366,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610496000000,
        y: 362.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610582400000,
        y: 359.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610668800000,
        y: 356,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610928000000,
        y: 357,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611014400000,
        y: 363.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611100800000,
        y: 365,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611187200000,
        y: 363,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611273600000,
        y: 363.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611532800000,
        y: 364,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611619200000,
        y: 367.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611705600000,
        y: 367,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611792000000,
        y: 367.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611878400000,
        y: 371.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612137600000,
        y: 368.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612224000000,
        y: 383,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612310400000,
        y: 388.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612396800000,
        y: 396.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612483200000,
        y: 404,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612742400000,
        y: 410.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612828800000,
        y: 412.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612915200000,
        y: 415,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613001600000,
        y: 412.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613088000000,
        y: 412.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613347200000,
        y: 425,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613433600000,
        y: 417.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613520000000,
        y: 417.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613606400000,
        y: 424,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613692800000,
        y: 417.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613952000000,
        y: 419.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614038400000,
        y: 426.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614124800000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614211200000,
        y: 440.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614297600000,
        y: 433.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614556800000,
        y: 433.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614643200000,
        y: 422.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614729600000,
        y: 428,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614816000000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614902400000,
        y: 456.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615161600000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615248000000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615334400000,
        y: 436,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615420800000,
        y: 442.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615507200000,
        y: 445.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615766400000,
        y: 443.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615852800000,
        y: 448,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615939200000,
        y: 431.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616025600000,
        y: 417.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616112000000,
        y: 410.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616371200000,
        y: 415.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616457600000,
        y: 402,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616544000000,
        y: 411.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616630400000,
        y: 396,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616716800000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617062400000,
        y: 409.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617148800000,
        y: 408,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617235200000,
        y: 399.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617667200000,
        y: 404.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617753600000,
        y: 395,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617840000000,
        y: 398,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617926400000,
        y: 398.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618185600000,
        y: 400.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618272000000,
        y: 404.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618358400000,
        y: 422.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618444800000,
        y: 422.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618531200000,
        y: 422.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618790400000,
        y: 423,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618876800000,
        y: 414,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618963200000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619049600000,
        y: 410,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619136000000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619395200000,
        y: 411.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619481600000,
        y: 411,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619568000000,
        y: 421,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619654400000,
        y: 423.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619740800000,
        y: 414.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620086400000,
        y: 429,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620172800000,
        y: 433.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620259200000,
        y: 427.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620345600000,
        y: 427,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620604800000,
        y: 425,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620691200000,
        y: 427,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620777600000,
        y: 435,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620864000000,
        y: 417.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620950400000,
        y: 419.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621209600000,
        y: 420,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621296000000,
        y: 414,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621382400000,
        y: 395.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621468800000,
        y: 406.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621555200000,
        y: 406.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621814400000,
        y: 410,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621900800000,
        y: 410.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621987200000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622073600000,
        y: 419.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622160000000,
        y: 424.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622505600000,
        y: 427.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622592000000,
        y: 433,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622678400000,
        y: 433.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622764800000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623024000000,
        y: 436.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623110400000,
        y: 435.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623196800000,
        y: 437.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623283200000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623369600000,
        y: 435.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623628800000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623715200000,
        y: 444.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623801600000,
        y: 451,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623888000000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623974400000,
        y: 443.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624233600000,
        y: 447.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624320000000,
        y: 447.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624406400000,
        y: 456.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624492800000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624579200000,
        y: 458.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624838400000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624924800000,
        y: 458,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625011200000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625097600000,
        y: 463.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625184000000,
        y: 466.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625443200000,
        y: 472,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625529600000,
        y: 456.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625616000000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625702400000,
        y: 450.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625788800000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626048000000,
        y: 462.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626134400000,
        y: 465.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626220800000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626307200000,
        y: 458,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626393600000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626652800000,
        y: 426.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626739200000,
        y: 428,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626825600000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626912000000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626998400000,
        y: 454.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627257600000,
        y: 454.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627344000000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627430400000,
        y: 456.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627516800000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627603200000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627862400000,
        y: 452.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627948800000,
        y: 449,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628035200000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628121600000,
        y: 441.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628208000000,
        y: 438,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628467200000,
        y: 425,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628553600000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628640000000,
        y: 433.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628726400000,
        y: 437.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628812800000,
        y: 437.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629072000000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629158400000,
        y: 430.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629244800000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629331200000,
        y: 407.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629417600000,
        y: 409.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629676800000,
        y: 429,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629763200000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629849600000,
        y: 440.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629936000000,
        y: 440.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630022400000,
        y: 447.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630368000000,
        y: 450.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630454400000,
        y: 442.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630540800000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630627200000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630886400000,
        y: 456.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630972800000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631059200000,
        y: 467,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631145600000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631232000000,
        y: 463.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631491200000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631577600000,
        y: 468.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631664000000,
        y: 480,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631750400000,
        y: 476.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631836800000,
        y: 475.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632096000000,
        y: 474,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632182400000,
        y: 465.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632268800000,
        y: 479.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632355200000,
        y: 492.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632441600000,
        y: 495.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632700800000,
        y: 503.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632787200000,
        y: 497.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632873600000,
        y: 496.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632960000000,
        y: 498.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633046400000,
        y: 497.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633305600000,
        y: 516.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633392000000,
        y: 527.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633478400000,
        y: 516.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633564800000,
        y: 519,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633651200000,
        y: 521,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633910400000,
        y: 532,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633996800000,
        y: 527.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634083200000,
        y: 525.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634169600000,
        y: 524.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634256000000,
        y: 535,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634515200000,
        y: 527.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634601600000,
        y: 533.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634688000000,
        y: 533.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634774400000,
        y: 523.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634860800000,
        y: 533.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635120000000,
        y: 541.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635206400000,
        y: 532.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635292800000,
        y: 522.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635379200000,
        y: 514,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635465600000,
        y: 519.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635724800000,
        y: 525.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635811200000,
        y: 524.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635897600000,
        y: 507.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635984000000,
        y: 506,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636070400000,
        y: 512,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636329600000,
        y: 516.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636416000000,
        y: 518.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636502400000,
        y: 517.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636588800000,
        y: 507.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636675200000,
        y: 510.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636934400000,
        y: 504.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637020800000,
        y: 507.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637107200000,
        y: 505,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637193600000,
        y: 502.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637280000000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637539200000,
        y: 494.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637625600000,
        y: 508,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637712000000,
        y: 511.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637798400000,
        y: 510,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637884800000,
        y: 460.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638144000000,
        y: 473.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638230400000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638316800000,
        y: 453.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638403200000,
        y: 449.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638489600000,
        y: 451,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638748800000,
        y: 454,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638835200000,
        y: 479.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638921600000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639008000000,
        y: 477,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639094400000,
        y: 473.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639353600000,
        y: 476.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639440000000,
        y: 471,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639526400000,
        y: 462,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639612800000,
        y: 476,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639699200000,
        y: 468.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639958400000,
        y: 444.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640044800000,
        y: 464.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640131200000,
        y: 466.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640217600000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640304000000,
        y: 473.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640736000000,
        y: 490.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640822400000,
        y: 490.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640908800000,
        y: 489.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641254400000,
        y: 491.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641340800000,
        y: 503.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641427200000,
        y: 510.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641513600000,
        y: 509.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641772800000,
        y: 506,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641859200000,
        y: 515.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641945600000,
        y: 522.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642032000000,
        y: 519.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642118400000,
        y: 526.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642377600000,
        y: 532.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642464000000,
        y: 533.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642550400000,
        y: 544.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642636800000,
        y: 550.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642723200000,
        y: 547,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642982400000,
        y: 531.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643068800000,
        y: 540,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643155200000,
        y: 555.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643241600000,
        y: 551.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643328000000,
        y: 558.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643587200000,
        y: 553,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643673600000,
        y: 558.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643760000000,
        y: 554.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643846400000,
        y: 569.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643932800000,
        y: 593.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644192000000,
        y: 587,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644278400000,
        y: 566.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644364800000,
        y: 585.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644451200000,
        y: 590,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644537600000,
        y: 592.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644796800000,
        y: 593.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644883200000,
        y: 581,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644969600000,
        y: 590.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645056000000,
        y: 571.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645142400000,
        y: 575.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645401600000,
        y: 585.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645488000000,
        y: 593.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645574400000,
        y: 591.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645660800000,
        y: 618.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645747200000,
        y: 579.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646006400000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646092800000,
        y: 663.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646179200000,
        y: 669.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646265600000,
        y: 686.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646352000000,
        y: 714.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646611200000,
        y: 762.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646697600000,
        y: 799,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646784000000,
        y: 756.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646870400000,
        y: 723.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646956800000,
        y: 690.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647216000000,
        y: 654.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647302400000,
        y: 634,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647388800000,
        y: 618.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647475200000,
        y: 647.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647561600000,
        y: 643.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647820800000,
        y: 686.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650326400000,
        y: 639,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650412800000,
        y: 647.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650499200000,
        y: 651,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650585600000,
        y: 646.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650844800000,
        y: 602.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650931200000,
        y: 631,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651017600000,
        y: 630.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651104000000,
        y: 645.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651190400000,
        y: 650,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651536000000,
        y: 630.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651622400000,
        y: 646.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651708800000,
        y: 651.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651795200000,
        y: 652.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652054400000,
        y: 610.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652140800000,
        y: 597,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652227200000,
        y: 618,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652313600000,
        y: 623,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652400000000,
        y: 630.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652659200000,
        y: 647.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652745600000,
        y: 660.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652832000000,
        y: 625.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652918400000,
        y: 625,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653004800000,
        y: 642.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653264000000,
        y: 652.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653350400000,
        y: 650.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653436800000,
        y: 647.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653523200000,
        y: 663,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653609600000,
        y: 651.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653868800000,
        y: 658.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653955200000,
        y: 680,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654041600000,
        y: 668.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654473600000,
        y: 672.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654560000000,
        y: 674.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654646400000,
        y: 689.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654732800000,
        y: 686.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654819200000,
        y: 678.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655078400000,
        y: 689.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655164800000,
        y: 706.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655251200000,
        y: 679.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655337600000,
        y: 656,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655424000000,
        y: 639.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655683200000,
        y: 633.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655769600000,
        y: 635.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655856000000,
        y: 612.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655942400000,
        y: 620,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656028800000,
        y: 631.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656288000000,
        y: 633.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656374400000,
        y: 641.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656460800000,
        y: 647,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656547200000,
        y: 621,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656633600000,
        y: 619.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656892800000,
        y: 629,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656979200000,
        y: 553,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657065600000,
        y: 552.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657152000000,
        y: 587.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657238400000,
        y: 590,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657497600000,
        y: 584.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657584000000,
        y: 538.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657670400000,
        y: 538.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657756800000,
        y: 523.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658102400000,
        y: 575.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658188800000,
        y: 570.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658275200000,
        y: 578.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658361600000,
        y: 576.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658448000000,
        y: 596.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658707200000,
        y: 573,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658793600000,
        y: 569.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658880000000,
        y: 576,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658966400000,
        y: 583.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659052800000,
        y: 601.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659312000000,
        y: 579.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659398400000,
        y: 599.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659484800000,
        y: 587.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659571200000,
        y: 563.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659657600000,
        y: 572.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659916800000,
        y: 570.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660003200000,
        y: 571.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660176000000,
        y: 593.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660262400000,
        y: 587,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660521600000,
        y: 560.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660694400000,
        y: 553.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660780800000,
        y: 569.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661126400000,
        y: 549.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661212800000,
        y: 578.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661299200000,
        y: 577,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661385600000,
        y: 587.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661472000000,
        y: 573,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661817600000,
        y: 565.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661904000000,
        y: 550.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661990400000,
        y: 536,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662076800000,
        y: 545.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662336000000,
        y: 559.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662422400000,
        y: 539.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662508800000,
        y: 514.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662595200000,
        y: 524.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662681600000,
        y: 534.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662940800000,
        y: 550.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663027200000,
        y: 531,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663113600000,
        y: 546.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663200000000,
        y: 518.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663286400000,
        y: 533,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663718400000,
        y: 512.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663804800000,
        y: 525.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663891200000,
        y: 504,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664150400000,
        y: 499.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664236800000,
        y: 498.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664323200000,
        y: 505,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664409600000,
        y: 514,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664496000000,
        y: 505.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664755200000,
        y: 511.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664841600000,
        y: 534.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664928000000,
        y: 547.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665014400000,
        y: 543,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665100800000,
        y: 564.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665360000000,
        y: 557.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665446400000,
        y: 536.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665532800000,
        y: 512.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665619200000,
        y: 516,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665705600000,
        y: 509.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665964800000,
        y: 500.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666051200000,
        y: 496.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666137600000,
        y: 514.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666224000000,
        y: 510,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666310400000,
        y: 500.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666569600000,
        y: 508,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666656000000,
        y: 502.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666742400000,
        y: 525.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666828800000,
        y: 532.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666915200000,
        y: 530,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667174400000,
        y: 521.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667260800000,
        y: 528.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667347200000,
        y: 527.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667433600000,
        y: 518.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667520000000,
        y: 529.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667779200000,
        y: 541.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667865600000,
        y: 529.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667952000000,
        y: 509.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668038400000,
        y: 512.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668124800000,
        y: 507.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668384000000,
        y: 502.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668470400000,
        y: 504,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668556800000,
        y: 485.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668729600000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668988800000,
        y: 427,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669075200000,
        y: 442.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669161600000,
        y: 417.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669248000000,
        y: 430,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669334400000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669593600000,
        y: 442.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669680000000,
        y: 437.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669766400000,
        y: 447.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669852800000,
        y: 463.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669939200000,
        y: 450.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670198400000,
        y: 441.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670284800000,
        y: 422,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670371200000,
        y: 419.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670803200000,
        y: 400.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670889600000,
        y: 419.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670976000000,
        y: 425.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671062400000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671148800000,
        y: 399.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671494400000,
        y: 416.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671580800000,
        y: 435.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671667200000,
        y: 441.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671753600000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672185600000,
        y: 429.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672272000000,
        y: 425.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672704000000,
        y: 428,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672790400000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672963200000,
        y: 407.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673222400000,
        y: 403.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673308800000,
        y: 413.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673395200000,
        y: 426,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673481600000,
        y: 434,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673568000000,
        y: 438,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673827200000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673913600000,
        y: 452,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674000000000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674172800000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674432000000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674518400000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674604800000,
        y: 458.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674691200000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674777600000,
        y: 456.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675036800000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675123200000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675209600000,
        y: 453.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675296000000,
        y: 442.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675382400000,
        y: 435.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675641600000,
        y: 432.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675728000000,
        y: 450.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675814400000,
        y: 461.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675900800000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675987200000,
        y: 477.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676246400000,
        y: 474,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676332800000,
        y: 468.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676419200000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676505600000,
        y: 466,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676592000000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676851200000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676937600000,
        y: 443.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677024000000,
        y: 432.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677110400000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677456000000,
        y: 439.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677542400000,
        y: 443.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677628800000,
        y: 448.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677801600000,
        y: 461.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678060800000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678147200000,
        y: 454,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678233600000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678406400000,
        y: 441.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678752000000,
        y: 423,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678838400000,
        y: 383.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678924800000,
        y: 390.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679011200000,
        y: 384,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679270400000,
        y: 384.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679356800000,
        y: 393,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679443200000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679529600000,
        y: 410,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679616000000,
        y: 400,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679875200000,
        y: 414,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679961600000,
        y: 431.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680048000000,
        y: 430.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680134400000,
        y: 436.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680220800000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680566400000,
        y: 463.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680652800000,
        y: 467,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680739200000,
        y: 464.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681171200000,
        y: 471.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681257600000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681344000000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681430400000,
        y: 473.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681689600000,
        y: 469.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681776000000,
        y: 475.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681948800000,
        y: 453.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682035200000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682294400000,
        y: 470.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682380800000,
        y: 457.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682467200000,
        y: 456.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682553600000,
        y: 452,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682640000000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682985600000,
        y: 435.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683072000000,
        y: 418.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683158400000,
        y: 405.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683244800000,
        y: 424.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683590400000,
        y: 433.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683676800000,
        y: 437.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683763200000,
        y: 426.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683849600000,
        y: 426.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684108800000,
        y: 427.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684195200000,
        y: 422.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684281600000,
        y: 433.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684368000000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684454400000,
        y: 434,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684713600000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684800000000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684886400000,
        y: 442.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684972800000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685059200000,
        y: 437.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685404800000,
        y: 421.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685491200000,
        y: 418,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685577600000,
        y: 432,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685664000000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685923200000,
        y: 435.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686009600000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686096000000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686182400000,
        y: 436.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686268800000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686528000000,
        y: 424.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686614400000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686700800000,
        y: 434,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686787200000,
        y: 441.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686873600000,
        y: 444.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687132800000,
        y: 441.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687219200000,
        y: 437.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687305600000,
        y: 451.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687392000000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687478400000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687737600000,
        y: 435.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687824000000,
        y: 430,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687910400000,
        y: 437.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687996800000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688083200000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688342400000,
        y: 452.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688428800000,
        y: 449.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688515200000,
        y: 452.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688601600000,
        y: 431.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688688000000,
        y: 458.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688947200000,
        y: 467.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689033600000,
        y: 471.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689120000000,
        y: 470.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689206400000,
        y: 471.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689292800000,
        y: 471.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689552000000,
        y: 463.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689638400000,
        y: 466.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689724800000,
        y: 473,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689811200000,
        y: 469.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689897600000,
        y: 476.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690156800000,
        y: 487,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690243200000,
        y: 489.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690329600000,
        y: 492.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690416000000,
        y: 499.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690502400000,
        y: 509.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690761600000,
        y: 520.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690848000000,
        y: 520.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690934400000,
        y: 520.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691020800000,
        y: 522,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691107200000,
        y: 534,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691366400000,
        y: 523.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691452800000,
        y: 518.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691539200000,
        y: 535.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691625600000,
        y: 539.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691712000000,
        y: 539.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691971200000,
        y: 534.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692057600000,
        y: 529,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692144000000,
        y: 527.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692230400000,
        y: 526.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692316800000,
        y: 530,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692576000000,
        y: 532.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692662400000,
        y: 527.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692748800000,
        y: 519.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692835200000,
        y: 514,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692921600000,
        y: 521,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693267200000,
        y: 526.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693353600000,
        y: 525.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693440000000,
        y: 534.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693526400000,
        y: 547.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693785600000,
        y: 539,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693872000000,
        y: 554.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693958400000,
        y: 548,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694044800000,
        y: 552.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694131200000,
        y: 550.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694390400000,
        y: 541.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694476800000,
        y: 564.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694563200000,
        y: 561,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694649600000,
        y: 568.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694736000000,
        y: 565.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694995200000,
        y: 567,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695081600000,
        y: 558.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695168000000,
        y: 555.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695254400000,
        y: 558.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695340800000,
        y: 558.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695600000000,
        y: 551.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695686400000,
        y: 554,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695772800000,
        y: 564,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695859200000,
        y: 551,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695945600000,
        y: 535.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696204800000,
        y: 505,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696291200000,
        y: 523.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696377600000,
        y: 506.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696464000000,
        y: 478.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696550400000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696809600000,
        y: 509.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696896000000,
        y: 502.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696982400000,
        y: 493.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697068800000,
        y: 497,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697155200000,
        y: 518.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697414400000,
        y: 518.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697500800000,
        y: 518,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697587200000,
        y: 535,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697673600000,
        y: 532.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697760000000,
        y: 536.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698019200000,
        y: 528,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698105600000,
        y: 510.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698192000000,
        y: 509.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698278400000,
        y: 506.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698364800000,
        y: 506,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698624000000,
        y: 506.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698710400000,
        y: 512.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698796800000,
        y: 512,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698883200000,
        y: 507.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698969600000,
        y: 498.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699228800000,
        y: 503.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699315200000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699401600000,
        y: 466.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699488000000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699574400000,
        y: 472,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699833600000,
        y: 479,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699920000000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700006400000,
        y: 479.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700092800000,
        y: 454.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700179200000,
        y: 464.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700438400000,
        y: 486.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700524800000,
        y: 478.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700611200000,
        y: 461.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700697600000,
        y: 472,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700784000000,
        y: 481,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701043200000,
        y: 471.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701129600000,
        y: 478.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701216000000,
        y: 479.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701302400000,
        y: 459.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701388800000,
        y: 462.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701648000000,
        y: 454.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701734400000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701820800000,
        y: 428.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701907200000,
        y: 425.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701993600000,
        y: 439,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702252800000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702339200000,
        y: 430.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702425600000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702512000000,
        y: 452.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702598400000,
        y: 448.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702857600000,
        y: 461.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702944000000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703030400000,
        y: 477,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703116800000,
        y: 465.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703203200000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703635200000,
        y: 466.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703721600000,
        y: 453.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703808000000,
        y: 446.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704153600000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704240000000,
        y: 471,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704326400000,
        y: 458.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704412800000,
        y: 468.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704672000000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704758400000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704844800000,
        y: 453,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704931200000,
        y: 468.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705017600000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705276800000,
        y: 462,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705363200000,
        y: 464.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705449600000,
        y: 462.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705536000000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705622400000,
        y: 465.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705881600000,
        y: 470.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705968000000,
        y: 470.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706054400000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706140800000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706227200000,
        y: 478.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706486400000,
        y: 479.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706572800000,
        y: 487.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706659200000,
        y: 467.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706745600000,
        y: 452.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706832000000,
        y: 445.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707091200000,
        y: 444,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707177600000,
        y: 453.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707264000000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707350400000,
        y: 464.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707436800000,
        y: 462.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707696000000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707782400000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707868800000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707955200000,
        y: 471.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708041600000,
        y: 478.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708300800000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708387200000,
        y: 470.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708473600000,
        y: 476.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708560000000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708646400000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708905600000,
        y: 475.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708992000000,
        y: 476,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1709078400000,
        y: 473.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710374400000,
        y: 496,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710460800000,
        y: 499.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710720000000,
        y: 508.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710806400000,
        y: 510,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710892800000,
        y: 502.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710979200000,
        y: 498,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711065600000,
        y: 503.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711324800000,
        y: 513.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711411200000,
        y: 511.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711497600000,
        y: 508.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711584000000,
        y: 513.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712016000000,
        y: 523.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712102400000,
        y: 524,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712188800000,
        y: 515.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712275200000,
        y: 527,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712534400000,
        y: 516,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712620800000,
        y: 512.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712707200000,
        y: 506,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712793600000,
        y: 508.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712880000000,
        y: 518.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713139200000,
        y: 511.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713225600000,
        y: 518.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713312000000,
        y: 512,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713398400000,
        y: 505.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713484800000,
        y: 504.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713744000000,
        y: 499.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713830400000,
        y: 501.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713916800000,
        y: 501,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714003200000,
        y: 504,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714089600000,
        y: 511.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714348800000,
        y: 509,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714435200000,
        y: 509,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714608000000,
        y: 487,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714694400000,
        y: 481.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715040000000,
        y: 483.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715126400000,
        y: 483.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715212800000,
        y: 480,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715299200000,
        y: 481.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715558400000,
        y: 474,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715644800000,
        y: 467.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715731200000,
        y: 471.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715817600000,
        y: 471.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715904000000,
        y: 472,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716163200000,
        y: 477.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716249600000,
        y: 477.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716336000000,
        y: 466.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716422400000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716508800000,
        y: 466.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716854400000,
        y: 484.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716940800000,
        y: 484,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717027200000,
        y: 479.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717113600000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717372800000,
        y: 456.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717459200000,
        y: 454.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717545600000,
        y: 448,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717632000000,
        y: 457.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717718400000,
        y: 459.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717977600000,
        y: 468.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718064000000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718150400000,
        y: 470.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718236800000,
        y: 470.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718323200000,
        y: 472.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718582400000,
        y: 478.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718668800000,
        y: 476.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718755200000,
        y: 490.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718841600000,
        y: 488,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718928000000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719187200000,
        y: 494,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719273600000,
        y: 490.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719360000000,
        y: 489.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719446400000,
        y: 500,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719532800000,
        y: 497,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719792000000,
        y: 501.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719878400000,
        y: 519.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719964800000,
        y: 512.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720051200000,
        y: 514.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720137600000,
        y: 517.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720396800000,
        y: 509.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720483200000,
        y: 500.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720569600000,
        y: 497.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720656000000,
        y: 493,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720742400000,
        y: 498.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721001600000,
        y: 495.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721088000000,
        y: 491.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721174400000,
        y: 493,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721260800000,
        y: 484.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721347200000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721692800000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721779200000,
        y: 474.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721865600000,
        y: 468.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721952000000,
        y: 461.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722211200000,
        y: 462.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722297600000,
        y: 455.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722384000000,
        y: 466,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722470400000,
        y: 459.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722556800000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722816000000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722902400000,
        y: 444.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722988800000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723075200000,
        y: 458.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723161600000,
        y: 461.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723420800000,
        y: 469.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723507200000,
        y: 466,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723593600000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723680000000,
        y: 473.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723766400000,
        y: 464.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724025600000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724112000000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724198400000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724284800000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724371200000,
        y: 468.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724716800000,
        y: 472,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724803200000,
        y: 462.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724889600000,
        y: 468.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724976000000,
        y: 453.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725235200000,
        y: 449.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725321600000,
        y: 443.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725408000000,
        y: 450.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725494400000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725580800000,
        y: 436.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725840000000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725926400000,
        y: 412,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726012800000,
        y: 416.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726099200000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726185600000,
        y: 425,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726444800000,
        y: 428.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726531200000,
        y: 430.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726617600000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726704000000,
        y: 440.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726790400000,
        y: 444.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1727049600000,
        y: 438.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1727136000000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729209600000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729468800000,
        y: 449,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729555200000,
        y: 472.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729641600000,
        y: 469.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729728000000,
        y: 461.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729814400000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730073600000,
        y: 449.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730160000000,
        y: 435.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730246400000,
        y: 449.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730332800000,
        y: 456.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730419200000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730678400000,
        y: 456.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730764800000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730851200000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730937600000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731024000000,
        y: 465.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731283200000,
        y: 453.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731369600000,
        y: 454.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731456000000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731542400000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731628800000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731888000000,
        y: 454,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731974400000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732060800000,
        y: 456.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732147200000,
        y: 456.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732233600000,
        y: 466.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732492800000,
        y: 451,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732579200000,
        y: 456.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732665600000,
        y: 453.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732752000000,
        y: 447.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732838400000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733097600000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733184000000,
        y: 453.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733270400000,
        y: 456.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733356800000,
        y: 451.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733443200000,
        y: 457.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733702400000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733788800000,
        y: 458.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733875200000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733961600000,
        y: 454.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734048000000,
        y: 461.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734307200000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734393600000,
        y: 451.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734480000000,
        y: 464.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734566400000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734652800000,
        y: 453.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734912000000,
        y: 448.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734998400000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735257600000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735516800000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735603200000,
        y: 459.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735776000000,
        y: 465.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735862400000,
        y: 468.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736121600000,
        y: 464.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736208000000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736294400000,
        y: 462.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736380800000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736467200000,
        y: 477.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736726400000,
        y: 491,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736812800000,
        y: 481,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736899200000,
        y: 486,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736985600000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737072000000,
        y: 483.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737331200000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737417600000,
        y: 481.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737504000000,
        y: 472,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737590400000,
        y: 464.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737676800000,
        y: 463,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737936000000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738022400000,
        y: 452,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738108800000,
        y: 456.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738195200000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738281600000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738540800000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738627200000,
        y: 466.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738713600000,
        y: 462.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738800000000,
        y: 470.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738886400000,
        y: 472.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1739145600000,
        y: 477.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1739232000000,
        y: 484.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1739318400000,
        y: 473.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1739404800000,
        y: 469.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      }
    ],
    id: 'mock2-Mid',
    marker: {
      radius: 1,
      symbol: 'circle'
    },
    metadata: {
      decimalPlaces: 2,
      hideName: true,
      field: 'Mid',
      combinedIndex: 1,
      Code: 'GX0000090',
      Period: '1',
      PeriodType: 'Prompt',
      TimeRef: '1630',
      type: 'Last',
      TimeZone: 'Europe/London',
      Commodity: 'Fuel Oil',
      PricingBasis: 'Flat',
      Alias: 'FO1NWEC',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_Europe',
      Title: 'Fuel Oil 1% NWE FOB Cargoes',
      TradingHub: 'NWE',
      DeliveryBasis: 'FOB Cargoes',
      Source: 'GX',
      Units: 'MT'
    },
    color: '#6363DF'
  },
  {
    showInNavigator: true,
    name: 'mock3',
    data: [
      {
        x: 1232323200000,
        y: 262.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232409600000,
        y: 267.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232496000000,
        y: 271.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232582400000,
        y: 268.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232668800000,
        y: 279.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1232928000000,
        y: 291,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233014400000,
        y: 275.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233100800000,
        y: 278.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233187200000,
        y: 280.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233273600000,
        y: 281.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233532800000,
        y: 277.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233619200000,
        y: 277.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233705600000,
        y: 278,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233792000000,
        y: 280,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1233878400000,
        y: 279,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234137600000,
        y: 281.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234224000000,
        y: 275.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234310400000,
        y: 269.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234396800000,
        y: 265.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234483200000,
        y: 271.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234742400000,
        y: 269.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234828800000,
        y: 259.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1234915200000,
        y: 256.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235001600000,
        y: 265,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235088000000,
        y: 265.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235347200000,
        y: 263.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235433600000,
        y: 268,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235520000000,
        y: 274.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235606400000,
        y: 289,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235692800000,
        y: 287.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1235952000000,
        y: 275.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236038400000,
        y: 275.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236124800000,
        y: 291.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236211200000,
        y: 281.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236297600000,
        y: 283.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236556800000,
        y: 289,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236643200000,
        y: 286,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236729600000,
        y: 275.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236816000000,
        y: 282.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1236902400000,
        y: 288.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237161600000,
        y: 288.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237248000000,
        y: 296,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237334400000,
        y: 295.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237420800000,
        y: 307.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237507200000,
        y: 310.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237766400000,
        y: 317.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237852800000,
        y: 317,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1237939200000,
        y: 314.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238025600000,
        y: 320.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238112000000,
        y: 315.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238371200000,
        y: 302.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238457600000,
        y: 300,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238544000000,
        y: 304.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238630400000,
        y: 322,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238716800000,
        y: 324,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1238976000000,
        y: 321.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239062400000,
        y: 318.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239148800000,
        y: 324,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239235200000,
        y: 331.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239667200000,
        y: 323.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239753600000,
        y: 324,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239840000000,
        y: 326.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1239926400000,
        y: 327.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240185600000,
        y: 310.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240272000000,
        y: 310.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240358400000,
        y: 310.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240444800000,
        y: 314,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240531200000,
        y: 323.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240790400000,
        y: 318.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240876800000,
        y: 315.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1240963200000,
        y: 321.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241049600000,
        y: 323.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241136000000,
        y: 337.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241481600000,
        y: 345.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241568000000,
        y: 355.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241654400000,
        y: 394,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1241740800000,
        y: 399.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242000000000,
        y: 398.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242086400000,
        y: 401,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242172800000,
        y: 399.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242259200000,
        y: 394.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242345600000,
        y: 390.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242604800000,
        y: 398,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242691200000,
        y: 401.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242777600000,
        y: 413,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242864000000,
        y: 411,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1242950400000,
        y: 414,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243296000000,
        y: 419.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243382400000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243468800000,
        y: 439.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243555200000,
        y: 449.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243814400000,
        y: 457.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243900800000,
        y: 461.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1243987200000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244073600000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244160000000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244419200000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244505600000,
        y: 468.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244592000000,
        y: 476.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244678400000,
        y: 484,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1244764800000,
        y: 479.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245024000000,
        y: 468.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245110400000,
        y: 474.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245196800000,
        y: 470.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245283200000,
        y: 477,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245369600000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245628800000,
        y: 451.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245715200000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245801600000,
        y: 463.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245888000000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1245974400000,
        y: 466.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246233600000,
        y: 478.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246320000000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246406400000,
        y: 468.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246492800000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246579200000,
        y: 448,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246838400000,
        y: 433.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1246924800000,
        y: 424.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247011200000,
        y: 410.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247097600000,
        y: 405.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247184000000,
        y: 401.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247443200000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247529600000,
        y: 409.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247616000000,
        y: 424.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247702400000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1247788800000,
        y: 439.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248048000000,
        y: 443.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248134400000,
        y: 452.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248220800000,
        y: 447.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248307200000,
        y: 462.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248393600000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248652800000,
        y: 473.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248739200000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248825600000,
        y: 445.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248912000000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1248998400000,
        y: 473.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249257600000,
        y: 490.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249344000000,
        y: 493,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249430400000,
        y: 496.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249516800000,
        y: 497.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249603200000,
        y: 493.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249862400000,
        y: 490.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1249948800000,
        y: 481.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250035200000,
        y: 490.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250121600000,
        y: 496,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250208000000,
        y: 482.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250467200000,
        y: 468.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250553600000,
        y: 484.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250640000000,
        y: 500.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250726400000,
        y: 504.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1250812800000,
        y: 504.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251072000000,
        y: 508.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251158400000,
        y: 496.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251244800000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251331200000,
        y: 490.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251417600000,
        y: 498.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251763200000,
        y: 468.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251849600000,
        y: 463.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1251936000000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252022400000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252281600000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252368000000,
        y: 480.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252454400000,
        y: 483.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252540800000,
        y: 482.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252627200000,
        y: 477.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252886400000,
        y: 467.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1252972800000,
        y: 473.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253059200000,
        y: 485.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253145600000,
        y: 493.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253232000000,
        y: 487.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253491200000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253577600000,
        y: 480.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253664000000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253750400000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1253836800000,
        y: 445.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254096000000,
        y: 451.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254182400000,
        y: 447.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254268800000,
        y: 463.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254355200000,
        y: 469.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254441600000,
        y: 463.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254700800000,
        y: 461.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254787200000,
        y: 471.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254873600000,
        y: 463.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1254960000000,
        y: 474.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255046400000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255305600000,
        y: 486.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255392000000,
        y: 490,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255478400000,
        y: 494.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255564800000,
        y: 504.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255651200000,
        y: 513,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255910400000,
        y: 519.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1255996800000,
        y: 517.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256083200000,
        y: 531,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256169600000,
        y: 533,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256256000000,
        y: 527.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256515200000,
        y: 520.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256601600000,
        y: 523.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256688000000,
        y: 511,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256774400000,
        y: 527.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1256860800000,
        y: 511,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257120000000,
        y: 518,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257206400000,
        y: 521.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257292800000,
        y: 534.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257379200000,
        y: 531.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257465600000,
        y: 513.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257724800000,
        y: 528,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257811200000,
        y: 525.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257897600000,
        y: 526.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1257984000000,
        y: 513,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258070400000,
        y: 511.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258329600000,
        y: 527.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258416000000,
        y: 528.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258502400000,
        y: 535.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258588800000,
        y: 521.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258675200000,
        y: 516.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1258934400000,
        y: 526.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259020800000,
        y: 510.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259107200000,
        y: 522,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259193600000,
        y: 519.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259280000000,
        y: 516.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259539200000,
        y: 524.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259625600000,
        y: 533.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259712000000,
        y: 522.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259798400000,
        y: 523.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1259884800000,
        y: 522.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260144000000,
        y: 511.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260230400000,
        y: 502.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260316800000,
        y: 492.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260403200000,
        y: 483.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260489600000,
        y: 479,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260748800000,
        y: 484,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260835200000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1260921600000,
        y: 498.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261008000000,
        y: 490.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261094400000,
        y: 500.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261353600000,
        y: 497.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261440000000,
        y: 494.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261526400000,
        y: 508.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1261612800000,
        y: 511.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262044800000,
        y: 523.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262131200000,
        y: 529.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262217600000,
        y: 532,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262563200000,
        y: 543,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262649600000,
        y: 546.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262736000000,
        y: 553.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262822400000,
        y: 556.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1262908800000,
        y: 555.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263168000000,
        y: 552.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263254400000,
        y: 544.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263340800000,
        y: 531,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263427200000,
        y: 530.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263513600000,
        y: 524,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263772800000,
        y: 522.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263859200000,
        y: 521.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1263945600000,
        y: 515.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264032000000,
        y: 506.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264118400000,
        y: 495.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264377600000,
        y: 497.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264464000000,
        y: 500,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264550400000,
        y: 498,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264636800000,
        y: 493,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264723200000,
        y: 492.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1264982400000,
        y: 495.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265068800000,
        y: 509.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265155200000,
        y: 517,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265241600000,
        y: 491,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265328000000,
        y: 478.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265587200000,
        y: 475,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265673600000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265760000000,
        y: 489.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265846400000,
        y: 498.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1265932800000,
        y: 492.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266192000000,
        y: 493.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266278400000,
        y: 511.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266364800000,
        y: 513.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266451200000,
        y: 525,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266537600000,
        y: 527.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266796800000,
        y: 528.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266883200000,
        y: 522.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1266969600000,
        y: 527.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267056000000,
        y: 512.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267142400000,
        y: 524,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267401600000,
        y: 517,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267488000000,
        y: 525.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267574400000,
        y: 533.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267660800000,
        y: 528.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1267747200000,
        y: 537.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268006400000,
        y: 538.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268092800000,
        y: 534.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268179200000,
        y: 541,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268265600000,
        y: 536.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268352000000,
        y: 534.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268611200000,
        y: 524,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268697600000,
        y: 538.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268784000000,
        y: 544,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268870400000,
        y: 541,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1268956800000,
        y: 530.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269216000000,
        y: 534,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269302400000,
        y: 539.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269388800000,
        y: 531.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269475200000,
        y: 531.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269561600000,
        y: 527.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269820800000,
        y: 542.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269907200000,
        y: 539.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1269993600000,
        y: 545,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270080000000,
        y: 554,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270512000000,
        y: 568.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270598400000,
        y: 563.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270684800000,
        y: 561.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1270771200000,
        y: 561.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271030400000,
        y: 564,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271116800000,
        y: 558.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271203200000,
        y: 570.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271289600000,
        y: 570.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271376000000,
        y: 558.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271635200000,
        y: 547.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271721600000,
        y: 555,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271808000000,
        y: 553.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271894400000,
        y: 554,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1271980800000,
        y: 563.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272240000000,
        y: 560.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272326400000,
        y: 554.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272412800000,
        y: 555.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272499200000,
        y: 570,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272585600000,
        y: 569.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1272931200000,
        y: 557.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273017600000,
        y: 539.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273104000000,
        y: 522.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273190400000,
        y: 508.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273449600000,
        y: 521.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273536000000,
        y: 523,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273622400000,
        y: 521,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273708800000,
        y: 521.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1273795200000,
        y: 503.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274054400000,
        y: 486.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274140800000,
        y: 484.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274227200000,
        y: 475.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274313600000,
        y: 461.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274400000000,
        y: 464.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274659200000,
        y: 457.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274745600000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274832000000,
        y: 466,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1274918400000,
        y: 485.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275004800000,
        y: 485,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275350400000,
        y: 483.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275436800000,
        y: 485.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275523200000,
        y: 492,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275609600000,
        y: 477.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275868800000,
        y: 471.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1275955200000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276041600000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276128000000,
        y: 494.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276214400000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276473600000,
        y: 496.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276560000000,
        y: 503.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276646400000,
        y: 508.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276732800000,
        y: 514,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1276819200000,
        y: 513,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277078400000,
        y: 519.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277164800000,
        y: 516.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277251200000,
        y: 501.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277337600000,
        y: 500.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277424000000,
        y: 510.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277683200000,
        y: 512.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277769600000,
        y: 494.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277856000000,
        y: 494.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1277942400000,
        y: 477.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278028800000,
        y: 474,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278288000000,
        y: 472.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278374400000,
        y: 482,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278460800000,
        y: 488.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278547200000,
        y: 500,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278633600000,
        y: 505,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278892800000,
        y: 497.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1278979200000,
        y: 510.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279065600000,
        y: 512.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279152000000,
        y: 507.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279238400000,
        y: 504.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279497600000,
        y: 509,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279670400000,
        y: 507,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279756800000,
        y: 520.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1279843200000,
        y: 517.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280102400000,
        y: 520.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280188800000,
        y: 510.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280275200000,
        y: 508.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280361600000,
        y: 519,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280448000000,
        y: 522.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280707200000,
        y: 545.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280793600000,
        y: 554.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280880000000,
        y: 556.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1280966400000,
        y: 551,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281052800000,
        y: 542.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281312000000,
        y: 545.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281398400000,
        y: 537.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281484800000,
        y: 523.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281571200000,
        y: 513.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281657600000,
        y: 506.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1281916800000,
        y: 505.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282003200000,
        y: 508.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282089600000,
        y: 507,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282176000000,
        y: 505,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282262400000,
        y: 496.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282521600000,
        y: 492.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282608000000,
        y: 484.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282694400000,
        y: 489.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282780800000,
        y: 500.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1282867200000,
        y: 509,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283212800000,
        y: 501.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283299200000,
        y: 506,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283385600000,
        y: 506,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283472000000,
        y: 504.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283731200000,
        y: 509,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283817600000,
        y: 507.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283904000000,
        y: 516.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1283990400000,
        y: 515,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284076800000,
        y: 519,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284336000000,
        y: 523,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284422400000,
        y: 523.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284508800000,
        y: 520.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284595200000,
        y: 518,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284681600000,
        y: 510.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1284940800000,
        y: 521,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285027200000,
        y: 515,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285113600000,
        y: 509.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285200000000,
        y: 511.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285286400000,
        y: 519.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285545600000,
        y: 517.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285632000000,
        y: 520.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285718400000,
        y: 526.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285804800000,
        y: 538.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1285891200000,
        y: 549.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286150400000,
        y: 549.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286236800000,
        y: 553.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286323200000,
        y: 555.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286409600000,
        y: 553.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286496000000,
        y: 557.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286755200000,
        y: 553.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286841600000,
        y: 550,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1286928000000,
        y: 559,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287014400000,
        y: 555.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287100800000,
        y: 546.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287360000000,
        y: 553.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287446400000,
        y: 537.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287532800000,
        y: 547,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287619200000,
        y: 541,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287705600000,
        y: 543.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1287964800000,
        y: 550.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288051200000,
        y: 551.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288137600000,
        y: 545.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288224000000,
        y: 551.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288310400000,
        y: 546.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288569600000,
        y: 554.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288656000000,
        y: 560.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288742400000,
        y: 567,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288828800000,
        y: 579,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1288915200000,
        y: 579,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289174400000,
        y: 579.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289260800000,
        y: 581,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289347200000,
        y: 585.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289433600000,
        y: 585.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289520000000,
        y: 569.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289779200000,
        y: 567.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289865600000,
        y: 554.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1289952000000,
        y: 546,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290038400000,
        y: 553.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290124800000,
        y: 550,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290384000000,
        y: 545.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290470400000,
        y: 542,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290556800000,
        y: 557.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290643200000,
        y: 562,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290729600000,
        y: 561,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1290988800000,
        y: 571.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291075200000,
        y: 565.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291161600000,
        y: 578.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291248000000,
        y: 588,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291334400000,
        y: 597,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291593600000,
        y: 599,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291680000000,
        y: 595.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291766400000,
        y: 592,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291852800000,
        y: 593.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1291939200000,
        y: 590.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292198400000,
        y: 595.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292284800000,
        y: 596.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292371200000,
        y: 601.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292457600000,
        y: 598.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292544000000,
        y: 597.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292803200000,
        y: 600.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292889600000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1292976000000,
        y: 609.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293062400000,
        y: 612.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293148800000,
        y: 611.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293580800000,
        y: 612.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293667200000,
        y: 604.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1293753600000,
        y: 606.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294099200000,
        y: 606,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294185600000,
        y: 612.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294272000000,
        y: 608.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294358400000,
        y: 610,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294617600000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294704000000,
        y: 628.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294790400000,
        y: 632,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294876800000,
        y: 631.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1294963200000,
        y: 634.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295222400000,
        y: 630.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295308800000,
        y: 629.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295395200000,
        y: 630.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295481600000,
        y: 619,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295568000000,
        y: 620.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295827200000,
        y: 620.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1295913600000,
        y: 608.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296000000000,
        y: 617.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296086400000,
        y: 617.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296172800000,
        y: 627.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296432000000,
        y: 641,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296518400000,
        y: 646.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296604800000,
        y: 652.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296691200000,
        y: 652,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1296777600000,
        y: 640,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297036800000,
        y: 635.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297123200000,
        y: 636,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297209600000,
        y: 640.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297296000000,
        y: 642.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297382400000,
        y: 638.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297641600000,
        y: 651.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297728000000,
        y: 646.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297814400000,
        y: 652,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297900800000,
        y: 656.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1297987200000,
        y: 653,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298246400000,
        y: 664,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298332800000,
        y: 685.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298419200000,
        y: 707.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298505600000,
        y: 721.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298592000000,
        y: 719.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298851200000,
        y: 724.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1298937600000,
        y: 739.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299024000000,
        y: 756.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299110400000,
        y: 751.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299196800000,
        y: 763,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299456000000,
        y: 768,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299542400000,
        y: 753,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299628800000,
        y: 761.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299715200000,
        y: 752.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1299801600000,
        y: 748.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300060800000,
        y: 745.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300147200000,
        y: 722.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300233600000,
        y: 726.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300320000000,
        y: 748.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300406400000,
        y: 746,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300665600000,
        y: 754,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300752000000,
        y: 760,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300838400000,
        y: 763,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1300924800000,
        y: 763,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301011200000,
        y: 765.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301270400000,
        y: 764,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301356800000,
        y: 763,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301443200000,
        y: 761.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301529600000,
        y: 772.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301616000000,
        y: 788.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301875200000,
        y: 800.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1301961600000,
        y: 810.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302048000000,
        y: 811.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302134400000,
        y: 811.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302220800000,
        y: 831.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302480000000,
        y: 820.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302566400000,
        y: 793.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302652800000,
        y: 802.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302739200000,
        y: 805,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1302825600000,
        y: 817,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303084800000,
        y: 801,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303171200000,
        y: 800.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303257600000,
        y: 817.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303344000000,
        y: 821.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303776000000,
        y: 820.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303862400000,
        y: 825.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1303948800000,
        y: 831.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304380800000,
        y: 820.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304467200000,
        y: 809.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304553600000,
        y: 752.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304640000000,
        y: 743.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304899200000,
        y: 760,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1304985600000,
        y: 780.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305072000000,
        y: 756.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305158400000,
        y: 747.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305244800000,
        y: 751.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305504000000,
        y: 747.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305590400000,
        y: 728.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305676800000,
        y: 747.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305763200000,
        y: 743.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1305849600000,
        y: 741.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306108800000,
        y: 730.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306195200000,
        y: 749.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306281600000,
        y: 763.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306368000000,
        y: 764.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306454400000,
        y: 764.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306800000000,
        y: 780.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306886400000,
        y: 772.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1306972800000,
        y: 766.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307059200000,
        y: 771,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307318400000,
        y: 768.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307404800000,
        y: 773.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307491200000,
        y: 784,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307577600000,
        y: 793,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307664000000,
        y: 783.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1307923200000,
        y: 785.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308009600000,
        y: 791.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308096000000,
        y: 769,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308182400000,
        y: 756.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308268800000,
        y: 750.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308528000000,
        y: 744.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308614400000,
        y: 744.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308700800000,
        y: 754.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308787200000,
        y: 720.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1308873600000,
        y: 710.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309132800000,
        y: 707.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309219200000,
        y: 725.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309305600000,
        y: 747,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309392000000,
        y: 748.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309478400000,
        y: 739.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309737600000,
        y: 743.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309824000000,
        y: 756.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309910400000,
        y: 757.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1309996800000,
        y: 782.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310083200000,
        y: 776.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310342400000,
        y: 771,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310428800000,
        y: 776,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310515200000,
        y: 787,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310601600000,
        y: 774.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310688000000,
        y: 778.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1310947200000,
        y: 772.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311033600000,
        y: 780.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311120000000,
        y: 784.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311206400000,
        y: 788.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311292800000,
        y: 789.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311552000000,
        y: 785.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311638400000,
        y: 787,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311724800000,
        y: 782.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311811200000,
        y: 784.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1311897600000,
        y: 774,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312156800000,
        y: 776.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312243200000,
        y: 773.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312329600000,
        y: 758,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312416000000,
        y: 728.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312502400000,
        y: 719.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312761600000,
        y: 694.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312848000000,
        y: 684.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1312934400000,
        y: 692.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313020800000,
        y: 710,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313107200000,
        y: 713.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313366400000,
        y: 722.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313452800000,
        y: 725.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313539200000,
        y: 732.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313625600000,
        y: 708.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313712000000,
        y: 711.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1313971200000,
        y: 711.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314057600000,
        y: 720.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314144000000,
        y: 725.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314230400000,
        y: 727,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314316800000,
        y: 729.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314662400000,
        y: 752,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314748800000,
        y: 755.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314835200000,
        y: 758,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1314921600000,
        y: 748.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315180800000,
        y: 736,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315267200000,
        y: 745.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315353600000,
        y: 765.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315440000000,
        y: 765.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315526400000,
        y: 746.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315785600000,
        y: 748,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315872000000,
        y: 748.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1315958400000,
        y: 744.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316044800000,
        y: 757.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316131200000,
        y: 753,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316390400000,
        y: 736.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316476800000,
        y: 742,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316563200000,
        y: 746.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316649600000,
        y: 710.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316736000000,
        y: 706,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1316995200000,
        y: 702.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317081600000,
        y: 717.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317168000000,
        y: 708,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317254400000,
        y: 704.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317340800000,
        y: 692.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317600000000,
        y: 692.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317686400000,
        y: 683.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317772800000,
        y: 697.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317859200000,
        y: 710.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1317945600000,
        y: 719.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318204800000,
        y: 738.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318291200000,
        y: 742,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318377600000,
        y: 751.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318464000000,
        y: 743,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318550400000,
        y: 765.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318809600000,
        y: 754.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318896000000,
        y: 753.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1318982400000,
        y: 750.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319068800000,
        y: 743.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319155200000,
        y: 755.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319414400000,
        y: 763.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319500800000,
        y: 764,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319587200000,
        y: 749,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319673600000,
        y: 767.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1319760000000,
        y: 758.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320019200000,
        y: 751.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320105600000,
        y: 748.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320192000000,
        y: 764,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320278400000,
        y: 767.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320364800000,
        y: 775.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320624000000,
        y: 788.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320710400000,
        y: 794.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320796800000,
        y: 787,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320883200000,
        y: 783.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1320969600000,
        y: 789.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321228800000,
        y: 780,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321315200000,
        y: 780,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321401600000,
        y: 784.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321488000000,
        y: 763,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321574400000,
        y: 754.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321833600000,
        y: 744,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1321920000000,
        y: 752.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322006400000,
        y: 746.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322092800000,
        y: 748.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322179200000,
        y: 744.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322438400000,
        y: 755.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322524800000,
        y: 762.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322611200000,
        y: 766.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322697600000,
        y: 755.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1322784000000,
        y: 760.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323043200000,
        y: 764,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323129600000,
        y: 762,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323216000000,
        y: 757.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323302400000,
        y: 746.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323388800000,
        y: 747.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323648000000,
        y: 741.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323734400000,
        y: 751.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323820800000,
        y: 723.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323907200000,
        y: 717.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1323993600000,
        y: 718,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324252800000,
        y: 717,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324339200000,
        y: 741.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324425600000,
        y: 743.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324512000000,
        y: 746.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1324598400000,
        y: 745.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325030400000,
        y: 743,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325116800000,
        y: 741.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325203200000,
        y: 742.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325548800000,
        y: 763.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325635200000,
        y: 773.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325721600000,
        y: 773.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1325808000000,
        y: 773.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326067200000,
        y: 774,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326153600000,
        y: 779,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326240000000,
        y: 772.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326326400000,
        y: 771,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326412800000,
        y: 763.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326672000000,
        y: 769.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326758400000,
        y: 775.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326844800000,
        y: 773,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1326931200000,
        y: 773,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327017600000,
        y: 764,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327276800000,
        y: 770.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327363200000,
        y: 767.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327449600000,
        y: 769.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327536000000,
        y: 773.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327622400000,
        y: 776.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327881600000,
        y: 771.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1327968000000,
        y: 769.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328054400000,
        y: 770.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328140800000,
        y: 765.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328227200000,
        y: 771.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328486400000,
        y: 784,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328572800000,
        y: 789.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328659200000,
        y: 790,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328745600000,
        y: 802.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1328832000000,
        y: 796,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329091200000,
        y: 806.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329177600000,
        y: 800,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329264000000,
        y: 810.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329350400000,
        y: 813.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329436800000,
        y: 811.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329696000000,
        y: 817.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329782400000,
        y: 822.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329868800000,
        y: 831,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1329955200000,
        y: 836.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330041600000,
        y: 844.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330300800000,
        y: 844,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330387200000,
        y: 833.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330473600000,
        y: 828.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330560000000,
        y: 849.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330646400000,
        y: 841.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330905600000,
        y: 840.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1330992000000,
        y: 830.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331078400000,
        y: 838.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331164800000,
        y: 851.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331251200000,
        y: 852.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331510400000,
        y: 847.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331596800000,
        y: 854,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331683200000,
        y: 847.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331769600000,
        y: 835.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1331856000000,
        y: 847.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332115200000,
        y: 852,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332201600000,
        y: 840.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332288000000,
        y: 841.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332374400000,
        y: 832,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332460800000,
        y: 844.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332720000000,
        y: 850.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332806400000,
        y: 848.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332892800000,
        y: 840.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1332979200000,
        y: 829.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333065600000,
        y: 830,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333324800000,
        y: 839.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333411200000,
        y: 841,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333497600000,
        y: 823.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1333584000000,
        y: 821.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334016000000,
        y: 809.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334102400000,
        y: 811.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334188800000,
        y: 820,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334275200000,
        y: 820,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334534400000,
        y: 807.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334620800000,
        y: 811,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334707200000,
        y: 801.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334793600000,
        y: 806.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1334880000000,
        y: 810.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335139200000,
        y: 802.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335225600000,
        y: 805,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335312000000,
        y: 801.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335398400000,
        y: 813.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335484800000,
        y: 809.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335744000000,
        y: 809,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335830400000,
        y: 818.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1335916800000,
        y: 810.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336003200000,
        y: 799,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336089600000,
        y: 772.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336435200000,
        y: 760.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336521600000,
        y: 761.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336608000000,
        y: 760,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336694400000,
        y: 757.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1336953600000,
        y: 747.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337040000000,
        y: 748,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337126400000,
        y: 742,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337212800000,
        y: 731.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337299200000,
        y: 725.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337558400000,
        y: 732,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337644800000,
        y: 733,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337731200000,
        y: 716,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337817600000,
        y: 719.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1337904000000,
        y: 724,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338163200000,
        y: 725.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338249600000,
        y: 723.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338336000000,
        y: 699.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338422400000,
        y: 688.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338508800000,
        y: 661.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1338940800000,
        y: 676.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339027200000,
        y: 671,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339113600000,
        y: 663.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339372800000,
        y: 660,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339459200000,
        y: 657.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339545600000,
        y: 658.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339632000000,
        y: 660.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339718400000,
        y: 663.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1339977600000,
        y: 654.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340064000000,
        y: 656.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340150400000,
        y: 641.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340236800000,
        y: 618,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340323200000,
        y: 621.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340582400000,
        y: 618.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340668800000,
        y: 625,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340755200000,
        y: 634.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340841600000,
        y: 624.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1340928000000,
        y: 656,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341187200000,
        y: 649,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341273600000,
        y: 678,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341360000000,
        y: 674.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341446400000,
        y: 679.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341532800000,
        y: 659.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341792000000,
        y: 669.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341878400000,
        y: 662.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1341964800000,
        y: 669.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342051200000,
        y: 670.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342137600000,
        y: 682.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342396800000,
        y: 693,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342483200000,
        y: 701,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342569600000,
        y: 709.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342656000000,
        y: 727,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1342742400000,
        y: 721.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343001600000,
        y: 699,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343088000000,
        y: 699.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343174400000,
        y: 700.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343260800000,
        y: 711.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343347200000,
        y: 718.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343606400000,
        y: 718,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343692800000,
        y: 710,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343779200000,
        y: 712,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343865600000,
        y: 711,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1343952000000,
        y: 730,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344211200000,
        y: 732.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344297600000,
        y: 744.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344384000000,
        y: 748.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344470400000,
        y: 751.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344556800000,
        y: 748.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344816000000,
        y: 752.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344902400000,
        y: 755,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1344988800000,
        y: 764.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345075200000,
        y: 770.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345161600000,
        y: 767.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345420800000,
        y: 766.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345507200000,
        y: 771.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345593600000,
        y: 772.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345680000000,
        y: 774.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1345766400000,
        y: 766.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346112000000,
        y: 758,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346198400000,
        y: 757.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346284800000,
        y: 757.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346371200000,
        y: 768.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346630400000,
        y: 768.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346716800000,
        y: 763.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346803200000,
        y: 759.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346889600000,
        y: 763,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1346976000000,
        y: 765.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347235200000,
        y: 769.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347321600000,
        y: 772.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347408000000,
        y: 776.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347494400000,
        y: 780.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347580800000,
        y: 784.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347840000000,
        y: 774.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1347926400000,
        y: 756.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348012800000,
        y: 727.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348099200000,
        y: 732.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348185600000,
        y: 741.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348444800000,
        y: 733.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348531200000,
        y: 737.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348617600000,
        y: 728.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348704000000,
        y: 744.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1348790400000,
        y: 743.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349049600000,
        y: 745,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349136000000,
        y: 740,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349222400000,
        y: 715.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349308800000,
        y: 731.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349395200000,
        y: 731.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349654400000,
        y: 729.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349740800000,
        y: 743.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349827200000,
        y: 747.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1349913600000,
        y: 751.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350000000000,
        y: 743.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350259200000,
        y: 742.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350345600000,
        y: 742.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350432000000,
        y: 736.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350518400000,
        y: 733,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350604800000,
        y: 725.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350864000000,
        y: 714.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1350950400000,
        y: 702.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351036800000,
        y: 699.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351123200000,
        y: 699.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351209600000,
        y: 704.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351468800000,
        y: 702,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351555200000,
        y: 707.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351641600000,
        y: 707,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351728000000,
        y: 697,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1351814400000,
        y: 683.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352073600000,
        y: 681,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352160000000,
        y: 700.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352246400000,
        y: 682.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352332800000,
        y: 678.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352419200000,
        y: 686.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352678400000,
        y: 690.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352764800000,
        y: 685,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352851200000,
        y: 690.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1352937600000,
        y: 688,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353024000000,
        y: 681.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353283200000,
        y: 687.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353369600000,
        y: 666.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353456000000,
        y: 669,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353542400000,
        y: 666.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353628800000,
        y: 680.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353888000000,
        y: 682.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1353974400000,
        y: 681,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354060800000,
        y: 674.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354147200000,
        y: 688.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354233600000,
        y: 691.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354492800000,
        y: 694.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354579200000,
        y: 678.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354665600000,
        y: 677.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354752000000,
        y: 666,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1354838400000,
        y: 661.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355097600000,
        y: 664.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355184000000,
        y: 665.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355270400000,
        y: 676.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355356800000,
        y: 671.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355443200000,
        y: 675.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355702400000,
        y: 675.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355788800000,
        y: 680.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355875200000,
        y: 686.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1355961600000,
        y: 685.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356048000000,
        y: 682.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356307200000,
        y: 679.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356566400000,
        y: 695.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356652800000,
        y: 696,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1356912000000,
        y: 696.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357084800000,
        y: 711,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357171200000,
        y: 719,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357257600000,
        y: 715.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357516800000,
        y: 713.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357603200000,
        y: 714,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357689600000,
        y: 713,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357776000000,
        y: 715,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1357862400000,
        y: 704,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358121600000,
        y: 710,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358208000000,
        y: 714.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358294400000,
        y: 719.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358380800000,
        y: 730,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358467200000,
        y: 735.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358726400000,
        y: 738.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358812800000,
        y: 743.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358899200000,
        y: 742,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1358985600000,
        y: 741.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359072000000,
        y: 740.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359331200000,
        y: 751.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359417600000,
        y: 765,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359504000000,
        y: 770.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359590400000,
        y: 772.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359676800000,
        y: 788.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1359936000000,
        y: 782,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360022400000,
        y: 786.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360108800000,
        y: 784.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360195200000,
        y: 785.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360281600000,
        y: 802.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360540800000,
        y: 804.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360627200000,
        y: 805,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360713600000,
        y: 806.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360800000000,
        y: 802.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1360886400000,
        y: 795.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361145600000,
        y: 796.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361232000000,
        y: 792.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361318400000,
        y: 780.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361404800000,
        y: 768.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361491200000,
        y: 768.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361750400000,
        y: 772.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361836800000,
        y: 770.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1361923200000,
        y: 768.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362009600000,
        y: 763.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362096000000,
        y: 748.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362355200000,
        y: 746,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362441600000,
        y: 753.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362528000000,
        y: 752.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362614400000,
        y: 754.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362700800000,
        y: 751.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1362960000000,
        y: 750.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363046400000,
        y: 745,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363132800000,
        y: 740.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363219200000,
        y: 743.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363305600000,
        y: 750,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363564800000,
        y: 746.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363651200000,
        y: 739.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363737600000,
        y: 739.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363824000000,
        y: 734.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1363910400000,
        y: 735.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364169600000,
        y: 738,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364256000000,
        y: 743.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364342400000,
        y: 749.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364428800000,
        y: 750.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364860800000,
        y: 753.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1364947200000,
        y: 736.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365033600000,
        y: 723.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365120000000,
        y: 717.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365379200000,
        y: 716.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365465600000,
        y: 723.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365552000000,
        y: 727,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365638400000,
        y: 721,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365724800000,
        y: 705.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1365984000000,
        y: 691.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366070400000,
        y: 684.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366156800000,
        y: 675,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366243200000,
        y: 679,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366329600000,
        y: 683.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366588800000,
        y: 687.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366675200000,
        y: 687.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366761600000,
        y: 697.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366848000000,
        y: 707.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1366934400000,
        y: 710,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367193600000,
        y: 714.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367280000000,
        y: 708.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367366400000,
        y: 684.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367452800000,
        y: 699.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367539200000,
        y: 716,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367884800000,
        y: 716.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1367971200000,
        y: 713.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368057600000,
        y: 714,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368144000000,
        y: 705.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368403200000,
        y: 705,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368489600000,
        y: 702.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368576000000,
        y: 701.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368662400000,
        y: 712,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1368748800000,
        y: 715.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369008000000,
        y: 720,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369094400000,
        y: 714,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369180800000,
        y: 706,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369267200000,
        y: 700,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369353600000,
        y: 702.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369699200000,
        y: 715,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369785600000,
        y: 704.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369872000000,
        y: 703,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1369958400000,
        y: 693.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370217600000,
        y: 694.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370304000000,
        y: 697,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370390400000,
        y: 703,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370476800000,
        y: 704.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370563200000,
        y: 709.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370822400000,
        y: 706.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370908800000,
        y: 696,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1370995200000,
        y: 703.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371081600000,
        y: 706,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371168000000,
        y: 716.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371427200000,
        y: 715.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371513600000,
        y: 716,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371600000000,
        y: 715.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371686400000,
        y: 694.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1371772800000,
        y: 683,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372032000000,
        y: 684.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372118400000,
        y: 690.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372204800000,
        y: 688,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372291200000,
        y: 698.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372377600000,
        y: 696,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372636800000,
        y: 694.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372723200000,
        y: 699,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372809600000,
        y: 710,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372896000000,
        y: 707.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1372982400000,
        y: 714,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373241600000,
        y: 716,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373328000000,
        y: 716.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373414400000,
        y: 724.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373500800000,
        y: 721.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373587200000,
        y: 725.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373846400000,
        y: 727.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1373932800000,
        y: 727.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374019200000,
        y: 731,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374105600000,
        y: 734.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374192000000,
        y: 733.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374451200000,
        y: 727.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374537600000,
        y: 731.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374624000000,
        y: 724.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374710400000,
        y: 725.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1374796800000,
        y: 723.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375056000000,
        y: 725.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375142400000,
        y: 721,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375228800000,
        y: 725.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375315200000,
        y: 727.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375401600000,
        y: 728,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375660800000,
        y: 725.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375747200000,
        y: 720.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375833600000,
        y: 717.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1375920000000,
        y: 711.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376006400000,
        y: 720.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376265600000,
        y: 723.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376352000000,
        y: 729.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376438400000,
        y: 730,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376524800000,
        y: 736,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376611200000,
        y: 738,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376870400000,
        y: 736.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1376956800000,
        y: 728.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377043200000,
        y: 727.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377129600000,
        y: 729.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377216000000,
        y: 736.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377561600000,
        y: 755.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377648000000,
        y: 764.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377734400000,
        y: 761.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1377820800000,
        y: 755.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378080000000,
        y: 742,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378166400000,
        y: 746.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378252800000,
        y: 744.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378339200000,
        y: 749.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378425600000,
        y: 757,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378684800000,
        y: 748.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378771200000,
        y: 730.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378857600000,
        y: 729.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1378944000000,
        y: 734.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379030400000,
        y: 734.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379289600000,
        y: 725.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379376000000,
        y: 716.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379462400000,
        y: 724.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379548800000,
        y: 722.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379635200000,
        y: 717.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379894400000,
        y: 711,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1379980800000,
        y: 711,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380067200000,
        y: 714.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380153600000,
        y: 715.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380240000000,
        y: 716.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380499200000,
        y: 709.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380585600000,
        y: 702.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380672000000,
        y: 712.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380758400000,
        y: 709.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1380844800000,
        y: 710.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381104000000,
        y: 709.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381190400000,
        y: 712.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381276800000,
        y: 701.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381363200000,
        y: 714.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381449600000,
        y: 710.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381708800000,
        y: 708,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381795200000,
        y: 705.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381881600000,
        y: 709.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1381968000000,
        y: 699.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382054400000,
        y: 702.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382313600000,
        y: 699.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382400000000,
        y: 696,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382486400000,
        y: 689,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382572800000,
        y: 689.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382659200000,
        y: 685.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1382918400000,
        y: 695,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383004800000,
        y: 692.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383091200000,
        y: 694.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383177600000,
        y: 690,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383264000000,
        y: 673.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383523200000,
        y: 670,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383609600000,
        y: 664.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383696000000,
        y: 666.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383782400000,
        y: 657.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1383868800000,
        y: 662,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384128000000,
        y: 670,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384214400000,
        y: 666.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384300800000,
        y: 670.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384387200000,
        y: 673.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384473600000,
        y: 674.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384732800000,
        y: 674.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384819200000,
        y: 674.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384905600000,
        y: 673.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1384992000000,
        y: 683.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385078400000,
        y: 686.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385337600000,
        y: 683.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385424000000,
        y: 687.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385510400000,
        y: 684.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385596800000,
        y: 685,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385683200000,
        y: 685.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1385942400000,
        y: 684.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386028800000,
        y: 695.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386115200000,
        y: 698.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386201600000,
        y: 695,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386288000000,
        y: 694,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386547200000,
        y: 687.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386633600000,
        y: 684.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386720000000,
        y: 686,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386806400000,
        y: 682,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1386892800000,
        y: 678.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387152000000,
        y: 688.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387238400000,
        y: 683.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387324800000,
        y: 690.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387411200000,
        y: 698.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387497600000,
        y: 703,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387756800000,
        y: 703.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1387843200000,
        y: 706,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388102400000,
        y: 713.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388361600000,
        y: 706.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388448000000,
        y: 704,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388620800000,
        y: 689,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388707200000,
        y: 680.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1388966400000,
        y: 680,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389052800000,
        y: 681.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389139200000,
        y: 680.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389225600000,
        y: 677.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389312000000,
        y: 678,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389571200000,
        y: 677.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389657600000,
        y: 680,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389744000000,
        y: 689.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389830400000,
        y: 688.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1389916800000,
        y: 692,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390176000000,
        y: 689.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390262400000,
        y: 695.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390348800000,
        y: 702,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390435200000,
        y: 702.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390521600000,
        y: 698.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390780800000,
        y: 692,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390867200000,
        y: 698,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1390953600000,
        y: 699.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391040000000,
        y: 702.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391126400000,
        y: 696.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391385600000,
        y: 694.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391472000000,
        y: 694.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391558400000,
        y: 696,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391644800000,
        y: 703.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391731200000,
        y: 714.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1391990400000,
        y: 714.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392076800000,
        y: 713.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392163200000,
        y: 714.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392249600000,
        y: 713.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392336000000,
        y: 713.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392595200000,
        y: 714.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392681600000,
        y: 719,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392768000000,
        y: 723.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392854400000,
        y: 718.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1392940800000,
        y: 716.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393200000000,
        y: 718.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393286400000,
        y: 711.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393372800000,
        y: 715.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393459200000,
        y: 713.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393545600000,
        y: 714.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393804800000,
        y: 732.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393891200000,
        y: 721,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1393977600000,
        y: 711.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394064000000,
        y: 707.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394150400000,
        y: 714,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394409600000,
        y: 708.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394496000000,
        y: 704.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394582400000,
        y: 700.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394668800000,
        y: 697.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1394755200000,
        y: 700.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395014400000,
        y: 692.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395100800000,
        y: 683.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395187200000,
        y: 691.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395273600000,
        y: 688,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395360000000,
        y: 696.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395619200000,
        y: 698.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395705600000,
        y: 699.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395792000000,
        y: 697.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395878400000,
        y: 703.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1395964800000,
        y: 704.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396224000000,
        y: 699.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396310400000,
        y: 698.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396396800000,
        y: 687,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396483200000,
        y: 693.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396569600000,
        y: 700.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396828800000,
        y: 695.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1396915200000,
        y: 701,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397001600000,
        y: 708.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397088000000,
        y: 707.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397174400000,
        y: 708,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397433600000,
        y: 709.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397520000000,
        y: 715.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397606400000,
        y: 717.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1397692800000,
        y: 716.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398124800000,
        y: 713.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398211200000,
        y: 711.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398297600000,
        y: 715.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398384000000,
        y: 712.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398643200000,
        y: 710,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398729600000,
        y: 716,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398816000000,
        y: 706,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398902400000,
        y: 708.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1398988800000,
        y: 712.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399334400000,
        y: 707.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399420800000,
        y: 708.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399507200000,
        y: 708.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399593600000,
        y: 709,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399852800000,
        y: 708.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1399939200000,
        y: 712,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400025600000,
        y: 718,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400112000000,
        y: 717.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400198400000,
        y: 719.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400457600000,
        y: 719.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400544000000,
        y: 719.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400630400000,
        y: 724.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400716800000,
        y: 723.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1400803200000,
        y: 724,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401148800000,
        y: 724.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401235200000,
        y: 723.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401321600000,
        y: 726,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401408000000,
        y: 721.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401667200000,
        y: 718.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401753600000,
        y: 717.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401840000000,
        y: 717,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1401926400000,
        y: 714.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402012800000,
        y: 715.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402272000000,
        y: 719,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402358400000,
        y: 719.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402444800000,
        y: 720.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402531200000,
        y: 729.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402617600000,
        y: 738.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402876800000,
        y: 741.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1402963200000,
        y: 739.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403049600000,
        y: 738.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403136000000,
        y: 743,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403222400000,
        y: 743.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403481600000,
        y: 741.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403568000000,
        y: 743.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403654400000,
        y: 738.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403740800000,
        y: 737.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1403827200000,
        y: 736,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404086400000,
        y: 730,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404172800000,
        y: 725.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404259200000,
        y: 716.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404345600000,
        y: 711.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404432000000,
        y: 709,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404691200000,
        y: 707.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404777600000,
        y: 700.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404864000000,
        y: 695.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1404950400000,
        y: 695.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405036800000,
        y: 691.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405296000000,
        y: 687.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405382400000,
        y: 683.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405468800000,
        y: 690,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405555200000,
        y: 695.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405641600000,
        y: 697.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405900800000,
        y: 699.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1405987200000,
        y: 692.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406073600000,
        y: 695.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406160000000,
        y: 684,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406246400000,
        y: 685.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406505600000,
        y: 682.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406592000000,
        y: 689,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406678400000,
        y: 687,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406764800000,
        y: 679.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1406851200000,
        y: 674.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407110400000,
        y: 676,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407196800000,
        y: 671,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407283200000,
        y: 672.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407369600000,
        y: 672.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407456000000,
        y: 673.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407715200000,
        y: 674.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407801600000,
        y: 663.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407888000000,
        y: 660.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1407974400000,
        y: 655,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408060800000,
        y: 656.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408320000000,
        y: 649.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408406400000,
        y: 644,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408492800000,
        y: 648.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408579200000,
        y: 652,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1408665600000,
        y: 650.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409011200000,
        y: 648.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409097600000,
        y: 653.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409184000000,
        y: 656.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409270400000,
        y: 663,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409529600000,
        y: 669.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409616000000,
        y: 659.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409702400000,
        y: 661.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409788800000,
        y: 662,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1409875200000,
        y: 652.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410134400000,
        y: 647.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410220800000,
        y: 645.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410307200000,
        y: 636,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410393600000,
        y: 635.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410480000000,
        y: 632.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410739200000,
        y: 630.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410825600000,
        y: 636.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410912000000,
        y: 638.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1410998400000,
        y: 632.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411084800000,
        y: 630.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411344000000,
        y: 625,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411430400000,
        y: 625.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411516800000,
        y: 625.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411603200000,
        y: 630.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411689600000,
        y: 630.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1411948800000,
        y: 637.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412035200000,
        y: 627.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412121600000,
        y: 620,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412208000000,
        y: 607.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412294400000,
        y: 603.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412553600000,
        y: 602.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412640000000,
        y: 600,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412726400000,
        y: 594,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412812800000,
        y: 588.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1412899200000,
        y: 578.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413158400000,
        y: 576.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413244800000,
        y: 558.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413331200000,
        y: 544,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413417600000,
        y: 543.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413504000000,
        y: 550.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413763200000,
        y: 546.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413849600000,
        y: 544.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1413936000000,
        y: 549,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414022400000,
        y: 551.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414108800000,
        y: 552.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414368000000,
        y: 548.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414454400000,
        y: 549.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414540800000,
        y: 557.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414627200000,
        y: 552.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414713600000,
        y: 545.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1414972800000,
        y: 543.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415059200000,
        y: 530.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415145600000,
        y: 532,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415232000000,
        y: 529.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415318400000,
        y: 533,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415577600000,
        y: 528.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415664000000,
        y: 518.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415750400000,
        y: 516,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415836800000,
        y: 499.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1415923200000,
        y: 496,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416182400000,
        y: 497.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416268800000,
        y: 494.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416355200000,
        y: 493.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416441600000,
        y: 495,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416528000000,
        y: 504.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416787200000,
        y: 505.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416873600000,
        y: 493.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1416960000000,
        y: 488.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417046400000,
        y: 464.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417132800000,
        y: 454.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417392000000,
        y: 444.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417478400000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417564800000,
        y: 444,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417651200000,
        y: 437.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417737600000,
        y: 433,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1417996800000,
        y: 419,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418083200000,
        y: 417.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418169600000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418256000000,
        y: 397.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418342400000,
        y: 385.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418601600000,
        y: 376,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418688000000,
        y: 371.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418774400000,
        y: 370.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418860800000,
        y: 366,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1418947200000,
        y: 368.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419206400000,
        y: 367.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419292800000,
        y: 370.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419379200000,
        y: 368.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419811200000,
        y: 359.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419897600000,
        y: 350.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1419984000000,
        y: 344.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420156800000,
        y: 340.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420416000000,
        y: 321,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420502400000,
        y: 311,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420588800000,
        y: 304.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420675200000,
        y: 307.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1420761600000,
        y: 303.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421020800000,
        y: 294.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421107200000,
        y: 284,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421193600000,
        y: 289,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421280000000,
        y: 295.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421366400000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421625600000,
        y: 299,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421712000000,
        y: 294,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421798400000,
        y: 293,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421884800000,
        y: 293.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1421971200000,
        y: 292.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422230400000,
        y: 292,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422316800000,
        y: 296,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422403200000,
        y: 295.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422489600000,
        y: 293,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422576000000,
        y: 303,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422835200000,
        y: 330,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1422921600000,
        y: 349.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423008000000,
        y: 345.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423094400000,
        y: 349.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423180800000,
        y: 357.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423440000000,
        y: 363.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423526400000,
        y: 355.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423612800000,
        y: 348,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423699200000,
        y: 361.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1423785600000,
        y: 381.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424044800000,
        y: 386,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424131200000,
        y: 382.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424217600000,
        y: 383.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424304000000,
        y: 370.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424390400000,
        y: 373,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424649600000,
        y: 364.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424736000000,
        y: 364.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424822400000,
        y: 366.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424908800000,
        y: 372.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1424995200000,
        y: 374.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425254400000,
        y: 373.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425340800000,
        y: 376.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425427200000,
        y: 371.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425513600000,
        y: 374.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425600000000,
        y: 368.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425859200000,
        y: 364.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1425945600000,
        y: 350.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426032000000,
        y: 351,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426118400000,
        y: 354,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426204800000,
        y: 338,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426464000000,
        y: 325.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426550400000,
        y: 322.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426636800000,
        y: 328.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426723200000,
        y: 331.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1426809600000,
        y: 335.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427068800000,
        y: 339.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427155200000,
        y: 341.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427241600000,
        y: 345,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427328000000,
        y: 362.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427414400000,
        y: 353.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427673600000,
        y: 343.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427760000000,
        y: 339.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427846400000,
        y: 352.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1427932800000,
        y: 347.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428364800000,
        y: 371.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428451200000,
        y: 360.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428537600000,
        y: 358,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428624000000,
        y: 364,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428883200000,
        y: 367,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1428969600000,
        y: 370.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429056000000,
        y: 383.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429142400000,
        y: 393.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429228800000,
        y: 396.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429488000000,
        y: 394.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429574400000,
        y: 388.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429660800000,
        y: 386.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429747200000,
        y: 396,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1429833600000,
        y: 397.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430092800000,
        y: 400.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430179200000,
        y: 400,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430265600000,
        y: 406.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430352000000,
        y: 412,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430438400000,
        y: 412.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430784000000,
        y: 421.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430870400000,
        y: 425.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1430956800000,
        y: 415.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431043200000,
        y: 409.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431302400000,
        y: 406.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431388800000,
        y: 415.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431475200000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431561600000,
        y: 416.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431648000000,
        y: 414,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431907200000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1431993600000,
        y: 402.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432080000000,
        y: 403,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432166400000,
        y: 415.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432252800000,
        y: 412.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432598400000,
        y: 401.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432684800000,
        y: 396.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432771200000,
        y: 393.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1432857600000,
        y: 409,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433116800000,
        y: 413,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433203200000,
        y: 419.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433289600000,
        y: 412.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433376000000,
        y: 399,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433462400000,
        y: 398.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433721600000,
        y: 400.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433808000000,
        y: 411.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433894400000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1433980800000,
        y: 417,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434067200000,
        y: 413.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434326400000,
        y: 407.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434412800000,
        y: 407.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434499200000,
        y: 408.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434585600000,
        y: 412.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434672000000,
        y: 405,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1434931200000,
        y: 404,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435017600000,
        y: 412.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435104000000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435190400000,
        y: 404.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435276800000,
        y: 404.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435536000000,
        y: 398.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435622400000,
        y: 404,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435708800000,
        y: 403.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435795200000,
        y: 400.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1435881600000,
        y: 391.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436140800000,
        y: 372.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436227200000,
        y: 359.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436313600000,
        y: 362.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436400000000,
        y: 372.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436486400000,
        y: 372.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436745600000,
        y: 373.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436832000000,
        y: 372,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1436918400000,
        y: 370,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437004800000,
        y: 366,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437091200000,
        y: 362.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437350400000,
        y: 360.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437436800000,
        y: 360,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437523200000,
        y: 357.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437609600000,
        y: 355.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437696000000,
        y: 345.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1437955200000,
        y: 340.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438041600000,
        y: 339.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438128000000,
        y: 342.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438214400000,
        y: 341.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438300800000,
        y: 334.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438560000000,
        y: 315.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438646400000,
        y: 311.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438732800000,
        y: 312,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438819200000,
        y: 307.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1438905600000,
        y: 305.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439164800000,
        y: 312,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439251200000,
        y: 307.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439337600000,
        y: 308.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439424000000,
        y: 307.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439510400000,
        y: 307.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439769600000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439856000000,
        y: 300.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1439942400000,
        y: 293,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440028800000,
        y: 290.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440115200000,
        y: 281.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440374400000,
        y: 267,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440460800000,
        y: 266.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440547200000,
        y: 266.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440633600000,
        y: 284,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1440720000000,
        y: 308.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441065600000,
        y: 317.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441152000000,
        y: 309.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441238400000,
        y: 321.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441324800000,
        y: 314.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441584000000,
        y: 306.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441670400000,
        y: 311.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441756800000,
        y: 303.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441843200000,
        y: 303.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1441929600000,
        y: 301.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442188800000,
        y: 294,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442275200000,
        y: 292.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442361600000,
        y: 308.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442448000000,
        y: 306,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442534400000,
        y: 299.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442793600000,
        y: 300.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442880000000,
        y: 300.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1442966400000,
        y: 301.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443052800000,
        y: 296.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443139200000,
        y: 299,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443398400000,
        y: 292.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443484800000,
        y: 296.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443571200000,
        y: 297.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443657600000,
        y: 297.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1443744000000,
        y: 292.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444003200000,
        y: 304,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444089600000,
        y: 315.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444176000000,
        y: 317.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444262400000,
        y: 322.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444348800000,
        y: 323.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444608000000,
        y: 315,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444694400000,
        y: 305.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444780800000,
        y: 300.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444867200000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1444953600000,
        y: 302.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445212800000,
        y: 299.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445299200000,
        y: 294.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445385600000,
        y: 292.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445472000000,
        y: 292,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445558400000,
        y: 289.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445817600000,
        y: 288.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445904000000,
        y: 283.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1445990400000,
        y: 295.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446076800000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446163200000,
        y: 300.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446422400000,
        y: 298,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446508800000,
        y: 303.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446595200000,
        y: 298.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446681600000,
        y: 293.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1446768000000,
        y: 288.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447027200000,
        y: 285.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447113600000,
        y: 285.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447200000000,
        y: 278.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447286400000,
        y: 270.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447372800000,
        y: 265.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447632000000,
        y: 260.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447718400000,
        y: 263.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447804800000,
        y: 260.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447891200000,
        y: 261.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1447977600000,
        y: 262.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448236800000,
        y: 265.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448323200000,
        y: 270.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448409600000,
        y: 271,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448496000000,
        y: 270.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448582400000,
        y: 269.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448841600000,
        y: 268.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1448928000000,
        y: 264,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449014400000,
        y: 255.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449100800000,
        y: 256,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449187200000,
        y: 254,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449446400000,
        y: 241,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449532800000,
        y: 236.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449619200000,
        y: 235,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449705600000,
        y: 231.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1449792000000,
        y: 224,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450051200000,
        y: 220,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450137600000,
        y: 226.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450224000000,
        y: 219.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450310400000,
        y: 218,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450396800000,
        y: 217,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450656000000,
        y: 212.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450742400000,
        y: 213.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450828800000,
        y: 217.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1450915200000,
        y: 222.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451347200000,
        y: 221,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451433600000,
        y: 216,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451520000000,
        y: 214.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451865600000,
        y: 219.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1451952000000,
        y: 217.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452038400000,
        y: 206.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452124800000,
        y: 204,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452211200000,
        y: 196.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452470400000,
        y: 188.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452556800000,
        y: 181.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452643200000,
        y: 180,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452729600000,
        y: 180.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1452816000000,
        y: 174,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453075200000,
        y: 169.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453161600000,
        y: 170.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453248000000,
        y: 161.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453334400000,
        y: 168,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453420800000,
        y: 185.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453680000000,
        y: 180.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453766400000,
        y: 187,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453852800000,
        y: 191.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1453939200000,
        y: 202,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454025600000,
        y: 203.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454284800000,
        y: 203.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454371200000,
        y: 192.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454457600000,
        y: 200.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454544000000,
        y: 204,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454630400000,
        y: 199.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454889600000,
        y: 195.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1454976000000,
        y: 184,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455062400000,
        y: 178.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455148800000,
        y: 173.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455235200000,
        y: 187.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455494400000,
        y: 195.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455580800000,
        y: 190,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455667200000,
        y: 199.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455753600000,
        y: 200.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1455840000000,
        y: 193,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456099200000,
        y: 202,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456185600000,
        y: 194.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456272000000,
        y: 193.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456358400000,
        y: 197.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456444800000,
        y: 206.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456704000000,
        y: 208.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456790400000,
        y: 207.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456876800000,
        y: 210.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1456963200000,
        y: 211.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457049600000,
        y: 218.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457308800000,
        y: 229.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457395200000,
        y: 231.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457481600000,
        y: 235.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457568000000,
        y: 231,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457654400000,
        y: 237.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1457913600000,
        y: 232.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458000000000,
        y: 226,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458086400000,
        y: 232.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458172800000,
        y: 243.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458259200000,
        y: 243.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458518400000,
        y: 243.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458604800000,
        y: 245.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458691200000,
        y: 241.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1458777600000,
        y: 234,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459209600000,
        y: 231.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459296000000,
        y: 233,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459382400000,
        y: 235,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459468800000,
        y: 226.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459728000000,
        y: 221,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459814400000,
        y: 216.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459900800000,
        y: 227.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1459987200000,
        y: 226.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460073600000,
        y: 243,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460332800000,
        y: 250.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460419200000,
        y: 259.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460505600000,
        y: 262.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460592000000,
        y: 261.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460678400000,
        y: 254,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1460937600000,
        y: 252,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461024000000,
        y: 259,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461110400000,
        y: 265.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461196800000,
        y: 268.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461283200000,
        y: 270.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461542400000,
        y: 268.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461628800000,
        y: 272,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461715200000,
        y: 278,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461801600000,
        y: 286.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1461888000000,
        y: 287.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462233600000,
        y: 276,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462320000000,
        y: 275.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462406400000,
        y: 279,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462492800000,
        y: 280.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462752000000,
        y: 271,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462838400000,
        y: 277.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1462924800000,
        y: 290.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463011200000,
        y: 294.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463097600000,
        y: 297.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463356800000,
        y: 304.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463443200000,
        y: 308,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463529600000,
        y: 308.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463616000000,
        y: 301.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463702400000,
        y: 304.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1463961600000,
        y: 302.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464048000000,
        y: 306.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464134400000,
        y: 309.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464220800000,
        y: 313.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464307200000,
        y: 310.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464652800000,
        y: 312.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464739200000,
        y: 311.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464825600000,
        y: 312.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1464912000000,
        y: 313,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465171200000,
        y: 317.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465257600000,
        y: 322.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465344000000,
        y: 328.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465430400000,
        y: 327,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465516800000,
        y: 320.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465776000000,
        y: 318.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465862400000,
        y: 313.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1465948800000,
        y: 312,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466035200000,
        y: 302.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466121600000,
        y: 308.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466380800000,
        y: 320.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466467200000,
        y: 320.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466553600000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466640000000,
        y: 324,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466726400000,
        y: 312.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1466985600000,
        y: 302.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467072000000,
        y: 307.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467158400000,
        y: 321,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467244800000,
        y: 319.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467331200000,
        y: 317.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467590400000,
        y: 318.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467676800000,
        y: 305.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467763200000,
        y: 303.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467849600000,
        y: 301.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1467936000000,
        y: 295.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468195200000,
        y: 295.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468281600000,
        y: 301,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468368000000,
        y: 297.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468454400000,
        y: 300.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468540800000,
        y: 303,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468800000000,
        y: 298,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468886400000,
        y: 296.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1468972800000,
        y: 297,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469059200000,
        y: 294.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469145600000,
        y: 287.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469404800000,
        y: 282.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469491200000,
        y: 281.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469577600000,
        y: 277.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469664000000,
        y: 272.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1469750400000,
        y: 270.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470009600000,
        y: 263,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470096000000,
        y: 258.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470182400000,
        y: 262.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470268800000,
        y: 268.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470355200000,
        y: 271.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470614400000,
        y: 279,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470700800000,
        y: 280,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470787200000,
        y: 275.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470873600000,
        y: 281.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1470960000000,
        y: 289,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471219200000,
        y: 296.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471305600000,
        y: 302.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471392000000,
        y: 304.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471478400000,
        y: 314,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471564800000,
        y: 316.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471824000000,
        y: 309.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471910400000,
        y: 310.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1471996800000,
        y: 306.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472083200000,
        y: 309.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472169600000,
        y: 313.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472515200000,
        y: 305.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472601600000,
        y: 298.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472688000000,
        y: 283.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1472774400000,
        y: 289,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473033600000,
        y: 292.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473120000000,
        y: 292.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473206400000,
        y: 295,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473292800000,
        y: 311.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473379200000,
        y: 307.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473638400000,
        y: 305.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473724800000,
        y: 298,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473811200000,
        y: 292.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473897600000,
        y: 292.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1473984000000,
        y: 289.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474243200000,
        y: 291.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474329600000,
        y: 290.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474416000000,
        y: 296.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474502400000,
        y: 303.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474588800000,
        y: 299.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474848000000,
        y: 300.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1474934400000,
        y: 293.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475020800000,
        y: 298.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475107200000,
        y: 313.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475193600000,
        y: 314.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475452800000,
        y: 316.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475539200000,
        y: 324,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475625600000,
        y: 328.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475712000000,
        y: 333.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1475798400000,
        y: 334.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476057600000,
        y: 339.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476144000000,
        y: 334.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476230400000,
        y: 331,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476316800000,
        y: 333,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476403200000,
        y: 332.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476662400000,
        y: 329.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476748800000,
        y: 328.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476835200000,
        y: 337.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1476921600000,
        y: 332,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477008000000,
        y: 329.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477267200000,
        y: 328.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477353600000,
        y: 325.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477440000000,
        y: 321.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477526400000,
        y: 322.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477612800000,
        y: 321.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477872000000,
        y: 309.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1477958400000,
        y: 304.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478044800000,
        y: 296.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478131200000,
        y: 292.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478217600000,
        y: 288.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478476800000,
        y: 290.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478563200000,
        y: 293,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478649600000,
        y: 293.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478736000000,
        y: 292,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1478822400000,
        y: 283.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479081600000,
        y: 279.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479168000000,
        y: 292.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479254400000,
        y: 296.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479340800000,
        y: 294,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479427200000,
        y: 293,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479686400000,
        y: 306,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479772800000,
        y: 306.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479859200000,
        y: 309,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1479945600000,
        y: 309,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480032000000,
        y: 301.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480291200000,
        y: 307.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480377600000,
        y: 295.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480464000000,
        y: 314.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480550400000,
        y: 333.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480636800000,
        y: 337,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480896000000,
        y: 340.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1480982400000,
        y: 337.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481068800000,
        y: 333.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481155200000,
        y: 335.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481241600000,
        y: 340.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481500800000,
        y: 352.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481587200000,
        y: 353.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481673600000,
        y: 348.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481760000000,
        y: 340.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1481846400000,
        y: 347.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482105600000,
        y: 347.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482192000000,
        y: 350.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482278400000,
        y: 348,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482364800000,
        y: 348,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482451200000,
        y: 346.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1482969600000,
        y: 358.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483056000000,
        y: 358.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483401600000,
        y: 354.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483488000000,
        y: 352.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483574400000,
        y: 358.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483660800000,
        y: 360.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1483920000000,
        y: 352,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484006400000,
        y: 344.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484092800000,
        y: 347.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484179200000,
        y: 353,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484265600000,
        y: 353,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484524800000,
        y: 351.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484611200000,
        y: 353.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484697600000,
        y: 348,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484784000000,
        y: 344.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1484870400000,
        y: 354,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485129600000,
        y: 353.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485216000000,
        y: 354.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485302400000,
        y: 352.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485388800000,
        y: 358,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485475200000,
        y: 354.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485734400000,
        y: 351.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485820800000,
        y: 356,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485907200000,
        y: 358,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1485993600000,
        y: 357,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486080000000,
        y: 356.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486339200000,
        y: 352.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486425600000,
        y: 345,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486512000000,
        y: 345.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486598400000,
        y: 348.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486684800000,
        y: 355.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1486944000000,
        y: 349.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487030400000,
        y: 351.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487116800000,
        y: 349.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487203200000,
        y: 348.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487289600000,
        y: 348.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487548800000,
        y: 350.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487635200000,
        y: 353.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487721600000,
        y: 347.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487808000000,
        y: 352,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1487894400000,
        y: 354,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488153600000,
        y: 352.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488240000000,
        y: 348.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488326400000,
        y: 354.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488412800000,
        y: 347.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488499200000,
        y: 348.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488758400000,
        y: 350,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488844800000,
        y: 351.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1488931200000,
        y: 342.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489017600000,
        y: 325.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489104000000,
        y: 322.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489363200000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489449600000,
        y: 317.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489536000000,
        y: 323.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489622400000,
        y: 325,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489708800000,
        y: 325.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1489968000000,
        y: 325,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490054400000,
        y: 322,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490140800000,
        y: 316.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490227200000,
        y: 318.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490313600000,
        y: 318.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490572800000,
        y: 319.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490659200000,
        y: 324.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490745600000,
        y: 328.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490832000000,
        y: 335.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1490918400000,
        y: 336.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491177600000,
        y: 339,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491264000000,
        y: 342.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491350400000,
        y: 345.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491436800000,
        y: 348.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491523200000,
        y: 352.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491782400000,
        y: 356.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491868800000,
        y: 357.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1491955200000,
        y: 358.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492041600000,
        y: 358.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492473600000,
        y: 353.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492560000000,
        y: 347.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492646400000,
        y: 339.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492732800000,
        y: 334.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1492992000000,
        y: 332.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493078400000,
        y: 333.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493164800000,
        y: 336.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493251200000,
        y: 329,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493337600000,
        y: 332.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493683200000,
        y: 332.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493769600000,
        y: 329,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493856000000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1493942400000,
        y: 322.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494201600000,
        y: 323.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494288000000,
        y: 322.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494374400000,
        y: 328.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494460800000,
        y: 335,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494547200000,
        y: 333.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494806400000,
        y: 342,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494892800000,
        y: 341.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1494979200000,
        y: 344.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495065600000,
        y: 346.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495152000000,
        y: 352.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495411200000,
        y: 354.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495497600000,
        y: 356.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495584000000,
        y: 357.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495670400000,
        y: 347.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1495756800000,
        y: 342.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496102400000,
        y: 342.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496188800000,
        y: 335.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496275200000,
        y: 337.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496361600000,
        y: 330.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496620800000,
        y: 327,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496707200000,
        y: 330.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496793600000,
        y: 326.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496880000000,
        y: 322.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1496966400000,
        y: 323.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497225600000,
        y: 325,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497312000000,
        y: 323.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497398400000,
        y: 316,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497484800000,
        y: 313,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497571200000,
        y: 313.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497830400000,
        y: 314,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1497916800000,
        y: 305.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498003200000,
        y: 303.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498089600000,
        y: 302.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498176000000,
        y: 303.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498435200000,
        y: 304.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498521600000,
        y: 311.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498608000000,
        y: 315,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498694400000,
        y: 318.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1498780800000,
        y: 321.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499040000000,
        y: 330,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499126400000,
        y: 331.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499212800000,
        y: 321.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499299200000,
        y: 325,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499385600000,
        y: 315.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499644800000,
        y: 315.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499731200000,
        y: 317.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499817600000,
        y: 321.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499904000000,
        y: 325,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1499990400000,
        y: 327.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500249600000,
        y: 329,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500336000000,
        y: 328.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500422400000,
        y: 333.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500508800000,
        y: 333.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500595200000,
        y: 327.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500854400000,
        y: 326.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1500940800000,
        y: 334.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501027200000,
        y: 341.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501113600000,
        y: 343.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501200000000,
        y: 348.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501459200000,
        y: 350.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501545600000,
        y: 345,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501632000000,
        y: 347.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501718400000,
        y: 349.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1501804800000,
        y: 348,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502064000000,
        y: 346.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502150400000,
        y: 350.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502236800000,
        y: 351,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502323200000,
        y: 350.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502409600000,
        y: 345.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502668800000,
        y: 343.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502755200000,
        y: 338,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502841600000,
        y: 337.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1502928000000,
        y: 337.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503014400000,
        y: 342.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503273600000,
        y: 344.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503360000000,
        y: 344.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503446400000,
        y: 347.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503532800000,
        y: 346.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503619200000,
        y: 347,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1503964800000,
        y: 342.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504051200000,
        y: 341.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504137600000,
        y: 348,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504224000000,
        y: 353,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504483200000,
        y: 351.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504569600000,
        y: 355.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504656000000,
        y: 361.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504742400000,
        y: 362,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1504828800000,
        y: 360.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505088000000,
        y: 358.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505174400000,
        y: 361.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505260800000,
        y: 365.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505347200000,
        y: 370.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505433600000,
        y: 370.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505692800000,
        y: 367.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505779200000,
        y: 367.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505865600000,
        y: 373.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1505952000000,
        y: 373,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506038400000,
        y: 375.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506297600000,
        y: 383.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506384000000,
        y: 386.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506470400000,
        y: 385,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506556800000,
        y: 384.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506643200000,
        y: 379.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506902400000,
        y: 369.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1506988800000,
        y: 365.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507075200000,
        y: 365.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507161600000,
        y: 372,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507248000000,
        y: 361.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507507200000,
        y: 363,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507593600000,
        y: 371.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507680000000,
        y: 371.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507766400000,
        y: 367,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1507852800000,
        y: 371.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508112000000,
        y: 376.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508198400000,
        y: 376,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508284800000,
        y: 377.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508371200000,
        y: 374.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508457600000,
        y: 375.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508716800000,
        y: 375.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508803200000,
        y: 376.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508889600000,
        y: 379.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1508976000000,
        y: 382,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509062400000,
        y: 390.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509321600000,
        y: 393,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509408000000,
        y: 396.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509494400000,
        y: 396.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509580800000,
        y: 397,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509667200000,
        y: 403.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1509926400000,
        y: 417.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510012800000,
        y: 418,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510099200000,
        y: 417,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510185600000,
        y: 418.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510272000000,
        y: 417.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510531200000,
        y: 415,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510617600000,
        y: 404.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510704000000,
        y: 403.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510790400000,
        y: 401.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1510876800000,
        y: 405.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511136000000,
        y: 404.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511222400000,
        y: 406,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511308800000,
        y: 409.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511395200000,
        y: 411.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511481600000,
        y: 412.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511740800000,
        y: 412.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511827200000,
        y: 412.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1511913600000,
        y: 410,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512000000000,
        y: 408.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512086400000,
        y: 415.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512345600000,
        y: 411.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512432000000,
        y: 410.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512518400000,
        y: 403,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512604800000,
        y: 405.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512691200000,
        y: 411,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1512950400000,
        y: 417.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513036800000,
        y: 414.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513123200000,
        y: 407.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513209600000,
        y: 411,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513296000000,
        y: 412.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513555200000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513641600000,
        y: 414.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513728000000,
        y: 419.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513814400000,
        y: 423.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1513900800000,
        y: 422.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514332800000,
        y: 434.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514419200000,
        y: 433.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514505600000,
        y: 435.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514851200000,
        y: 437.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1514937600000,
        y: 442.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515024000000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515110400000,
        y: 443.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515369600000,
        y: 444,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515456000000,
        y: 449,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515542400000,
        y: 454.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515628800000,
        y: 457.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515715200000,
        y: 457.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1515974400000,
        y: 461.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516060800000,
        y: 458.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516147200000,
        y: 457.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516233600000,
        y: 457.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516320000000,
        y: 452.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516579200000,
        y: 454.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516665600000,
        y: 458,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516752000000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516838400000,
        y: 462.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1516924800000,
        y: 462.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517184000000,
        y: 458,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517270400000,
        y: 451.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517356800000,
        y: 452.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517443200000,
        y: 456.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517529600000,
        y: 453.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517788800000,
        y: 449.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517875200000,
        y: 442.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1517961600000,
        y: 435.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518048000000,
        y: 425.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518134400000,
        y: 417,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518393600000,
        y: 414.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518480000000,
        y: 411,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518566400000,
        y: 417.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518652800000,
        y: 422.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518739200000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1518998400000,
        y: 430.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519084800000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519171200000,
        y: 429.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519257600000,
        y: 434.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519344000000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519603200000,
        y: 442,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519689600000,
        y: 437,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519776000000,
        y: 427,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519862400000,
        y: 417.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1519948800000,
        y: 418,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520208000000,
        y: 427.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520294400000,
        y: 429,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520380800000,
        y: 425.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520467200000,
        y: 415.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520553600000,
        y: 423,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520812800000,
        y: 422.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520899200000,
        y: 419.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1520985600000,
        y: 419.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521072000000,
        y: 421.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521158400000,
        y: 427,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521417600000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521504000000,
        y: 434.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521590400000,
        y: 445,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521676800000,
        y: 444.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1521763200000,
        y: 448.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522022400000,
        y: 448.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522108800000,
        y: 449.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522195200000,
        y: 445.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522281600000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522713600000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522800000000,
        y: 425.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522886400000,
        y: 424.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1522972800000,
        y: 419,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523232000000,
        y: 423.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523318400000,
        y: 435.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523404800000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523491200000,
        y: 446.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523577600000,
        y: 451.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523836800000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1523923200000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524009600000,
        y: 451,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524096000000,
        y: 459.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524182400000,
        y: 455.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524441600000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524528000000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524614400000,
        y: 451,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524700800000,
        y: 454.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1524787200000,
        y: 455.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525046400000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525132800000,
        y: 459.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525219200000,
        y: 453.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525305600000,
        y: 451.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525392000000,
        y: 459.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525737600000,
        y: 461.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525824000000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525910400000,
        y: 477.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1525996800000,
        y: 478.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526256000000,
        y: 481.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526342400000,
        y: 489.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526428800000,
        y: 488.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526515200000,
        y: 499.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526601600000,
        y: 497.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526860800000,
        y: 499,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1526947200000,
        y: 504.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527033600000,
        y: 496.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527120000000,
        y: 490.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527206400000,
        y: 474,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527552000000,
        y: 463.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527638400000,
        y: 470.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527724800000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1527811200000,
        y: 475,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528070400000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528156800000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528243200000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528329600000,
        y: 478.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528416000000,
        y: 478.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528675200000,
        y: 481.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528761600000,
        y: 481,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528848000000,
        y: 482,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1528934400000,
        y: 481.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529020800000,
        y: 468.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529280000000,
        y: 474.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529366400000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529452800000,
        y: 482,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529539200000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529625600000,
        y: 483.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529884800000,
        y: 485.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1529971200000,
        y: 488.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530057600000,
        y: 500.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530144000000,
        y: 501.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530230400000,
        y: 508.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530489600000,
        y: 510.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530576000000,
        y: 503,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530662400000,
        y: 511.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530748800000,
        y: 509,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1530835200000,
        y: 504.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531094400000,
        y: 508.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531180800000,
        y: 511.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531267200000,
        y: 494.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531353600000,
        y: 480,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531440000000,
        y: 492,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531699200000,
        y: 479.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531785600000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531872000000,
        y: 475.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1531958400000,
        y: 478.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532044800000,
        y: 478.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532304000000,
        y: 481.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532390400000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532476800000,
        y: 486,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532563200000,
        y: 488.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532649600000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532908800000,
        y: 488,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1532995200000,
        y: 486.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533081600000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533168000000,
        y: 475,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533254400000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533513600000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533600000000,
        y: 479.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533686400000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533772800000,
        y: 466,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1533859200000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534118400000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534204800000,
        y: 470.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534291200000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534377600000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534464000000,
        y: 462.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534723200000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534809600000,
        y: 468,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534896000000,
        y: 479.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1534982400000,
        y: 481.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535068800000,
        y: 488.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535414400000,
        y: 490,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535500800000,
        y: 492,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535587200000,
        y: 497.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535673600000,
        y: 498.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1535932800000,
        y: 499,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536019200000,
        y: 502.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536105600000,
        y: 495,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536192000000,
        y: 489.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536278400000,
        y: 488.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536537600000,
        y: 493.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536624000000,
        y: 502.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536710400000,
        y: 513,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536796800000,
        y: 505,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1536883200000,
        y: 502,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537142400000,
        y: 505.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537228800000,
        y: 512.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537315200000,
        y: 516.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537401600000,
        y: 514,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537488000000,
        y: 514,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537747200000,
        y: 525.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537833600000,
        y: 533.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1537920000000,
        y: 534.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538006400000,
        y: 537.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538092800000,
        y: 545.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538352000000,
        y: 562.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538438400000,
        y: 567.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538524800000,
        y: 570.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538611200000,
        y: 570.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538697600000,
        y: 566.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1538956800000,
        y: 559,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539043200000,
        y: 564.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539129600000,
        y: 558.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539216000000,
        y: 540,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539302400000,
        y: 537.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539561600000,
        y: 540.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539648000000,
        y: 542,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539734400000,
        y: 536.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539820800000,
        y: 533.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1539907200000,
        y: 536.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540166400000,
        y: 535.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540252800000,
        y: 518.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540339200000,
        y: 513.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540425600000,
        y: 516.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540512000000,
        y: 518.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540771200000,
        y: 518.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540857600000,
        y: 512.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1540944000000,
        y: 509.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541030400000,
        y: 495.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541116800000,
        y: 491.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541376000000,
        y: 495,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541462400000,
        y: 487,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541548800000,
        y: 486.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541635200000,
        y: 483.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541721600000,
        y: 475,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1541980800000,
        y: 477.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542067200000,
        y: 453.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542153600000,
        y: 448.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542240000000,
        y: 450.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542326400000,
        y: 450.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542585600000,
        y: 449.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542672000000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542758400000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542844800000,
        y: 431.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1542931200000,
        y: 414.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543190400000,
        y: 418.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543276800000,
        y: 417,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543363200000,
        y: 412,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543449600000,
        y: 410.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543536000000,
        y: 406.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543795200000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543881600000,
        y: 419.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1543968000000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544054400000,
        y: 403.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544140800000,
        y: 418.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544400000000,
        y: 409.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544486400000,
        y: 408.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544572800000,
        y: 408,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544659200000,
        y: 407.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1544745600000,
        y: 406.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545004800000,
        y: 401,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545091200000,
        y: 384.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545177600000,
        y: 385,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545264000000,
        y: 374.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545350400000,
        y: 363,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545609600000,
        y: 360.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545868800000,
        y: 367,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1545955200000,
        y: 359,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546214400000,
        y: 360.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546387200000,
        y: 371.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546473600000,
        y: 368.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546560000000,
        y: 378.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546819200000,
        y: 386.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546905600000,
        y: 390.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1546992000000,
        y: 406,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547078400000,
        y: 413.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547164800000,
        y: 410.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547424000000,
        y: 405.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547510400000,
        y: 405.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547596800000,
        y: 411.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547683200000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1547769600000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548028800000,
        y: 422.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548115200000,
        y: 415,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548201600000,
        y: 415,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548288000000,
        y: 416.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548374400000,
        y: 420.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548633600000,
        y: 410.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548720000000,
        y: 418.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548806400000,
        y: 426.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548892800000,
        y: 428,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1548979200000,
        y: 424.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549238400000,
        y: 427,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549324800000,
        y: 425.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549411200000,
        y: 427.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549497600000,
        y: 422,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549584000000,
        y: 424.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549843200000,
        y: 422.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1549929600000,
        y: 429.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550016000000,
        y: 437.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550102400000,
        y: 442.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550188800000,
        y: 451.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550448000000,
        y: 455.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550534400000,
        y: 454.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550620800000,
        y: 461.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550707200000,
        y: 462,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1550793600000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551052800000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551139200000,
        y: 446.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551225600000,
        y: 454.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551312000000,
        y: 454.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551398400000,
        y: 448.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551657600000,
        y: 451.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551744000000,
        y: 452.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551830400000,
        y: 451.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1551916800000,
        y: 450.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552003200000,
        y: 445.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552262400000,
        y: 452.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552348800000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552435200000,
        y: 458.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552521600000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552608000000,
        y: 458.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552867200000,
        y: 460.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1552953600000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553040000000,
        y: 465.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553126400000,
        y: 465.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553212800000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553472000000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553558400000,
        y: 462.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553644800000,
        y: 463.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553731200000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1553817600000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554076800000,
        y: 474,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554163200000,
        y: 478.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554249600000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554336000000,
        y: 479.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554422400000,
        y: 480.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554681600000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554768000000,
        y: 486.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554854400000,
        y: 488.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1554940800000,
        y: 485,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555027200000,
        y: 484.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555286400000,
        y: 483.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555372800000,
        y: 483.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555459200000,
        y: 483.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555545600000,
        y: 484,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1555977600000,
        y: 499.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556064000000,
        y: 500.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556150400000,
        y: 501.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556236800000,
        y: 484.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556496000000,
        y: 484.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556582400000,
        y: 487.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556668800000,
        y: 489,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556755200000,
        y: 481.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1556841600000,
        y: 486.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557187200000,
        y: 479.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557273600000,
        y: 481,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557360000000,
        y: 478,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557446400000,
        y: 483.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557705600000,
        y: 483.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557792000000,
        y: 483.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557878400000,
        y: 488,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1557964800000,
        y: 496,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558051200000,
        y: 494.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558310400000,
        y: 492.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558396800000,
        y: 491.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558483200000,
        y: 483,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558569600000,
        y: 463.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1558656000000,
        y: 460,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559001600000,
        y: 470.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559088000000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559174400000,
        y: 459,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559260800000,
        y: 441.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559520000000,
        y: 433.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559606400000,
        y: 435.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559692800000,
        y: 427,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559779200000,
        y: 428.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1559865600000,
        y: 441,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560124800000,
        y: 441.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560211200000,
        y: 438.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560297600000,
        y: 425.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560384000000,
        y: 429,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560470400000,
        y: 431,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560729600000,
        y: 426,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560816000000,
        y: 433.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560902400000,
        y: 435,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1560988800000,
        y: 448.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561075200000,
        y: 457.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561334400000,
        y: 456.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561420800000,
        y: 460.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561507200000,
        y: 467.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561593600000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561680000000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1561939200000,
        y: 465.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562025600000,
        y: 453.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562112000000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562198400000,
        y: 458.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562284800000,
        y: 460.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562544000000,
        y: 462.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562630400000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562716800000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562803200000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1562889600000,
        y: 476.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563148800000,
        y: 470.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563235200000,
        y: 464.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563321600000,
        y: 452,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563408000000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563494400000,
        y: 441.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563753600000,
        y: 448,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563840000000,
        y: 447.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1563926400000,
        y: 446.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564012800000,
        y: 445.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564099200000,
        y: 443.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564358400000,
        y: 445.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564444800000,
        y: 452.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564531200000,
        y: 459.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564617600000,
        y: 439.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564704000000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1564963200000,
        y: 423.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565049600000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565136000000,
        y: 391.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565222400000,
        y: 394.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565308800000,
        y: 402.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565568000000,
        y: 402.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565654400000,
        y: 416.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565740800000,
        y: 404,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565827200000,
        y: 402.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1565913600000,
        y: 402.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566172800000,
        y: 409,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566259200000,
        y: 410,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566345600000,
        y: 413,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566432000000,
        y: 410.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566518400000,
        y: 406.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566864000000,
        y: 406,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1566950400000,
        y: 415,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567036800000,
        y: 418.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567123200000,
        y: 411.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567382400000,
        y: 414.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567468800000,
        y: 418.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567555200000,
        y: 430.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567641600000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567728000000,
        y: 433.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1567987200000,
        y: 445.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568073600000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568160000000,
        y: 436.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568246400000,
        y: 430.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568332800000,
        y: 435,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568592000000,
        y: 482.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568678400000,
        y: 481,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568764800000,
        y: 465.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568851200000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1568937600000,
        y: 465,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569196800000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569283200000,
        y: 455.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569369600000,
        y: 448.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569456000000,
        y: 448.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569542400000,
        y: 445.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569801600000,
        y: 437.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569888000000,
        y: 418.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1569974400000,
        y: 412.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570060800000,
        y: 414,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570147200000,
        y: 419,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570406400000,
        y: 425.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570492800000,
        y: 418.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570579200000,
        y: 422.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570665600000,
        y: 420.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1570752000000,
        y: 427.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571011200000,
        y: 420.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571097600000,
        y: 411.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571184000000,
        y: 422.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571270400000,
        y: 408.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571356800000,
        y: 409.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571616000000,
        y: 415.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571702400000,
        y: 422.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571788800000,
        y: 427.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571875200000,
        y: 434,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1571961600000,
        y: 436.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572220800000,
        y: 431,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572307200000,
        y: 430,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572393600000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572480000000,
        y: 421.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572566400000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572825600000,
        y: 447.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572912000000,
        y: 450.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1572998400000,
        y: 445.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573084800000,
        y: 448.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573171200000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573430400000,
        y: 444.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573516800000,
        y: 439.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573603200000,
        y: 439.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573689600000,
        y: 440,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1573776000000,
        y: 446.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574035200000,
        y: 440.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574121600000,
        y: 432.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574208000000,
        y: 440.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574294400000,
        y: 449,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574380800000,
        y: 446.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574640000000,
        y: 449.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574726400000,
        y: 453.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574812800000,
        y: 451,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574899200000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1574985600000,
        y: 438.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575244800000,
        y: 429,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575331200000,
        y: 429.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575417600000,
        y: 441.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575504000000,
        y: 446.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575590400000,
        y: 450.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575849600000,
        y: 450.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1575936000000,
        y: 451.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576022400000,
        y: 446.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576108800000,
        y: 454.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576195200000,
        y: 465.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576454400000,
        y: 469.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576540800000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576627200000,
        y: 472.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576713600000,
        y: 474.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1576800000000,
        y: 469.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577059200000,
        y: 472.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577145600000,
        y: 477,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577404800000,
        y: 483.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577664000000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577750400000,
        y: 478.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1577923200000,
        y: 477.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578009600000,
        y: 490.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578268800000,
        y: 496.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578355200000,
        y: 494.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578441600000,
        y: 477.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578528000000,
        y: 472.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578614400000,
        y: 471.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578873600000,
        y: 463.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1578960000000,
        y: 467.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579046400000,
        y: 463.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579132800000,
        y: 468.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579219200000,
        y: 467.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579478400000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579564800000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579651200000,
        y: 455.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579737600000,
        y: 446.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1579824000000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580083200000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580169600000,
        y: 431.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580256000000,
        y: 429.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580342400000,
        y: 419,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580428800000,
        y: 412.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580688000000,
        y: 396,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580774400000,
        y: 396.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580860800000,
        y: 403.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1580947200000,
        y: 399.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581033600000,
        y: 397.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581292800000,
        y: 389.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581379200000,
        y: 391.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581465600000,
        y: 402.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581552000000,
        y: 405,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581638400000,
        y: 409,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581897600000,
        y: 405.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1581984000000,
        y: 406,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582070400000,
        y: 416.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582156800000,
        y: 417.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582243200000,
        y: 404.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582502400000,
        y: 387.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582588800000,
        y: 379.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582675200000,
        y: 370.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582761600000,
        y: 353.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1582848000000,
        y: 340.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583107200000,
        y: 352.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583193600000,
        y: 359,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583280000000,
        y: 353.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583366400000,
        y: 348.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583452800000,
        y: 309.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583712000000,
        y: 241,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583798400000,
        y: 252.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583884800000,
        y: 245.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1583971200000,
        y: 226.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584057600000,
        y: 226.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584316800000,
        y: 202.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584403200000,
        y: 192,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584489600000,
        y: 153.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584576000000,
        y: 172.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584662400000,
        y: 166.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1584921600000,
        y: 162.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585008000000,
        y: 172,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585094400000,
        y: 175.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585180800000,
        y: 166.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585267200000,
        y: 144.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585526400000,
        y: 130,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585612800000,
        y: 140,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585699200000,
        y: 134.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585785600000,
        y: 163,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1585872000000,
        y: 193.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586131200000,
        y: 184.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586217600000,
        y: 178.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586304000000,
        y: 179.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586390400000,
        y: 177.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586822400000,
        y: 165.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586908800000,
        y: 155,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1586995200000,
        y: 158.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587081600000,
        y: 160,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587340800000,
        y: 58.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587513600000,
        y: 117,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587600000000,
        y: 125.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587686400000,
        y: 120.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1587945600000,
        y: 105.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588118400000,
        y: 125.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588204800000,
        y: 146.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588291200000,
        y: 164.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588550400000,
        y: 166,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588636800000,
        y: 191.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588723200000,
        y: 184.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1588809600000,
        y: 185.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589155200000,
        y: 186.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589241600000,
        y: 189.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589328000000,
        y: 186.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589414400000,
        y: 195.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589500800000,
        y: 207.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589760000000,
        y: 230,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589846400000,
        y: 225.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1589932800000,
        y: 234.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590019200000,
        y: 237,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590105600000,
        y: 230,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590451200000,
        y: 236.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590537600000,
        y: 230.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590624000000,
        y: 229,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590710400000,
        y: 234.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1590969600000,
        y: 253,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591056000000,
        y: 263.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591142400000,
        y: 264.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591228800000,
        y: 264.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591315200000,
        y: 279.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591574400000,
        y: 285,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591660800000,
        y: 282.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591747200000,
        y: 285.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591833600000,
        y: 271.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1591920000000,
        y: 266.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592179200000,
        y: 270.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592265600000,
        y: 279.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592352000000,
        y: 282.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592438400000,
        y: 286.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592524800000,
        y: 294.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592784000000,
        y: 296.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592870400000,
        y: 296.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1592956800000,
        y: 283,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593043200000,
        y: 282.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593129600000,
        y: 281.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593388800000,
        y: 286.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593475200000,
        y: 289.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593561600000,
        y: 294.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593648000000,
        y: 300.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593734400000,
        y: 301.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1593993600000,
        y: 305.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594080000000,
        y: 304,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594166400000,
        y: 303,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594252800000,
        y: 298.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594339200000,
        y: 301.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594598400000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594684800000,
        y: 302.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594771200000,
        y: 306.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594857600000,
        y: 306.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1594944000000,
        y: 303,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595203200000,
        y: 302.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595289600000,
        y: 313,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595376000000,
        y: 311,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595462400000,
        y: 311.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595548800000,
        y: 307.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595808000000,
        y: 306.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595894400000,
        y: 307.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1595980800000,
        y: 309,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596067200000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596153600000,
        y: 301.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596412800000,
        y: 307.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596499200000,
        y: 309.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596585600000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596672000000,
        y: 315.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1596758400000,
        y: 310.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597017600000,
        y: 312.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597104000000,
        y: 313.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597190400000,
        y: 315.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597276800000,
        y: 315,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597363200000,
        y: 312.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597622400000,
        y: 313.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597708800000,
        y: 318,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597795200000,
        y: 317.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597881600000,
        y: 314.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1597968000000,
        y: 309,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598227200000,
        y: 314.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598313600000,
        y: 320,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598400000000,
        y: 319.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598486400000,
        y: 315.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598572800000,
        y: 315.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1598918400000,
        y: 315.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599004800000,
        y: 309.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599091200000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599177600000,
        y: 295.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599436800000,
        y: 289.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599523200000,
        y: 275,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599609600000,
        y: 278.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599696000000,
        y: 277,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1599782400000,
        y: 278,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600041600000,
        y: 275.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600128000000,
        y: 279.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600214400000,
        y: 291,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600300800000,
        y: 298.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600387200000,
        y: 300.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600646400000,
        y: 289.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600732800000,
        y: 290.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600819200000,
        y: 291.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600905600000,
        y: 291.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1600992000000,
        y: 292.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601251200000,
        y: 295,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601337600000,
        y: 287.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601424000000,
        y: 289,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601510400000,
        y: 281.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601596800000,
        y: 280.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601856000000,
        y: 290.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1601942400000,
        y: 299,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602028800000,
        y: 296,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602115200000,
        y: 302.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602201600000,
        y: 304,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602460800000,
        y: 296.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602547200000,
        y: 298.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602633600000,
        y: 303.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602720000000,
        y: 301.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1602806400000,
        y: 303.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603065600000,
        y: 302.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603152000000,
        y: 302,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603238400000,
        y: 296.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603324800000,
        y: 299.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603411200000,
        y: 296.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603670400000,
        y: 286,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603756800000,
        y: 290.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603843200000,
        y: 279.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1603929600000,
        y: 269,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604016000000,
        y: 266,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604275200000,
        y: 269.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604361600000,
        y: 278,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604448000000,
        y: 285.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604534400000,
        y: 287,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604620800000,
        y: 279.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604880000000,
        y: 294.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1604966400000,
        y: 302.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605052800000,
        y: 308.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605139200000,
        y: 306,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605225600000,
        y: 302.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605484800000,
        y: 306.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605571200000,
        y: 305,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605657600000,
        y: 310.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605744000000,
        y: 308.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1605830400000,
        y: 311,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606089600000,
        y: 319,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606176000000,
        y: 330.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606262400000,
        y: 336,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606348800000,
        y: 334.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606435200000,
        y: 336.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606694400000,
        y: 332.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606780800000,
        y: 332.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606867200000,
        y: 335.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1606953600000,
        y: 339.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607040000000,
        y: 343,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607299200000,
        y: 342.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607385600000,
        y: 341.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607472000000,
        y: 342.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607558400000,
        y: 352.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607644800000,
        y: 351.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607904000000,
        y: 351.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1607990400000,
        y: 356.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608076800000,
        y: 357,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608163200000,
        y: 361,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608249600000,
        y: 365.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608508800000,
        y: 355.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608595200000,
        y: 353.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608681600000,
        y: 359.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1608768000000,
        y: 359.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609200000000,
        y: 359.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609286400000,
        y: 361.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609372800000,
        y: 361.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609718400000,
        y: 363,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609804800000,
        y: 374,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609891200000,
        y: 385.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1609977600000,
        y: 386.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610064000000,
        y: 393.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610323200000,
        y: 394.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610409600000,
        y: 400,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610496000000,
        y: 398.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610582400000,
        y: 396.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610668800000,
        y: 391.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1610928000000,
        y: 390.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611014400000,
        y: 395.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611100800000,
        y: 398.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611187200000,
        y: 397.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611273600000,
        y: 392.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611532800000,
        y: 393.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611619200000,
        y: 395.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611705600000,
        y: 396.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611792000000,
        y: 393,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1611878400000,
        y: 392.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612137600000,
        y: 396.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612224000000,
        y: 407,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612310400000,
        y: 413.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612396800000,
        y: 415.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612483200000,
        y: 422.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612742400000,
        y: 428.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612828800000,
        y: 432.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1612915200000,
        y: 436.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613001600000,
        y: 435.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613088000000,
        y: 440.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613347200000,
        y: 448.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613433600000,
        y: 448.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613520000000,
        y: 454,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613606400000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613692800000,
        y: 448.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1613952000000,
        y: 456,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614038400000,
        y: 462.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614124800000,
        y: 470.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614211200000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614297600000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614556800000,
        y: 458.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614643200000,
        y: 449.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614729600000,
        y: 454.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614816000000,
        y: 473.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1614902400000,
        y: 488.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615161600000,
        y: 489.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615248000000,
        y: 481.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615334400000,
        y: 478.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615420800000,
        y: 491,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615507200000,
        y: 491.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615766400000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615852800000,
        y: 480.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1615939200000,
        y: 476.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616025600000,
        y: 457.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616112000000,
        y: 451.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616371200000,
        y: 453.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616457600000,
        y: 437,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616544000000,
        y: 447,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616630400000,
        y: 436.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616716800000,
        y: 450,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1616976000000,
        y: 453.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617062400000,
        y: 452.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617148800000,
        y: 449.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617235200000,
        y: 446.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617667200000,
        y: 442.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617753600000,
        y: 438.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617840000000,
        y: 442.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1617926400000,
        y: 441.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618185600000,
        y: 443,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618272000000,
        y: 446,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618358400000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618444800000,
        y: 465.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618531200000,
        y: 466.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618790400000,
        y: 466.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618876800000,
        y: 461.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1618963200000,
        y: 456.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619049600000,
        y: 454.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619136000000,
        y: 457.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619395200000,
        y: 455,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619481600000,
        y: 458.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619568000000,
        y: 467,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619654400000,
        y: 470.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1619740800000,
        y: 464,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620086400000,
        y: 475.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620172800000,
        y: 479.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620259200000,
        y: 474.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620345600000,
        y: 472.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620604800000,
        y: 472,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620691200000,
        y: 474,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620777600000,
        y: 480.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620864000000,
        y: 469,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1620950400000,
        y: 473.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621209600000,
        y: 479,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621296000000,
        y: 478.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621382400000,
        y: 461,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621468800000,
        y: 457,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621555200000,
        y: 459.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621814400000,
        y: 471,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621900800000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1621987200000,
        y: 478.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622073600000,
        y: 481.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622160000000,
        y: 484.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622505600000,
        y: 488.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622592000000,
        y: 495,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622678400000,
        y: 495.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1622764800000,
        y: 499.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623024000000,
        y: 497.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623110400000,
        y: 498.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623196800000,
        y: 500.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623283200000,
        y: 501.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623369600000,
        y: 504.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623628800000,
        y: 507.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623715200000,
        y: 512.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623801600000,
        y: 517.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623888000000,
        y: 508.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1623974400000,
        y: 508.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624233600000,
        y: 515.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624320000000,
        y: 518,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624406400000,
        y: 521.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624492800000,
        y: 520.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624579200000,
        y: 524.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624838400000,
        y: 520.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1624924800000,
        y: 519.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625011200000,
        y: 519.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625097600000,
        y: 526.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625184000000,
        y: 530.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625443200000,
        y: 533.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625529600000,
        y: 523.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625616000000,
        y: 512.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625702400000,
        y: 514,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1625788800000,
        y: 526,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626048000000,
        y: 524.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626134400000,
        y: 530,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626220800000,
        y: 527,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626307200000,
        y: 515.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626393600000,
        y: 512,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626652800000,
        y: 483.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626739200000,
        y: 479.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626825600000,
        y: 496.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626912000000,
        y: 506.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1626998400000,
        y: 511.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627257600000,
        y: 511.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627344000000,
        y: 512,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627430400000,
        y: 514,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627516800000,
        y: 520.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627603200000,
        y: 524.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627862400000,
        y: 507.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1627948800000,
        y: 501,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628035200000,
        y: 490,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628121600000,
        y: 490,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628208000000,
        y: 488,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628467200000,
        y: 475.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628553600000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628640000000,
        y: 487.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628726400000,
        y: 491.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1628812800000,
        y: 488,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629072000000,
        y: 480,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629158400000,
        y: 478.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629244800000,
        y: 474,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629331200000,
        y: 456.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629417600000,
        y: 453.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629676800000,
        y: 469.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629763200000,
        y: 484.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629849600000,
        y: 490.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1629936000000,
        y: 488.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630022400000,
        y: 494,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630368000000,
        y: 498.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630454400000,
        y: 489,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630540800000,
        y: 500.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630627200000,
        y: 499.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630886400000,
        y: 496,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1630972800000,
        y: 493,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631059200000,
        y: 496.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631145600000,
        y: 495.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631232000000,
        y: 498.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631491200000,
        y: 504.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631577600000,
        y: 506.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631664000000,
        y: 516.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631750400000,
        y: 515.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1631836800000,
        y: 514.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632096000000,
        y: 509.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632182400000,
        y: 506.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632268800000,
        y: 516.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632355200000,
        y: 525.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632441600000,
        y: 531.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632700800000,
        y: 540.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632787200000,
        y: 539.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632873600000,
        y: 538.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1632960000000,
        y: 537,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633046400000,
        y: 536.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633305600000,
        y: 550,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633392000000,
        y: 563.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633478400000,
        y: 555.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633564800000,
        y: 558.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633651200000,
        y: 565.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633910400000,
        y: 572,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1633996800000,
        y: 570.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634083200000,
        y: 569.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634169600000,
        y: 573.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634256000000,
        y: 581.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634515200000,
        y: 581,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634601600000,
        y: 583.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634688000000,
        y: 588.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634774400000,
        y: 583,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1634860800000,
        y: 588.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635120000000,
        y: 594,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635206400000,
        y: 592.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635292800000,
        y: 581.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635379200000,
        y: 575.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635465600000,
        y: 577.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635724800000,
        y: 580.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635811200000,
        y: 580.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635897600000,
        y: 566.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1635984000000,
        y: 559.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636070400000,
        y: 566,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636329600000,
        y: 574.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636416000000,
        y: 580,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636502400000,
        y: 576,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636588800000,
        y: 568.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636675200000,
        y: 566,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1636934400000,
        y: 561.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637020800000,
        y: 565.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637107200000,
        y: 557.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637280000000,
        y: 540.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637539200000,
        y: 542.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637625600000,
        y: 557,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637712000000,
        y: 564,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637798400000,
        y: 561.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1637884800000,
        y: 532.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638144000000,
        y: 509.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638230400000,
        y: 488.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638316800000,
        y: 486.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638403200000,
        y: 481,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638489600000,
        y: 484.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638748800000,
        y: 490.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638835200000,
        y: 511.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1638921600000,
        y: 514.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639008000000,
        y: 510.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639094400000,
        y: 509.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639353600000,
        y: 509.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639440000000,
        y: 502.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639526400000,
        y: 500.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639612800000,
        y: 512.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639699200000,
        y: 505,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1639958400000,
        y: 483.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640044800000,
        y: 499.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640131200000,
        y: 509.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640217600000,
        y: 527,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640304000000,
        y: 527.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640736000000,
        y: 544.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640822400000,
        y: 541.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1640908800000,
        y: 539,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641254400000,
        y: 543.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641340800000,
        y: 550.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641427200000,
        y: 559.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641513600000,
        y: 559.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641772800000,
        y: 555.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641859200000,
        y: 569.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1641945600000,
        y: 580.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642032000000,
        y: 580.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642118400000,
        y: 591,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642377600000,
        y: 599,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642464000000,
        y: 604.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642550400000,
        y: 613,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642636800000,
        y: 614.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642723200000,
        y: 608.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1642982400000,
        y: 597.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643068800000,
        y: 607,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643155200000,
        y: 619,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643241600000,
        y: 618.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643328000000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643587200000,
        y: 624.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643673600000,
        y: 638,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643760000000,
        y: 636,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643846400000,
        y: 657,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1643932800000,
        y: 677,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644192000000,
        y: 667.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644278400000,
        y: 647.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644364800000,
        y: 668.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644451200000,
        y: 676,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644537600000,
        y: 680.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644796800000,
        y: 684.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644883200000,
        y: 672.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1644969600000,
        y: 679,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645056000000,
        y: 659.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645142400000,
        y: 661.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645401600000,
        y: 658.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645488000000,
        y: 676,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645574400000,
        y: 676.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645660800000,
        y: 708,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1645747200000,
        y: 669,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646006400000,
        y: 698,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646092800000,
        y: 754.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646179200000,
        y: 767.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646265600000,
        y: 761.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646352000000,
        y: 803.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646611200000,
        y: 874.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646697600000,
        y: 939.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646784000000,
        y: 904.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646870400000,
        y: 849.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1646956800000,
        y: 799.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647216000000,
        y: 773.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647302400000,
        y: 759.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647388800000,
        y: 718.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647475200000,
        y: 760.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647561600000,
        y: 767.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647820800000,
        y: 792.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647907200000,
        y: 818.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1647993600000,
        y: 836.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648080000000,
        y: 837.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648166400000,
        y: 843.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648425600000,
        y: 817,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648512000000,
        y: 815.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648598400000,
        y: 813,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648684800000,
        y: 811.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1648771200000,
        y: 833,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649030400000,
        y: 832.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649116800000,
        y: 832.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649203200000,
        y: 830.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649289600000,
        y: 830.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649376000000,
        y: 830,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649635200000,
        y: 829.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649721600000,
        y: 829,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649808000000,
        y: 827.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1649894400000,
        y: 826.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650326400000,
        y: 824.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650412800000,
        y: 822.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650499200000,
        y: 821.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650585600000,
        y: 820.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650844800000,
        y: 702.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1650931200000,
        y: 730.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651017600000,
        y: 736.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651104000000,
        y: 761,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651190400000,
        y: 782.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651536000000,
        y: 763.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651622400000,
        y: 782.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651708800000,
        y: 783.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1651795200000,
        y: 787,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652054400000,
        y: 737.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652140800000,
        y: 727,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652227200000,
        y: 750.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652313600000,
        y: 755.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652400000000,
        y: 763.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652659200000,
        y: 789.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652745600000,
        y: 810.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652832000000,
        y: 783,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1652918400000,
        y: 778.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653004800000,
        y: 790.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653264000000,
        y: 789,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653350400000,
        y: 791.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653436800000,
        y: 788.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653523200000,
        y: 813.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653609600000,
        y: 817.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653868800000,
        y: 843.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1653955200000,
        y: 860.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654041600000,
        y: 836.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654473600000,
        y: 869.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654560000000,
        y: 887.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654646400000,
        y: 908.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654732800000,
        y: 921.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1654819200000,
        y: 912.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655078400000,
        y: 902.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655164800000,
        y: 907,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655251200000,
        y: 872,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655337600000,
        y: 842.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655424000000,
        y: 824.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655683200000,
        y: 828.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655769600000,
        y: 820.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655856000000,
        y: 817.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1655942400000,
        y: 815.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656028800000,
        y: 831.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656288000000,
        y: 828,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656374400000,
        y: 829,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656460800000,
        y: 839.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656547200000,
        y: 824.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656633600000,
        y: 816.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656892800000,
        y: 833.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1656979200000,
        y: 760,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657065600000,
        y: 763.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657152000000,
        y: 781,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657238400000,
        y: 773.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657497600000,
        y: 766,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657584000000,
        y: 720.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657670400000,
        y: 712.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657756800000,
        y: 704,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1657843200000,
        y: 722.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658102400000,
        y: 748,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658188800000,
        y: 734.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658275200000,
        y: 743.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658361600000,
        y: 722.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658448000000,
        y: 712,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658707200000,
        y: 700.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658793600000,
        y: 693.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658880000000,
        y: 711.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1658966400000,
        y: 717.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659052800000,
        y: 723.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659312000000,
        y: 679.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659398400000,
        y: 690.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659484800000,
        y: 668.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659571200000,
        y: 641.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659657600000,
        y: 666,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1659916800000,
        y: 665.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660003200000,
        y: 669.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660089600000,
        y: 675.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660176000000,
        y: 701,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660262400000,
        y: 702,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660521600000,
        y: 668.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660608000000,
        y: 656.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660694400000,
        y: 658.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660780800000,
        y: 678.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1660867200000,
        y: 679,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661126400000,
        y: 671.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661212800000,
        y: 693.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661299200000,
        y: 690.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661385600000,
        y: 707.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661472000000,
        y: 684.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661817600000,
        y: 689,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661904000000,
        y: 668,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1661990400000,
        y: 623.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662076800000,
        y: 635.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662336000000,
        y: 652,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662422400000,
        y: 644.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662508800000,
        y: 610.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662595200000,
        y: 601,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662681600000,
        y: 618.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1662940800000,
        y: 638,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663027200000,
        y: 627.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663113600000,
        y: 651.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663200000000,
        y: 621.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663286400000,
        y: 622.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663632000000,
        y: 610,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663718400000,
        y: 604.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663804800000,
        y: 607.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1663891200000,
        y: 582.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664150400000,
        y: 577,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664236800000,
        y: 572.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664323200000,
        y: 580.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664409600000,
        y: 589,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664496000000,
        y: 585.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664755200000,
        y: 588,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664841600000,
        y: 604,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1664928000000,
        y: 621.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665014400000,
        y: 625.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665100800000,
        y: 643.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665360000000,
        y: 637,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665446400000,
        y: 624.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665532800000,
        y: 607.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665619200000,
        y: 615.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665705600000,
        y: 613.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1665964800000,
        y: 604.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666051200000,
        y: 596.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666137600000,
        y: 608,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666224000000,
        y: 608.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666310400000,
        y: 595.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666569600000,
        y: 605,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666656000000,
        y: 590.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666742400000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666828800000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1666915200000,
        y: 614,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667174400000,
        y: 595.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667260800000,
        y: 602.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667347200000,
        y: 607.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667433600000,
        y: 599,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667520000000,
        y: 620.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667779200000,
        y: 627.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667865600000,
        y: 620.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1667952000000,
        y: 606,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668038400000,
        y: 604.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668124800000,
        y: 611,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668384000000,
        y: 597.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668470400000,
        y: 589.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668556800000,
        y: 571.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668643200000,
        y: 560.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668729600000,
        y: 516.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1668988800000,
        y: 491.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669075200000,
        y: 537.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669161600000,
        y: 517.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669248000000,
        y: 525.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669334400000,
        y: 526.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669593600000,
        y: 513,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669680000000,
        y: 521.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669766400000,
        y: 529.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669852800000,
        y: 529.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1669939200000,
        y: 529.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670198400000,
        y: 527.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670284800000,
        y: 508.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670371200000,
        y: 497,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670457600000,
        y: 456.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670544000000,
        y: 482.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670803200000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670889600000,
        y: 488.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1670976000000,
        y: 503.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671062400000,
        y: 488.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671148800000,
        y: 474.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671408000000,
        y: 496.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671494400000,
        y: 495.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671580800000,
        y: 509.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671667200000,
        y: 505.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1671753600000,
        y: 508.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672185600000,
        y: 511.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672272000000,
        y: 499.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672358400000,
        y: 512.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672704000000,
        y: 507.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672790400000,
        y: 475,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672876800000,
        y: 450.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1672963200000,
        y: 521.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673222400000,
        y: 498.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673308800000,
        y: 504.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673395200000,
        y: 516,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673481600000,
        y: 538.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673568000000,
        y: 548.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673827200000,
        y: 547.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1673913600000,
        y: 559,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674000000000,
        y: 568.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674086400000,
        y: 557.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674172800000,
        y: 561,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674432000000,
        y: 584.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674518400000,
        y: 573.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674604800000,
        y: 582.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674691200000,
        y: 589.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1674777600000,
        y: 584,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675036800000,
        y: 577.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675123200000,
        y: 572,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675209600000,
        y: 566,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675296000000,
        y: 559.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675382400000,
        y: 547.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675641600000,
        y: 530.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675728000000,
        y: 546.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675814400000,
        y: 555,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675900800000,
        y: 564.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1675987200000,
        y: 574.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676246400000,
        y: 575.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676332800000,
        y: 569,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676419200000,
        y: 556,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676505600000,
        y: 559.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676592000000,
        y: 536.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676851200000,
        y: 539.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1676937600000,
        y: 533,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677024000000,
        y: 515.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677110400000,
        y: 515.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677196800000,
        y: 532,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677456000000,
        y: 529.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677542400000,
        y: 543,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677628800000,
        y: 539.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677715200000,
        y: 554,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1677801600000,
        y: 549.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678060800000,
        y: 554.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678147200000,
        y: 552,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678233600000,
        y: 549.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678320000000,
        y: 545.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678406400000,
        y: 538.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678665600000,
        y: 534.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678752000000,
        y: 519.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678838400000,
        y: 475.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1678924800000,
        y: 485,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679011200000,
        y: 471.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679270400000,
        y: 483.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679356800000,
        y: 490.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679443200000,
        y: 499.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679529600000,
        y: 506.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679616000000,
        y: 491,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679875200000,
        y: 500,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1679961600000,
        y: 517.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680048000000,
        y: 515.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680134400000,
        y: 520.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680220800000,
        y: 533,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680480000000,
        y: 546.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680566400000,
        y: 547.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680652800000,
        y: 549.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1680739200000,
        y: 552.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681171200000,
        y: 554.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681257600000,
        y: 564.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681344000000,
        y: 556.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681430400000,
        y: 548.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681689600000,
        y: 544,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681776000000,
        y: 542.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681862400000,
        y: 535.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1681948800000,
        y: 520,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682035200000,
        y: 517.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682294400000,
        y: 528,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682380800000,
        y: 519.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682467200000,
        y: 519.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682553600000,
        y: 507,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682640000000,
        y: 518.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1682985600000,
        y: 498,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683072000000,
        y: 478.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683158400000,
        y: 468.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683244800000,
        y: 484,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683590400000,
        y: 490.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683676800000,
        y: 499,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683763200000,
        y: 487,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1683849600000,
        y: 480.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684108800000,
        y: 485,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684195200000,
        y: 483.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684281600000,
        y: 494.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684368000000,
        y: 499.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684454400000,
        y: 494.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684713600000,
        y: 494.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684800000000,
        y: 498.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684886400000,
        y: 505.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1684972800000,
        y: 496.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685059200000,
        y: 500.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685404800000,
        y: 486,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685491200000,
        y: 476.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685577600000,
        y: 490.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685664000000,
        y: 497.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1685923200000,
        y: 504.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686009600000,
        y: 506.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686096000000,
        y: 516,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686182400000,
        y: 507.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686268800000,
        y: 509.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686528000000,
        y: 490,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686614400000,
        y: 502.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686700800000,
        y: 505,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686787200000,
        y: 507.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1686873600000,
        y: 516,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687132800000,
        y: 519.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687219200000,
        y: 513.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687305600000,
        y: 523.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687392000000,
        y: 506.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687478400000,
        y: 507.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687737600000,
        y: 506.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687824000000,
        y: 497.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687910400000,
        y: 502,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1687996800000,
        y: 504,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688083200000,
        y: 517.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688342400000,
        y: 518,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688428800000,
        y: 513.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688515200000,
        y: 517.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688601600000,
        y: 495,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688688000000,
        y: 513.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1688947200000,
        y: 522.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689033600000,
        y: 521.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689120000000,
        y: 519.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689206400000,
        y: 524.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689292800000,
        y: 524,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689552000000,
        y: 515.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689638400000,
        y: 525.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689724800000,
        y: 528.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689811200000,
        y: 516.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1689897600000,
        y: 528.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690156800000,
        y: 543,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690243200000,
        y: 543,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690329600000,
        y: 549,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690416000000,
        y: 554.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690502400000,
        y: 560.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690761600000,
        y: 571.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690848000000,
        y: 575.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1690934400000,
        y: 571.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691020800000,
        y: 587,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691107200000,
        y: 598,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691366400000,
        y: 588.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691452800000,
        y: 582.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691539200000,
        y: 586.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691625600000,
        y: 595.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691712000000,
        y: 591.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1691971200000,
        y: 589.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692057600000,
        y: 578,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692144000000,
        y: 574.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692230400000,
        y: 573.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692316800000,
        y: 576.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692576000000,
        y: 580,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692662400000,
        y: 570,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692748800000,
        y: 561,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692835200000,
        y: 555.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1692921600000,
        y: 570.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693267200000,
        y: 575.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693353600000,
        y: 572.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693440000000,
        y: 578.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693526400000,
        y: 588.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693785600000,
        y: 581.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693872000000,
        y: 594.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1693958400000,
        y: 590,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694044800000,
        y: 589.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694131200000,
        y: 594,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694390400000,
        y: 573.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694476800000,
        y: 606.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694563200000,
        y: 601.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694649600000,
        y: 614.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694736000000,
        y: 615.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1694995200000,
        y: 616.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695081600000,
        y: 603.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695168000000,
        y: 599.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695254400000,
        y: 600.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695340800000,
        y: 603.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695600000000,
        y: 592.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695686400000,
        y: 598.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695772800000,
        y: 615.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695859200000,
        y: 604,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1695945600000,
        y: 590.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696204800000,
        y: 573.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696291200000,
        y: 591.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696377600000,
        y: 574,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696464000000,
        y: 545,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696550400000,
        y: 553,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696809600000,
        y: 570,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696896000000,
        y: 562.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1696982400000,
        y: 556.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697068800000,
        y: 563,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697155200000,
        y: 587,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697414400000,
        y: 589.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697500800000,
        y: 582.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697587200000,
        y: 591.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697673600000,
        y: 589.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1697760000000,
        y: 598.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698019200000,
        y: 586,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698105600000,
        y: 569.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698192000000,
        y: 572,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698278400000,
        y: 575.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698364800000,
        y: 569.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698624000000,
        y: 566.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698710400000,
        y: 574,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698796800000,
        y: 578.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698883200000,
        y: 575.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1698969600000,
        y: 563.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699228800000,
        y: 565,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699315200000,
        y: 546.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699401600000,
        y: 536.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699488000000,
        y: 544.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699574400000,
        y: 548,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699833600000,
        y: 553.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1699920000000,
        y: 570,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700006400000,
        y: 555,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700092800000,
        y: 528.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700179200000,
        y: 544.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700438400000,
        y: 571.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700524800000,
        y: 565.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700611200000,
        y: 548,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700697600000,
        y: 554.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1700784000000,
        y: 553.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701043200000,
        y: 542,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701129600000,
        y: 547.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701216000000,
        y: 550,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701302400000,
        y: 533.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701388800000,
        y: 544.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701648000000,
        y: 536,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701734400000,
        y: 535.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701820800000,
        y: 507,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701907200000,
        y: 501.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1701993600000,
        y: 510.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702252800000,
        y: 507.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702339200000,
        y: 496.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702425600000,
        y: 497,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702512000000,
        y: 514,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702598400000,
        y: 510.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702857600000,
        y: 531.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1702944000000,
        y: 537,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703030400000,
        y: 544,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703116800000,
        y: 532.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703203200000,
        y: 536,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703635200000,
        y: 539,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703721600000,
        y: 527.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1703808000000,
        y: 518,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704153600000,
        y: 518.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704240000000,
        y: 538.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704326400000,
        y: 527.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704412800000,
        y: 535.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704672000000,
        y: 507,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704758400000,
        y: 522.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704844800000,
        y: 518.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1704931200000,
        y: 525,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705017600000,
        y: 517.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705276800000,
        y: 517.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705363200000,
        y: 518.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705449600000,
        y: 522,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705536000000,
        y: 530.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705622400000,
        y: 535.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705881600000,
        y: 543.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1705968000000,
        y: 541.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706054400000,
        y: 543.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706140800000,
        y: 554.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706227200000,
        y: 562.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706486400000,
        y: 567.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706572800000,
        y: 573.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706659200000,
        y: 559.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706745600000,
        y: 554.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1706832000000,
        y: 537.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707091200000,
        y: 536.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707177600000,
        y: 538.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707264000000,
        y: 545.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707350400000,
        y: 561.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707436800000,
        y: 564.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707696000000,
        y: 565,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707782400000,
        y: 571.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707868800000,
        y: 563.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1707955200000,
        y: 563.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708041600000,
        y: 566.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708300800000,
        y: 565,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708387200000,
        y: 555.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708473600000,
        y: 562.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708560000000,
        y: 567.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708646400000,
        y: 556.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708905600000,
        y: 559.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1708992000000,
        y: 569.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1709078400000,
        y: 570.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710374400000,
        y: 584.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710460800000,
        y: 585.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710720000000,
        y: 591.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710806400000,
        y: 593,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710892800000,
        y: 581,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1710979200000,
        y: 576.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711065600000,
        y: 584.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711324800000,
        y: 595,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711411200000,
        y: 590.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711497600000,
        y: 583.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1711584000000,
        y: 591,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712016000000,
        y: 599,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712102400000,
        y: 604.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712188800000,
        y: 597.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712275200000,
        y: 611.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712534400000,
        y: 600.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712620800000,
        y: 597.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712707200000,
        y: 595,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712793600000,
        y: 596.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1712880000000,
        y: 607.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713139200000,
        y: 596.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713225600000,
        y: 605.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713312000000,
        y: 596.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713398400000,
        y: 587.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713484800000,
        y: 586.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713744000000,
        y: 583.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713830400000,
        y: 586.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1713916800000,
        y: 585.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714003200000,
        y: 576,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714089600000,
        y: 589.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714348800000,
        y: 581.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714435200000,
        y: 572.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714608000000,
        y: 551.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1714694400000,
        y: 553.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715040000000,
        y: 556.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715126400000,
        y: 558.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715212800000,
        y: 557.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715299200000,
        y: 557.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715558400000,
        y: 554.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715644800000,
        y: 546.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715731200000,
        y: 547.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715817600000,
        y: 549.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1715904000000,
        y: 553.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716163200000,
        y: 555,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716249600000,
        y: 548,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716336000000,
        y: 540.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716422400000,
        y: 531.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716508800000,
        y: 529.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716854400000,
        y: 544.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1716940800000,
        y: 543.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717027200000,
        y: 537.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717113600000,
        y: 531.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717372800000,
        y: 513.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717459200000,
        y: 513.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717545600000,
        y: 508,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717632000000,
        y: 518.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717718400000,
        y: 514.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1717977600000,
        y: 527.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718064000000,
        y: 530.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718150400000,
        y: 534.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718236800000,
        y: 535.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718323200000,
        y: 541,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718582400000,
        y: 543.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718668800000,
        y: 548.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718755200000,
        y: 555.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718841600000,
        y: 552.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1718928000000,
        y: 555,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719187200000,
        y: 558.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719273600000,
        y: 558.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719360000000,
        y: 553.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719446400000,
        y: 564,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719532800000,
        y: 559.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719792000000,
        y: 566.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719878400000,
        y: 578,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1719964800000,
        y: 575.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720051200000,
        y: 583.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720137600000,
        y: 583.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720396800000,
        y: 572.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720483200000,
        y: 562.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720569600000,
        y: 564.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720656000000,
        y: 558,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1720742400000,
        y: 563,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721001600000,
        y: 561.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721088000000,
        y: 554.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721174400000,
        y: 557.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721260800000,
        y: 557,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721347200000,
        y: 552.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721692800000,
        y: 535.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721779200000,
        y: 543.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721865600000,
        y: 544.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1721952000000,
        y: 535.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722211200000,
        y: 536,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722297600000,
        y: 527.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722384000000,
        y: 539.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722470400000,
        y: 534.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722556800000,
        y: 513.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722816000000,
        y: 513,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722902400000,
        y: 513,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1722988800000,
        y: 526.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723075200000,
        y: 528.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723161600000,
        y: 531.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723420800000,
        y: 540,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723507200000,
        y: 540,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723593600000,
        y: 534.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723680000000,
        y: 542,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1723766400000,
        y: 530.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724025600000,
        y: 529.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724112000000,
        y: 521.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724198400000,
        y: 521.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724284800000,
        y: 524,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724371200000,
        y: 531.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724716800000,
        y: 541.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724803200000,
        y: 535.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724889600000,
        y: 537.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1724976000000,
        y: 524.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725235200000,
        y: 522.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725321600000,
        y: 511,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725408000000,
        y: 510.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725494400000,
        y: 513.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725580800000,
        y: 498.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725840000000,
        y: 484.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1725926400000,
        y: 466,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726012800000,
        y: 470.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726099200000,
        y: 487.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726185600000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726444800000,
        y: 490.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726531200000,
        y: 494,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726617600000,
        y: 491,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726704000000,
        y: 498.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1726790400000,
        y: 506.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1727049600000,
        y: 507.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1727136000000,
        y: 519.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729209600000,
        y: 503.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729468800000,
        y: 508.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729555200000,
        y: 526.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729641600000,
        y: 518.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729728000000,
        y: 515.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1729814400000,
        y: 517,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730073600000,
        y: 489.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730160000000,
        y: 482,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730246400000,
        y: 497.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730332800000,
        y: 497,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730419200000,
        y: 498.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730678400000,
        y: 504.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730764800000,
        y: 515.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730851200000,
        y: 517.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1730937600000,
        y: 512,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731024000000,
        y: 502.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731283200000,
        y: 490.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731369600000,
        y: 493.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731456000000,
        y: 494,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731542400000,
        y: 491.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731628800000,
        y: 490.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731888000000,
        y: 497.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1731974400000,
        y: 492,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732060800000,
        y: 493.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732147200000,
        y: 499.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732233600000,
        y: 504,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732492800000,
        y: 493.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732579200000,
        y: 495.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732665600000,
        y: 489.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732752000000,
        y: 486.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1732838400000,
        y: 488,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733097600000,
        y: 480.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733184000000,
        y: 493.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733270400000,
        y: 492.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733356800000,
        y: 485.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733443200000,
        y: 482.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733702400000,
        y: 486,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733788800000,
        y: 481.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733875200000,
        y: 493.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1733961600000,
        y: 493,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734048000000,
        y: 499,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734307200000,
        y: 495.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734393600000,
        y: 491.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734480000000,
        y: 500.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734566400000,
        y: 491,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734652800000,
        y: 490.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734912000000,
        y: 488.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1734998400000,
        y: 496,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735257600000,
        y: 500.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735516800000,
        y: 503.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735603200000,
        y: 503.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735776000000,
        y: 513.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1735862400000,
        y: 514.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736121600000,
        y: 517,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736208000000,
        y: 516,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736294400000,
        y: 512.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736380800000,
        y: 523,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736467200000,
        y: 532.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736726400000,
        y: 542.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736812800000,
        y: 534.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736899200000,
        y: 537,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1736985600000,
        y: 535,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737072000000,
        y: 539.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737331200000,
        y: 534,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737417600000,
        y: 533.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737504000000,
        y: 532.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737590400000,
        y: 524.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737676800000,
        y: 528.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1737936000000,
        y: 526,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738022400000,
        y: 525.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738108800000,
        y: 527.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738195200000,
        y: 528.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738281600000,
        y: 523,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738540800000,
        y: 524.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738627200000,
        y: 528.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738713600000,
        y: 516.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738800000000,
        y: 517,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1738886400000,
        y: 511.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1739145600000,
        y: 520,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1739232000000,
        y: 526.5,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1739318400000,
        y: 520.25,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      },
      {
        x: 1739404800000,
        y: 514.75,
        custom: {
          periodAbs: 'Prompt',
          decimalPlaces: 2
        }
      }
    ],
    id: 'mock3-Mid',
    marker: {
      radius: 1,
      symbol: 'circle'
    },
    metadata: {
      decimalPlaces: 2,
      hideName: true,
      field: 'Mid',
      combinedIndex: 2,
      Code: 'GX0000087',
      Period: '1',
      PeriodType: 'Prompt',
      TimeRef: '1630',
      type: 'Last',
      TimeZone: 'Europe/London',
      Commodity: 'Fuel Oil',
      PricingBasis: 'Flat',
      Alias: 'FO05NWEBRG',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_Europe',
      Title: 'Fuel Oil 0.5% NWE FOB Barges',
      TradingHub: 'NWE',
      DeliveryBasis: 'FOB Barges',
      Source: 'GX',
      Units: 'MT'
    },
    color: '#F3733F'
  }
];

export const mockDhData: DhData = [
  {
    transactionTime: '2016-12-27T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '54.29',
      Low: '54.19',
      PeriodAbs: '2017-02',
      PeriodStart: '2017-02-01T00:00:00',
      Mid: '54.24',
      FactsheetVersion: '0',
      PeriodEnd: '2017-02-28T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.27',
      Low: '53.17',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.22',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.52',
      Low: '53.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '52.52',
      Low: '52.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-20T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.32',
      Low: '53.22',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.27',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-19T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.61',
      Low: '53.51',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.56',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.08',
      Low: '52.98',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.03',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '52.12',
      Low: '52.02',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.07',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '52.11',
      Low: '52.01',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.06',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-13T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.97',
      Low: '53.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-12T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '53.67',
      Low: '53.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '52.37',
      Low: '52.27',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.32',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '51.97',
      Low: '51.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-07T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '50.66',
      Low: '50.56',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '50.61',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-06T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '51.67',
      Low: '51.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-05T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '52.33',
      Low: '52.23',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.28',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-02T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '52.16',
      Low: '52.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-01T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '51.60',
      Low: '51.50',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.55',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-30T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '49.96',
      Low: '49.86',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '49.91',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-29T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '45.69',
      Low: '45.59',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '45.64',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-28T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '47.16',
      Low: '47.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '47.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '47.48',
      Low: '47.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '47.66',
      Low: '47.56',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.61',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '47.82',
      Low: '47.72',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.77',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-18T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '46.08',
      Low: '45.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-17T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '45.90',
      Low: '45.80',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.85',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '45.89',
      Low: '45.79',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.84',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '46.10',
      Low: '46.00',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.05',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '43.54',
      Low: '43.44',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.49',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-11T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '43.48',
      Low: '43.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-10T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '44.65',
      Low: '44.55',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '44.60',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '45.28',
      Low: '45.18',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.23',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0001083'
    },
    values: {
      High: '45.08',
      Low: '44.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },

  {
    transactionTime: '2016-12-27T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '54.29',
      Low: '54.19',
      PeriodAbs: '2017-02',
      PeriodStart: '2017-02-01T00:00:00',
      Mid: '54.24',
      FactsheetVersion: '0',
      PeriodEnd: '2017-02-28T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.27',
      Low: '53.17',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.22',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.52',
      Low: '53.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '52.52',
      Low: '52.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-20T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.32',
      Low: '53.22',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.27',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-19T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.61',
      Low: '53.51',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.56',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.08',
      Low: '52.98',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.03',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '52.12',
      Low: '52.02',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.07',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '52.11',
      Low: '52.01',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.06',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-13T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.97',
      Low: '53.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-12T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '53.67',
      Low: '53.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '52.37',
      Low: '52.27',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.32',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '51.97',
      Low: '51.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-07T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '50.66',
      Low: '50.56',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '50.61',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-06T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '51.67',
      Low: '51.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-05T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '52.33',
      Low: '52.23',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.28',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-02T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '52.16',
      Low: '52.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-01T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '51.60',
      Low: '51.50',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.55',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-30T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '49.96',
      Low: '49.86',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '49.91',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-29T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '45.69',
      Low: '45.59',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '45.64',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-28T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '47.16',
      Low: '47.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '47.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '47.48',
      Low: '47.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '47.66',
      Low: '47.56',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.61',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '47.82',
      Low: '47.72',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.77',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-18T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '46.08',
      Low: '45.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-17T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '45.90',
      Low: '45.80',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.85',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '45.89',
      Low: '45.79',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.84',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '46.10',
      Low: '46.00',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.05',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '43.54',
      Low: '43.44',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.49',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-11T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '43.48',
      Low: '43.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-10T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '44.65',
      Low: '44.55',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '44.60',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '45.28',
      Low: '45.18',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.23',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000968'
    },
    values: {
      High: '45.08',
      Low: '44.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },

  {
    transactionTime: '2016-12-27T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '54.29',
      Low: '54.19',
      PeriodAbs: '2017-02',
      PeriodStart: '2017-02-01T00:00:00',
      Mid: '54.24',
      FactsheetVersion: '0',
      PeriodEnd: '2017-02-28T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.27',
      Low: '53.17',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.22',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.52',
      Low: '53.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '52.52',
      Low: '52.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-20T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.32',
      Low: '53.22',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.27',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-19T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.61',
      Low: '53.51',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.56',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.08',
      Low: '52.98',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.03',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '52.12',
      Low: '52.02',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.07',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '52.11',
      Low: '52.01',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.06',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-13T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.97',
      Low: '53.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-12T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '53.67',
      Low: '53.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '52.37',
      Low: '52.27',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.32',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '51.97',
      Low: '51.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-07T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '50.66',
      Low: '50.56',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '50.61',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-06T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '51.67',
      Low: '51.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-05T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '52.33',
      Low: '52.23',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.28',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-02T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '52.16',
      Low: '52.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-01T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '51.60',
      Low: '51.50',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.55',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-30T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '49.96',
      Low: '49.86',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '49.91',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-29T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '45.69',
      Low: '45.59',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '45.64',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-28T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '47.16',
      Low: '47.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '47.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '47.48',
      Low: '47.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '47.66',
      Low: '47.56',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.61',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '47.82',
      Low: '47.72',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.77',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-18T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '46.08',
      Low: '45.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-17T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '45.90',
      Low: '45.80',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.85',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '45.89',
      Low: '45.79',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.84',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '46.10',
      Low: '46.00',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.05',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '43.54',
      Low: '43.44',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.49',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-11T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '43.48',
      Low: '43.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-10T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '44.65',
      Low: '44.55',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '44.60',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '45.28',
      Low: '45.18',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.23',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000977'
    },
    values: {
      High: '45.08',
      Low: '44.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },

  {
    transactionTime: '2016-12-27T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '54.29',
      Low: '54.19',
      PeriodAbs: '2017-02',
      PeriodStart: '2017-02-01T00:00:00',
      Mid: '54.24',
      FactsheetVersion: '0',
      PeriodEnd: '2017-02-28T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.27',
      Low: '53.17',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.22',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.52',
      Low: '53.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '52.52',
      Low: '52.42',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.47',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-20T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.32',
      Low: '53.22',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.27',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-19T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.61',
      Low: '53.51',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.56',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.08',
      Low: '52.98',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.03',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '52.12',
      Low: '52.02',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.07',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '52.11',
      Low: '52.01',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.06',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-13T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.97',
      Low: '53.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-12T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '53.67',
      Low: '53.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '53.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '52.37',
      Low: '52.27',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.32',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '51.97',
      Low: '51.87',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.92',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-07T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '50.66',
      Low: '50.56',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '50.61',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-06T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '51.67',
      Low: '51.57',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.62',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-05T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '52.33',
      Low: '52.23',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.28',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-02T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '52.16',
      Low: '52.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '52.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-12-01T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '51.60',
      Low: '51.50',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '51.55',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-30T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '49.96',
      Low: '49.86',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '49.91',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-29T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '45.69',
      Low: '45.59',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '45.64',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-28T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '47.16',
      Low: '47.06',
      PeriodAbs: '2017-01',
      PeriodStart: '2017-01-01T00:00:00',
      Mid: '47.11',
      FactsheetVersion: '0',
      PeriodEnd: '2017-01-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-23T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '47.48',
      Low: '47.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-22T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '47.66',
      Low: '47.56',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.61',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-21T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '47.82',
      Low: '47.72',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '47.77',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-18T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '46.08',
      Low: '45.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-17T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '45.90',
      Low: '45.80',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.85',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-16T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '45.89',
      Low: '45.79',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.84',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-15T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '46.10',
      Low: '46.00',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '46.05',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-14T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '43.54',
      Low: '43.44',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.49',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-11T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '43.48',
      Low: '43.38',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '43.43',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-10T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '44.65',
      Low: '44.55',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '44.60',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-09T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '45.28',
      Low: '45.18',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.23',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  },
  {
    transactionTime: '2016-11-08T22:00:00',
    insertTime: '2024-07-11T12:22:47',
    symbol: {
      PeriodType: 'Month',
      TimeRef: '1600',
      Period: '1',
      Code: 'GX0000097'
    },
    values: {
      High: '45.08',
      Low: '44.98',
      PeriodAbs: '2016-12',
      PeriodStart: '2016-12-01T00:00:00',
      Mid: '45.03',
      FactsheetVersion: '0',
      PeriodEnd: '2016-12-31T23:59:59',
      PeriodRel: '1',
      RecordStatus: 'N'
    },
    metadata: {
      TimeZone: 'America/Chicago',
      Commodity: 'Crude',
      PricingBasis: 'Flat',
      Alias: 'WTIM1',
      Currency: 'USD',
      Frequency: 'Daily',
      HolidayCalendar: 'Holidays_GX_NorthAmerica',
      Title: 'Crude Oil WTI Midland (Cushing Cash Expiry)',
      TradingHub: 'North America',
      DeliveryBasis: 'FOB',
      Source: 'GX',
      Units: 'BBL'
    }
  }
];
