import Highcharts from 'highcharts/highstock';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box
} from '@mui/material';
import { useEffect, useState } from 'react';
import LegendItem from './LegendItem';

const FullScreenLegend = ({ chart }: { chart: Highcharts.Chart }) => {
  const [series, setSeries] = useState<Highcharts.Series[]>([]);
  const names = chart?.series?.map(item => item.name).join(',');

  useEffect(() => {
    setSeries(
      chart.series.filter((series: Highcharts.Series) => !series.name.includes('Navigator'))
    );
  }, [names]);

  return (
    <Box sx={{ pt: 1, pr: 3 }}>
      <TableContainer
        sx={{ width: '100%', pl: '78px', maxHeight: 155, pr: 1 }}
        component={Paper}
      >
        <Table
          sx={{
            minWidth: 600,
            minHeight: series.length * 25 + 50,
            thead: {
              top: 0,
              zIndex: 1303
            }
          }}
          size='small'
          aria-label='api keys table'
        >
          <TableHead>
            <TableRow sx={{ '.MuiTableCell-head': { backgroundColor: 'background.paper' } }}>
              <TableCell></TableCell>
              <TableCell>
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Title
                </Typography>
              </TableCell>
              <TableCell>
                {' '}
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Code
                </Typography>
              </TableCell>
              <TableCell align='right'>
                {' '}
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Time Ref
                </Typography>
              </TableCell>
              <TableCell align='right'>
                {' '}
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Period Type
                </Typography>
              </TableCell>
              <TableCell align='right'>
                {' '}
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Period
                </Typography>
              </TableCell>
              <TableCell align='right'>
                {' '}
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Column
                </Typography>
              </TableCell>
              <TableCell align='center'>
                {' '}
                <Typography
                  sx={theme => ({ color: theme.palette.info.dark })}
                  variant='body2'
                >
                  Factsheet
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ maxHeight: 150 }}>
            {series?.map((series: any, index) => (
              <LegendItem
                key={series.name + index}
                chart={chart}
                series={series}
                index={index}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FullScreenLegend;
