import { Button, SxProps } from '@mui/material';
import { useState } from 'react';
import PremiumDialog from 'pages/DataHub/UpgradeToPremiumScreen/PremiumDialog';

const UpgradeToPremiumButton = ({ sx }: { sx?: SxProps }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <Button
        variant='contained'
        onClick={() => setDialogOpen(true)}
        sx={{ width: 250, ...sx }}
      >
        <svg
          width='14'
          height='14'
          viewBox='0 0 14 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_6108_2799)'>
            <rect
              width='14'
              height='14'
              fill='white'
              fillOpacity='0.01'
            />
            <path
              d='M2.91602 11.666V10.4993H11.0827V11.666H2.91602ZM2.91602 9.62435L2.17227 4.9431C2.15282 4.9431 2.13085 4.94563 2.10635 4.95068C2.08185 4.95574 2.06007 4.95807 2.04102 4.95768C1.79796 4.95768 1.59146 4.87252 1.42152 4.70218C1.25157 4.53185 1.16641 4.32535 1.16602 4.08268C1.16563 3.84002 1.25079 3.63352 1.42152 3.46318C1.59224 3.29285 1.79874 3.20768 2.04102 3.20768C2.28329 3.20768 2.48999 3.29285 2.6611 3.46318C2.83221 3.63352 2.91718 3.84002 2.91602 4.08268C2.91602 4.15074 2.90863 4.21393 2.89385 4.27227C2.87907 4.3306 2.86216 4.38407 2.8431 4.43268L4.66602 5.24935L6.48893 2.7556C6.38199 2.67782 6.29449 2.57574 6.22643 2.44935C6.15838 2.32296 6.12435 2.18685 6.12435 2.04102C6.12435 1.79796 6.20952 1.59127 6.37985 1.42093C6.55018 1.2506 6.75668 1.16563 6.99935 1.16602C7.24202 1.16641 7.44871 1.25157 7.61943 1.42152C7.79016 1.59146 7.87513 1.79796 7.87435 2.04102C7.87435 2.18685 7.84032 2.32296 7.77227 2.44935C7.70421 2.57574 7.61671 2.67782 7.50977 2.7556L9.33268 5.24935L11.1556 4.43268C11.1362 4.38407 11.119 4.3306 11.1043 4.27227C11.0895 4.21393 11.0823 4.15074 11.0827 4.08268C11.0827 3.83963 11.1679 3.63293 11.3382 3.4626C11.5085 3.29227 11.715 3.20729 11.9577 3.20768C12.2004 3.20807 12.407 3.29324 12.5778 3.46318C12.7485 3.63313 12.8335 3.83963 12.8327 4.08268C12.8319 4.32574 12.7469 4.53243 12.5778 4.70277C12.4086 4.8731 12.2019 4.95807 11.9577 4.95768C11.9382 4.95768 11.9165 4.95535 11.8924 4.95068C11.8682 4.94602 11.8463 4.94349 11.8264 4.9431L11.0827 9.62435H2.91602ZM3.90768 8.45768H10.091L10.4702 6.02227L8.93893 6.6931L6.99935 4.02435L5.05977 6.6931L3.52852 6.02227L3.90768 8.45768Z'
              fill='#F8F8F8'
            />
          </g>
          <defs>
            <clipPath id='clip0_6108_2799'>
              <rect
                width='14'
                height='14'
                fill='white'
              />
            </clipPath>
          </defs>
        </svg>
        &nbsp; Enquire about Premium
      </Button>
      <PremiumDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

export default UpgradeToPremiumButton;
