import StockChart from 'components/Chart/StockChart';
import { mockChartSeries } from 'pages/DataHub/constants';

const UnauthenticatedChart = ({ code }: { code: string }) => {
  return (
    <StockChart
      height={14}
      chartSeries={mockChartSeries}
      error={false}
      loading={false}
      parent={{
        mainPageTitle: 'factsheets',
        tabName: code
      }}
      showLegend={false}
    />
  );
};
export default UnauthenticatedChart;
