import { Box, List } from '@mui/material';
import { useSelector } from 'react-redux';
import { ReactElement } from 'react';
import { RootState } from '../../../store';
import DataHubDropdownItem from './DataHubDropdownItem';
import Popper from 'components/Popper';
import { DataHub } from 'slices/dataHubSlice';
import useUserDetails from 'hooks/useUserAccess';

const DataHubsDropdown = ({
  setActiveTab,
  buttonContent,
  buttonVariant
}: {
  setActiveTab: (index: number) => void;
  buttonContent: ReactElement;
  buttonVariant?: 'text' | 'outlined' | 'contained';
}) => {
  const { dataHubs } = useSelector((state: RootState) => state.dataHub);
  const { checkUserHasPremium } = useUserDetails();

  return (
    <Popper
      disabled={!dataHubs?.length || !checkUserHasPremium()}
      placement='bottom-start'
      buttonVariant={buttonVariant}
      buttonContent={buttonContent}
    >
      <Box
        sx={{
          width: 192,
          borderBottom: '1px solid',
          borderLeft: '1px solid',
          borderColor: 'primary.main',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '14px',
          maxHeight: 424,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px'
          },
          '&::-webkit-scrollbar-track': {
            my: 0.5
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '12px',
            backgroundColor: '#6e729c',
            backgroundClip: 'content-box'
          }
        }}
      >
        <List disablePadding>
          {[...dataHubs]
            .sort((a, b) => new Date(b.savedAt).getTime() - new Date(a.savedAt).getTime())
            .map((dataHub: DataHub) => (
              <DataHubDropdownItem
                key={dataHub.id}
                dataHub={dataHub}
                setActiveTab={setActiveTab}
              />
            ))}
        </List>
      </Box>
    </Popper>
  );
};

export default DataHubsDropdown;
