import { Box, Button, SvgIconTypeMap } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export const ExportGroup = ({
  groupName,
  GroupIcon,
  options,
  setPreviewOptionId
}: {
  groupName: string;
  GroupIcon: OverridableComponent<SvgIconTypeMap> & { muiName: string };
  options: any;
  setPreviewOptionId: (id: string) => void;
}) => {
  return (
    <Box sx={{ my: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <GroupIcon
          fontSize='small'
          sx={{ mr: 1 }}
        />
        {groupName}
      </Box>
      <Grid
        container
        spacing={2}
      >
        {options.map(
          ({
            id,
            label,
            icon,
            outlined,
            disabled
          }: {
            id: string;
            label?: string;
            icon: string;
            outlined?: boolean;
            disabled?: boolean;
          }) => (
            <ExportItem
              key={id}
              id={id}
              setPreviewOptionId={setPreviewOptionId}
              icon={icon}
              label={label}
              outlined={outlined}
              disabled={disabled}
            />
          )
        )}
      </Grid>
    </Box>
  );
};

const ExportItem = ({
  id,
  label,
  icon,
  outlined,
  disabled,
  setPreviewOptionId
}: {
  id: string;
  setPreviewOptionId: (id: string) => void;
  label?: string;
  icon: string;
  outlined?: boolean;
  disabled?: boolean;
}) => {
  return (
    <Grid
      size={{
        xs: 12,
        md: 12,
        lg: 4
      }}
    >
      <Button
        disabled={disabled}
        variant={outlined ? 'outlined' : 'contained'}
        fullWidth
        sx={{
          height: 33,
          display: 'flex',
          justifyContent: 'flex-start',
          backgroundColor: outlined ? '' : 'secondary.main',
          '&:hover': {
            backgroundColor: outlined ? '' : 'secondary.main'
          }
        }}
        onClick={() => setPreviewOptionId(id)}
      >
        <Box
          component='img'
          src={icon}
          sx={{ mr: 1 }}
        />
        {label}
      </Button>
    </Grid>
  );
};

export default ExportGroup;
