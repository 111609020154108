import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import httpInstances from '../api/axiosInstance';
import { CatalogItem, spotColumns } from 'pages/Catalog/constants';

type SpotCatalogState = {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  error: string | null;
  spotCatalog: CatalogItem[] | null;
};

type ErrorResponse = {
  message: string;
};

const initialState: SpotCatalogState = {
  status: 'idle',
  error: null,
  spotCatalog: null
};

export const getSpotCatalog = createAsyncThunk('getSpotCatalog', async (_, { rejectWithValue }) => {
  try {
    const { data } = await httpInstances.axiosDataInstance.get(
      `/catalogue/spot?status=Live,Archive`,
      {
        headers: {
          fields: spotColumns.map(column => column.accessorKey)
        }
      }
    );
    const extendedWithSymbol: CatalogItem[] = data.map((catalogItem: CatalogItem) => ({
      ...catalogItem,
      Symbol: {
        Code: catalogItem.Code,
        Period: String(catalogItem.Period),
        PeriodType: catalogItem.PeriodType,
        TimeRef: catalogItem.TimeRef
      }
    }));

    return extendedWithSymbol;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const errorResponse = error.response.data;
      return rejectWithValue(errorResponse);
    }
    throw error;
  }
});

const spotCatalogSlice = createSlice({
  name: 'spotCatalog',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSpotCatalog.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getSpotCatalog.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.spotCatalog = action.payload;
      })
      .addCase(getSpotCatalog.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      });
  }
});

export default spotCatalogSlice.reducer;
