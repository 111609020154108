import { Box, ButtonBase, Dialog, FormControlLabel, Switch, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { CloseFullscreen } from '@mui/icons-material';
import { ChartSeries } from 'components/Chart/useChartSeries';
import FullScreenChart from 'components/Chart/FullScreenChart';
import Tabs from 'components/Tabs';
import { ChartsBlockTabType } from 'pages/Markets/MarketsComponents/types';
import NavigationMenu from 'layouts/NavigationMenu';

const FullScreenChartsBlock = ({
  fullScreen,
  setFullScreen,
  tabs,
  activeTab,
  setActiveTab,
  marketTitle,
  pageTitle,
  loading,
  error,
  chartSeries
}: {
  fullScreen: boolean;
  setFullScreen: Dispatch<SetStateAction<boolean>>;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabs: ChartsBlockTabType[];
  marketTitle: string;
  pageTitle: string;
  loading: boolean;
  error: boolean;
  chartSeries: ChartSeries[] | undefined;
}) => {
  const [isLightMode, setIsLightMode] = useState<boolean>(false);

  return (
    <Dialog
      fullScreen
      open={fullScreen}
      onClose={() => setFullScreen(false)}
      sx={{ position: 'absolute' }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%'
        }}
        className='visible-scrollbar'
      >
        <NavigationMenu />
        <Box
          sx={{
            pl: 2,
            pt: 2,
            pr: 3,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100vh',
            overflowY: 'auto'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant='h2'
                sx={{ mr: 6 }}
              >
                {marketTitle}
              </Typography>
              <Tabs
                mainPageTitle={marketTitle}
                subPageTitle={pageTitle}
                tabs={tabs.map(({ name }) => ({ name }))}
                activeTab={activeTab}
                onChange={setActiveTab}
              />
            </Box>
            <Box
              display='flex'
              flexDirection={'column'}
              alignItems={'flex-end'}
            >
              <ButtonBase
                sx={{ width: 24, height: 24, pt: 1, pr: 4 }}
                onClick={() => setFullScreen(false)}
                aria-label='close'
              >
                <CloseFullscreen />
              </ButtonBase>
              <FormControlLabel
                sx={{ mt: 1, fontWeight: 500 }}
                control={
                  <Switch
                    checked={isLightMode}
                    onChange={() => setIsLightMode(!isLightMode)}
                  />
                }
                labelPlacement={'start'}
                label={isLightMode ? 'Dark mode' : 'Light mode'}
              />
            </Box>
          </Box>
          <FullScreenChart
            error={error}
            loading={loading}
            chartSeries={chartSeries}
            isLightMode={isLightMode}
            parent={{
              mainPageTitle: marketTitle,
              subPageTitle: pageTitle,
              tabName: tabs?.at(activeTab)?.name
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default FullScreenChartsBlock;
