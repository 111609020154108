import { ButtonBase } from '@mui/material';
import { FeedOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { openFactsheet } from 'slices/factsheetDialogSlice';

const FactsheetDialogButton = ({ code }: { code: string }) => {
  const dispatch = useDispatch();
  return (
    <>
      <ButtonBase
        onClick={e => {
          e.stopPropagation();
          dispatch(openFactsheet(code));
        }}
      >
        <FeedOutlined sx={{ fontSize: 16 }} />
      </ButtonBase>
    </>
  );
};
export default FactsheetDialogButton;
