import { FilterFn, filterFns, Row, SortingFn } from '@tanstack/react-table';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';

export interface CatalogItem {
  Code: string;
  PeriodType: string;
  TimeRef: string;
  IndexModule: string;
  Alias: string;
  Change: string;
  Commodity: string;
  Currency: string;
  DeliveryBasis: string;
  FactsheetVersion: string;
  Frequency: string;
  Group: string;
  HolidayCalendar: string;
  Increment: string;
  Index: string;
  InterAffiliateDataAccepted: string;
  Last: string;
  LastDate: string;
  Period: number;
  PeriodMax: string;
  PeriodMin: string;
  PermissionLatestStatus: string;
  Precision: string;
  PricingBasis: string;
  PriorityToTransactions: string;
  QaSeries: string;
  SoleSourcedDataAccepted: string;
  Source: string;
  StartDate: string;
  EndDate: string;
  Status: string;
  ThirdPartyMappings: string;
  TimeRefDetails: string;
  TimeZone: string;
  Title: string;
  TradingHub: string;
  Units: string;
  Symbol: SelectedSymbol;
  [key: string]: any;
}
export type CountedLabelFunction = (
  key: string,
  joined?: boolean
) => {
  label: string;
  count: number;
}[];

export type SpecialFiltersType = {
  oilOnly: boolean;
  energyTransitionOnly: boolean;
};

export function extractExistingParams(searchParams: URLSearchParams) {
  const entries = Array.from(searchParams.entries());
  return entries.reduce((acc: any, a) => ((acc[a[0]] = acc[a[0]] || []).push(a[1]), acc), {});
}

export const getAmountLabel = (
  rows: Row<CatalogItem>[] | undefined,
  isForwardCurves: boolean,
  expandedData: boolean
) => {
  const codesLabel = `${rows?.length.toLocaleString('en-US')} ${rows?.length === 1 ? 'code' : 'codes'}`;
  if (isForwardCurves) return codesLabel;
  else {
    if (expandedData)
      return `${rows?.length.toLocaleString('en-US')} ${rows?.length === 1 ? 'item' : 'items'}`;
    return codesLabel;
  }
};

export const labeledFilterColumns = [
  {
    Commodity: (getCountedLabels: CountedLabelFunction) => getCountedLabels('Commodity')
  },
  {
    TradingHub: (getCountedLabels: CountedLabelFunction) => getCountedLabels('TradingHub')
  },
  {
    DeliveryBasis: (getCountedLabels: CountedLabelFunction) => getCountedLabels('DeliveryBasis')
  },
  {
    PricingBasis: (getCountedLabels: CountedLabelFunction) => getCountedLabels('PricingBasis')
  },
  {
    Units: (getCountedLabels: CountedLabelFunction) => getCountedLabels('Units')
  },
  {
    Currency: (getCountedLabels: CountedLabelFunction) => getCountedLabels('Currency')
  },
  {
    Frequency: (getCountedLabels: CountedLabelFunction) => getCountedLabels('Frequency')
  },
  {
    IndexModule: (getCountedLabels: CountedLabelFunction) => getCountedLabels('IndexModule', true)
  }
];
export const oilOnlyModules = [
  'AfricaCrude',
  'AmericasMarine',
  'AsiaBiofuels',
  'AsiaCrude',
  'AsiaFeedstocks',
  'AsiaFuelOil',
  'AsiaGasoil',
  'AsiaGasoline',
  'AsiaJet',
  'AsiaLPG',
  'AsiaMarine',
  'AsiaNaphtha',
  'EuropeanBiofuels',
  'EuropeanCrude',
  'EuropeanFeedstocks',
  'EuropeanFuelOil',
  'EuropeanGasoil',
  'EuropeanGasoline',
  'EuropeanJet',
  'EuropeanLPG',
  'EuropeanMarine',
  'EuropeanNaphtha',
  'GlobalIndexes',
  'GlobalOilSelect',
  'MiddleEastCrude',
  'MiddleEastFuelOil',
  'MiddleEastGasoil',
  'MiddleEastGasoline',
  'MiddleEastJet',
  'MiddleEastNaphtha',
  'NorthAmericaBiofuels',
  'NorthAmericaCrude',
  'NorthAmericaDistillate',
  'NorthAmericaFeedstocks',
  'NorthAmericaFuelOil',
  'NorthAmericaGasoline',
  'NorthAmericaJet',
  'NorthAmericaNaphtha',
  'NorthAmericaNGLs',
  'NorthAmericaStorage',
  'SouthAmericaCrude'
];

export const energyTransitionModules = [
  'EuropeanSAF',
  'AsiaSAF',
  'NorthAmericaSAF',
  'AsiaBiofuels',
  'EuropeanBiofuels',
  'GlobalHydrogen',
  'GlobalMarineCarbon',
  'GlobalVoluntaryCarbon',
  'NorthAmericaBiofuels'
];
const PERIOD_TYPE_SORTING_ORDER: Record<string, number> = {
  Prompt: 1,
  Hour: 2,
  '12Hour': 3,
  HalfDay: 4,
  Day: 5,
  Week: 6,
  HalfMonth: 7,
  Cycle: 8,
  Month: 9,
  Quarter: 10,
  Season: 11,
  HalfYear: 12,
  Year: 13
};

const FREQUENCY_SORTING_ORDER: Record<string, number> = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  Quarterly: 4,
  Annual: 5
};

const sortAsNumber: SortingFn<CatalogItem> = (
  rowA: Row<CatalogItem>,
  rowB: Row<CatalogItem>,
  columnId: string
) => {
  return Number(rowA.getValue(columnId)) - Number(rowB.getValue(columnId));
};

const sortByOrder = (
  rowA: Row<CatalogItem>,
  rowB: Row<CatalogItem>,
  columnId: string,
  order: Record<string, number>
) => {
  const rankA = order[rowA.getValue(columnId) as string] ?? Number.MIN_VALUE;
  const rankB = order[rowB.getValue(columnId) as string] ?? Number.MIN_VALUE;
  return rankA - rankB;
};
const sortAsPeriodType: SortingFn<CatalogItem> = (
  rowA: Row<CatalogItem>,
  rowB: Row<CatalogItem>,
  columnId: string
) => {
  return sortByOrder(rowA, rowB, columnId, PERIOD_TYPE_SORTING_ORDER);
};

const sortAsFrequency: SortingFn<CatalogItem> = (
  rowA: Row<CatalogItem>,
  rowB: Row<CatalogItem>,
  columnId: string
) => {
  return sortByOrder(rowA, rowB, columnId, FREQUENCY_SORTING_ORDER);
};

export const arrEqualsAll: FilterFn<CatalogItem> = (
  row: Row<CatalogItem>,
  columnId: string,
  filterValue: string[]
) => {
  return filterValue.every(selectedFilterValue => {
    const value = row.getValue(columnId) as string;
    return value?.toLocaleLowerCase() === selectedFilterValue.toLocaleLowerCase();
  });
};
export const filterByStatus: FilterFn<CatalogItem> = (
  row: Row<CatalogItem>,
  columnId: string,
  filterValue: string[]
) => {
  const selectedFilterValue = filterValue[0];
  if (!selectedFilterValue) {
    const value = row.getValue(columnId) as string;
    return value !== 'Archive';
  }
  return true;
};
export const spotColumns = [
  { accessorKey: 'Symbol', header: '', size: 20, enableGlobalFilter: false },
  {
    accessorKey: 'Status',
    header: '',
    size: 0,
    filterFn: filterByStatus,
    enableGlobalFilter: false
  },
  { accessorKey: 'Code', header: 'Code', size: 100 },
  {
    accessorKey: 'TimeRef',
    header: 'TimeRef',
    size: 95,
    sortingFn: sortAsNumber,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'PeriodType',
    header: 'PeriodType',
    size: 120,
    sortingFn: sortAsPeriodType,
    enableGlobalFilter: false
  },
  { accessorKey: 'Period', header: 'Period', size: 100, enableGlobalFilter: false },
  { accessorKey: 'Alias', header: 'Alias' },
  { accessorKey: 'Title', header: 'Full Title', size: 650 },
  { accessorKey: 'LastDate', header: 'Last Date', size: 100, enableGlobalFilter: false },
  {
    accessorKey: 'Last',
    header: 'Last',
    size: 100,
    sortingFn: sortAsNumber,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'Change',
    header: 'Change',
    size: 100,
    sortingFn: sortAsNumber,
    enableGlobalFilter: false
  },
  { accessorKey: 'Currency', header: 'Currency', size: 100, enableGlobalFilter: false },
  { accessorKey: 'Units', header: 'Units', size: 80, enableGlobalFilter: false },
  { accessorKey: 'Commodity', header: 'Commodity', size: 130, enableGlobalFilter: false },
  { accessorKey: 'TradingHub', header: 'Trading Hub', size: 120, enableGlobalFilter: false },
  { accessorKey: 'DeliveryBasis', header: 'Delivery Basis', size: 150, enableGlobalFilter: false },
  { accessorKey: 'PricingBasis', header: 'Pricing Basis', size: 150, enableGlobalFilter: false },
  {
    accessorKey: 'Frequency',
    header: 'Frequency',
    size: 120,
    sortingFn: sortAsFrequency,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'IndexModule',
    header: 'Index Module',
    size: 200,
    filterFn: filterFns.arrIncludesAll,
    enableGlobalFilter: false
  },
  { accessorKey: 'StartDate', header: 'Start Date', size: 120, enableGlobalFilter: false },
  { accessorKey: 'EndDate', header: 'End Date', size: 120, enableGlobalFilter: false },
  {
    accessorKey: 'FactsheetVersion',
    header: 'Latest Factsheet',
    size: 170,
    enableGlobalFilter: false
  },
  { accessorKey: 'TimeZone', header: 'Timezone', size: 140, enableGlobalFilter: false },
  {
    accessorKey: 'HolidayCalendar',
    header: 'Holiday Calendar',
    size: 220,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'LastTradeDateCalendar',
    header: 'Last Trade Date Calendar',
    size: 220,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'Increment',
    header: 'Increment',
    size: 110,
    sortingFn: sortAsNumber,
    enableGlobalFilter: false
  },
  { accessorKey: 'Index', header: 'Columns Available', size: 170, enableGlobalFilter: false },
  {
    accessorKey: 'Factsheet',
    header: 'Factsheet',
    size: 100,
    enableGlobalFilter: false
  }
];

export const forwardCurvesColumns = [
  { accessorKey: 'Symbol', header: '', size: 20, enableGlobalFilter: false },
  {
    accessorKey: 'Status',
    header: '',
    size: 0,
    filterFn: filterByStatus,
    enableGlobalFilter: false
  },
  { accessorKey: 'Code', header: 'Code', size: 100 },
  {
    accessorKey: 'TimeRef',
    header: 'TimeRef',
    size: 95,
    sortingFn: sortAsNumber,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'PeriodType',
    header: 'PeriodType',
    size: 120,
    sortingFn: sortAsPeriodType,
    enableGlobalFilter: false
  },
  { accessorKey: 'Periods', header: 'NumPeriods', size: 120, enableGlobalFilter: false },
  { accessorKey: 'Title', header: 'Full Title', size: 550 },
  { accessorKey: 'Currency', header: 'Currency', size: 100, enableGlobalFilter: false },
  { accessorKey: 'Units', header: 'Units', size: 100, enableGlobalFilter: false },
  { accessorKey: 'Commodity', header: 'Commodity', enableGlobalFilter: false },
  { accessorKey: 'TradingHub', header: 'Trading Hub', enableGlobalFilter: false },
  { accessorKey: 'DeliveryBasis', header: 'Delivery Basis', size: 150, enableGlobalFilter: false },
  { accessorKey: 'PricingBasis', header: 'Pricing Basis', size: 150, enableGlobalFilter: false },
  { accessorKey: 'Frequency', header: 'Frequency', size: 120, enableGlobalFilter: false },
  {
    accessorKey: 'IndexModule',
    header: 'Index Module',
    size: 200,
    filterFn: filterFns.arrIncludesAll,
    enableGlobalFilter: false
  },
  { accessorKey: 'StartDate', header: 'StartDate', size: 120, enableGlobalFilter: false },
  { accessorKey: 'EndDate', header: 'EndDate', size: 120, enableGlobalFilter: false },
  {
    accessorKey: 'FactsheetVersion',
    header: 'Latest Factsheet',
    size: 170,
    enableGlobalFilter: false
  },
  { accessorKey: 'TimeZone', header: 'Timezone', size: 140, enableGlobalFilter: false },
  {
    accessorKey: 'HolidayCalendar',
    header: 'Holiday Calendar',
    size: 220,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'LastTradeDateCalendar',
    header: 'Last Trade Date Calendar',
    size: 220,
    enableGlobalFilter: false
  },
  { accessorKey: 'Increment', header: 'Increment', size: 110, enableGlobalFilter: false },
  {
    accessorKey: 'Index',
    header: 'Columns Available',
    size: 170,
    sortingFn: sortAsNumber,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'Factsheet',
    header: 'Factsheet',
    size: 100,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'PeriodMin',
    size: 0,
    enableGlobalFilter: false
  },
  {
    accessorKey: 'PeriodMax',
    size: 0,
    enableGlobalFilter: false
  }
];

export const defaultVisibilityState = {
  Status: false,
  TimeRef: false,
  PeriodType: false,
  Period: false,
  StartDate: false,
  EndDate: false,
  FactsheetVersion: false,
  TimeZone: false,
  HolidayCalendar: false,
  LastTradeDateCalendar: false,
  Increment: false,
  Index: false,
  PricingBasis: false,
  DeliveryBasis: false,
  Frequency: false,
  IndexModule: false,
  PeriodMax: false,
  PeriodMin: false
};

export const displayedSettingsColumnsList = [
  {
    label: 'Default columns',
    columns: [
      {
        displayLabel: 'Currency',
        accessorKey: 'Currency'
      },
      {
        displayLabel: 'Units',
        accessorKey: 'Units'
      },
      {
        displayLabel: 'Commodity',
        accessorKey: 'Commodity'
      },
      {
        displayLabel: 'Trading Hub',
        accessorKey: 'TradingHub'
      }
    ]
  },
  {
    label: 'Additional Filters',
    columns: [
      {
        displayLabel: 'Delivery Basis',
        accessorKey: 'DeliveryBasis'
      },
      {
        displayLabel: 'Pricing Basis',
        accessorKey: 'PricingBasis'
      },
      {
        displayLabel: 'Frequency',
        accessorKey: 'Frequency'
      },
      {
        displayLabel: 'Index Module',
        accessorKey: 'IndexModule'
      }
    ]
  },
  {
    label: 'Additional columns',
    columns: [
      {
        displayLabel: 'Start Date',
        accessorKey: 'StartDate'
      },
      {
        displayLabel: 'End Date',
        accessorKey: 'EndDate'
      },
      {
        displayLabel: 'Latest Factsheet',
        accessorKey: 'FactsheetVersion'
      },
      {
        displayLabel: 'Timezone',
        accessorKey: 'TimeZone'
      },
      {
        displayLabel: 'Holiday Calendar',
        accessorKey: 'HolidayCalendar'
      },
      {
        displayLabel: 'Last Trade Date Calendar',
        accessorKey: 'LastTradeDateCalendar'
      },
      {
        displayLabel: 'Increment',
        accessorKey: 'Increment'
      },
      {
        displayLabel: 'Columns Available',
        accessorKey: 'Index'
      }
    ]
  }
];

export const alphabeticallySortedFilters = ['IndexModule'];

export const allColumnIdsToChangeVisibility = [
  'Units',
  'Currency',
  'Commodity',
  'TradingHub',
  'DeliveryBasis',
  'PricingBasis',
  'FactsheetVersion',
  'Frequency',
  'StartDate',
  'EndDate',
  'IndexModule',
  'TimeZone',
  'HolidayCalendar',
  'LastTradeDateCalendar',
  'Increment',
  'Index'
];
