import { useNavigate } from 'react-router-dom';
import { ButtonBase, Typography } from '@mui/material';

const TokenLink = () => {
  const navigate = useNavigate();
  return (
    <Typography sx={{ ml: 1.5 }}>
      Don’t have one yet? Create a new token quickly and easily{' '}
      <ButtonBase
        onClick={() => navigate('/profile')}
        sx={{ color: 'primary.main', textDecoration: 'none', fontSize: 16, mb: 0.5 }}
      >
        here.
      </ButtonBase>
    </Typography>
  );
};

export default TokenLink;
