import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, ButtonBase, styled, Typography } from '@mui/material';
import { ContentCopyOutlined } from '@mui/icons-material';
import ConfirmDialog from './ConfirmDialog';
import useApiKeyTable from './useApiKeyTable';
import { ApiKeyOutput } from 'slices/apiKeyListSlice';
import DateText from 'components/DateText';
import { DateTextVariants } from 'components/DateText/useDateText';
import useCopyToClipboard from 'utils/useCopyToClipboard';

const StyledTableCell = styled(TableCell)(() => ({
  paddingRight: 30,
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '50px',
  paddingRight: 1,
  borderBottom: `0.5px solid ${theme.palette.info.light}`,
  '&:last-child tr': { border: 0, borderBottom: 0 }
}));

const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
  height: '50px',
  borderBottom: `1px solid ${theme.palette.text.primary}`
}));

const emptyMessage = (
  <Box sx={{ display: 'flex', width: '100%' }}>
    <Typography
      align='center'
      sx={{ width: '100%', my: 5 }}
    >
      No token generated yet.
    </Typography>
  </Box>
);

const ApiKeyTable = ({ apiKeys }: { apiKeys: ApiKeyOutput[] }) => {
  const { open, setOpen, openConfirmModal, deleteKey } = useApiKeyTable();
  const { copyToClipboard } = useCopyToClipboard();
  return (
    <Box
      sx={() => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      })}
    >
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        deleteCallback={deleteKey}
      />
      {apiKeys.length ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 600 }}
            size='small'
            aria-label='api keys table'
          >
            <TableHead>
              <StyledHeaderRow>
                <StyledTableCell>
                  <Typography variant='subtitle2'>Name</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant='subtitle2'>Access Token</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant='subtitle2'>Creation Date</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant='subtitle2'>Last Used</Typography>
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledHeaderRow>
            </TableHead>
            <TableBody>
              {apiKeys.map(apiKey => (
                <StyledTableRow
                  key={apiKey.label}
                  sx={{ '&:last-child': { borderBottom: 0 } }}
                >
                  <StyledTableCell
                    sx={{ width: '25%' }}
                    component='th'
                    scope='row'
                  >
                    <Typography sx={{ maxWidth: '200px', overflow: 'hidden' }}>
                      {apiKey.label}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '15%' }}>
                    {apiKey.prefixedRawApiKey && (
                      <Box
                        sx={{
                          display: 'flex',
                          maxWidth: '200px',
                          overflowX: 'auto',
                          overflowY: 'hidden'
                        }}
                      >
                        <Typography fontSize={14}>
                          {apiKey.prefixedRawApiKey.substring(0, 9) + '**********'}
                        </Typography>
                        <ButtonBase
                          onClick={() =>
                            copyToClipboard(
                              apiKey.prefixedRawApiKey || '',
                              'Api key copied to clipboard!'
                            )
                          }
                        >
                          <ContentCopyOutlined sx={{ mx: 0.5, fontSize: 14 }} />
                          <Typography fontSize={14}>Copy</Typography>
                        </ButtonBase>
                      </Box>
                    )}
                    {!apiKey.prefixedRawApiKey && (
                      <Typography
                        sx={{ maxWidth: '200px', overflowX: 'auto', overflowY: 'hidden' }}
                      >
                        {'**********' + apiKey.lastThreeCharacters}
                      </Typography>
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '20%' }}>
                    <DateText
                      dateString={apiKey.creationDate}
                      variant={DateTextVariants.FULL}
                    />
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '30%' }}>
                    {' '}
                    <DateText
                      dateString={apiKey.lastUsed ?? ''}
                      variant={DateTextVariants.WITH_TIME}
                    />
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '15%' }}>
                    <Button
                      onClick={() => openConfirmModal(apiKey)}
                      sx={{ color: 'primary.main' }}
                    >
                      Remove
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        emptyMessage
      )}
    </Box>
  );
};

export default ApiKeyTable;
