import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { RootState } from '../store';
import httpInstances from 'api/axiosInstance';
import { detectEnvironment } from 'utils/commonFunctions';

type MarketsListState = {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  error: string | null;
  marketsList: MarketsListItem[];
};

type ErrorResponse = {
  message: string;
};

const initialState: MarketsListState = {
  status: 'idle',
  error: null,
  marketsList: []
};

export interface MarketsListItem {
  marketId: string;
  metadata: {
    displayName: string;
    commodity: string;
    region: string;
  };
}

interface MarketsListDto {
  insightId: string;
  metadata: {
    displayName: string;
    commodity: string;
    region: string;
  };
}
export const getMarketsList = createAsyncThunk('getMarketsList', async (_, { rejectWithValue }) => {
  try {
    const { data } = await httpInstances.axiosInternalInstance.get(
      `${detectEnvironment() === 'staging' ? '/staging/' : '/'}insights`
    );

    // change the marketId to lowercase
    return data.map((marketsListItem: MarketsListDto) => {
      return { ...marketsListItem, marketId: marketsListItem.insightId.toLowerCase() };
    });
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const errorResponse = error.response.data;
      return rejectWithValue(errorResponse);
    }
    throw error;
  }
});

const marketsListSlice = createSlice({
  name: 'marketsList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMarketsList.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getMarketsList.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.marketsList = action.payload;
      })
      .addCase(getMarketsList.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message ?? 'Request failed';
        } else {
          state.error = action.error.message ?? 'Request failed';
        }
      });
  }
});

export const selectMarketsListStatus = (state: RootState) => state.marketsList.status;
export default marketsListSlice.reducer;
