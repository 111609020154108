import Stack from '@mui/material/Stack';
import CommoditiesTable from './CommoditiesTable';
import Tiles from './Tiles';
import ChartsBlock from './ChartsBlock';
import { ChartsBlockTabType, CommoditiesTableTabType } from './types';
import { generateUUID } from 'utils/commonFunctions';
import { MarketsComponentType } from 'slices/marketsSlice';

const MarketsComponents = ({
  components,
  marketTitle,
  pageTitle
}: {
  components: MarketsComponentType[];
  marketTitle: string;
  pageTitle: string;
}) => (
  <Stack
    sx={{ width: '100%', mt: 3 }}
    spacing={{ xs: 2, sm: 3 }}
    direction='row'
    useFlexGap
    flexWrap='wrap'
  >
    {!!components.length &&
      components.map(({ type, tabs, tiles, layout }) => {
        if (type === 'tiles' && tiles?.length) {
          return (
            <Tiles
              key={generateUUID()}
              tiles={tiles}
            />
          );
        }
        if (type === 'table' && tabs?.length) {
          return (
            <CommoditiesTable
              marketTitle={marketTitle}
              pageTitle={pageTitle}
              key={generateUUID()}
              tabs={tabs as CommoditiesTableTabType[]}
              layout={layout}
            />
          );
        }
        if (type === 'graph' && tabs?.length) {
          return (
            <ChartsBlock
              layout={layout}
              key={generateUUID()}
              marketTitle={marketTitle}
              pageTitle={pageTitle}
              tabs={tabs as ChartsBlockTabType[]}
            />
          );
        }
      })}
  </Stack>
);

export default MarketsComponents;
