import { Box, Button, Paper, Typography } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import useDataHubStats from './useDataHubStats';
import Loader from 'components/Loader';
import { RootState } from 'store';
import Dialog from 'components/Dialog';

const DataHubStats = () => {
  const { loading } = useSelector((state: RootState) => state.dhData);
  const { status } = useSelector((state: RootState) => state.dataHub);
  const { stats, deleteGroupFromDataHub } = useDataHubStats();
  const [groupToDelete, setGroupToDelete] = useState({ name: '', label: '' });
  const isLoading = status === 'loading' || loading;

  return (
    <>
      <Paper
        sx={{
          py: 1,
          px: 2,
          mb: 2,
          fontSize: 12,
          height: 141,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Box sx={{ display: 'flex', height: 117, overflow: 'auto' }}>
            {stats.map(({ label, values }) => (
              <Box
                sx={{
                  display: 'flex',
                  width: '33%',
                  p: 0.7,
                  pl: 0,
                  '&: last-child': { pl: 0.7 }
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    key={label}
                    sx={{
                      fontSize: 14,
                      fontWeight: 700,
                      mb: 0.5
                    }}
                  >
                    {label}:{' '}
                  </Typography>
                  {values.map(({ name, count }) => (
                    <Box
                      sx={{ display: 'flex', mb: 0.5 }}
                      key={name}
                    >
                      <Typography sx={{ fontSize: 12 }}>
                        {name} ({count})
                      </Typography>
                      <Button
                        variant='outlined'
                        sx={{
                          p: 0,
                          backgroundColor: '#f8f8f833',
                          border: 'none',
                          fontSize: 9,
                          ml: 1,
                          height: 16
                        }}
                        onClick={() =>
                          setGroupToDelete({
                            name,
                            label: label.replace(/\s+/g, '')
                          })
                        }
                      >
                        <DeleteOutlined sx={{ fontSize: '4px', m: '0!important' }} />
                        Remove
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Paper>
      <Dialog
        text={
          <>
            Remove items?
            <br />
            <br />
            Selected items will be removed from the Data Hub
          </>
        }
        hasCancelButton
        open={!!groupToDelete.name.length}
        handleClose={() => setGroupToDelete({ name: '', label: '' })}
        handleAction={() => {
          deleteGroupFromDataHub(
            groupToDelete.name,
            groupToDelete.label as 'TradingHub' | 'PricingBasis' | 'Commodity'
          );
          setGroupToDelete({ name: '', label: '' });
        }}
        actionButtonText='Remove'
      />
    </>
  );
};

export default DataHubStats;
