import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import httpInstances from 'api/axiosInstance';
import useCodePreview from 'pages/DataHub/Export/ExportPreview/useCodePreview';

const useJsonCsvPreview = (
  exportType: string,
  includeMetadata: boolean,
  isCSV: boolean,
  capturePosthogEvent: () => void,
  from?: Date | null,
  to?: Date | null,
  fromPeriodType?: string,
  fromPeriod?: string
) => {
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const dataHubSymbols = dataHubs.find(dataHub => dataHub.id === activeDataHubId)?.symbols || [];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [fileContent, setFileContent] = useState<string>('');
  const { userConfig } = useSelector((state: RootState) => state.userConfig);
  const userTimezone = userConfig.timezone.value;

  const { createRequestToValue, createRequestFromValue, downloadFileContent } = useCodePreview(
    exportType,
    capturePosthogEvent,
    from,
    to,
    fromPeriodType,
    fromPeriod
  );
  const downloadFile = () => downloadFileContent(fileContent, isCSV ? 'csv' : 'json');

  useEffect(() => {
    getDataHubIndexes();
  }, [dataHubSymbols, from, to, includeMetadata, fromPeriodType, fromPeriod, exportType, isCSV]);

  const getDataHubIndexes = async () => {
    setLoading(true);
    setError(false);

    try {
      const headers = isCSV ? { Accept: 'text/csv' } : undefined;

      const requests = dataHubSymbols.map(({ Code, Period, PeriodType, TimeRef }) => {
        if (!Code) return null;
        const toValue = createRequestToValue();

        const fromValue = createRequestFromValue();

        return httpInstances.axiosDataInstance.get(
          `/index?code=${Code}&period=${Period}&periodType=${PeriodType}&timeRef=${TimeRef}&from=${fromValue}&timezone=${userTimezone}${
            toValue ? `&to=${toValue}` : ''
          }&metadata=${includeMetadata}`,
          { headers }
        );
      });

      const results = await Promise.all(requests.filter(Boolean));

      if (isCSV) {
        const csvData = combineCsvData(results.map(response => response?.data));
        setFileContent(csvData);
      } else {
        const flattenedData = results.flatMap(response => response?.data);
        setFileContent(JSON.stringify(flattenedData, null, 2));
      }
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const combineCsvData = (responses: string[]): string => {
    if (!responses || responses.length === 0) return '';

    const [firstResponse, ...otherResponses] = responses;
    const [header, ...rows] = firstResponse.split('\n').filter(line => line.trim() !== '');
    const combinedRows = [...rows];

    for (const response of otherResponses) {
      const [, ...dataRows] = response.split('\n');
      combinedRows.push(...dataRows.filter(row => row.trim() !== ''));
    }

    return [header, ...combinedRows].join('\n');
  };

  return { downloadFile, loading, error, fileContent };
};

export default useJsonCsvPreview;
