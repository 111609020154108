import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import posthogLib from 'posthog-js';
import { RootState } from '../../../../store';
import { exportOptionsGroups } from 'pages/DataHub/Export/exportList';
import useExportSettings, {
  ExportSettingsType
} from 'pages/DataHub/Export/ExportSettings/useExportSettings';
import useTokenGeneration from 'pages/Profile/TokenGeneration/useTokenGeneration';
import useCopyToClipboard from 'utils/useCopyToClipboard';

const useExportPreview = (
  previewOptionId: string,
  exportData: (type: string) => Promise<void>,
  exportSettings: ExportSettingsType
) => {
  const { apiKeys } = useTokenGeneration();
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const activeDataHub = dataHubs.find(dataHub => dataHub.id === activeDataHubId);
  const { userConfig } = useSelector((state: RootState) => state.userConfig);
  const userTimezone = userConfig.timezone.value;
  const gxURL = import.meta.env.VITE_GX_API_ENDPOINT;
  const { getFromToValues } = useExportSettings();
  const { fromValue, toValue } = getFromToValues(exportSettings);
  const urlToParam = toValue ? '&to=' + toValue : '';
  const uniqueSymbolCodes =
    [...new Set(activeDataHub?.symbols.map(symbol => symbol.Code))].join(',') || '';
  const sortedKeys = [...apiKeys]?.sort(
    (a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()
  );
  const apiKey = sortedKeys?.slice(-1)[0]?.prefixedRawApiKey ?? '<YourApiKeyHere>';
  const exportApiRequestString = `${gxURL}/index?code=${uniqueSymbolCodes}&from=${fromValue}${urlToParam}&metadata=${exportSettings.includeMetadata}&timezone=${userTimezone}&token=${apiKey}`;
  const exportCurlRequestString = `curl --location "${gxURL}/index?code=${uniqueSymbolCodes}&from=${fromValue}${urlToParam}&timezone=${userTimezone}&metadata=${exportSettings.includeMetadata}" --header "Accept: application/json" --header "Authorization: ApiKey ${apiKey}"`;

  const sqlFromDate = () => {
    if (!fromValue || fromValue === 'all') {
      return null;
    }
    try {
      // if date ends with 'd' subtract days from now
      if (fromValue.endsWith('d') || fromValue.endsWith('m') || fromValue.endsWith('y')) {
        const date = new Date();

        if (fromValue.endsWith('d')) {
          date.setDate(date.getDate() - parseInt(fromValue.slice(0, -1), 10));
        }

        if (fromValue.endsWith('m')) {
          date.setMonth(date.getMonth() - parseInt(fromValue.slice(0, -1), 10));
        }

        if (fromValue.endsWith('y')) {
          date.setFullYear(date.getFullYear() - parseInt(fromValue.slice(0, -1), 10));
        }

        return format(toZonedTime(date, userTimezone), 'yyyy-MM-dd');
      }
      return fromValue.split('T')[0];
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const prepareSnowflakeSqlQuery = (toValue: string) => {
    const uniqueQuotedSymbolCodes =
      [...new Set(activeDataHub?.symbols.map(symbol => `'${symbol.Code}'`))].join(',') || '';

    const sqlFrom = sqlFromDate() ? ` and DATE >= '${sqlFromDate()}'` : '';
    const sqlTo = toValue ? ` and DATE <= '${toValue.split('T')[0]}'` : '';
    return `select *
                               from GENERAL_INDEX.PUBLIC.INDEXES_ALL
                               where CODE in (${uniqueQuotedSymbolCodes}) ${sqlFrom} ${sqlTo}
                               order by DATE desc`;
  };

  const snowflakeSqlQuery = prepareSnowflakeSqlQuery(toValue);
  const dataHubSymbols = dataHubs.find(dataHub => dataHub.id === activeDataHubId)?.symbols || [];
  const { exportType, includeMetadata, from, to, period, periodNumber } = exportSettings;

  const findOptionById = () => {
    for (const group of exportOptionsGroups) {
      const foundOption = group.options.find(({ id }) => id === previewOptionId);
      if (foundOption) {
        return { ...foundOption, groupName: group.groupName };
      }
    }
  };

  const exportOption: any = findOptionById() || {};
  const { copyToClipboard } = useCopyToClipboard();

  const handleCopyToClipBoard = (textToCopy: string) => {
    capturePosthogEvent();
    copyToClipboard(textToCopy, 'Code snippet copied to clipboard!');
  };

  const handleExportData = () => {
    capturePosthogEvent();
    exportData(previewOptionId);
  };

  const capturePosthogEvent = () => {
    if ('Enable access' === exportOption?.buttonLabel) {
      posthogLib.capture('datahub_enable_access_button_click', {
        button: exportOption?.id
      });
      return;
    }
    const posthogData: any = {
      symbols: dataHubSymbols,
      exportName: exportOption.id,
      dateSettings: exportType,
      includeMetadata: includeMetadata
    };

    if (exportType === 'dates') {
      posthogData.from = from?.toLocaleString();
      posthogData.to = to?.toLocaleString();
    } else if (exportType === 'range') {
      posthogData.period = period;
      posthogData.periodNumber = periodNumber;
    }

    posthogLib.capture('datahub_export_button_click', posthogData);
  };

  const scrambleCurlApiKey = (content: string) => {
    if (content.includes('Authorization: ApiKey')) {
      const substrings = content.split('Authorization: ApiKey');
      return scrambleToken(substrings);
    }
    return content;
  };

  const scrambleApiToken = (content: string) => {
    if (content.includes('token=')) {
      const substrings = content.split('token=');
      return scrambleToken(substrings);
    }
    return content;
  };

  const scrambleToken = (tokenSubstrings: string[]) => {
    let scrambledToken: string;
    if (tokenSubstrings[1].indexOf('&') < 0) {
      scrambledToken = tokenSubstrings[1].substring(0, 9).padEnd(tokenSubstrings[1].length, '*');
    } else {
      const leftovers = tokenSubstrings[1].substring(tokenSubstrings.indexOf('&'));
      scrambledToken = leftovers[1].substring(0, 9).padEnd(leftovers[1].length, '*') + leftovers[1];
    }
    return tokenSubstrings[0] + scrambledToken;
  };

  return {
    capturePosthogEvent,
    exportOption,
    handleCopyToClipBoard,
    handleExportData,
    scrambleCurlApiKey,
    scrambleApiToken,
    snowflakeSqlQuery,
    exportApiRequestString,
    exportCurlRequestString
  };
};
export default useExportPreview;
