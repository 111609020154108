import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux-hooks';
import { loginTrip } from 'slices/authSlice';
import { RootState } from 'store';
import { getGxTokenFromStorage } from 'utils/commonFunctions';
import { getMarketsList } from 'slices/marketsListSlice';
import { getUserConfig } from 'slices/userConfigSlice';
import { getDataHubs } from 'slices/dataHubSlice';

const ProtectedLayout = () => {
  const gxToken = getGxTokenFromStorage();
  const dispatch = useAppDispatch();
  const { tripToken } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!tripToken && gxToken) {
      dispatch(loginTrip(gxToken));
    }
  }, [gxToken, tripToken]);

  useEffect(() => {
    if (tripToken) {
      dispatch(getMarketsList());
      dispatch(getUserConfig());
      dispatch(getDataHubs());
    }
  }, [tripToken]);

  if (!gxToken) {
    return (
      <Navigate
        replace
        to='/login'
      />
    );
  }
  return <Outlet />;
};

export default ProtectedLayout;
