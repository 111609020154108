import Highcharts from 'highcharts/highstock';
import { useState } from 'react';
import { ButtonBase } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const VisibilityToggleButton = ({ chart, index }: { chart: Highcharts.Chart; index: number }) => {
  const [isVisible, setIsVisible] = useState(true);
  const handleChange = () => {
    chart.series[index].setVisible(!isVisible);
    setIsVisible(!isVisible);
  };
  return (
    <ButtonBase onClick={handleChange}>
      {isVisible ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
    </ButtonBase>
  );
};
export default VisibilityToggleButton;
