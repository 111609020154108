import { useTheme } from '@mui/material';
import { Series, SeriesClickEventObject } from 'highcharts';
import { useSelector } from 'react-redux';
import createRangeSelector from '../useRangeSelector';
import useChartFunctions, { ChartParentComponent, getBaseImageUrl } from '../useChartFunctions';
import useTooltip from '../useTooltip';
import { ChartSeries } from '../useChartSeries';
import { RootState } from '../../../store';
import { DEFAULT_CHART_HEIGHT, fullScreenChartPalette, stockChartPalette } from 'utils/constants';
import useUserDetails from 'hooks/useUserAccess';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';

const CHART_GRID_COLOR = '#333';

const useSeriesClick = () => {
  const { addSymbolToSelection, isSymbolSelected, removeSymbolFromSelection } =
    useSelectionFunctions();

  const onSeriesClick = (event: SeriesClickEventObject) => {
    // @ts-expect-error: highcharts rules.
    const metadata: SymbolKey = event.point.series.userOptions.metadata;
    const symbol: SelectedSymbol = {
      Code: metadata.Code,
      Period: metadata.Period,
      PeriodType: metadata.PeriodType,
      TimeRef: metadata.TimeRef
    };
    if (isSymbolSelected(symbol)) {
      removeSymbolFromSelection(symbol);
    } else {
      addSymbolToSelection(symbol);
    }
  };
  return { onSeriesClick };
};

const useFullScreenOptions = ({
  height,
  isDataHub,
  isLightMode
}: {
  height?: number;
  isDataHub?: boolean;
  isLightMode: boolean;
}) => {
  const { checkUserIsBasic } = useUserDetails();
  const theme = useTheme();
  const { createTooltip } = useTooltip();
  const { userConfig } = useSelector((state: RootState) => state.userConfig);
  const {
    createNavigator,
    createPlotOptions,
    createYAxis,
    createXAxis,
    captureChartDownloadAction
  } = useChartFunctions();
  const { onSeriesClick } = useSeriesClick();

  const plotOptions = createPlotOptions();

  if (!isDataHub && plotOptions.series) {
    plotOptions.series.events = {
      click: onSeriesClick
    };
  }
  const createOptions = (chartSeries: ChartSeries[], chartParent: ChartParentComponent) => {
    const getWatermarkAttributes = () =>
      checkUserIsBasic()
        ? {
            x: window.innerWidth < 1025 ? 'calc(50% - 285px)' : 'calc(50% - 425px)',
            y: window.innerHeight < 750 ? 'calc(50% - 75px)' : 'calc(50% - 125px)',
            width: window.innerWidth < 1025 ? 190 * 3 : '950px',
            height: window.innerHeight < 750 ? 50 * 3 : '250px',
            zIndex: 1
          }
        : {
            x: 'calc(100% - 250px)',
            y: 'calc(100% - 220px)',
            width: '190px',
            height: '50px',
            zIndex: 1
          };
    chartSeries = chartSeries.map(series => {
      const palette = isLightMode ? fullScreenChartPalette : stockChartPalette;
      series.color =
        series.metadata.combinedIndex > 5
          ? '#A1A6AB'
          : palette[series.metadata.combinedIndex % palette.length];
      return series;
    });

    return {
      time: { timezone: userConfig?.timezone.value },
      chart: {
        animation: false,
        events: {
          load: function () {
            const chart: any = this;
            chart.watermarkImage = chart.renderer
              .image(
                getBaseImageUrl() + (isLightMode ? '/watermark-color.svg' : '/watermark-black.svg')
              )
              .attr(getWatermarkAttributes())
              .add();
          },
          redraw: function () {
            const chart: any = this;
            const imageUrl =
              getBaseImageUrl() + (isLightMode ? '/watermark-color.svg' : '/watermark-black.svg');

            if (chart.watermarkImage) {
              chart.watermarkImage.attr({
                href: imageUrl,
                ...getWatermarkAttributes()
              });
            }
          }
        },
        type: 'line',
        backgroundColor: isLightMode ? theme.palette.text.primary : theme.palette.background.paper,
        height: height ?? DEFAULT_CHART_HEIGHT,
        style: {
          fontFamily: 'Cairo, sans-serif'
        },
        zooming: {
          type: 'x'
        }
      },
      lang: {
        contextButtonTitle: 'Export chart'
      },
      exporting: {
        sourceWidth: 1000, // Use the chart's displayed width
        sourceHeight: 420,
        scale: 1,
        enabled: true,
        filename: 'GX-Chart',
        chartOptions: {
          chart: {
            events: {
              load: function () {
                const chart: any = this;
                chart.series.forEach((series: Series) => {
                  if (!series.visible) {
                    // @ts-expect-error: highcharts rules.
                    series.update({ showInLegend: false });
                  }
                });
              }
            }
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: '0.8em',
              textOverflow: 'ellipsis'
            }
          },
          navigator: {
            enabled: false
          },
          rangeSelector: { enabled: false },
          scrollbar: {
            enabled: false
          }
        },
        menuItemDefinitions: {
          // Define custom menu items
          downloadPNG: {
            textKey: 'downloadPNG',
            onclick: function () {
              captureChartDownloadAction(chartSeries, {
                format: 'PNG',
                fullScreen: true,
                darkMode: false,
                chartLocation: chartParent
              });
              // @ts-expect-error: highcharts rules.
              this.exportChart({ type: 'image/png' });
            }
          },
          downloadJPEG: {
            textKey: 'downloadJPEG',
            onclick: function () {
              captureChartDownloadAction(chartSeries, {
                format: 'JPEG',
                fullScreen: true,
                darkMode: false,
                chartLocation: chartParent
              });
              // @ts-expect-error: highcharts rules.
              this.exportChart({ type: 'image/jpeg' });
            }
          },
          downloadPDF: {
            textKey: 'downloadPDF',
            onclick: function () {
              captureChartDownloadAction(chartSeries, {
                format: 'PDF',
                fullScreen: true,
                darkMode: false,
                chartLocation: chartParent
              });
              // @ts-expect-error: highcharts rules.
              this.exportChart({ type: 'application/pdf' });
            }
          },
          downloadSVG: {
            textKey: 'downloadSVG',
            onclick: function () {
              captureChartDownloadAction(chartSeries, {
                format: 'SVG',
                fullScreen: true,
                darkMode: false,
                chartLocation: chartParent
              });
              // @ts-expect-error: highcharts rules.
              this.exportChart({ type: 'image/svg+xml' });
            }
          }
        },
        buttons: {
          contextButton: {
            symbol:
              "url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.196 19.021 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.021 19.8043 18.55 20 18 20H6ZM12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16Z' fill='%23f8f8f8'/%3E%3C/svg%3E)",
            symbolSize: 20,
            menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
          }
        }
      },
      stockTools: {
        gui: {
          buttons: [
            'indicators',
            'simpleShapes',
            'lines',
            'crookedLines',
            'measure',
            'advanced',
            'toggleAnnotations',
            'verticalLabels',
            'flags',
            'zoomChange',
            'currentPriceIndicator'
          ]
        }
      },
      plotHeight: (height ?? DEFAULT_CHART_HEIGHT) - 30,
      xAxis: createXAxis(isLightMode ? theme.palette.background.paper : theme.palette.text.primary),
      yAxis: createYAxis(isLightMode ? theme.palette.background.paper : theme.palette.text.primary),
      tooltip: {
        positioner: function (): any {
          return { x: 100, y: 80 };
        },
        formatter: function (): string {
          // @ts-expect-error: highcharts rules.
          const allChartSeries: Highcharts.Series[] = this.series.chart.series;
          // @ts-expect-error: highcharts rules.
          const hoveredPointXValue = this.x;
          return createTooltip(allChartSeries, hoveredPointXValue);
        },
        className: 'tooltip-fullscreen-chart',
        enabled: true,
        split: false,
        followPointer: true,
        backgroundColor: CHART_GRID_COLOR,
        borderRadius: 0,
        useHTML: true,
        shape: 'rect',
        style: {
          zIndex: 1303,
          color: theme.palette.text.primary,
          fontFamily: 'Cairo, sans-serif'
        }
      },
      plotOptions,
      series: chartSeries,
      legend: { enabled: false },
      navigator: createNavigator(theme),
      rangeSelector: createRangeSelector(theme, chartSeries),
      credits: {
        enabled: false
      }
    };
  };

  return { createOptions };
};

export default useFullScreenOptions;
