import { ReactElement, useEffect } from 'react';
import posthogLib from 'posthog-js';
import useUserDetails from 'hooks/useUserAccess';
import PostHogPageView from 'components/Tracking/PostHog/pageview';

const posthogKey = import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY;
const posthogHost = import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST;

export const initializePostHog = () => {
  console.log('initializing postHog');
  if (posthogKey && posthogHost) {
    posthogLib.init(posthogKey, {
      api_host: posthogHost,
      capture_pageview: false
    });
  }
};

export const identifyUserInPostHog = (
  email: string,
  hubSpotUserId: string | null,
  userAccessLevel: string | null
) => {
  if (posthogLib && email) {
    posthogLib.identify(email, {
      hubSpotUserId: hubSpotUserId,
      email: email,
      userAccessLevel: userAccessLevel
    });
  }
};

export function PostHogTrackingLister({ children }: { readonly children: ReactElement }) {
  const { getUserEmail, getUserAccess, getHsObjectId } = useUserDetails();
  const email = getUserEmail();
  const userAccessLevel = getUserAccess();
  const hubSpotUserId = getHsObjectId();
  useEffect(() => {
    if (posthogLib && email) {
      initializePostHog();
      identifyUserInPostHog(email, hubSpotUserId, userAccessLevel);
    }
  }, [posthogLib, email]);

  return (
    <>
      {children}
      <PostHogPageView />
    </>
  );
}
